import moment from "moment";
import { useEffect } from "react";
import i18n from "../../../../../../../../i18n";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import { CBStatisticWaltWale } from "../../../interfaces/CBStatisticQueries";
import KPISingleValueCard from "../SingleKPIValue";

interface Props {
  objectIds: string[];
  referenceId?: string;
}
export const CBVacancyWALT = (props: Props) => {
  const queryData = useStatisticQuery<CBStatisticWaltWale>(
    "WEIGHTED_AVERAGE_LEASE",
    1,
    {
      date: moment().startOf("day").toISOString(),
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });
  return (
    <KPISingleValueCard
      name={"WALT"}
      value={queryData.data?.WALT?.toString()}
      loading={queryData.loading}
      error={queryData.error}
      onReload={queryData.reload}
    />
  );
};

export const CBVacancyWALE = (props: Props) => {
  const queryData = useStatisticQuery<CBStatisticWaltWale>(
    "WEIGHTED_AVERAGE_LEASE",
    1,
    {
      date: moment().startOf("day").toISOString(),
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });
  return (
    <KPISingleValueCard
      name={i18n.t("cb:Vacancy.wale", "WALE")} //"WALE"}
      value={queryData.data?.WALE?.toString()}
      loading={queryData.loading}
      error={queryData.error}
      onReload={queryData.reload}
    />
  );
};
