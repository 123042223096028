import { CBRegion } from "@/apps/tatar/cashBudget/views/portfolio/common/CBCommonComponents";
import { CBPortfolioObjectAddress } from "@/apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import CBRentalObjectChangePlandata from "@/apps/tatar/cashBudget/views/tenants/components/rental-object/CBRentalObjectChangePlandata";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import LanguageService from "@/services/LanguageService";
import PermissionService from "@/services/PermissionService";
import { isDefined, when } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { OAObject } from "../../../types/object.interface";
import {
  ObjectKind,
  checkAnyFeature,
  checkFeature,
} from "../../../types/objectKind.interface";
import { AssetValueEntry } from "./AssetValueEntry";
import AssetValueForm from "./AssetValueForm";
import "./ObjectDetailData.scss";

interface ObjectDetailDataProps {
  asset: OAObject;
  kind: ObjectKind;
}
const ObjectDetailData = (props: ObjectDetailDataProps) => {
  const entity = OrgaStruct.getEntity(props.asset.data.entity);
  const bankAccount = props.asset.data.bankInfo.mainBankAccount
    ? OrgaStruct.getBankAccount(props.asset.data.bankInfo.mainBankAccount)
    : undefined;
  return (
    <div className={classNames(`object-detail-data`)}>
      {entity && bankAccount && (
        <div className={`info-row`}>
          {entity && <div className={`entity-name`}>{entity.displayName}</div>}
          {bankAccount && (
            <div className={`bank-account`}>
              <span>{bankAccount.displayName}</span>
              <span className={`iban`}>
                {StringUtils.formatIban(bankAccount.iban)}
              </span>
            </div>
          )}
        </div>
      )}
      <AddressFeature {...props} />
      <AssetValueFeature {...props} />
      <RentalIncomeFeature {...props} />
      <FieldsFeature {...props} />
    </div>
  );
};

export default ObjectDetailData;

const AddressFeature = (props: ObjectDetailDataProps) => {
  const featureActive = checkFeature("address", props.kind);

  const address = props.asset.data?.feature?.address?.address || [];

  if (!featureActive || address.length === 0) {
    return null;
  }
  return (
    <CBRegion
      title={
        address?.length === 1
          ? i18n.t("cb:Portfolio.Objekt.address", "Adresse")
          : i18n.t("cb:Portfolio.Objekt.addresses", "Adressen")
      }
    >
      {address
        .sort((a, b) => (a.isMain ? -1 : 1))
        .map((address, index) => (
          <AddressEntry key={index} address={address} />
        ))}
    </CBRegion>
  );
};

const FieldsFeature = (props: ObjectDetailDataProps) => {
  const featureActive =
    checkAnyFeature(["immo", "purchasePrice"], props.kind) ||
    (props.kind?.data?.customfields || []).length > 0;

  const address = props.asset.data?.feature?.address?.address || [];

  if (!featureActive || address.length === 0) {
    return null;
  }

  const fields: { label: string; value: string }[] = [];

  if (checkFeature("immo", props.kind)) {
    fields.push({
      label: i18n.t("cb:Portfolio.Objekt.area", "Fläche"),
      value: StringUtils.formatArea(props.asset.data?.feature?.immo?.area),
    });

    fields.push({
      label: i18n.t("cb:Portfolio.Objekt.constructionYear", "Baujahr"),
      value: props.asset.data?.feature?.immo?.constructionYear?.toString(),
    });
  }
  if (
    checkFeature("purchasePrice", props.kind) &&
    PermissionService.hasObjectKindPermission(props.kind, "purchasePrice.view")
  ) {
    fields.push({
      label: i18n.t("cb:Portfolio.Objekt.purchasePrice", "Kaufpreis"),
      value: StringUtils.formatCurrency(
        props.asset.data?.feature?.purchasePrice?.value.amount,
        undefined,
        undefined,
        props.asset.data?.feature?.purchasePrice?.value.currency
      ),
    });

    fields.push({
      label: i18n.t("cb:Portfolio.Objekt.purchaseDate", "Kaufdatum"),
      value: when(
        props.asset.data?.feature?.purchasePrice?.date,
        (value) => StringUtils.formatDate(value),
        "-"
      ),
    });
  }

  props.kind.data.customfields.forEach((cf) => {
    let value = "-";
    if (isDefined(props.asset.data.custom?.[cf.id])) {
      switch (cf.fieldType) {
        case "boolean":
          value = props.asset.data.custom?.[cf.id] ? "Ja" : "Nein";
          break;
        case "number":
          value = props.asset.data.custom?.[cf.id].toString();
          break;
        case "string":
          value = props.asset.data.custom?.[cf.id].toString();
          break;
        case "date":
          value = when(
            props.asset.data.custom?.[cf.id],
            (value) => StringUtils.formatDate(value),
            "-"
          );
          break;
        default:
          value = props.asset.data.custom?.[(cf as any).id].toString();
      }
    }
    fields.push({
      label: LanguageService.translateLabel(cf.displayName),
      value: value,
    });
  });

  return (
    <CBRegion title={i18n.t("cb:Portfolio.Objekt.fields", "Daten")}>
      <table className={`field-values`}>
        {fields.map((f) => (
          <tr>
            <td className={`field-label`}>{f.label}</td>
            <td className={`field-value`}>{f.value}</td>
          </tr>
        ))}
      </table>
    </CBRegion>
  );
};

const AssetValueFeature = (props: ObjectDetailDataProps) => {
  const featureActive =
    checkFeature("assetValue", props.kind) &&
    PermissionService.hasObjectKindPermission(props.kind, "assetValue.view");
  if (!featureActive) {
    return null;
  }
  const assetValue = props.asset.data.feature?.assetValue;

  return (
    <CBRegion
      className="asset-value-feature"
      title={i18n.t("cb:Portfolio.Objekt.currentValue", "Aktueller Wert")}
      titleButtons={
        PermissionService.hasObjectKindPermission(
          props.kind,
          "assetValue.createEdit"
        ) && (
          <BFButton
            onClick={() => {
              ModalManager.show({
                size: "xs",
                noPadding: true,
                content: (staate, setState, close) => (
                  <AssetValueForm onClose={close} asset={props.asset} />
                ),
              });
            }}
            appearance="link"
          >
            {i18n.t("Global.Buttons.add")}
          </BFButton>
        )
      }
    >
      <>
        {!assetValue.current && (
          <div className="no-value-added">
            {i18n.t("cb:Portfolio.Objekt.noValueSet")}
          </div>
        )}
        {assetValue.current && (
          <AssetValueEntry
            isCurrent
            valueEntry={assetValue.current}
            obj={props.asset}
            editable={PermissionService.hasObjectKindPermission(
              props.kind,
              "assetValue.createEdit"
            )}
          />
        )}

        {(assetValue.history || []).length > 0 && (
          <div className="entries">
            {assetValue.history
              .sort(
                (a, b) => Number(new Date(b.date)) - Number(new Date(a.date))
              )
              .map((valueEntry, index) => (
                <AssetValueEntry
                  key={index}
                  valueEntry={valueEntry}
                  obj={props.asset}
                  editable={PermissionService.hasObjectKindPermission(
                    props.kind,
                    "assetValue.createEdit"
                  )}
                />
              ))}
          </div>
        )}
      </>
    </CBRegion>
  );
};

const RentalIncomeFeature = (props: ObjectDetailDataProps) => {
  const featureActive =
    checkAnyFeature(["immo"], props.kind) ||
    (props.kind?.data?.customfields || []).length > 0;
  if (!featureActive) {
    return null;
  }
  return (
    <CBRegion
      className="rental-income-feature"
      title={i18n.t("cb:Portfolio.Objekt.rentalIncome", "Mieteinnahmen")}
      titleButtons={
        PermissionService.hasObjectKindPermission(
          props.kind,
          "rental.units.createEdit"
        ) && (
          <BFButton
            onClick={() => {
              ModalManager.show({
                backdrop: "static",
                size: "xxl",
                noPadding: true,
                content: (state, setState, onClose) => (
                  <CBRentalObjectChangePlandata
                    objectId={props.asset._id}
                    unitType={props.asset.data.type}
                    onClose={onClose}
                  />
                ),
              });
            }}
            appearance="link"
          >
            {i18n.t("cb:Portfolio.Object.changePlan", "Planmieten ändern")}
          </BFButton>
        )
        // PermissionService.hasBusinessUnitRole("cb_rentalChangePlanData") && (
        // )
      }
    >
      <div className={`entry-container`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:Portfolio.Objekt.rentalIncome", "Mieteinnahmen")}
          </div>
          <div className={`value`}>
            {when(
              props.asset.kpi?.NYKM,
              StringUtils.formatCurrency(props.asset.kpi?.NYKM),
              "-"
            )}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:Portfolio.Objekt.rentalIncomePlan",
              "Geplante Mieteinnahmen"
            )}
          </div>
          <div className={`value`}>
            {when(
              props.asset.kpi?.NYKM_SHOULD,
              StringUtils.formatCurrency(props.asset.kpi?.NYKM_SHOULD),
              "-"
            )}
          </div>
        </div>
      </div>
    </CBRegion>
  );
};

export const AddressEntry = (props: { address: CBPortfolioObjectAddress }) => (
  <div className="street">
    <div className="address-entry">
      {props.address?.streetname || ""} {props.address?.streetnumber || ""}
    </div>
    <div className="city">
      {props.address?.postalcode || ""} {props.address?.city || ""}
    </div>
  </div>
);
