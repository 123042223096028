import _ from "lodash";
import i18n from "../../i18n";
import { Contact, ContactType } from "../../model/db/Contact";

class ContactUtilsClass {
  getContactTypes: () => ContactType[] = () => [
    "EMPLOYEE",
    "INVOICE",
    "TENANT",
    "OTHER",
    "TRANSIENT",
  ];
  reduceContactTypes = (contactTypes: ContactType[]) => {
    return _.uniq(
      contactTypes.map((tag) => {
        if (tag === "TRANSIENT") return "OTHER";

        return tag;
      })
    );
  };
  getContactTypeName(contactType: ContactType) {
    switch (contactType) {
      case "EMPLOYEE":
        return i18n.t("Contact.Type.EMPLOYEE", "Mitarbeiter");
      case "INVOICE":
        return i18n.t("Contact.Type.INVOICE", "Rechnungsteller");
      case "TENANT":
        return i18n.t("Contact.Type.TENANT", "Mieter");
      case "BILL":
        return i18n.t("Contact.Type.BILL", "Kunde");
      case "OTHER":
        return i18n.t("Contact.Type.OTHER", "Weitere");
      case "TRANSIENT":
        return i18n.t("Contact.Type.TRANSIENT", "Vorläufig");
      default:
        return contactType;
    }
  }
  getInfoLine = (contact: Contact) => {
    let infoLines: string[] = [];

    if (contact.data.address?.length > 0) {
      const firstAddress = contact.data.address[0];
      if (firstAddress.zip && firstAddress.city) {
        infoLines.push(`${firstAddress.zip} ${firstAddress.city}`);
      } else if (firstAddress.city) {
        infoLines.push(firstAddress.city);
      }
      if (firstAddress.street) {
        infoLines.push(firstAddress.street);
      }
    }
    if (contact.data.emails?.length > 0) {
      infoLines.push(contact.data.emails[0]);
    }

    if (contact.data.note) {
      infoLines.push(contact.data.note);
    }
    return infoLines.join(" | ");
  };
}
const ContactUtils = new ContactUtilsClass();
export default ContactUtils;
