import { TypedBaseAsset } from "@/model/general-assets/BaseAsset";
import { CustomfieldType_edit } from "@/modules/customfields/CustomField.interface";
import { TranslatedLabel } from "@/services/LanguageService";
import _ from "lodash";
import { RentalUnitTypeGroup } from "../../cashBudget/views/tenants/TenantsInterfaces";

const RelatedFeatures: { [type: string]: ObjectKindType[] } = {
  immo: ["assetValue", "address", "loanable", "purchasePrice"],
};

export const getRelatedFeatures = (feature: ObjectKindType) => {
  return [feature, ...(RelatedFeatures[feature] || [])] as string[];
};
export const checkFeature = (feature: ObjectKindType, kind: ObjectKind) =>
  getFeaturesForKind(kind).includes(feature);
export const checkAnyFeature = (features: ObjectKindType[], kind: ObjectKind) =>
  features.some((feature) => checkFeature(feature, kind));
export const getFeaturesForKind = (kind: ObjectKind) => {
  return _.uniq(
    kind.data.features.map((feature) => getRelatedFeatures(feature.type)).flat()
  );
};

export type ObjectKindType =
  | "immo"
  | "communication"
  | "assetValue"
  | "address"
  | "loanable"
  | "purchasePrice"
  | "flightradar24";
type ObjectKindFeature_IMMO = {
  type: "immo";
};
type ObjectKindFeature_COMMUNICATION = {
  type: "communication";
};
type ObjectKindFeature_ASSETVALUE = {
  type: "assetValue";
};
type ObjectKindFeature_ADDRESS = {
  type: "address";
};
type ObjectKindFeature_LOANBALE = {
  type: "loanable";
};
type ObjectKindFeature_PURCHASE = {
  type: "purchasePrice";
};

export type ObjectKindFeature =
  | ObjectKindFeature_IMMO
  | ObjectKindFeature_COMMUNICATION
  | ObjectKindFeature_ASSETVALUE
  | ObjectKindFeature_ADDRESS
  | ObjectKindFeature_PURCHASE
  | ObjectKindFeature_LOANBALE;

export type RentalUnitType = {
  id: string;
  type: string;
  displayName: TranslatedLabel;
  group: RentalUnitTypeGroup;
  status: "active" | "archived";
  taxRate: number;
};
export type ImmoDebitPosition = {
  automaticDebitPosition: boolean;
  displayName: TranslatedLabel;
  id: string;
  status: "active" | "archived";
  priority: number; // 1 HOCH - 10 NIEDRIG
  kind:
    | "rent"
    | "operatingCost"
    | "operatingCostAllInclusive"
    | "additionalPayment";
  taxRate: number;
  updateLastRentChangeDate: boolean;
  relevantForAgreement: boolean;
};
export type ImmoDeposit = {
  displayName: TranslatedLabel;
  status: "active" | "archived";
  id: string;
  automaticDebitPosition: boolean;
};
export type ImmoAccountTemplate = {
  id: string;
  status: "active" | "archived";
  displayName: TranslatedLabel;
  debitposition: ImmoDebitPosition[];
  deposit: ImmoDeposit[];
};
export type ObjectKindData = {
  icon?: {
    type: string;
    data: string;
  };
  displayName: TranslatedLabel;
  features: ObjectKindFeature[];
  customfields: CustomfieldType_edit[];
  type: string;
  config: {
    immo?: {
      accountingTemplate: ImmoAccountTemplate[];
      rentalUnitTypes: RentalUnitType[];
    };
  };
};

export type ObjectKind = TypedBaseAsset<ObjectKindData>;
