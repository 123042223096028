import axios from "axios";
import i18n from "../i18n";
import { AssetTypes } from "../model/AssetTypes";
import { ListResponse } from "../model/common/HttpModel";
import { Contact, ContactData, ContactType } from "../model/db/Contact";
import { HTTP } from "../utils/Http";
import MQ from "../utils/MatchQueryUtils";
import SubmitService from "./SubmitService";

type FetchParam = {
  search: string;
  businessUnits: string[];
  contactTypes: ContactType[];
  offset: number;
  cancelToken: any;
};
const DEFAULT_LIST_LIIMIT = 20;
class ContactServiceClass {
  async submitContact(
    data: Partial<ContactData>,
    updateId?: string,
    force?: boolean
  ) {
    const dataTransformed: Partial<ContactData> = {
      ...data,
      address: data.address || [],
      contactPersons: data.contactPersons || [],
      phone: data.phone || [],
      emails: data.emails || [],
      contactType: data.contactType || [],
      bankAccount: data.bankAccount || [],
    };
    if (data.address?.length > 0 && !data.address.some((e) => e.isMain)) {
      throw {
        error: i18n.t(
          "Contact.noMainAddressError",
          "Bitte wählen Sie eine Hauptadresse aus."
        ),
      };
    }

    return await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Contact,
      data: {
        _id: updateId,
        data: {
          ...dataTransformed,
          displayName:
            data.personType === "organization"
              ? data.companyName
              : `${data.firstName ? data.firstName + " " : ""}${data.lastName}`,

          status: "active",
          forceUpload: force,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }

  async fetchContacts(param: FetchParam) {
    const data = (await HTTP.post({
      url: "asset/list/contact",
      bodyParams: {
        textQuery: param.search,
        matchQuery: MQ.and(
          MQ.in("data.contactType", param.contactTypes),
          MQ.in("data.type", param.businessUnits),
          MQ.eq("data.status", "active")
        ),
        skip: param.offset,
        limit: DEFAULT_LIST_LIIMIT,
      },
      cancelToken: param.cancelToken
        ? new axios.CancelToken((cancel) => (param.cancelToken.cancel = cancel))
        : undefined,
    })) as ListResponse<Contact>;
    return data;
  }
}
const ContactService = new ContactServiceClass();
export default ContactService;
