import React from "react";
import { ReloadMessage } from "../configurable/components/TableComponent/TableComponent";
import {
  ActionData,
  ActionDataStateChange,
} from "../model/common/DataBus/ActionData";
import Toast from "../modules/abstract-ui/notification/Toast";
import ExpressionHelper from "../modules/generic-forms/util/ExpressionHelper";
import { SendEvent } from "../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../utils/Constants";
import { handleError } from "../utils/ErrorCodes";
import { HTTP } from "../utils/Http";
import ModalManager from "./../components/ModalComponent/ModalManager";
import DataBus from "./DataBus";

export interface DeleteAssetMessage {
  assetId: string;
  assetType?: string;

  tablesToReload?: string[];
  goToUrl?: string;
  additionalEvents?: SendEvent[];
  confirmText?: string | React.ReactNode;
}

class AssetServiceClass {
  constructor() {}

  init() {
    DataBus.subscribe<ActionData>(DataBusSubKeys.DELETE_ASSET, (data) =>
      this.actionDeleteAssetDialog(data)
    );
    DataBus.subscribe<ActionData>(DataBusSubKeys.ACTION_DELETE_ASSET, (data) =>
      this.actionDeleteAssetDialog(data)
    );
    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_DELETE_ASSET,
      {
        type: "state",
        hidden: false,
        disabled: false,
      },
      true
    );

    DataBus.subscribe<DeleteAssetMessage>(DataBusSubKeys.DELETE_ASSET, (data) =>
      this.deleteAsset(data)
    );
  }

  getAssetById(assetType: string, assetId: string) {
    return new Promise((resolve, reject) => {
      HTTP.get({
        url: `asset/${assetType}/${assetId}`,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  actionDeleteAssetDialog(actionData: ActionData) {
    if (actionData.type === "click") {
      DataBus.emit<ActionDataStateChange>(
        DataBusSubKeys.ACTION_DELETE_ASSET,
        {
          type: "state",
          hidden: false,
          disabled: false,
          loading: true,
        },
        true
      );
      this.deleteAsset({
        assetId: actionData.data["assetId"],
        assetType: actionData.data["assetType"],
        tablesToReload: actionData.data["tablesToReload"],
        goToUrl: actionData.data["goToUrl"],
        additionalEvents: actionData.data["additionalEvents"],
        confirmText: actionData.data["confirmText"],
      }).finally(() => {
        DataBus.emit<ActionDataStateChange>(
          DataBusSubKeys.ACTION_DELETE_ASSET,
          {
            type: "state",
            hidden: false,
            disabled: false,
            loading: false,
          },
          true
        );
      });
    }
  }

  deleteAsset(msg: DeleteAssetMessage) {
    return new Promise((resolve, reject) => {
      const deleteProcess = () => {
        HTTP.delete({
          url: `asset/${msg.assetType}/${msg.assetId}`,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (msg.additionalEvents) {
              ExpressionHelper.handleEvents(msg.additionalEvents, response);
            }
            DataBus.emit(DataBusSubKeys.ACTION_DELETE_ASSET_RESPONSE, {
              success: true,
              data: response,
            });
            if (msg.tablesToReload) {
              DataBus.emit(DataBusSubKeys.RELOAD, {
                identifiers: msg.tablesToReload,
              } as ReloadMessage);
            }
            Toast.success(
              (window as any).translate(
                "AssetService.Notifications.Delete.Success"
              )
            );
            if (msg.goToUrl) {
              (window as any).router.push(msg.goToUrl);
            }
            resolve(response);
          })
          .catch((err) => {
            DataBus.emit(DataBusSubKeys.ACTION_DELETE_ASSET_RESPONSE, {
              success: false,
              data: err,
            });
            handleError(err);
            reject(err);
          });
      };

      if (msg.confirmText) {
        ModalManager.confirm({
          onConfirm: () => {
            deleteProcess();
          },
          onAbort: () => {
            reject("canceled_by_user");
          },
          message: msg.confirmText,
        });
      } else {
        deleteProcess();
      }
    });
  }
}

const AssetService = new AssetServiceClass();

export default AssetService;
