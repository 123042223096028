import moment from "moment";
import React from "react";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import i18n from "../../../../../../../../i18n";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import {
  StatisticResult,
  useAssetCache,
  useConstants,
} from "../../../../../../../../redux/hooks";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { CBPortfolioImmoConfig } from "../../../interfaces/CBPortfolioAsset";
import { CBStatisticImmoVacancyDistribution } from "../../../interfaces/CBStatisticQueries";
import CBRentalStatusChart from "./CBRentalStatusChart";
import "./CBRenterDistributionCard.scss";

const checkUnassigned = (
  data: CBStatisticImmoVacancyDistribution,
  configData: CBPortfolioImmoConfig
) => {
  if (!data || !configData) {
    return false;
  }
  return (
    Object.entries(data).filter(
      ([key, _kpi]) => configData.data.typeMappings[key] === undefined
    ).length !== 0
  );
};
const aggreagateDistributionData = (
  data: CBStatisticImmoVacancyDistribution,
  configData: CBPortfolioImmoConfig,
  selector?: "business" | "private" | "parking" | "not-assigned"
) => {
  return null;
  // if (!data || !configData) {
  //   return [] as { rent: number; count: number; area: number; name: string }[];
  // }
  // return Object.entries(data)
  //   .filter(([key, _kpi]) =>
  //     !selector
  //       ? true
  //       : selector !== "not-assigned"
  //       ? configData.data.typeMappings[key] === selector
  //       : configData.data.typeMappings[key] === undefined
  //   )
  //   .map(([key, kpi]) => ({
  //     name: key,
  //     rent: kpi.rent.nonVacant + kpi.rent.vacant,
  //     area: kpi.area.nonVacant + kpi.area.vacant,
  //     count: kpi.count.nonVacant + kpi.count.vacant,
  //   }))
  //   .reduce((prev, current) => {
  //     const category = selector
  //       ? current.name
  //       : nameOf(configData.data.typeMappings[current.name]);
  //     let updated = false;
  //     const newArr = prev.map((entry) => {
  //       if (entry.name === category) {
  //         updated = true;
  //         return {
  //           name: entry.name,
  //           area: entry.area + current.area,
  //           count: entry.count + current.count,
  //           rent: entry.rent + current.rent,
  //         };
  //       } else {
  //         return entry;
  //       }
  //     });
  //     if (!updated) {
  //       newArr.push({
  //         name: category,
  //         rent: current.rent,
  //         count: current.count,
  //         area: current.area,
  //       });
  //     }
  //     return newArr;
  //   }, [] as { rent: number; count: number; area: number; name: string }[]);
};

const nameOf = (
  filter: "business" | "parking" | "private" | "not-assigned"
) => {
  switch (filter) {
    case "business":
      return i18n.t("cb:Renter.business", "Gewerbe");
    case "parking":
      return i18n.t("cb:Renter.parking", "Stellplatz");
    case "private":
      return i18n.t("cb:Renter.private", "Privat");
    case "not-assigned":
    default:
      return i18n.t("cb:Renter.not-assigned", "Sonstiges");
  }
};
interface VacancyDistributionCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBRenterDistributionCard = (props: VacancyDistributionCardProps) => {
  const constants = useConstants();
  const [yearly, setYearly] = React.useState(false);
  const configCache = useAssetCache<CBPortfolioImmoConfig>("portfolio-config", {
    type: "op",
    name: "data.type",
    op: "in",
    value: constants.currency === "USD" ? ["immo-us"] : ["immo"],
  });
  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-renter-distribution-card"
      title={i18n.t("cb:Renter.rentalStatus", "Mieteinnahmen SOLL/IST ")}
      headRight={
        <BFButtonToggle
          buttons={[
            {
              text: i18n.t("cb:Renter.ShowMonthly", "Monatlich"),
              value: false,
            },
            {
              text: i18n.t("cb:Renter.ShowYearly", "Jährlich"),
              value: true,
            },
          ]}
          value={yearly}
          onChange={(val: boolean) => setYearly(val)}
        />
      }
      childOptions={{
        renderWhileLoading: true,
        render: (
          queryData: StatisticResult<CBStatisticImmoVacancyDistribution>
        ) => (
          <div className="charts">
            <CBRentalStatusChart
              month={moment().startOf("day").utc(true)}
              objectIds={props.objectIds}
              referenceId={props.referenceId}
              className="chart-entry full"
              yearly={yearly}
            />
          </div>
        ),
      }}
    />
  );
};
export default CBRenterDistributionCard;

const generateDistributionChart = (
  title: string,
  selector: "area" | "rent" | "count",
  data: { name: string; area: number; rent: number; count: number }[]
) => {
  return {
    title: {
      text: title,
      textStyle: {
        fontSize: 15,
        fontWeight: 400,
        fontFamily: "Roboto",
      },
    },
    tooltip: {
      trigger: "item",
      valueFormatter: (val) =>
        selector === "area"
          ? `${StringUtils.formatNumber(val)} ${StringUtils.getAreaUnit()}`
          : selector === "rent"
          ? StringUtils.formatCurrency(val)
          : val,
      // formatter: (entry) => {
      //   return `${entry.data.name}:\n${
      //     selector === "area"
      //       ? StringUtils.formatNumber(entry.data.value) + " m3"
      //       : selector === "rent"
      //       ? StringUtils.formatCurrency(entry.data.value)
      //       : entry.data.value
      //   }`;
      // },
    },
    // legend: {
    //   orient: "vertical",
    //   left: "left",
    // },
    series: [
      {
        name: title,
        type: "pie",
        radius: "90%",
        labelLine: {
          show: false,
        },
        label: {
          position: "inner",
          formatter: (entry) => {
            return `${entry.data.name}:\n${
              selector === "area"
                ? StringUtils.formatNumber(entry.data.value) +
                  " " +
                  StringUtils.getAreaUnit()
                : selector === "rent"
                ? StringUtils.formatCurrency(entry.data.value)
                : entry.data.value
            }`;
          },
        },
        data: data?.map((entry) => ({
          name: entry.name,
          value: entry[selector],
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
};
