import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFLongLat from "@/modules/abstract-ui/forms/longlat/BFLongLat";
import BfRadio from "@/modules/abstract-ui/forms/radio/BfRadio";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import DataBus from "@/services/DataBus";
import { DataBusSubKeys } from "@/utils/Constants";
import classNames from "classnames";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OAObject } from "../../../types/object.interface";
import {
  ObjectKind,
  getFeaturesForKind,
} from "../../../types/objectKind.interface";
import "./ObjectFormFeatureAddress.scss";

const MAP_ID = "object-form-feature-address-map";
interface ObjectFormFeatureAddressProps {}

export const getInitialValueFeatureAddress = (
  asset: OAObject,
  kind: ObjectKind
) => {
  const features = getFeaturesForKind(kind);

  if (features.includes("address")) {
    return {
      location: asset?.data?.feature?.address?.location,
      address:
        (asset?.data?.feature?.address?.address || []).length > 0
          ? asset?.data?.feature?.address?.address
          : [
              {
                streetname: "",
                streetnumber: "",
                postalcode: "",
                city: "",
                isMain: true,
              },
            ],
    };
  } else {
    return undefined;
  }
};
const ObjectFormFeatureAddress = (props: ObjectFormFeatureAddressProps) => {
  return (
    <div className={classNames(`object-form-feature-address`)}>
      <div className={`map-component`}>
        <Field name="feature.address.location" validate={FV.required()}>
          {({ input, meta }) => (
            <BFLongLat
              identifier={MAP_ID}
              {...input}
              {...FV.getValidation(meta)}
            />
          )}
        </Field>
      </div>
      <FieldArray name="feature.address.address">
        {({ fields }) => (
          <div className={`addresses`}>
            {fields.map((field, index) => (
              <div className={`address-entry`}>
                {fields.length !== 1 && (
                  <Field name={`${field}.isMain`}>
                    {({ input, meta }) => (
                      <BfRadio
                        checked={input.value}
                        onChange={(_, checked) => {
                          if (checked) {
                            fields.forEach((field, index) =>
                              fields.update(index, {
                                ...fields.value[index],
                                isMain: false,
                              })
                            );
                            input.onChange(true);
                          }
                        }}
                      >
                        {i18n.t(
                          "obj:ObjectForm.Address.MainAddress",
                          "Hauptadresse"
                        )}
                      </BfRadio>
                    )}
                  </Field>
                )}

                <div className={`field-row`}>
                  <Field name={`${field}.streetname`} validate={FV.required()}>
                    {({ input, meta }) => (
                      <div className={`field __flex-2`}>
                        <BFInput
                          {...input}
                          label={`${i18n.t(
                            "obj:ObjectForm.Address.Streetname",
                            "Straßenname"
                          )}*`}
                          {...FV.getValidation(meta)}
                        />
                      </div>
                    )}
                  </Field>
                  <Field
                    name={`${field}.streetnumber`}
                    validate={FV.required()}
                  >
                    {({ input, meta }) => (
                      <div className={`field __flex-1`}>
                        <BFInput
                          {...input}
                          label={`${i18n.t(
                            "obj:ObjectForm.Address.Streetnumber",
                            "Nummer"
                          )}*`}
                          {...FV.getValidation(meta)}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className={`field-row`}>
                  <Field name={`${field}.postalcode`} validate={FV.required()}>
                    {({ input, meta }) => (
                      <div className={`field __flex-1`}>
                        <BFInput
                          {...input}
                          label={`${i18n.t(
                            "obj:ObjectForm.Address.Postalcode",
                            "Postleitzahl"
                          )}*`}
                          {...FV.getValidation(meta)}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name={`${field}.city`} validate={FV.required()}>
                    {({ input, meta }) => (
                      <div className={`field __flex-2`}>
                        <BFInput
                          {...input}
                          label={`${i18n.t(
                            "obj:ObjectForm.Address.City",
                            "Stadt"
                          )}*`}
                          {...FV.getValidation(meta)}
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className={`address-actions`}>
                  <BFButton
                    appearance="link"
                    noPadding
                    size="xs"
                    onClick={() => {
                      const value = fields.value[index];
                      DataBus.emit(DataBusSubKeys.BF_LONG_LAT_QUERY, {
                        identifier: MAP_ID,
                        query: `${value.postalcode || ""} ${value.city || ""} ${
                          value.streetname || ""
                        } ${value.streetnumber || ""}`,
                      });
                    }}
                  >
                    {i18n.t(
                      "obj:ObjectForm.Address.ToCard",
                      "In Karte übertragen"
                    )}
                  </BFButton>
                  <BFButton
                    tooltip={
                      fields.length === 1
                        ? {
                            tooltip: i18n.t(
                              "obj:ObjectForm.Address.RemoveButton.Tooltip",
                              "Es muss mindestens eine Adresse angegeben sein."
                            ),
                          }
                        : undefined
                    }
                    disabled={fields.length === 1}
                    appearance="link"
                    noPadding
                    size="xs"
                    onClick={() => {
                      const isDeletedRowMain = fields.value[index].isMain;

                      if (isDeletedRowMain) {
                        fields.update(index === 0 ? 1 : 0, {
                          ...fields.value[index === 0 ? 1 : 0],
                          isMain: true,
                        });
                      }
                      fields.remove(index);
                    }}
                  >
                    {i18n.t("obj:ObjectForm.Address.RemoveButton", "Entfernen")}
                  </BFButton>
                </div>
              </div>
            ))}

            <div className={`add-button`}>
              <BFButton
                size="xs"
                noPadding
                appearance="link"
                onClick={() => {
                  fields.push({});
                }}
              >
                {i18n.t(
                  "obj:ObjectForm.Address.AddButton",
                  "Weitere Adresse hinzufügen"
                )}
              </BFButton>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default ObjectFormFeatureAddress;
