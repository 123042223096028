import StringUtils from "../../utils/StringUtils";
import UserLoader from "../AssetLoader/UserLoader";

interface UsernameProps {
  id: string;
  className?: string;
}
const Username = (props: UsernameProps) => {
  if (!StringUtils.isObjectId(props.id)) {
    return <span>{props.id}</span>;
  }
  return (
    <UserLoader
      id={props.id}
      render={(data) => (
        <span className={props.className}>{data.displayname}</span>
      )}
      placeholderProps={{ width: 50 }}
    />
  );
};

export default Username;
