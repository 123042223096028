import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import classNames from "classnames";
import moment from "moment";
import { Field } from "react-final-form";
import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFDate from "../../../../../../../modules/abstract-ui/forms/date/BFDate";
import BFInputTable, {
  BFInputTableColumn,
} from "../../../../../../../modules/abstract-ui/forms/input-table/BFInputTable";
import BFInput from "../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import EZAssetSelectMultiple from "../../../../../../../modules/ez-form/form-elements/ez-asset-select/EZAssetSelectMultiple";
import LanguageService from "../../../../../../../services/LanguageService";
import NumberUtils from "../../../../../../../utils/ NumberUtils";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import CBRentalService from "../../CBRentalService";
import { RentalAgreementChangeReasons } from "../../CBRentalUtils";
import { RentalAgreement, RentalUnit } from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "./CBRentalAgreementHelper";
import "./CBRentalAgreementRentChange.scss";

interface CBRentalAgreementRentChangeProps {
  onClose: () => void;
  rentalAgreement: RentalAgreement;
  kind: ObjectKind;
}
const CBRentalAgreementRentChange = (
  props: CBRentalAgreementRentChangeProps
) => {
  const options = ObjectKindStruct.getRentalUnitAssetSelectionOptions(
    {
      renderValue: (asset: RentalUnit, index: number) => {
        return <RentalUnitTableEntry rentalUnit={asset} />;
      },
    } as any,
    props.kind._id
  );
  const taxable = props.rentalAgreement.data.taxable;
  const objectId = props.rentalAgreement.data.objectId;
  return (
    <AssetLoader
      assetType={AssetTypes.Portfolio.Object}
      id={props.rentalAgreement.data.objectId}
      render={(object: OAObject) => (
        <FormStruct
          initialValues={{
            rentalUnits: props.rentalAgreement.data.rentalUnits.map((e) =>
              typeof e === "string" ? e : (e as any).rentalUnitId
            ),
            from: moment().startOf("day").utc(true).toISOString(),
            taxable: props.rentalAgreement.data.taxable,
            paymentPositions: props.rentalAgreement.data.paymentPositions,
          }}
          className={classNames("cb-rental-agreement-rent-change")}
          title={i18n.t(
            "cb:RentalAgreementRentChange.title",
            "Neue Mietperiode anlegen"
          )}
          description={i18n.t(
            "cb:RentalAgreementRentChange.description",
            "Bitte geben Sie an, welche Miete und welche Mieteinheiten ab welchem Datum gelten sollen."
          )}
          onSubmit={async (values) => {
            await CBRentalService.addTimePeriodToAgreement(
              props.rentalAgreement._id,
              values
            );
            props.onClose();
          }}
          submitText={i18n.t(
            "cb:RentalAgreementRentChange.submit",
            "Mietperiode hinzufügen"
          )}
          onAbort={props.onClose}
          render={(form) => {
            return (
              <>
                <div className={`__h3 margin-bottom-10`}>
                  {i18n.t(
                    "cb:RentalAgreement.RentChange.rentalUnits",
                    "Mieteinheiten"
                  )}
                </div>

                <Field
                  name="rentalUnits"
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.min(1)
                  )}
                >
                  {({ input, meta }) => (
                    <>
                      <div className={`stacking-plan`}>
                        <ObjectStackingPlan
                          id="rental-unit-change"
                          objectId={props.rentalAgreement.data.objectId}
                          selectMode={"multiple"}
                          onSelect={(units) => {
                            //todo update logic here
                            // if (
                            //   !_.isEqual(
                            //     (input.value || []).map((e) => e.assetId),
                            //     units
                            //   )
                            // ) {
                            //   input.onChange(
                            //     units.map((e) => ({
                            //       assetId: e,
                            //       assetType: AssetTypes.Rental.RentalUnit,
                            //     }))
                            //   );
                            // }
                          }}
                          selected={(input.value || []).map((e) => e)}
                        />
                      </div>
                      <RentalUnitTableHeader />
                      {(input.value || []).length === 0 && (
                        <div className={`__empty`}>
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.noRentalUnits",
                            "Keine Mieteinheiten ausgewählt"
                          )}
                        </div>
                      )}
                      <EZAssetSelectMultiple
                        onChange={(assets) => {
                          input.onChange(assets.map((e) => e.assetId));
                        }}
                        value={(input.value || []).map((e) => ({
                          assetId: e,
                          assetType: AssetTypes.Rental.RentalUnit,
                        }))}
                        initialAssetType={options[0].id}
                        block
                        identifier="cb-rental-agreement-rental-unit-select"
                        addButtonText={i18n.t(
                          "cb:RentalAgreement.Form.Fields.addRentalUnit",
                          "Mieteinheit hinzufügen"
                        )}
                        appearance="clear"
                        validation={
                          meta.error &&
                          typeof meta.error === "string" &&
                          meta.touched
                            ? {
                                level: "error",
                                message: meta.error,
                              }
                            : undefined
                        }
                        additionalMatchQuery={MQ.combineSpread(
                          "and",
                          {
                            type: "op",
                            op: "eq",
                            name: "data.type",
                            value: props.rentalAgreement.data.type,
                          },
                          {
                            type: "op",
                            op: "eq",
                            name: "data.objectId",
                            value: props.rentalAgreement.data.objectId,
                          }
                        )}
                        assetTypes={options}
                      />
                    </>
                  )}
                </Field>

                <div className={`__h3 margin-bottom-10 margin-top-10`}>
                  {i18n.t("cb:RentalAgreement.RentChange.rent", "Mietpreis")}
                </div>

                <div className={`__field`}>
                  <Field
                    name="paymentPositions"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.min(0)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInputTable
                        value={input.value || []}
                        columns={[
                          {
                            identifier: "net",
                            label: i18n.t(
                              "cb:RentalAgreement.Form.Fields.paymentPositions.net",
                              "Netto"
                            ),
                            flexWidth: 3,
                            inputProps: (rowValue, onRowChange) => ({
                              type: "priceInput",
                              textAlign: "left",
                              prefix: StringUtils.getCurrencySymbol(),
                              removeSuffixPadding: true,
                              suffix:
                                taxable &&
                                rowValue?.gross &&
                                !NumberUtils.equalsNormalized(
                                  rowValue.gross / 1.19,
                                  rowValue.net
                                ) ? (
                                  <BFButton
                                    className={`suggest-button`}
                                    appearance="link"
                                    size="xs"
                                    onClick={() => {
                                      onRowChange({
                                        ...rowValue,
                                        net: rowValue.gross / 1.19,
                                      });
                                    }}
                                  >
                                    {StringUtils.formatCurrency(
                                      rowValue.gross / 1.19
                                    )}
                                  </BFButton>
                                ) : undefined,
                            }),
                          },
                          ...(taxable
                            ? [
                                {
                                  identifier: "gross",
                                  label: i18n.t(
                                    "cb:RentalAgreement.Form.Fields.paymentPositions.gross",
                                    "Brutto"
                                  ),
                                  flexWidth: 3,
                                  inputProps: (rowValue, onRowChange) => ({
                                    type: "priceInput",
                                    textAlign: "left",
                                    prefix: StringUtils.getCurrencySymbol(),

                                    removeSuffixPadding: true,
                                    suffix:
                                      rowValue?.net &&
                                      taxable &&
                                      !NumberUtils.equalsNormalized(
                                        rowValue?.net * 1.19,
                                        rowValue?.gross
                                      ) ? (
                                        <BFButton
                                          className={`suggest-button`}
                                          appearance="link"
                                          size="xs"
                                          onClick={() => {
                                            onRowChange({
                                              ...rowValue,
                                              gross: rowValue?.net * 1.19,
                                            });
                                          }}
                                        >
                                          {StringUtils.formatCurrency(
                                            rowValue?.net * 1.19
                                          )}
                                        </BFButton>
                                      ) : undefined,
                                  }),
                                } as BFInputTableColumn,
                                {
                                  identifier: "tax",
                                  label: i18n.t(
                                    "cb:RentalAgreement.Form.Fields.paymentPositions.tax",
                                    "Ust. Betrag"
                                  ),
                                  flexWidth: 2,
                                  render: (rowValue, onChange, row) => {
                                    return (
                                      <div className={`info`}>
                                        {rowValue?.net && rowValue?.gross
                                          ? StringUtils.formatCurrency(
                                              rowValue?.gross - rowValue?.net
                                            )
                                          : "-"}
                                      </div>
                                    );
                                  },
                                } as BFInputTableColumn,
                              ]
                            : []),
                          {
                            identifier: "type",
                            label: "",
                            flexWidth: 2,
                            render: (value, onChange, row) => (
                              <div className={`info`}>
                                <div className={`info-1`}>{row.data.info1}</div>
                                <div className={`info-2`}>{row.data.info2}</div>
                              </div>
                            ),
                          },
                        ]}
                        rows={
                          // OrgaStruct.getRentalTargetPositions(objectId)

                          object.data.feature.immo.accounting.debitposition
                            ?.filter(
                              (position) => position.relevantForAgreement
                            )
                            .filter(
                              (position) => position.status !== "archived"
                            )
                            .map((position) => ({
                              identifier: position.id,
                              label: LanguageService.translateLabel(
                                position.displayName
                              ),
                              data: {
                                info1: position.automaticDebitPosition
                                  ? i18n.t("cb:", "monatliche Abrechnung")
                                  : "einmalige Abrechnung",
                                taxRate: position.taxRate,
                                info2: ((kind) => {
                                  switch (kind) {
                                    case "rent":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.rent",
                                        "Mieteinnahmen"
                                      );
                                    case "operatingCost":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCost",
                                        "Betriebskosten"
                                      );
                                    case "operatingCostAllInclusive":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.operatingCostAllInclusive",
                                        "Betriebskostenpauschale"
                                      );
                                    case "additionalPayment":
                                      return i18n.t(
                                        "cb:RentalAgreement.Form.Fields.paymentPositions.additionalPayment",
                                        "Zusatzleistung"
                                      );
                                    default:
                                      return "-";
                                  }
                                })(position.kind),
                              },
                            })) || []
                        }
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
                <div className={`__h3 margin-bottom-10 margin-top-10`}>
                  {i18n.t(
                    "cb:RentalAgreement.RentChange.plannedPeriod",
                    "Änderungszeitraum"
                  )}
                </div>

                <div className={`field-row`}>
                  <div className={`__flex-1`}>
                    <div className={`__field`}>
                      <Field
                        name="from"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <BFDate
                            oneTap
                            label={i18n.t(
                              "cb:RentalAgreement.validFrom",
                              "Gültig ab"
                            )}
                            {...input}
                            validation={
                              meta.error && meta.touched
                                ? {
                                    level: "error",
                                    message: meta.error,
                                  }
                                : undefined
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={`__flex-1`}>
                    <div className={`__field`}>
                      <Field name="to">
                        {({ input, meta }) => (
                          <BFDate
                            oneTap
                            label={i18n.t(
                              "cb:RentalAgreement.validTo",
                              "Gültig bis"
                            )}
                            {...input}
                            validation={
                              meta.error && meta.touched
                                ? {
                                    level: "error",
                                    message: meta.error,
                                  }
                                : undefined
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>

                <div className={`__h3 margin-bottom-10 margin-top-10`}>
                  {i18n.t(
                    "cb:RentalAgreement.RentChange.reasonForChange",
                    "Grund der Anpassung"
                  )}
                </div>
                <Field name={`reason`}>
                  {({ input, meta }) =>
                    RentalAgreementChangeReasons().find(
                      (e) => e.value === input.value
                    )?.editable === false ? null : (
                      <div className={`__field`}>
                        <BFSelect
                          {...input}
                          cleanable={false}
                          label={`${i18n.t(
                            "cb:RentalAgreement.RentChange.reasonChange",
                            "Änderungsgrund"
                          )}*`}
                          data={RentalAgreementChangeReasons().filter(
                            (e) => e.editable
                          )}
                        />
                      </div>
                    )
                  }
                </Field>
                <div className={`__field`}>
                  <Field name={`comment`}>
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        type="textarea"
                        label={`${i18n.t(
                          "cb:RentalAgreement.RentChange.comment",
                          "Kommentar"
                        )}`}
                      />
                    )}
                  </Field>
                </div>
              </>
            );
          }}
        />
      )}
    />
  );
};

export default CBRentalAgreementRentChange;
