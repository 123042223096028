import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import i18n from "../../../i18n";
import { TableSort } from "../../../model/common/CommonInterfaces";
import BFList from "../../../modules/abstract-ui/data/list/BFList";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import { DefaultIcons } from "../../../modules/abstract-ui/icon/DefaultIcons";
import InfiniteTable, {
  ExpandKey,
} from "../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../redux/hooks";
import { InfineTableCache } from "../../../redux/reducers/application/ApplicationInterface";
import { MatchQuery } from "../../../services/DataService";
import { usePrevious } from "../../../utils/Hooks";
import "./ListComponent.scss";

type ListComponentBasePropsDataUrl = {
  dataUrl: string;
  assetType?: string;
};
type ListComponentBasePropsDataAssetType = {
  assetType: string;
  dataUrl?: string;
};

type ListComponentBaseProps = (
  | // this forces at least dataurl or assettype to be set,
  // if both are set, dataurl is used
  ListComponentBasePropsDataUrl
  | ListComponentBasePropsDataAssetType
) & {
  // Props to pass through to BFList
  emptyText?: string;
  params?: any;
  paramsConverter?: (data: any, index: number, params: any) => any;
  onScroll?: (ev: React.UIEvent<HTMLDivElement>) => void;
  newDataWarningText?: string;
  flow?: boolean;
  // props needed for dataHandling
  overwriteSkip?: (cache: InfineTableCache) => number;
  useNewDataWarning?: boolean;
  identifier: string;
  limitPerRequest?: number;
  loadDelay?: number;
  asPost?: boolean;
  additionalMatchQuery?: MatchQuery;
  reloadOnMount?: boolean;
  cleanupOnUnmount?: boolean;
  //Fixme - what to do here?
  initialVisibleSort?: TableSort;
  hiddenSort?: TableSort[];
  className?: string;
  disableEndlessScroll?: boolean;
  listDirty?: boolean;
  listDirtyText?: string;

  hiddenSortFirst?: boolean;
  isSearchMode?: boolean;
  expandKeys?: (ExpandKey | string)[];
};

export type ListComponentProps = ListComponentBaseProps & {
  dataRenderType?: "default" | undefined;
  render: (node: any, index: number, params: any) => React.ReactNode;
};

export type ListComponentRenderAsListProps = ListComponentBaseProps & {
  dataRenderType: "render-as-list";
  render: (node: any[], params: any) => React.ReactNode;
};

export const ListComponent = ({
  dataRenderType,
  useNewDataWarning,
  identifier,
  dataUrl,
  assetType,
  limitPerRequest,
  loadDelay,
  asPost,
  additionalMatchQuery,
  initialVisibleSort,
  hiddenSort,
  hiddenSortFirst,
  overwriteSkip,
  cleanupOnUnmount,
  reloadOnMount,
  render,
  disableEndlessScroll,
  isSearchMode,
  expandKeys,
  listDirty,
  listDirtyText,
  ...listProps
}: ListComponentProps | ListComponentRenderAsListProps) => {
  const dispatch = useDispatch();
  const previousAdditionalMatchQuery = usePrevious(additionalMatchQuery);
  const tableCache = useTypedSelector(
    (state) => state.application.infiniteTables[identifier]
  );
  useEffect(() => {
    if (reloadOnMount && tableCache) {
      dispatch(InfiniteTable.reloadData(identifier, true));
    }
    return () => {
      // cleanup
      if (cleanupOnUnmount) {
        dispatch(InfiniteTable.clearTable(identifier));
      }
    };
  }, []);
  const useDataUrl =
    dataUrl || `/api/asset/${asPost ? "list/" : ""}${assetType}`;

  useEffect(() => {
    if (tableCache && tableCache.url !== useDataUrl) {
      dispatch(
        InfiniteTable.initialize(identifier, {
          url: useDataUrl,
          limitPerRequest: limitPerRequest || 30,
          additionalMatchQuery: additionalMatchQuery,
          asPost: asPost,
          loadDelay: loadDelay,
          visibleSort: initialVisibleSort,
          hiddenSort: hiddenSort,
          hiddenSortFirst,
          expandKeys: expandKeys?.map((e) =>
            typeof e === "string" ? { key: e } : e
          ),
          // filterStatus: undefined,
          // searchTerm: undefined,
          // sort: undefined,
        })
      );
    }
  }, [tableCache, useDataUrl]);

  useEffect(() => {
    if (!tableCache) {
      dispatch(
        InfiniteTable.initialize(identifier, {
          url: useDataUrl,
          limitPerRequest: limitPerRequest || 30,
          additionalMatchQuery: additionalMatchQuery,
          asPost: asPost,
          loadDelay: loadDelay,
          visibleSort: initialVisibleSort,
          hiddenSortFirst,
          hiddenSort: hiddenSort,
          expandKeys: expandKeys?.map((e) =>
            typeof e === "string" ? { key: e } : e
          ),
          // filterStatus: undefined,
          // searchTerm: undefined,
          // sort: undefined,
        })
      );
    }
  }, [tableCache]);
  useEffect(() => {
    if (tableCache?.reload) {
      // reload requested from other sources - directly reload without delay
      dispatch(InfiniteTable.reloadData(identifier, true));
    }
  }, [tableCache]);

  useEffect(() => {
    if (!_.isEqual(additionalMatchQuery, previousAdditionalMatchQuery)) {
      // reload with new match query - don't ignore delay
      dispatch(
        InfiniteTable.setMatchQuery(identifier, additionalMatchQuery, false)
      );
    }
  }, [additionalMatchQuery]);

  const onScrollEnd = () => {
    if (disableEndlessScroll || listDirty) {
      return;
    }
    // dispatch action to load next data - action will check itself if there is more data available
    dispatch(
      InfiniteTable.loadNextData(identifier, overwriteSkip?.(tableCache))
    );
  };

  const newDataAvailable = useNewDataWarning
    ? tableCache?.newDataIDs?.length
    : 0;

  // TODO SJ discuss with Benni
  let data = tableCache?.data || [];
  if (
    isSearchMode &&
    (!tableCache?.searchTerm || tableCache?.loading !== null)
  ) {
    data = [];
  }
  if (dataRenderType === "render-as-list") {
    const renderAsList: (node: any[], params: any) => React.ReactNode = render;
    return (
      <BFList
        render={renderAsList}
        dataRenderType={"render-as-list"}
        loading={tableCache?.loading}
        data={data}
        onScrollEnd={onScrollEnd}
        onNewDataReload={() =>
          dispatch(InfiniteTable.reloadData(identifier, false))
        }
        newDataAvailable={newDataAvailable}
        {...listProps}
      />
    );
  }
  return (
    <BFList
      render={render}
      dataRenderType={dataRenderType}
      loading={tableCache?.loading}
      data={tableCache?.data || []}
      onScrollEnd={onScrollEnd}
      onNewDataReload={() =>
        dispatch(InfiniteTable.reloadData(identifier, false))
      }
      newDataAvailable={newDataAvailable}
      postNode={
        listDirty && (
          <div className={`refresh-data-node`}>
            <div className={`info`}>
              {listDirtyText ||
                i18n.t(
                  "ListComponent.listDirtyInfo",
                  "Einträge aus der Liste wurden bearbeitet, die Einträge müssen neu geladen werden. Klicken Sie auf 'Neu laden', um die Liste neu zu laden."
                )}
            </div>
            <BFButton
              size="xs"
              icon={{
                ...DefaultIcons.REFRESH,
                size: "xxs",
              }}
              text={i18n.t("Global.Button.Refresh", "Neu laden")}
              onClick={async () =>
                await dispatch(
                  InfiniteTable.reloadDataPromise(identifier, true)
                )
              }
            ></BFButton>
          </div>
        )
      }
      {...listProps}
    />
  );
};

export default ListComponent;
