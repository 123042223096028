import { useRef, useState } from "react";
import { Loader, SelectPicker } from "rsuite";
import ModalManager from "../../components/ModalComponent/ModalManager";
import i18n from "../../i18n";
import { ListResponse } from "../../model/common/HttpModel";
import { Contact, ContactType } from "../../model/db/Contact";
import ContactService from "../../services/ContactService";
import { Placement } from "../abstract-ui/common/Placements";
import LabeledInput from "../abstract-ui/forms/LabeledInput";
import BFButton from "../abstract-ui/general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../abstract-ui/general/ValidationPopover/ValidationPopover";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../abstract-ui/icon/DefaultIcons";
import ContactForm from "./ContactForm";
import "./ContactSelect.scss";
import { ContactSelectMenuItem } from "./ContactSelectMenuItem";
import { ContactSelectValue } from "./ContactSelectValue";

const FixedLoader = () => (
  <div className={`contact-load-mask`}>
    <Loader content="Loading..." />
  </div>
);

type ContactSelectProps = {
  businessUnits: string[];
  contactTypes: ContactType[];

  placement?: Placement;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  label?: string;
  labelPosition?: "top" | "left";
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;

  addText?: string;
  initialCreateObj?: Partial<Contact>;
};
const ContactSelect = (props: ContactSelectProps) => {
  const picker = useRef<any>(null);
  const cancelToken = useRef({ cancel: null });
  const rebounce = useRef(null);
  const [search, setSearch] = useState("");
  const [data, setData] =
    useState<ListResponse<{ value: string; label: string; obj: Contact }>>(
      null
    );
  const [loading, setLoading] = useState(false);

  const fetchData = async (search?: string, append?: boolean) => {
    clearTimeout(rebounce.current);
    typeof cancelToken.current.cancel === "function" &&
      cancelToken.current.cancel();
    setSearch(search);
    setLoading(true);

    rebounce.current = setTimeout(async () => {
      const response = await ContactService.fetchContacts({
        businessUnits: props.businessUnits,
        contactTypes: props.contactTypes,
        cancelToken: cancelToken.current,
        offset: append ? data?.data?.length || 0 : 0,
        search,
      });

      const newData = response.data.map((e) => ({
        label: e.data.displayName,
        value: e._id,
        obj: e,
      }));

      setData({
        ...response,
        data: append ? [...data?.data, ...newData] : newData,
      });
      setLoading(false);
    }, 200);
  };

  const onItemsRendered = (props) => {
    if (props.visibleStopIndex >= data?.data?.length - 1) {
      fetchData(search, true);
    }
  };

  const renderValue = (value, item) => {
    return <ContactSelectValue id={value} />;
  };
  const renderMenuItem = (label, item) => {
    return (
      <ContactSelectMenuItem
        contact={item.obj}
        closePicker={() => {
          picker.current?.close?.();
        }}
      />
    );
  };

  const renderMenu = (menu) => {
    return (
      <>
        {menu}
        {loading && <FixedLoader />}
        <div className={`action-container`}>
          <BFButton
            appearance="link"
            onClick={() => {
              picker.current?.close?.();

              ModalManager.show({
                noPadding: true,
                backdrop: "static",
                size: "md",
                content: (states, setStates, closeModal) => (
                  <ContactForm
                    onClose={closeModal}
                    businessUnits={props.businessUnits}
                    onSuccessfulSubmit={(contact) => {
                      props.onChange(contact._id);
                    }}
                    contact={props.initialCreateObj as Contact}
                  />
                ),
              });
            }}
          >
            <BfIcon {...DefaultIcons.ADD} size="xxs" />
            {props.addText ||
              i18n.t("Contact.Selection.newContact", "Neuen Kontakt anlegen")}
          </BFButton>
        </div>
      </>
    );
  };

  return (
    <LabeledInput
      label={props.label}
      labelPosition={props.labelPosition}
      error={!!props.validation?.message}
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <SelectPicker
          ref={picker}
          onClose={() => {
            setSearch("");
          }}
          className={`contact-select ${props.className || ""}`}
          data={data?.data || []}
          virtualized
          placeholder={
            props.placeholder ||
            i18n.t("Contact.Selection.placeholder", "Kontakt auswählen")
          }
          placement={props.placement}
          onChange={props.onChange}
          value={props.value}
          disabled={props.disabled}
          menuClassName="contact-select-menu"
          renderMenuItem={renderMenuItem}
          renderMenu={renderMenu}
          renderValue={props.value ? renderValue : undefined}
          searchBy={() => true} // filter is done by backend
          onEntering={() => {
            if (search || !data?.data?.length) {
              fetchData();
            }
            document
              .querySelector(".contact-select-menu .rs-picker-search-bar-input")
              ?.setAttribute(
                "placeholder",
                i18n.t("Contact.Selection.search", "Suchen")
              );
          }}
          listProps={{
            onItemsRendered,
            itemSize: (item) => 40,
          }}
          onSearch={(search) => {
            fetchData(search);
          }}
        />
      </ValidationPopover>
    </LabeledInput>
  );
};

export default ContactSelect;
