import BookingDetailsModal from "./impl/booking/BookingDetailModal";
import ContactDetailsModal from "./impl/contact/ContactDetailModal";
import InvoiceDetailsModal from "./impl/invoice/InvoiceDetailModal";
import LoanDetailsModal from "./impl/loan/LoanDetailsModal";
import CBObjectDetailModal from "./impl/object/CBObjectDetailModal";
import RentalAgreementDetailsModal from "./impl/rental/RentalAgreementDetailsModal";
import RentalUnitDetailsModal from "./impl/rental/RentalUnitDetailsModal";
import RentalVacanyDetailsModal from "./impl/rental/RentalVacantDetailsModal";
import APSupplyUnitDetailModal from "./impl/supply-unit/APSupplyUnitDetailModal";
import APTechnicalUnitDetailModal from "./impl/technical-unit/APTechnicalUnitDetailModal";

interface DetailModalsProps {}
const GlobalDetailModals = (props: DetailModalsProps) => {
  return (
    <>
      <APTechnicalUnitDetailModal />
      <APSupplyUnitDetailModal />
      <CBObjectDetailModal />
      <RentalAgreementDetailsModal />
      <RentalUnitDetailsModal />
      <RentalVacanyDetailsModal />

      <ContactDetailsModal />
      <BookingDetailsModal />
      <LoanDetailsModal />
      <InvoiceDetailsModal />
    </>
  );
};

export default GlobalDetailModals;
