import classNames from "classnames";
import AssetLoader from "../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../components/ModalComponent/ModalManager";
import i18n from "../../../i18n";
import { ActivityAsset } from "../../../model/general-assets/BaseAsset";
import DataBusDefaults from "../../../services/DataBusDefaults";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../abstract-ui/general/Button/BFDetailsButton";
import BFPlaceholder from "../../abstract-ui/general/Placeholder/BFPlaceholder";
import BFUseTooltip from "../../abstract-ui/general/tooltip/BFUseTooltip";
import ActivityUtils from "../ActivityUtils";
import "./ActivityLabel.scss";

interface ActivityLabelProps {
  assetId: string;
  assetType: string;
  withType?: boolean;
  className?: string;
  hideButtonLink?: boolean;
  onClick?: () => void | (() => Promise<void>);
}
const ActivityLabel = (props: ActivityLabelProps) => {
  if (props.assetId === undefined || props.assetType === undefined) return null;
  return (
    <AssetLoader
      assetType={props.assetType}
      id={props.assetId}
      renderLoading={() => (
        <BFPlaceholder loading width={80}>
          {" "}
        </BFPlaceholder>
      )}
      render={(asset: ActivityAsset) => {
        if (!asset) {
          return (
            <div className={`error`}>
              {i18n.t("ActivityLabel.notFound", "Aktivität nicht gefunden")}
            </div>
          );
        }
        const content = (
          <span className={classNames("activity-label", props.className)}>
            {props.withType && (
              <span className="activity-label__type">
                {ActivityUtils.getNameOfActivityType(props.assetType)}{" "}
              </span>
            )}
            {asset.data.activityId && (
              <span className={`activity-label__id`}>
                {asset.data.activityId} -{" "}
              </span>
            )}
            <span className={`activity-label__name`}>
              {asset.data.displayName}
            </span>
          </span>
        );
        if (props.hideButtonLink) {
          return content;
        }
        return (
          <BFDetailsButton
            noPadding
            appearance="link"
            data={{
              assetType: props.assetType,
              assetId: props.assetId,
              type: asset.data.type,
            }}
            onClick={async () => {
              await props.onClick?.();
            }}
          >
            {content}
          </BFDetailsButton>
        );
      }}
    />
  );
};

export default ActivityLabel;

interface ActivitiesLabelProps {
  activities: { assetId: string; assetType: string }[];
  withType?: boolean;
  className?: string;
  hideButtonLink?: boolean;
}
export const ActivitiesLabel = (props: ActivitiesLabelProps) => {
  if (
    props.activities === undefined ||
    !Array.isArray(props.activities) ||
    props.activities.length === 0
  ) {
    return null;
  }
  if (props.activities.length === 1) {
    return (
      <ActivityLabel
        assetId={props.activities[0].assetId}
        assetType={props.activities[0].assetType}
        withType={props.withType}
        className={props.className}
        hideButtonLink={props.hideButtonLink}
      />
    );
  } else {
    const labelContent = (
      <span className={classNames("activities-label", props.className)}>
        {props.activities.length}{" "}
        {i18n.t("ActivityLabel.activities", "Aktivitäten")}
      </span>
    );

    const content = props.hideButtonLink ? (
      labelContent
    ) : (
      <div style={{ display: "inline-block" }}>
        <BFButton
          appearance="link"
          noPadding
          className="activities-button"
          onClick={() => {
            ModalManager.show({
              buttons: [],
              backdrop: true,
              size: "sm",
              noPadding: true,
              content: (states, setStates, closeModal) => (
                <OpenActivitiesModal
                  activities={props.activities}
                  onClose={() => closeModal()}
                />
              ),
            });
          }}
        >
          {labelContent}
        </BFButton>
      </div>
    );

    return (
      <BFUseTooltip
        tooltip={
          <div className={`activities-tooltip`}>
            {props.activities.map((activity, index) => (
              <div className={`activity-label-container`} key={index}>
                <ActivityLabel
                  key={index}
                  assetId={activity.assetId}
                  assetType={activity.assetType}
                  withType={props.withType}
                  hideButtonLink
                />
              </div>
            ))}
          </div>
        }
      >
        {content}
      </BFUseTooltip>
    );
  }
};

const OpenActivitiesModal = (props: {
  onClose: () => void;
  activities: { assetId: string; assetType: string }[];
}) => {
  return (
    <div className={`open-activities-modal`}>
      <div className={`__h2`}>
        {i18n.t("ActivityLabel.activities", "Aktivitäten")}
      </div>
      {props.activities.map((activity, index) => (
        <div className={`activity-label-container`}>
          <ActivityLabel
            key={index}
            assetId={activity.assetId}
            assetType={activity.assetType}
            withType
            onClick={() => {
              DataBusDefaults.closeAllModals();
            }}
          />
        </div>
      ))}
      <div className={`actions`}>
        <BFButton appearance="outline" onClick={props.onClose}>
          {i18n.t("Global.Buttons.close")}
        </BFButton>
      </div>
    </div>
  );
};
