import classNames from "classnames";
import { useState } from "react";
import i18n from "../../i18n";
import { Contact } from "../../model/db/Contact";
import BFButton from "../abstract-ui/general/Button/BFButton";
import "./ContactDetailModal.scss";
import ContactForm from "./ContactForm";
import ContactView from "./ContactView";
import ContactDetailsInvoiceSection from "./contact-details/ContactDetailsInvoiceSection";
import ContactDetailsTenantSection from "./contact-details/ContactDetailsTenantSection";

interface ContactDetailModalProps {
  contact: Contact;
}
const ContactDetails = (props: ContactDetailModalProps) => {
  const [editActive, setEditActive] = useState(false);
  return (
    <div className={classNames(`contact-detail-modal __card`)}>
      <div className={`contact-details`}>
        {editActive && (
          <ContactForm
            noPadding
            onClose={() => setEditActive(false)}
            businessUnits={[props.contact.data.type]}
            onSuccessfulSubmit={() => setEditActive(false)}
            contact={props.contact}
          />
        )}
        {!editActive && (
          <ContactView
            contact={props.contact}
            actions={
              <div className={`contact-view-actions`}>
                <BFButton
                  noPadding
                  appearance={"link"}
                  onClick={() => setEditActive(true)}
                >
                  {i18n.t("Global.Buttons.edit")}
                </BFButton>
              </div>
            }
          />
        )}
      </div>
      <div className={`contact-connections`}>
        {props.contact.data.contactType?.includes("INVOICE") && (
          <ContactDetailsInvoiceSection
            contact={props.contact}
            direction="INCOMING"
          />
        )}
        {props.contact.data.contactType?.includes("BILL") && (
          <ContactDetailsInvoiceSection
            contact={props.contact}
            direction="OUTGOING"
          />
        )}
        {props.contact.data.contactType?.includes("TENANT") && (
          <ContactDetailsTenantSection contact={props.contact} />
        )}
      </div>
    </div>
  );
};

export default ContactDetails;
