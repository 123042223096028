import { CSSProperties } from "react";
import Log from "../../debug/Log";
import { useUserCache } from "../../redux/hooks";
import StringUtils from "../../utils/StringUtils";
import AvatarComponent from "./AvatarComponent";

interface UserAvatarProps {
  id: string;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
  className?: string;
  style?: CSSProperties;
}
const UserAvatar = (props: UserAvatarProps) => {
  const user = useUserCache(props.id);
  const { id, ...avatarProps } = props;

  if (!StringUtils.isObjectId(props.id)) {
    return (
      <AvatarComponent displayName={props.id} avatar="" {...avatarProps} />
    );
  }

  if (user.state === "loading") {
    return (
      <AvatarComponent loading displayName="" avatar="" {...avatarProps} />
    );
  }
  if (user.state === "cached") {
    return (
      <AvatarComponent
        displayName={user.data.displayname}
        avatar={user.data.avatar}
        {...avatarProps}
      />
    );
  }
  if (user.state === "error") {
    // todo add image for error
    return <AvatarComponent displayName={""} avatar={""} {...avatarProps} />;
  }
  Log.error("invalid state - this should never happen", user);
  return null;
};

export default UserAvatar;
