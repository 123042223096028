import GlobalActions from "@/services/GlobalActions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Drawer } from "rsuite";
import ListComponent from "../../configurable/components/ListComponent/ListComponent";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import { Contact, ContactType } from "../../model/db/Contact";
import InfiniteTable from "../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { setUiConfig } from "../../services/ApplicationService";
import PermissionService from "../../services/PermissionService";
import MQ from "../../utils/MatchQueryUtils";
import BFStatus from "../abstract-ui/data/status/BFStatus";
import BFInput from "../abstract-ui/forms/input/BFInput";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFDropdown from "../abstract-ui/general/Dropdown/BFDropdown";
import { DropdownButton } from "../abstract-ui/general/Dropdown/BFDropdownContent";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../abstract-ui/icon/DefaultIcons";
import "./ContactDrawer.scss";
import ContactLabel from "./ContactLabel";
import ContactUtils from "./ContactUtils";

type ContactTypeFilter = { label: string; contactType: ContactType[] };
const getContactTypeFilter: () => ContactTypeFilter[] = () => [
  {
    label: i18n.t("Contacts.DrawerTitle", "Alle Kontakte"),
    contactType: null,
  },
  {
    label: ContactUtils.getContactTypeName("EMPLOYEE"),
    contactType: ["EMPLOYEE"],
  },
  {
    label: ContactUtils.getContactTypeName("INVOICE"),
    contactType: ["INVOICE"],
  },
  {
    label: ContactUtils.getContactTypeName("TENANT"),
    contactType: ["TENANT"],
  },
  {
    label: ContactUtils.getContactTypeName("BILL"),
    contactType: ["BILL"],
  },
  {
    label: ContactUtils.getContactTypeName("OTHER"),
    contactType: ["OTHER", "TRANSIENT"],
  },
];
const TABLE_IDENTIFIER = "CONTACTS_GLOBAL_LISt";
interface ContactDrawerProps {}
const ContactDrawer = (props: ContactDrawerProps) => {
  const [filters] = useState(getContactTypeFilter());
  const [contactFilter, setContactFilter] = useState<ContactTypeFilter>(
    filters[0]
  );
  const tableSearch =
    useTypedSelector(
      (state) => state.application.infiniteTables[TABLE_IDENTIFIER]?.searchTerm
    ) || "";
  const dispatch = useDispatch();
  const open = useTypedSelector(
    (state) => state.uiConfig.general[DefaultUIConfigs.OPEN_CONTACT_DRAWER]
  );

  const onClose = () => {
    dispatch(setUiConfig(DefaultUIConfigs.OPEN_CONTACT_DRAWER, false));
  };
  return (
    <Drawer
      backdropClassName={"contact-drawer-backdrop"}
      placement={"right"}
      size={"sm"}
      open={open}
      onClose={onClose}
      //   closeButton
      //   onBackdropClick={onClose}
    >
      <div className={`contact-drawer-container`}>
        <div className={`header`}>
          <div className={`title`}>
            <BFDropdown
              className="contact-drawer-dropdown"
              items={filters.map(
                (contactType) =>
                  ({
                    type: "button",
                    active: contactFilter === contactType,
                    text: contactType.label,
                    onSelect: () => setContactFilter(contactType),
                  } as DropdownButton)
              )}
              label={contactFilter.label}
            />
          </div>
          <div className={`search`}>
            <BFInput
              focusOnMount
              value={tableSearch}
              placeholder={i18n.t("Global.Labels.Search")}
              onChange={(value: string) => {
                dispatch(InfiniteTable.setSearch(TABLE_IDENTIFIER, value));
              }}
              prefix={<BfIcon {...DefaultIcons.SEARCH} size="xs" />}
            />
          </div>
        </div>
        <div className={`search-result`}>
          <ListComponent
            assetType={AssetTypes.Contact}
            identifier={TABLE_IDENTIFIER}
            additionalMatchQuery={MQ.and(
              MQ.in("data.type", PermissionService.getAllPermissionUnits()),
              contactFilter?.contactType
                ? MQ.in("data.contactType", contactFilter.contactType)
                : undefined
            )}
            render={(contact: Contact) => {
              const infoLine = ContactUtils.getInfoLine(contact);
              return (
                <BFButton
                  appearance="clear-on-white"
                  className={`contact-entry`}
                  onClick={() => {
                    GlobalActions.openDetails(AssetTypes.Contact, contact._id);
                  }}
                >
                  <div className={`left`}>
                    <div className={`contact-name`}>
                      <ContactLabel
                        contact={contact}
                        withType
                        placement="leftStart"
                      />
                    </div>

                    {infoLine && <div className={`info-line`}>{infoLine}</div>}
                  </div>
                  <div className={`right`}>
                    <div className={`tags`}>
                      {ContactUtils.reduceContactTypes(
                        contact.data?.contactType || []
                      ).map((tag) => (
                        <BFStatus
                          key={tag}
                          size="xxs"
                          label={ContactUtils.getContactTypeName(tag)}
                        />
                      ))}
                    </div>
                  </div>
                </BFButton>
              );
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ContactDrawer;
