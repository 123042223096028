import io from "socket.io-client";
import Log from "../../debug/Log";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { store } from "../../redux/store";
import { UNIQUE_REQUEST_ID } from "../../utils/Http";
import { setUiConfig } from "../ApplicationService";
import { isDevelopment } from "./../../utils/Properties";
import { SocketCommand, UpdateCommand } from "./NotificationInterface";

const MAX_ATTEMPTS = 60;
const serverURL = isDevelopment()
  ? process.env.REACT_APP_URL_NOTIFICATIONS
  : "https://" + window.location.hostname;
let _idCounter = 0;
class SocketServiceClass {
  subscriptions: any = {
    general: {},
    user: {},
    group: {},
    team: {},
    assets: {},
  };
  userId: string;
  socket: SocketIOClient.Socket = null;
  reconnectionAttempts = 0;
  intercepter: (data: SocketCommand) => void;
  debugIntercepter: (data: any) => void;

  addIntercepter(callback: (data: SocketCommand) => void) {
    this.intercepter = callback;
  }
  addDebugIntercepter(callback: (data: any) => void) {
    this.debugIntercepter = callback;
  }

  emitSubscribeRequests() {
    ["user", "team", "group"].forEach((type) => {
      if (Object.keys(this.subscriptions[type]).length === 0) {
        this.socket.emit("req", "lr", type);
      }
    });
    Object.entries(this.subscriptions.assets).forEach(([assetType, subs]) => {
      if (Object.keys(subs).length === 0) {
        this.socket.emit("req", "lr", `asset-${assetType}`);
      }
    });
  }

  connect(userId: string, mandator: string, socketId: string) {
    this.userId = userId;

    Log.info("#SocketService - connecting", userId, mandator, socketId);
    this.socket = io(serverURL, {
      query: {
        token: socketId,
        mandator: mandator,
        id: userId,
      },
    });
    this.socket.on("connect", (data) => {
      Log.info("#SocketService socketio connected successfully");

      this.emitSubscribeRequests();
      store.dispatch(
        setUiConfig(DefaultUIConfigs.SOCKET_RECONNECT_STATE, null)
      );
    });
    this.socket.on("debug", (data: string) => {
      Log.info("#SocketService  message", data);
      if (this.debugIntercepter) {
        this.debugIntercepter(data);
      }
    });
    this.socket.on("msg", (data: SocketCommand) => {
      this.handleSocketMessage(data);
    });
    this.socket.on("error", (data) => {
      Log.info("#SocketService  error", data);
    });
    this.socket.on("reconnecting", (data) => {
      Log.info("#SocketService  reconnecting", data);
    });
    this.socket.on("reconnect", (data) => {
      Log.info("#SocketService  reconnect", data);
    });
    this.socket.on("reconnect_attempt", (data) => {
      Log.info("#SocketService  reconnect_attempt", data);
    });
    this.socket.on("connect_timeout", (data) => {
      Log.info("#SocketService  connect_timeout", data);

      setTimeout(() => {
        this.socket.connect();
      }, 1000);
    });
    this.socket.on("connect_error", (data) => {
      Log.info("#SocketService  connect_error", data);
      this.reconnectionAttempts++;

      if (this.reconnectionAttempts > MAX_ATTEMPTS) {
        store.dispatch(
          setUiConfig(DefaultUIConfigs.SOCKET_RECONNECT_STATE, "failed")
        );
      } else {
        store.dispatch(
          setUiConfig(DefaultUIConfigs.SOCKET_RECONNECT_STATE, "reconnecting")
        );

        setTimeout(() => {
          this.socket.connect();
        }, 1000);
      }
    });
    this.socket.on("reconnect_error", (data) => {
      Log.info("#SocketService  reconnect_error", data);

      setTimeout(() => {
        this.socket.connect();
      }, 1000);
    });
    this.socket.on("reconnect_failed", (data) => {
      Log.info("#SocketService  reconnect_failed", data);

      setTimeout(() => {
        this.socket.connect();
      }, 1000);
    });
    this.socket.on("disconnect", (data) => {
      Log.info("#SocketService  disconnect", data);

      setTimeout(() => {
        this.socket.connect();
      }, 1000);
    });
    this.socket.connect();
  }

  emitMessage(event: string, arg1: any, arg2) {
    this.socket.emit(event, arg1, arg2);
  }

  unsubscribe(id: number) {
    ["user", "team", "group"].forEach((type) => {
      if (this.subscriptions[type][id]) {
        delete this.subscriptions[type][id];
        if (Object.keys(this.subscriptions[type]).length === 0) {
          this.socket.emit("req", "lr", type);
          Log.info("#SocketService  emit req lr", type);
        }
      }
    });

    Object.entries(this.subscriptions.assets).forEach(([assetType, subs]) => {
      if (subs[id]) {
        delete subs[id];
        if (Object.keys(subs).length === 0) {
          this.socket.emit("req", "lr", `asset-${assetType}`);
          Log.info("#SocketService  emit req lr", `asset-${assetType}`);
        }
      }
    });
    Object.entries(this.subscriptions.general).forEach(([dataType, subs]) => {
      if (subs[id]) {
        delete subs[id];
      }
    });
  }

  handleSocketMessage(data: SocketCommand) {
    Log.info("#SocketService  message", data);

    if (data.id === UNIQUE_REQUEST_ID) {
      return;
    }
    if (this.intercepter) {
      this.intercepter(data);
    }

    if (data.type !== "general") {
      if (
        data.sender === this.userId &&
        ["update", "create", "delete"].indexOf(data.obj) !== -1
      ) {
        return;
      }

      if (data.type === "asset") {
        if (this.subscriptions.assets[data.assetType]) {
          Object.values(this.subscriptions.assets[data.assetType]).forEach(
            (cb: (data: SocketCommand) => void) => {
              Log.info("#CacheService call socket", cb, data);
              cb(data);
            }
          );
        }
      } else {
        if (this.subscriptions.general[data.type]) {
          Object.values(this.subscriptions[data.type]).forEach(
            (cb: (data: SocketCommand) => void) => cb(data)
          );
        }
      }
    } else {
      if (this.subscriptions.general[data.dataType]) {
        // handle general commands
        Object.values(this.subscriptions.general[data.dataType]).forEach(
          (cb: (data: SocketCommand) => void) => cb(data)
        );
      }
    }
  }

  subscribeAsset(assetType: string, callback: (msg: UpdateCommand) => void) {
    if (!this.subscriptions.assets[assetType]) {
      this.subscriptions.assets[assetType] = {};

      this.socket.emit("req", "jr", `asset-${assetType}`);
      Log.info("#SocketService  emit req jr", `asset-${assetType}`);
    }
    const id = _idCounter++;
    this.subscriptions.assets[assetType][id] = callback;
    return id;
  }

  subscribeUser(callback: (msg: UpdateCommand) => void) {
    if (Object.keys(this.subscriptions.user).length === 0) {
      this.socket.emit("req", "jr", `user`);
      Log.info("#SocketService  emit req jr", `user`);
    }
    const id = _idCounter++;
    this.subscriptions.user[id] = callback;
    return id;
  }
  subscribeTeam(callback: (msg: UpdateCommand) => void) {
    if (Object.keys(this.subscriptions.team).length === 0) {
      this.socket.emit("req", "jr", `team`);
      Log.info("#SocketService  emit req jr", `team`);
    }
    const id = _idCounter++;
    this.subscriptions.team[id] = callback;
    return id;
  }
  subscribeGroup(callback: (msg: UpdateCommand) => void) {
    if (Object.keys(this.subscriptions.group).length === 0) {
      this.socket.emit("req", "jr", `group`);
      Log.info("#SocketService  emit req jr", `group`);
    }
    const id = _idCounter++;
    this.subscriptions.group[id] = callback;
    return id;
  }
  subscribe(dataType: string, callback: (msg: UpdateCommand) => void) {
    if (!this.subscriptions.general[dataType]) {
      this.subscriptions.general[dataType] = {};
    }
    const id = _idCounter++;
    this.subscriptions.general[dataType][id] = callback;
    return id;
  }
}

const SocketService = new SocketServiceClass();
export default SocketService;

if (isDevelopment()) {
  (window as any).SocketService = SocketService;
}
