import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { FilterOptionWithMatchQueryConverter } from "../../../../../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import { DateFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/DateFilterOption";
import { DefaultFilterOptions } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/DefaultFilterOptions";
import { ListFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/ListFilterOption";
import { NumberFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/NumberFilterOption";
import { ListSelectionValueWithNegation } from "../../../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayMultipleSelectionWithNegation";
import LanguageService from "../../../../../../../services/LanguageService";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import { getConfigRentalUnitTypeGroup } from "../../CBTenantsConst";
import { allRentalUnitTypeGroups } from "../../TenantsInterfaces";

export const CBRentalVacancyFilterOptions: (
  objectKindId: string,
  filterTypes?: string[]
) => FilterOptionWithMatchQueryConverter[] = (objectKindId, filterTypes) => {
  const rentalUnitTypeGroups = allRentalUnitTypeGroups.map((group) => {
    const conf = getConfigRentalUnitTypeGroup(group);
    const data = ObjectKindStruct.getUnitTypesByGroup(objectKindId, group);
    return {
      value: group,
      label: conf.label,
      data: data.map((unitType) => unitType.id),
    };
  });

  const unitTypes = ObjectKindStruct.getUnitTypeByObjectKind(objectKindId).map(
    (unitType) => ({
      value: unitType.id,
      label: LanguageService.translateLabel(unitType.displayName),
    })
  );

  return [
    ...DefaultFilterOptions(),
    ListFilterOption({
      field: "data.tags",
      label: i18n.t("cb:RentalVacancy.tags", "Tags"),
      data: UnitStruct.getTagConfigForUnits(
        filterTypes,
        AssetTypes.Rental.RentalVacancy
      )
        .filter((e) => e.status !== "archived")
        .map((e) => ({
          label: LanguageService.translateLabel(e.displayName),
          value: e.id,
        })),
      allowNegation: true,
    }),
    ListFilterOption({
      field: "nested.unitTypeGroup",
      label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
      data: rentalUnitTypeGroups,
      matchQueryConverter: (value: ListSelectionValueWithNegation) =>
        value && value.value && value.value.length > 0
          ? MQ[value.negate ? "nin" : "in"](
              "nested.unitUnitType",
              value.value
                .map(
                  (v) => rentalUnitTypeGroups.find((e) => e.value === v)?.data
                )
                .reduce((prev, cur) => [...prev, ...cur], [])
            )
          : undefined,
    }),
    ListFilterOption({
      field: "nested.unitUnitType",
      label: i18n.t("cb:RentalUnit.unitType", "Art"),
      data: unitTypes,
    }),
    NumberFilterOption({
      field: "nested.unitQuantity",
      label: i18n.t("cb:RentalUnit.quantity", "Anzahl/Fläche"),
      type: "area",
    }),
    DateFilterOption({
      field: "data.moveIn",
      label: i18n.t("cb:RentalVacancy.moveIn"),
    }),
    DateFilterOption({
      field: "data.moveOut",
      label: i18n.t("cb:RentalVacancy.moveOut"),
    }),
    NumberFilterOption({
      field: "data.rentGross",
      label: i18n.t("cb:RentalVacancy.rentGross"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.rentNet",
      label: i18n.t("cb:RentalVacancy.rentNet"),
      type: "currency",
    }),
  ];
};
