import BaseAsset, {
  ActivityAsset,
  TypedBaseAsset,
} from "../../../model/general-assets/BaseAsset";
import { DocumentStoreDocument } from "../../../modules/document-store/DSInterfaces";
import {
  APStructure,
  EntityStructure,
  PortfolioStructure,
  UnitStructure,
} from "../../AppConfigInterfaces";
import { UrgentData } from "../invoiceApp/RAInterfaces";

export type AppConfigDamageClaim = {
  entities: EntityStructure;
  businessUnits: string[];
  businessUnitConfig: UnitStructure;
  portfolio: PortfolioStructure;
  activities: APStructure;
};

export type APOfferStatus = "new" | "inApproval" | "approved" | "rejected";
export interface APOffer {
  linkToCdn: string;
  value: number;
  vendor: string;
  executionDate?: Date;
  mail?: string;
  phone?: string;
  status: APOfferStatus;
  note?: string;
  didComparison?: boolean;
  comparisons?: {
    article: string;
    comparisonPrice: number;
    unitPrice: number;
    link: string;
  }[];
}

export type APOfferApprovalHistory = {
  type: "OFFER_SELECTED" | "OFFER_STARTED";
  user: string;
  id?: string;
  date: Date;
};
export interface APOfferApproval {
  id: string;
  displayName: string;
  offerChain: any[];
  taskId: string;
  status: string;
  offersInApproval: string[];
  chosenOffer: {
    id: string;
  };
  history: APOfferApprovalHistory[];
  declineReason?: string;
  urgent?: UrgentData;
}

export interface APActivity extends ActivityAsset {
  data: {
    meta?: any;
    activityId: string;
    displayName: string;
    type: string;
    entity: string;
    objectId: string;
    relations?: { assetId: string; assetType: string }[];
    status: string;
    subStatus?: string;
    assignee?: string;
    shortDescription: string;
    estimatedCosts?: number;
    isInsuranceClaim?: boolean;
    creationDate: Date;
    activityCostCenter?: string;
    finishDate?: Date;

    priority?: APPriority;
    dueDate?: Date;
    tags?: string[];
    supervisor?: string[];
    attachments: DocumentStoreDocument[];
    offers: APOffer[];
    lastChange: {
      date: Date;
      type: "internal" | "external";
      from: string;
    };

    currentActiveBudgetId?: string;

    offerApproval: APOfferApproval[];
    extra: {
      sumInvoiceValue?: number;
    };
  };
  nested: {
    renterDisplayName: string;
    contactDisplayName?: string;
    _id: string;
  };
}

export type APProjectActivity = APActivity & {
  data: {
    dateFrom?: Date;
    dateTo?: Date;
  };
  internal: {
    budgetApprovalId?: string;
  };
  calculated: {
    budgetSumShould?: number;
    budgetSumBooked?: number;
    progressDate?: number;
    progressBudget?: number;
    progressSchedule?: number;

    notLinkedInvoiceValue?: number;
  };
};

export enum APPriority {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  CRITICAL = 4,
}

export interface APActivityRating extends BaseAsset {
  data: {
    linkedAsset: {
      assetId: string;
      assetType: string;
    };
    ratedAssignee?: string;
    rating: number;
    comment: string;
    potentialSavings?: number;
  };
}

export type APRentReductionFormValue = {
  activityId: string;
  type: string;

  originalAmount: number;
  reducedAmount: number;

  damageSince: Date;
  damageReported: Date;
  damageFixed?: Date;

  noticeDamageRecification: boolean;
  reductionCalculationNote: string;
  reductionLegalBasis: string;
};
export type APRentReductionData = APRentReductionFormValue & {
  attachments: DocumentStoreDocument[];
};
export type APRentReduction = TypedBaseAsset<APRentReductionData>;
