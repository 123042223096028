import { TARGET } from "../../utils/Http";
import {
  APConfig,
  APConfigStatus,
  APConfigTag,
  APStatusTags,
  APStructure,
} from "../AppConfigInterfaces";
import { APActivity } from "../tatar/activityApp/ActivityInterfaces";
import AbstractAppConfigUtils from "./AbstractAppConfigUtils";

class ACActivitytilsClass extends AbstractAppConfigUtils<APStructure> {
  getName(): string {
    return "activities";
  }
  getUrl() {
    return `/${this.constants?.serviceUrl}/getConfig`;
  }
  getTarget(): TARGET {
    return "EMPTY";
  }
  convertData(data: any): APStructure {
    return data as APStructure;
  }
  getConfig(type: string) {
    return this.useCache<APConfig>("getConfig", arguments, () =>
      this.getData()?.find((e) => e.data.type === type)
    );
  }
  getStatusOf(damageClaim: APActivity) {
    return this.getStatus(damageClaim.data.type, damageClaim.data.status);
  }
  getAllStatus(types?: string[]) {
    return this.useCache<(APConfigStatus & { type: string })[]>(
      "getAllStatus",
      arguments,
      () => {
        const configs = this.getData();
        const status: (APConfigStatus & { type: string })[] = [];
        configs.forEach((config) => {
          if (!types || types.includes(config.data.type)) {
            status.push(
              ...config.data.status.map(
                (e) =>
                  ({
                    ...e,
                    type: config.data.type,
                  } as APConfigStatus & { type: string })
              )
            );
          }
        });
        return status;
      }
    );
  }
  getStatus(type: string, status: string) {
    return this.useCache<APConfigStatus>("getStatus", arguments, () => {
      const config = this.getConfig(type);
      if (!config) {
        return null;
      }
      return config?.data?.status?.find((e) => e.id === status);
    });
  }
  getStatusByTags(tag: APStatusTags) {
    return this.useCache<APConfigStatus[]>("getStatusByTags", arguments, () => {
      const configs = this.getAllStatus();
      if (!configs) {
        return null;
      }
      return configs.filter((e) => e.tags.includes(tag));
    });
  }
  getStatusByTypeAndTags(type: string, tag: APStatusTags) {
    return this.useCache<APConfigStatus[]>("getStatusByTags", arguments, () => {
      const configs = this.getAllStatus([type]);
      if (!configs) {
        return null;
      }
      return configs.filter((e) => e.tags.includes(tag));
    });
  }

  getTagsOf(damageClaim: APActivity) {
    const tags = this.getTags(damageClaim.data.type);
    return (damageClaim.data.tags || [])
      .map((tag) => tags.find((t) => t.id === tag) || null)
      .filter((e) => e !== null);
  }
  getTags(type: string) {
    return this.useCache<APConfigTag[]>("getTags", arguments, () => {
      const config = this.getConfig(type);
      if (!config) {
        return [];
      }
      return config.data.tags;
    });
  }
  getAllTags(types?: string[]) {
    return this.useCache<(APConfigTag & { type: string })[]>(
      "getAllTags",
      arguments,
      () => {
        const configs = this.getData();
        const tags: (APConfigTag & { type: string })[] = [];
        configs.forEach((config) => {
          if (!types || types.includes(config.data.type)) {
            tags.push(
              ...config.data.tags.map((e) => ({ ...e, type: config.data.type }))
            );
          }
        });
        return tags;
      }
    );
  }
  getStatusByActivityStatus(
    ...activityStatus: ("active" | "inactive" | "waiting")[]
  ) {
    return this.useCache<APConfigStatus[]>("getStatusBy", arguments, () => {
      const status = this.getAllStatus();
      if (!status) {
        return [];
      }
      return status.filter((e) => activityStatus.includes(e.activity_status));
    });
  }
  getStatusEntries(type: string, currentStatus: string) {
    return this.useCache<APConfigStatus[]>(
      "getStatusEntries",
      arguments,
      () => {
        const config = this.getConfig(type);
        if (!config) {
          return [];
        }
        const status = config.data.status.find((e) => e.id === currentStatus);
        if (status) {
          return [
            status,
            ...config.data.status.filter((e) =>
              status.possibleNextStatuses.includes(e.id)
            ),
          ];
        }
        return [];
      }
    );
  }
}
const ACActivityUtils = new ACActivitytilsClass();
export default ACActivityUtils;
(window as any).ACActivityUtils = ACActivityUtils;
