import React from "react";
import { Message } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

interface MessageProps {
  type?: TypeAttributes.Status;
  bordered?: boolean;
  centered?: boolean;
  closable?: boolean;
  duration?: number;
  header?: React.ReactNode;
  showIcon?: boolean;
  full?: boolean;
  children: React.ReactElement;
  onClose?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

const BFMessageBox = (props: MessageProps) => {
  return <Message {...props} />;
};

export default BFMessageBox;
