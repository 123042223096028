import classNames from "classnames";

import i18n from "../../../../../../i18n";
import {
  Contact,
  OrganizationContact,
  PersonContact,
} from "../../../../../../model/db/Contact";
import FormUtils from "../../../../../../utils/FormUtils";
import LabeledInput from "../../../../../abstract-ui/forms/LabeledInput";
import { Resubmission } from "../../../../RSInterfaces";
import RSAssetDetails from "../../../asset-details/RSAssetDetails";
import RSSubAssetMapper from "../../../asset-details/sub-asset-mapper/RSSubAssetMapper";
import "./RSTenantPreview.scss";

interface RSTenantPreviewProps {
  resubmission: Resubmission;
}

const RSTenantPreview = (props: RSTenantPreviewProps) => {
  const { linkedAsset } = props.resubmission.data;

  const renderTypeInfoDetails = (tenant: Contact) => {
    return (
      <RSSubAssetMapper resubmission={props.resubmission} asset={tenant} />
    );
  };

  return (
    <div className={classNames("rs-preview-tenant")}>
      <LabeledInput
        type="sub"
        label={i18n.t("Resubmission.Dashboard.Tenant.Title", "Mieter Daten")}
      >
        <RSAssetDetails
          assetParams={linkedAsset}
          renderAssetData={(asset: Contact) => {
            return (
              <div className="tenant-container">
                {asset.data.personType === "private" && (
                  <div className={`entry-list`}>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:Tenant.Labels.TenantType", "Art")}
                      </div>
                      <div className={`value text-selection-all`}>
                        {i18n.t("cb:Tenant.Labels.private", "Privat")}
                      </div>
                    </div>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:Tenant.Labels.salutation", "Anrede")}
                      </div>
                      <div className={`value text-selection-all`}>
                        {
                          FormUtils.getSalutationOptions().find(
                            (e) =>
                              e.value ===
                              (asset as PersonContact).data.salutation
                          )?.label
                        }
                      </div>
                    </div>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:Tenant.Labels.firstName", "Vorname")}
                      </div>
                      <div className={`value text-selection-all`}>
                        {(asset as PersonContact).data.firstName || "-"}
                      </div>
                    </div>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:Tenant.Labels.lastName", "Nachname")}
                      </div>
                      <div className={`value text-selection-all`}>
                        {(asset as PersonContact).data.lastName || "-"}
                      </div>
                    </div>
                  </div>
                )}
                {asset.data.personType === "organization" && (
                  <div className={`entry-list`}>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:Tenant.Labels.TenantType", "Art")}
                      </div>
                      <div className={`value text-selection-all`}>
                        {i18n.t("cb:Tenant.Labels.organization", "Firma")}
                      </div>
                    </div>

                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:Tenant.Labels.companyName", "Firmenname")}
                      </div>
                      <div className={`value text-selection-all`}>
                        {(asset as OrganizationContact).data.companyName || "-"}
                      </div>
                    </div>
                  </div>
                )}
                {asset.data.address && asset.data.address.length > 0 && (
                  <>
                    <div className={`__h3`}>
                      {i18n.t("cb:Tenant.Labels.Address", "Adresse")}
                    </div>
                    <div className={`entry-list-container`}>
                      {asset.data.address.map((address) => {
                        return (
                          <div className={`entry-list entry-list-element`}>
                            {[
                              [
                                i18n.t(
                                  "cb:Tenant.Labels.AddressField.Street",
                                  "Straße"
                                ),
                                address.street || "-",
                              ],
                              [
                                i18n.t(
                                  "cb:Tenant.Labels.AddressField.Additional",
                                  "Zusatz"
                                ),
                                address.additional || "-",
                              ],
                              [
                                i18n.t(
                                  "cb:Tenant.Labels.AddressField.ZIP",
                                  "Postleitzahl"
                                ),
                                address.zip || "-",
                              ],
                              [
                                i18n.t(
                                  "cb:Tenant.Labels.AddressField.City",
                                  "Stadt"
                                ),
                                address.city || "-",
                              ],
                              [
                                i18n.t(
                                  "cb:Tenant.Labels.AddressField.Country",
                                  "Land"
                                ),
                                FormUtils.getCountryLabel(address.country) ||
                                  "-",
                              ],
                            ].map(([label, value]) => (
                              <div className={`entry`}>
                                <div className={`label`}>{label}</div>
                                <div className={`value text-selection-all`}>
                                  {value}
                                </div>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {linkedAsset.subAsset ||
                  (linkedAsset.assetField && (
                    <div className={classNames("object-row", "type-info")}>
                      {renderTypeInfoDetails(asset)}
                    </div>
                  ))}
              </div>
            );
          }}
        />
      </LabeledInput>
    </div>
  );
};

export default RSTenantPreview;
