import { EmailFile } from "@/modules/document-store/hooks/useEmailFile";
import { openOrDownloadDocument } from "@/redux/actions/ui-config/ui-config-actions";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Collapse from "rsuite/esm/Animation/Collapse";
import useResizeObserver from "use-resize-observer";
import { TagConfigWithUnit } from "../../../apps/AppConfigInterfaces";
import UserAvatar from "../../../components/AvatarComponent/UserAvatar";
import Userlabel from "../../../components/AvatarComponent/Userlabel";
import ModalManager from "../../../components/ModalComponent/ModalManager";
import Timestamp from "../../../components/Timestamp/Timestamp";
import i18n from "../../../i18n";
import { isDefined } from "../../../utils/Helpers";
import { useGlobalKeyEvent } from "../../../utils/Hooks";
import TagView from "../../abstract-ui/data/tag-component/TagView";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import BFPortal from "../../abstract-ui/general/Portal/BFPortal";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../abstract-ui/icon/DefaultIcons";
import CommentsService from "../CommentsService";
import "./CommentContainer.scss";
import CommentEntryFooter, {
  CommentEntryFooterProps,
} from "./CommentEntryFooter";

interface CommentContainerProps {
  className?: string;
  sender?: string;
  icon?: string;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  date: Date;
  subData?: React.ReactNode;
  tagData?: {
    possibleTags: TagConfigWithUnit[];
    tags: string[];
  };
  pinned?: {
    userId: string;
    date: Date;
  };
  context?: {
    id: number;
    name: string;
  };
  commentId: string;

  archived?: {
    userId: string;
    reason: string;
  };

  content: React.ReactNode;
  onContextClick?: (contextId: number) => void;
  footer?: CommentEntryFooterProps;
  allowFullscreen?: boolean;
  allowArchive?: boolean;
  hideAllActions?: boolean;
  disableFocusMode?: boolean;
  apperance?: "normal" | "slim";
  onClose?: () => void;
  renderMailDuplicateAction?: (closeModal: () => void) => JSX.Element;
  onPinChange?: () => void;
  collapsible?: boolean;
  onPrint?: () => Partial<EmailFile>;
}
export const CommentContainer = (props: CommentContainerProps) => {
  const [archivedCollapsed, setArchivedCollapsed] = useState(
    props.onClose ? false : true
  );
  const [messageCollapsed, setMessageCollapsed] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const dispatch = useDispatch();
  const allowFullscreen =
    props.allowFullscreen === undefined ? true : props.allowFullscreen;
  const allowArchive =
    props.allowArchive === undefined ? true : props.allowArchive;

  const isArchived = isDefined(props.archived);

  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
  useGlobalKeyEvent(["Escape"], (ev) => {
    if (ev.key === "Escape" && isFullscreen) {
      setIsFullscreen(false);
    }
  });
  useEffect(() => {
    const child = contentRef.current?.querySelector(
      "div.comment-content-entry"
    );
    if (child) {
      if (child.clientHeight > contentRef.current?.clientHeight) {
        setShowScrollInfo(true);
      } else {
        setShowScrollInfo(false);
      }
    }
  }, [height]);

  const [showScrollInfo, setShowScrollInfo] = useState(false);
  const [scrollable, setScrollable] = useState(
    props.disableFocusMode ? true : false
  );

  const useAppearance = isFullscreen ? "normal" : props.apperance || "normal";
  const headerLeft = (
    <div className={`left`}>
      <div className={`header-text`}>{props.title}</div>
      {props.subTitle && <div className={`sub-text`}>{props.subTitle}</div>}
      <div className={`date`}>
        <Timestamp date={props.date} addDateBehind />
      </div>
    </div>
  );
  const content = (
    <>
      <div
        ref={ref}
        className={`content-wrapper`}
        tabIndex={0}
        onFocus={() => {
          if (props.disableFocusMode) return;
          setScrollable(true);
          // if (showScrollInfo) {
          //   contentRef.current?.scrollIntoView({
          //     behavior: "smooth",
          //     block: "center",
          //   });
          // }
        }}
        onBlur={() => {
          if (props.disableFocusMode) return;
          setScrollable(false);
        }}
      >
        <div
          className={`content`}
          ref={contentRef}
          style={{
            overflow: isFullscreen || scrollable ? "auto" : "hidden",
          }}
        >
          {props.content}
        </div>
        {!isFullscreen && showScrollInfo && !scrollable && (
          <div className={`scroll-info`}>
            {i18n.t(
              "CommentsModule.CommentEntry.scrollInfo",
              "Klicken Sie auf den Inhalt, um scrollen zu können."
            )}
          </div>
        )}
      </div>

      {props.footer && (
        <CommentEntryFooter
          {...props.footer}
          onReply={
            props.footer.onReply
              ? () => {
                  setIsFullscreen(false);
                  props.footer?.onReply();
                }
              : undefined
          }
        />
      )}
    </>
  );
  const isCollapsible = props.collapsible && !isFullscreen;
  const container = (
    <div
      className={classNames(
        "comment-container",
        props.className,
        `mode-${useAppearance}`
      )}
      // onFocusCapture={() => setScrollable(true)}

      // onBlurCapture={() => setScrollable(false)}
    >
      {props.sender && (
        <div className={`user`}>
          <UserAvatar id={props.sender} size={40} />
        </div>
      )}
      {props.icon && (
        <div className={`icon-container`}>
          <BfIcon type="light" width={24} height={24} data={props.icon} />
        </div>
      )}
      <div className={`bubble`}>
        <div className={`header`}>
          <div className={`header-main`}>
            {isCollapsible && (
              <BFButton
                noPadding
                className={`collapse-button`}
                appearance="clear-on-white"
                onClick={() => setMessageCollapsed(!messageCollapsed)}
              >
                {headerLeft}
                <div
                  className={classNames(`chevron`, {
                    expanded: !messageCollapsed,
                  })}
                >
                  <BfIcon {...DefaultIcons.CHEVRON} size="xs" />
                </div>
              </BFButton>
            )}
            {!isCollapsible && headerLeft}
            <div className={`right`}>
              {props.context && (
                <BFButton
                  appearance="link"
                  onClick={
                    props.onContextClick
                      ? () => props.onContextClick(props.context.id)
                      : undefined
                  }
                  disabled={!props.onContextClick}
                >
                  {props.context.name}
                </BFButton>
              )}

              {!props.hideAllActions && (
                <div className={`actions`}>
                  {props.onPinChange && (
                    <BFButton
                      tooltip={{
                        tooltip: props.pinned
                          ? i18n.t("CommentModule.Unpin", "Anpinnen aufheben")
                          : i18n.t("CommentModule.Pin", "Nachricht anpinnen"),
                      }}
                      onClick={async () => {
                        if (props.pinned) {
                          await CommentsService.unpinComment(props.commentId);
                        } else {
                          await CommentsService.pinComment(props.commentId);
                        }
                        props.onPinChange();
                      }}
                      appearance={props.pinned ? "link" : "link"}
                    >
                      <BfIcon
                        type="light"
                        data={props.pinned ? "bh-unpin" : "bh-pin"}
                        size="lg"
                      />
                    </BFButton>
                  )}
                  {props.onPrint && (
                    <BFButton
                      onClick={() => {
                        const mailProps = props.onPrint();
                        const body = contentRef.current.innerHTML;
                        dispatch(
                          openOrDownloadDocument(
                            { ...mailProps, html: body },
                            "comment"
                          )
                        );
                      }}
                      appearance="link"
                      className="print-button"
                    >
                      <BfIcon type="light" data="printer" size="xs" />
                    </BFButton>
                  )}
                  {props.renderMailDuplicateAction && (
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t(
                          "CommentModule.Duplicate",
                          "Nachricht duplizieren"
                        ),
                      }}
                      onClick={() => {
                        ModalManager.show({
                          buttons: [],
                          backdrop: "static",
                          size: "xxl",
                          content: (states, setStates, closeModal) => {
                            return props.renderMailDuplicateAction(closeModal);
                          },
                        });
                      }}
                      appearance="link"
                    >
                      <BfIcon type="light" data="duplicate-file" size="xs" />
                    </BFButton>
                  )}
                  {allowArchive && !isArchived && (
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t(
                          "CommentModule.Archive",
                          "Nachricht archivieren"
                        ),
                      }}
                      onClick={() => {
                        ModalManager.input({
                          message: i18n.t(
                            "CommentModule.ArchiveMessage",
                            "Bitte geben Sie einen Grund für das Archivieren der Nachricht an."
                          ),
                          title: i18n.t("CommentModule.Archive", "Archivieren"),
                          confirmButtonText: i18n.t(
                            "CommentModule.Archive",
                            "Archivieren"
                          ),
                          inputProps: {
                            max: 255,
                          },
                          onFinish: (reason: string) => {
                            CommentsService.archiveComment(
                              props.commentId,
                              reason
                            );
                          },
                        });
                      }}
                      appearance="link"
                    >
                      <BfIcon type="light" data="archive" size="xs" />
                    </BFButton>
                  )}
                  {allowArchive && isArchived && (
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t(
                          "CommentModule.Unarchive",
                          "Archivierung aufheben"
                        ),
                      }}
                      onClick={() => {
                        ModalManager.confirm({
                          message: i18n.t(
                            "CommentModule.UnarchiveMessage",
                            "Wpllen Sie die Archivierung der Nachricht aufheben?"
                          ),
                          title: i18n.t(
                            "CommentModule.Unarchive",
                            "Archivierung aufheben"
                          ),
                          confirmButtonText: i18n.t(
                            "CommentModule.Unarchive",
                            "Archivierung aufheben"
                          ),
                          onConfirm: () => {
                            CommentsService.unarchiveComment(props.commentId);
                          },
                        });
                      }}
                      appearance="link"
                    >
                      <BfIcon type="light" data="archive-drawer" size="xs" />
                    </BFButton>
                  )}
                  {isFullscreen && (
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t(
                          "CommentModule.CloseFullscreen",
                          "Vollbildmodus schließen"
                        ),
                      }}
                      onClick={() => setIsFullscreen(false)}
                      appearance="link"
                    >
                      <BfIcon type="light" data="close" size="xs" />
                    </BFButton>
                  )}
                  {allowFullscreen && !isFullscreen && (
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t(
                          "CommentModule.OpenFullscreen",
                          "Vollbildmodus öffnen"
                        ),
                      }}
                      onClick={() => setIsFullscreen(true)}
                      appearance="link"
                    >
                      <BfIcon type="light" data="expand-1" size="xs" />
                    </BFButton>
                  )}

                  {props.onClose && !isFullscreen && (
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t(
                          "CommentModule.CloseEntry",
                          "Nachricht schließen"
                        ),
                      }}
                      onClick={props.onClose}
                      appearance="link"
                    >
                      <BfIcon type="light" data="close" size="xs" />
                    </BFButton>
                  )}
                </div>
              )}
            </div>
          </div>

          {props.subData}
          {props.tagData && props.tagData.tags?.length > 0 && (
            <div className={`tag-view-container`}>
              <TagView {...props.tagData} />
            </div>
          )}
        </div>
        {isCollapsible && (
          <Collapse in={!messageCollapsed}>
            <div>{content}</div>
          </Collapse>
        )}
        {!isCollapsible && content}
      </div>
    </div>
  );

  if (isFullscreen) {
    return (
      <BFPortal>
        <div className={`comment-container-fullscreen`}>
          <div className={`comment-container-fullscreen-wrapper`}>
            {container}
          </div>
        </div>
      </BFPortal>
    );
  } else {
    if (allowArchive && isArchived) {
      return (
        <div className={`archived-comment-container`}>
          <div className={`archived-info-container`}>
            <div className={`archived-description`}>
              <Userlabel id={props.archived.userId} />
              <div>
                {i18n.t(
                  "CommentsModule.CommentEntry.archivedComment",
                  "hat eine Nachricht archiviert"
                )}
              </div>
              {!isDefined(props.onClose) && (
                <BFButton
                  noPadding
                  onClick={() => setArchivedCollapsed(!archivedCollapsed)}
                  appearance="link"
                >
                  {archivedCollapsed
                    ? i18n.t("CommentsModule.CommentEntry.show", "Anzeigen")
                    : i18n.t("CommentsModule.CommentEntry.hide", "Verstecken")}
                </BFButton>
              )}
            </div>
            {props.archived.reason && (
              <div className={`reason`}>{props.archived.reason}</div>
            )}
          </div>
          <Collapse in={!archivedCollapsed}>
            <div>{container}</div>
          </Collapse>
        </div>
      );
    } else {
      return container;
    }
  }
};
