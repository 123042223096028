import classNames from "classnames";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import { Resubmission } from "../../../../../../../../modules/resubmission/RSInterfaces";
import RSSubAssetMapper from "../../../../../../../../modules/resubmission/components/asset-details/sub-asset-mapper/RSSubAssetMapper";
import RSListDetailsComponent from "../../../../../../../../modules/resubmission/components/list-details/RSListDetailsComponent";
import { RentalVacancy } from "../../../TenantsInterfaces";

interface CBRentalVacancyResubmissionViewProps {
  rentalVacancy: RentalVacancy;
}
const CBRentalVacancyResubmissionView = (
  props: CBRentalVacancyResubmissionViewProps
) => {
  const renderResubmissionTenantDetails = (resubmission: Resubmission) => {
    return (
      <RSSubAssetMapper
        resubmission={resubmission}
        asset={props.rentalVacancy}
      />
    );
  };

  return (
    <div className={classNames("cb-tenant-resubmission-view")}>
      <RSListDetailsComponent
        asset={props.rentalVacancy}
        assetType={AssetTypes.Rental.RentalVacancy}
        assetDescription={i18n.t(
          "AssetTypes.CB.Labels.RentalVacancy",
          "Leerstand"
        )}
        listIdentifier={`AP_RESUBMISSIONS_${props.rentalVacancy._id}`}
        renderResubmissionAssetDetails={renderResubmissionTenantDetails}
        typePermissions={[props.rentalVacancy.data.type]}
      />
    </div>
  );
};

export default CBRentalVacancyResubmissionView;
