import AssetImages from "@/components/CDNImageGallery/AssetImages";
import POIMapComponent from "@/components/MapComponent/POIMapComponent";
import ModalManager from "@/components/ModalComponent/ModalManager";
import DebugDataComponent from "@/debug/DebugDataComponent";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import PermissionService from "@/services/PermissionService";
import { isNotDefined } from "@/utils/Helpers";
import ObjectForm from "../../../components/object-form/ObjectForm";
import { OAObject } from "../../../types/object.interface";
import {
  ObjectKind,
  checkAnyFeature,
  checkFeature,
} from "../../../types/objectKind.interface";
import ObjectExportDialog from "../../export/ObjectExportDialog";
import ObjectDetailData from "./ObjectDetailData";
import "./ObjectDetailGeneral.scss";

interface ObjectDetailGeneralProps {
  goBack?: () => void;
  asset: OAObject;
  kind: ObjectKind;
}

const ObjectDetailGeneral = (props: ObjectDetailGeneralProps) => {
  return (
    <div className={`object-detail-object-view`}>
      <div className={`header`}>
        <div className="gallery-container">
          <AssetImages
            assetId={props.asset._id}
            assetType={AssetTypes.Portfolio.Object}
            fieldPath="data.images"
            dimension={"md"}
          />
        </div>
        {/* <div
          className="background-image"
          style={{
            backgroundImage: `url(https://www.tatar.de/wp-content/uploads/2018/07/Goettingen-Zindelstrasse-5-7.jpg)`,
          }}
        /> */}
        {/* <img
          src={`https://www.tatar.de/wp-content/uploads/2018/07/Goettingen-Zindelstrasse-5-7.jpg`}
          alt={"meh"}   
        /> */}
      </div>
      <div className="asset-data">
        <div className={`title-bar`}>
          <BFButton
            className="go-back-action"
            appearance={"clear-on-white"}
            icon={{ size: "xs", ...DefaultIcons.BACK }}
            onClick={props.goBack}
          />
          <div className="title">
            <span className={`object-id`}>{props.asset?.data?.id}</span>{" "}
            {props.asset.data.displayName}
          </div>

          <div className="actions">
            {/* <BFButton
              appearance={"clear"}
              className="edit-button"
              icon={{ size: "xs", type: "light", data: "expand-1" }}
            /> */}
            {PermissionService.hasObjectKindPermission(
              props.kind,
              "asset.export"
            ) && (
              <BFButton
                appearance={"link"}
                className="export-button"
                size="xs"
                icon={{ size: "xs", type: "light", data: "office-file-pdf-1" }}
                onClick={async () => {
                  ModalManager.show({
                    size: "sm",
                    noPadding: true,
                    content: (state, setState, close) => (
                      <ObjectExportDialog
                        assets={[props.asset]}
                        referenceId={props.asset._id}
                        title={props.asset.data.displayName}
                        kind={props.kind}
                        onClose={close}
                      />
                    ),
                  });
                  // const hasFeatures =
                  //   checkAnyFeature(["address", "immo"], props.kind) ||
                  //   (props.kind?.data?.customfields || []).length > 0;
                  // ModalManager.show({
                  //   noPadding: true,
                  //   size: hasFeatures ? "xl" : "sm",
                  //   content: (states, setStates, closeModal) => (
                  //     <ObjectForm
                  //       kind={props.kind}
                  //       onClose={closeModal}
                  //       asset={props.asset}
                  //       onSuccess={(updatedAsset) => {
                  //         if (isNotDefined(props.asset)) {
                  //           //reload table, because a new one was added
                  //         }
                  //       }}
                  //     />
                  //   ),
                  // });
                }}
              />
            )}
            {PermissionService.hasObjectKindPermission(
              props.kind,
              "asset.createEdit"
            ) && (
              <BFButton
                appearance={"link"}
                className="edit-button"
                size="xs"
                icon={{ size: "xs", ...DefaultIcons.EDIT }}
                onClick={() => {
                  const hasFeatures =
                    checkAnyFeature(["address", "immo"], props.kind) ||
                    (props.kind?.data?.customfields || []).length > 0;

                  ModalManager.show({
                    noPadding: true,
                    size: hasFeatures ? "xl" : "sm",
                    content: (states, setStates, closeModal) => (
                      <ObjectForm
                        kind={props.kind}
                        onClose={closeModal}
                        asset={props.asset}
                        onSuccess={(updatedAsset) => {
                          if (isNotDefined(props.asset)) {
                            //reload table, because a new one was added
                          }
                        }}
                      />
                    ),
                  });
                }}
              />
            )}
          </div>
        </div>
        <DebugDataComponent data={props.asset} />

        <ObjectDetailData asset={props.asset} kind={props.kind} />
        {/* <CBPortfolioObjectView object={props.asset} />
        )} */}
        {/* {props.assetType === "portfolio-group" && (
          <div>
            {props.asset?.data?.objects?.map((objectId, index) => (
              <CBPortfolioGroupObjectWrapper
                key={objectId}
                index={index}
                objectId={objectId}
                onObjectLoaded={(obj) => {
                  if (Object.keys(obj.data.images || {}).length > 0) {
                    setImages([
                      ...images,
                      ...mapPortfolioObjectImagesToCDNEntries(obj, true),
                    ]);
                  }
                }}
              />
            ))}
          </div>
        )} */}
      </div>
      {checkFeature("flightradar24", props.kind) && (
        <div className={`flight-container`}>
          <FlightRadarFeature asset={props.asset} />
        </div>
      )}
      {checkFeature("address", props.kind) && (
        <div className={`map-container`}>
          {/* {props.assetType === "portfolio-group" && ( */}
          {/* <POIMapComponentPortfolioGroup
           asset={props.asset} /> */}
          {/* )}
        {props.assetType === "portfolio-object" && (
        )} */}
          <POIMapComponentPortfolioObject asset={props.asset} />
        </div>
      )}
    </div>
  );
};

export default ObjectDetailGeneral;

export const POIMapComponentPortfolioObject = (props: { asset: OAObject }) => {
  return (
    <POIMapComponent
      POIs={[
        {
          geo: {
            lng: props.asset?.data?.feature?.address?.location?.lng || 0,
            lat: props.asset?.data?.feature?.address?.location?.lat || 0,
          },
        },
      ]}
    />
  );
};

const FlightRadarFeature = (props: { asset: OAObject }) => {
  return (
    <iframe
      src={
        "https://www.flightradar24.com/simple?reg=" +
        props.asset.data.id +
        "&solo=1&z=8"
      }
      width="100%"
      height="100%"
      title="Flightradar24 Tracking"
      frameBorder="0"
    />
  );
};
