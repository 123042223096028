import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import classNames from "classnames";
import moment from "moment";
import { Field, FormRenderProps } from "react-final-form";
import FormFieldValues from "../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../i18n";
import { useTypedSelector } from "../../../../redux/hooks";
import LabeledInput from "../../../abstract-ui/forms/LabeledInput";
import BFAssignmentDefault from "../../../abstract-ui/forms/assignment/BFAssignmentDefault";
import BFDate from "../../../abstract-ui/forms/date/BFDate";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import BFSelect from "../../../abstract-ui/forms/select/BFSelect";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import ValidationPopover from "../../../abstract-ui/general/ValidationPopover/ValidationPopover";
import EZSelect from "../../../ez-form/form-elements/ez-select/EZSelect";
import {
  RSAssetBaseParams,
  RSAssetParams,
  Resubmission,
} from "../../RSInterfaces";
import RSService from "../../RSService";
import "./ResubmissionForm.scss";
import RSFeedbackFormPart from "./form-parts/RSFeedbackFormPart";
import RSRepetitionFormPart from "./form-parts/RSRepetitionFormPart";

interface ResubmissionFormProps {
  onClose: () => void;
  assetParams?: RSAssetBaseParams;
  listIdentifier?: string;
  resubmission?: Resubmission;
  typePermissions?: string[];
}

const ResubmissionForm = (props: ResubmissionFormProps) => {
  const unitTypes =
    UnitStruct.getUnitSelectOptions(props.typePermissions) || [];
  const activeApplication = useTypedSelector(
    (state) => state.uiConfig.activeApplication
  );
  const currentUserId = useTypedSelector((state) => state.global.user._id);

  const linkedAsset: RSAssetParams | undefined = props.assetParams
    ? {
        ...props.assetParams,
        appDisplayName: activeApplication.displayName,
        appLogoUrl: activeApplication.logo.drawer,
      }
    : undefined;

  const initialValuesEmpty = {
    title: "",
    description: "",
    dueDate: moment().add(1, "days").toDate(),
    linkedAsset: linkedAsset,
    resubmissionType: "single",
    type: unitTypes.length === 1 ? unitTypes[0].value : undefined,
    status: "open",
    priority: "low",
    category: "todo",
    assignees: {
      users: [currentUserId],
      teams: [],
    },
  };

  const initialValues = !props.resubmission
    ? initialValuesEmpty
    : {
        id: props.resubmission._id,
        title: props.resubmission.data.title,
        type: props.resubmission.data.type,
        category: props.resubmission.data.category,
        priority: props.resubmission.data.priority,
        resubmissionType: props.resubmission.data.resubmissionType,
        description: props.resubmission.data.description,
        status: props.resubmission.data.status,
        assignees: props.resubmission.data.assignees,
        dueDate: props.resubmission.data.dueDate,
        feedbackRecipients: props.resubmission.data.feedbackRecipients,
        remindDate: props.resubmission.data.remindDate,
        // Interval data will come later
      };

  const formTitle = props.resubmission
    ? i18n.t("Resubmission.Labels.Title.Edit", "Wiedervorlage bearbeiten")
    : i18n.t("Resubmission.Labels.Title.Create", "Wiedervorlage erstellen");

  const formSaveButton = props.resubmission
    ? i18n.t("Global.Buttons.save")
    : undefined;

  return (
    <div className={classNames("rs-creation-form-component")}>
      <FormStruct
        title={formTitle}
        className="rs-creation-form"
        initialValues={initialValues}
        submitText={formSaveButton}
        onSubmit={async (values) => {
          RSService.submitResubmissionAsync(values, props.listIdentifier);
          props.onClose();
        }}
        onAbort={(form: FormRenderProps) => {
          form.form.reset();
          props.onClose();
        }}
        render={(formProps) => {
          const { values } = formProps;
          return (
            <div className="fields">
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-10`}
              >
                {i18n.t("Resubmission.Titles.Resubmission", "Wiedervorlage")}
              </div>
              <div className="__form-row">
                <div className={classNames("__field", "__flex-1")}>
                  <Field name="title" validate={FormValidators.required()}>
                    {({ input, meta }) => {
                      const errorMsg =
                        meta.error && meta.touched ? meta.error : "";
                      return (
                        <BFInput
                          label={i18n.t("Resubmission.Labels.Title", "Titel")}
                          {...input}
                          validation={{ message: errorMsg, level: "error" }}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className="__form-row">
                <div className={classNames("__field", "__flex-1")}>
                  <Field
                    name="description"
                    validate={FormValidators.required()}
                  >
                    {({ input, meta }) => {
                      const errorMsg =
                        meta.error && meta.touched ? meta.error : "";
                      return (
                        <BFInput
                          type="textarea"
                          label={i18n.t(
                            "Resubmission.Labels.Description",
                            "Beschreibung"
                          )}
                          {...input}
                          validation={{ message: errorMsg, level: "error" }}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className="__form-row">
                {unitTypes.length > 1 && props.assetParams && (
                  <div className={`field`}>
                    <Field
                      name="type"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => {
                        return (
                          <BFSelect
                            disabled={unitTypes.length < 2}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            label={i18n.t(
                              "Resubmission.Labels.Type",
                              "Bereich"
                            )}
                            {...input}
                            data={unitTypes}
                          />
                        );
                      }}
                    </Field>
                  </div>
                )}
                <div className={`field`}>
                  <Field
                    name="priority"
                    validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => {
                      return (
                        <LabeledInput
                          label={i18n.t(
                            "Resubmission.Labels.Priority",
                            "Priorität"
                          )}
                        >
                          <EZSelect
                            dataType="tag"
                            data={RSService.getPriorityOptions()}
                            value={input.value}
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                          />
                        </LabeledInput>
                      );
                    }}
                  </Field>
                </div>
                <div className={`field`}>
                  <Field
                    name="category"
                    validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => {
                      return (
                        <BFSelect
                          validation={
                            meta.error && meta.touched
                              ? { level: "error", message: meta.error }
                              : undefined
                          }
                          label={i18n.t(
                            "Resubmission.Labels.Category",
                            "Kategorie"
                          )}
                          {...input}
                          data={RSService.getCategoryOptions()}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>

              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t("Resubmission.Titles.Assignees", "Empfänger und Datum")}
              </div>
              <div className="__form-row">
                <div className="__field">
                  <Field
                    name="assignees"
                    validate={FormValidators.requiredBFAssignmentValue()}
                  >
                    {({ input, meta }) => {
                      const message =
                        meta.error && meta.touched ? meta.error : undefined;

                      return (
                        <ValidationPopover level="error" message={message}>
                          <BFAssignmentDefault
                            label={i18n.t(
                              "Resubmission.Labels.Assignees",
                              "Empfänger Wiedervorlage"
                            )}
                            asOverlay
                            type="both"
                            value={input.value}
                            onChange={input.onChange}
                            error={meta.touched && meta.error}
                          />
                        </ValidationPopover>
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className="__form-row">
                <div className="__field">
                  <Field
                    name="dueDate"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.dateAfter(new Date())
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <BFDate
                          label={i18n.t(
                            "Resubmission.Labels.DueDate",
                            "Fällig am"
                          )}
                          placement="topStart"
                          {...input}
                          validation={{ level: "error", message: meta.error }}
                        />
                      );
                    }}
                  </Field>
                </div>
                {/* TODO SJ - talked to fabi, this will be implemented later
                 <div className="__field">
                  <Field name="remindDate">
                    {({ input, meta }) => {
                      return (
                        <BFDate
                          label={i18n.t(
                            "Resubmission.Labels.RemindDate",
                            "Erinnern ab"
                          )}
                          placement="topStart"
                          {...input}
                        />
                      );
                    }}
                  </Field>
                </div> */}
              </div>

              <FormFieldValues names={["helpers"]}>
                {([helpers]) => {
                  if (!helpers.showFeedback) {
                    return null;
                  }
                  return <RSFeedbackFormPart formProps={formProps} />;
                }}
              </FormFieldValues>
              <FormFieldValues names={["helpers"]}>
                {([helpers]) => {
                  if (!helpers.showRepetition) {
                    return null;
                  }
                  return <RSRepetitionFormPart formProps={formProps} />;
                }}
              </FormFieldValues>

              {/* ACTIONS TO ADD NEW FIELDS */}
              <div className={`add-actions`}>
                {!values?.helpers?.showFeedback && (
                  <BFButton
                    type="button"
                    appearance={"outline"}
                    size="xs"
                    text={i18n.t(
                      "Resubmission.Form.Buttons.addFeedback",
                      "Rückmeldung hinzufügen"
                    )}
                    onClick={() => {
                      formProps.form.mutators.setValue(
                        "helpers.showFeedback",
                        true
                      );
                      formProps.form.mutators.setValue("feedbackRecipients", {
                        users: [currentUserId],
                        teams: [],
                      });
                    }}
                  ></BFButton>
                )}
                {/* TODO SJ - talked to fabi, this will be implemented later
                we have to discuss, what this does and what options we need */}
                {/* {!values?.helpers?.showRepetition && (
                  <BFButton
                    type="button"
                    appearance={"outline"}
                    size="xs"
                    text={i18n.t(
                      "Resubmission.Form.Buttons.addRepitition",
                      "Wiederholung hinzufügen"
                    )}
                    onClick={() => {
                      formProps.form.mutators.setValue(
                        "helpers.showRepetition",
                        true
                      );
                      formProps.form.mutators.setValue(
                        "resubmissionType",
                        "interval"
                      );
                      formProps.form.mutators.setValue("interval", {
                        type: "daily",
                        endDate: moment().add(1, "month").toDate(),
                        days: RSService.getIntervalDayDefaultValues(),
                      });
                    }}
                  ></BFButton>
                )} */}
              </div>
              {props.assetParams && (
                <div className="row">
                  <div className="__field">
                    <LabeledInput
                      type="sub"
                      label={i18n.t(
                        "Resubmission.Labels.LinkedAsset",
                        "Verknüpfte Daten"
                      )}
                    >
                      <div>{props.assetParams.assetDescription}</div>
                    </LabeledInput>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default ResubmissionForm;
