import i18n from "../i18n";
import DataBusDefaults from "./DataBusDefaults";

class ServiceUtilsClass {
  async toastError(promise: () => Promise<any>, errorMsg?: string) {
    try {
      return await promise();
    } catch (error) {
      DataBusDefaults.toast({
        type: "error",
        text:
          errorMsg ||
          i18n.t("Global.Error.general", "Leider ist ein Fehler aufgetreten."),
      });
      throw error;
    }
  }
}
const ServiceUtils = new ServiceUtilsClass();
export default ServiceUtils;
