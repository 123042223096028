import { Field } from "react-final-form";
import FormValidators from "../../../../../../../../components/Form/Validation/FormValidators";
import BFDate from "../../../../../../../../modules/abstract-ui/forms/date/BFDate";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import LanguageService from "../../../../../../../../services/LanguageService";
import { FieldCustomField } from "../../../../config/technical-unit-config/CBTechnicalUnitConfigInterfaces";
// import './CBTechnicelUnitFormCustomField.scss';

interface CBTechnicelUnitFormCustomFieldProps {
  customFieldProps: FieldCustomField;
}
const CBTechnicelUnitFormCustomField = (
  props: CBTechnicelUnitFormCustomFieldProps
) => {
  return (
    <div className={`__field cb-technical-unit-form-custom-field`}>
      <Field
        name={`customFields.${props.customFieldProps.id}`}
        validate={
          props.customFieldProps.mandatory
            ? FormValidators.compose(FormValidators.required())
            : undefined
        }
      >
        {({ input, meta }) => (
          <>
            {props.customFieldProps.fieldType === "string" && (
              <BFInput
                {...input}
                {...FormValidators.getValidation(meta)}
                label={
                  LanguageService.translateLabel(
                    props.customFieldProps.displayName
                  ) + (props.customFieldProps.mandatory ? "*" : "")
                }
              />
            )}
            {props.customFieldProps.fieldType === "number" && (
              <BFInput
                type="number"
                {...input}
                {...FormValidators.getValidation(meta)}
                label={
                  LanguageService.translateLabel(
                    props.customFieldProps.displayName
                  ) + (props.customFieldProps.mandatory ? "*" : "")
                }
              />
            )}
            {props.customFieldProps.fieldType === "date" && (
              <BFDate
                {...input}
                {...FormValidators.getValidation(meta)}
                label={
                  LanguageService.translateLabel(
                    props.customFieldProps.displayName
                  ) + (props.customFieldProps.mandatory ? "*" : "")
                }
              />
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default CBTechnicelUnitFormCustomField;
