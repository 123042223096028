import { MouseEvent, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { TableSort } from "../../../model/common/CommonInterfaces";
import BFVirtualizedTable, {
  BFVirtualizedTablePosition,
  ColumnConfig,
} from "../../../modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import ExportDialog, { ExportType } from "../../../modules/export/ExportDialog";
import { TableExcelExportOptions } from "../../../modules/export/export.model";
import { setFlexCacheData } from "../../../redux/actions/application/application-actions";
import { selectFlexConfig } from "../../../redux/actions/application/application-selectors";
import { useDatabus, useTypedSelector } from "../../../redux/hooks";
import ArrayUtils from "../../../utils/ArrayUtils";
import { DATABUS_OPEN_EXPORT_DIALOG_INFINITE_TABLE } from "./VirtualizedTable";
import "./VirtualizedTable.scss";

const PREFIX = "MVT_";
export type ManagedVirtualizedTableProps = {
  // Props to pass through to virtualizedTable
  loading?: "general" | "append";
  hideHeader?: boolean;
  columns: { [columnId: string]: ColumnConfig };
  onRowClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>
  ) => void;
  onRowDoubleClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>,
    params?: any
  ) => void;
  params?: any;
  paramsConverter?: (data: any, index: number, params: any) => any;
  calculateSize?: (data: any, index: number) => number | undefined;
  estimatedSize?: number;
  hover?: boolean;
  overscan?: number;
  scrollingDelay?: number;
  identifierSelector?: string;
  persistCustomizations?: boolean;
  emptyText?: string;
  ignoreRowBorder?: boolean;
  ignoreColumnBorder?: boolean;
  exportTypes?: ExportType[];
  exportOptions?: TableExcelExportOptions;
  rowOverlay?: (
    position: BFVirtualizedTablePosition,
    node: any,
    index: number,
    params?: any
  ) => React.ReactNode;
  selectedIds?: string[];

  className?: string;
  // props needed for data handling
  selection?: "none" | "single" | "multiple";
  identifier: string;
  data: any[];

  //Fixme - what to do here?
  initialVisibleSort?: TableSort;
  hiddenSort?: TableSort[];
};
const ManagedVirtualizedTable = ({
  identifier,
  data,
  initialVisibleSort,
  hiddenSort,
  selection,
  exportTypes,
  exportOptions,
  ...tableProps
}: ManagedVirtualizedTableProps) => {
  const { sort } = useTypedSelector(
    (state) => selectFlexConfig(state, `${PREFIX}_${identifier}`) || {}
  );
  const scrollPosition = useRef({ x: 0, y: 0 });
  const dispatch = useDispatch();
  const [exportDialog, setExportDialog] = useState<boolean>(false);

  useDatabus(DATABUS_OPEN_EXPORT_DIALOG_INFINITE_TABLE, (databusProps) => {
    if (identifier === databusProps.identifier) {
      setExportDialog(true);
    }
  });

  const sortedData = () => {
    if (!data) {
      return [];
    }
    if (!sort && !hiddenSort) {
      return data;
    }
    return ArrayUtils.sortData(
      data,
      [sort, ...(hiddenSort || [])].filter((e) => e)
    );
  };
  return (
    <>
      {exportDialog && (
        <ExportDialog
          onClose={() => setExportDialog(false)}
          onSuccess={() => {
            setExportDialog(false);
          }}
          exportFileName={exportOptions?.filename}
          exportTypes={exportTypes}
          exportOptions={exportOptions}
          exportConfig={Object.entries(tableProps.columns)
            .filter(([key, value]) => value.export)
            .map(([key, value]) => ({
              id: key,
              ...(typeof value.export === "function"
                ? value.export(
                    tableProps.paramsConverter
                      ? tableProps.paramsConverter({}, null, tableProps.params)
                      : tableProps.params
                  )
                : value.export),
            }))}
          gatherDataFC={async () => {
            return data;
          }}
        />
      )}
      <BFVirtualizedTable
        {...tableProps}
        identifier={identifier}
        insetShadow
        data={sortedData()}
        sort={sort}
        onScroll={(scrollX, scrollY) => {
          scrollPosition.current = {
            x: scrollX,
            y: scrollY,
          };
        }}
        onSort={(sort) =>
          dispatch(setFlexCacheData(`${PREFIX}_${identifier}`, "sort", sort))
        }
      />
    </>
  );
};

ManagedVirtualizedTable.defaultProps = {
  persistCustomizations: true,
} as Partial<ManagedVirtualizedTableProps>;

export default ManagedVirtualizedTable;
