import { nanoid } from "nanoid";
import { useState } from "react";
import i18n from "../../../../i18n";
import DataBus from "../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../utils/Constants";
import StringUtils from "../../../../utils/StringUtils";
import { Placement } from "../../common/Placements";
import BFButton from "../../general/Button/BFButton";
import BFDropdown from "../../general/Dropdown/BFDropdown";
import BFUseTooltip from "../../general/tooltip/BFUseTooltip";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import { CurrencyValue } from "./BFCurrency";
import "./BFCurrencySelect.scss";
import BFInput from "./BFInput";
import {
  CurrencyOption,
  IMPORTANT_CURRENCIES,
  getCurrencyOptions,
} from "./Currencies";

interface BFCurrencySelectProps {
  favoriteCurrencies?: string[];
  value: CurrencyValue;
  onChange: (value: CurrencyValue) => void;
  placement?: Placement;
  disabled?: boolean;
}
const BFCurrencySelect = (props: BFCurrencySelectProps) => {
  const [identifier] = useState(nanoid());
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const currencies = getCurrencyOptions();

  const favCurrencyIds = props.favoriteCurrencies || IMPORTANT_CURRENCIES;

  const favCurrencies = favCurrencyIds
    .map((e) => currencies.find((c) => c.id === e))
    .filter((e) => e);

  const items = [];
  if (advancedOpen || !favCurrencies.length) {
    items.push({
      type: "panel",
      children: (
        <SearchCurrency
          dropdownIdentifier={identifier}
          {...props}
          currencies={currencies}
          onBack={
            favCurrencies.length ? () => setAdvancedOpen(false) : undefined
          }
        />
      ),
    });
  } else {
    items.push(
      ...favCurrencies.map((currency) => ({
        type: "button",
        active: currency.id === props.value?.currency,
        text: (
          <div className={`currency-item`}>
            <div className={`currency-name`}>{currency.name}</div>
            <div className={`currency-code`}>
              {currency.id} / {currency.symbol}
            </div>
          </div>
        ),
        onSelect: () => {
          props.onChange({
            ...props.value,
            currency: currency.id,
          });
        },
      }))
    );
    items.push({
      type: "button",
      text: i18n.t("BFCurrencySelect.advanced", "Weitere"),
      closeOnClick: false,
      className: "advanced-button",
      onSelect: () => {
        setAdvancedOpen(true);
      },
    });
  }

  const currencyValue = props.value?.currency || StringUtils.getCurrency();
  const currencyValueObj = currencies.find((c) => c.id === currencyValue);

  return (
    <BFDropdown
      disabled={props.disabled}
      identifier={identifier}
      className={`bf-currency-select`}
      // onFocus={props.onFocus}
      // onBlur={props.onBlur}
      onClose={() => {
        setAdvancedOpen(false);
      }}
      placement={props.placement}
      label={
        <BFUseTooltip
          tooltip={
            currencyValueObj ? (
              <div>
                {currencyValueObj.name} ({currencyValueObj.symbol})
              </div>
            ) : (
              "invalid currency"
            )
          }
        >
          <div>{currencyValue}</div>
        </BFUseTooltip>
      }
      items={items}
    />
  );
};

export default BFCurrencySelect;

const SearchCurrency = (props: {
  currencies: CurrencyOption[];
  value: CurrencyValue;
  onChange: (value: CurrencyValue) => void;
  onBack: () => void;
  dropdownIdentifier: string;
}) => {
  const [search, setSearch] = useState<string>("");

  const filteredCurrencies = props.currencies.filter(
    (c) =>
      c.name.toLowerCase().includes(search.toLowerCase()) ||
      c.id.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <div className={`search-currency-panel`}>
      {props.onBack && (
        <div>
          <BFButton appearance="link" size="xxs" onClick={props.onBack}>
            {i18n.t("Global.Buttons.back")}
          </BFButton>
        </div>
      )}
      <div className={`search-bar`}>
        <BFInput
          focusOnMount
          placeholder={i18n.t("Global.Labels.Search")}
          prefix={<BfIcon {...DefaultIcons.SEARCH} size="xxs" />}
          value={search}
          onChange={(val: string) => setSearch(val)}
        />
      </div>
      <div className={`items`}>
        {filteredCurrencies.length === 0 && (
          <div className={`empty`}>
            {i18n.t(
              "BFCurrencySelect.noResults",
              "Keine Währung für diese Suche gefunden"
            )}
          </div>
        )}
        {filteredCurrencies.map((c) => (
          <BFButton
            className={`item currency-item ${
              c.id === props.value.currency ? "active" : ""
            }`}
            appearance="clear-on-white"
            key={c.id}
            onClick={() => {
              props.onChange({
                ...props.value,
                currency: c.id,
              });
              DataBus.emit(
                DataBusSubKeys.DROPDOWN_TOGGLE,
                props.dropdownIdentifier
              );
            }}
          >
            <div className={`currency-name`}>{c.name}</div>
            <div className={`currency-code`}>
              {c.id} / {c.symbol}
            </div>
          </BFButton>
        ))}
      </div>
    </div>
  );
};
