import { Component } from "react";
import { Popover } from "rsuite";
import { translateNonGenerate } from "../../../../utils/Helpers";
import BfIcon from "../../icon/BfIcon";
import BFWhisper from "../whisper/BFWhisper";
import "./ValidationPopover.scss";

export type ValidatorPopoverStyle = "hidden" | "default";

type Props = {
  validatorStyle: ValidatorPopoverStyle;
  message?: string;
  level: "warning" | "error";
  marginTop?: number;
};

type States = {};

class ValidationPopover extends Component<Props, States> {
  ref;

  static defaultProps = {
    validatorStyle: "default",
  };

  readonly state: States = {};

  render() {
    if (this.props.validatorStyle === "hidden") {
      return this.props.children;
    }

    return (
      <BFWhisper
        placement="bottomStart"
        open={this.props.level === "error" && this.props.message ? true : false}
        speaker={this.renderSpeaker()}
        container={this.ref}
      >
        <div style={{ flexGrow: 1 }} className="validation-popover">
          <div
            className={`wrapper`}
            style={{ position: "relative", width: "100%" }}
            ref={(ref) => {
              this.ref = ref;
              // let current = ref as HTMLElement;
              // if(current) {
              //
              //     while(current !== null && !current.classList.contains("app-layout-content") && (current.style.position !== "relative" || (current.style.overflow !== "auto" && current.style.overflowY !== "auto"))) {
              //         current = current.parentElement;
              //     }
              // }
              // this.ref = current || ref;
            }}
          >
            {this.props.children}
          </div>
          {this.props.level === "warning" && this.props.message ? (
            <div className={`validation-below ${this.props.level}`}>
              <div>
                <BfIcon size={"lg"} data={"exclamation-circle"} />
              </div>
              <div className={"message"}>{this.props.message}</div>
            </div>
          ) : null}
        </div>
      </BFWhisper>
    );
  }

  renderSpeaker() {
    return (
      <Popover
        style={{ marginTop: this.props.marginTop }}
        className={`validation-popover ${this.props.level}`}
      >
        <div className={"message"}>
          {translateNonGenerate(this.props.message)}
        </div>
      </Popover>
    );
  }
}

export default ValidationPopover;
