import {
  getDocument,
  GlobalWorkerOptions,
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
  PDFPageProxy,
} from "pdfjs-dist";
import { useEffect, useRef, useState } from "react";
import { Loader } from "rsuite";
import DebugDataComponent from "../../debug/DebugDataComponent";
import Log from "../../debug/Log";
import i18n from "../../i18n";
import BFDropdown from "../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import DataBus from "../../services/DataBus";
import DataBusDefaults, {
  ScrollGroupOptions,
} from "../../services/DataBusDefaults";
import { DataBusSubKeys } from "../../utils/Constants";
import FileUtils from "../../utils/FileUtils";
import StorageCache from "../../utils/StorageCache";
import "./PDFViewer.scss";

export type PDFHighlight = {
  identifier: string;
  value: string;
  stroke: string | CanvasGradient | CanvasPattern;
  name?: string;
  top: number;
  left: number;
  width: number;
  height: number;
  page: number;
  padding?: number;
  scrollIntoView?: boolean;
};

interface Props {
  url: string;
  height?: number | string;
  highlights?: PDFHighlight[];
  download?: boolean;
  filename: string;
  scrollGroup?: string;
  scrollIdentifier?: string;
  zoomFactorStorageKey?: string;
}
type State = "loading" | "error" | "success";

const PDFViewer = (props: Props) => {
  const refLoadingTask = useRef<PDFDocumentLoadingTask>(null);
  const scrollRef = useRef<HTMLDivElement>();
  const [zoomFactor, setZoomFactor] = useState<number>(
    props.zoomFactorStorageKey
      ? (StorageCache.get(props.zoomFactorStorageKey) as number) || 1
      : 1
  );
  const [state, setState] = useState<State>("loading");
  const [pdfInstance, setPdfInstance] = useState<PDFDocumentProxy>(null);

  useEffect(() => {
    if (props.zoomFactorStorageKey) {
      StorageCache.save(props.zoomFactorStorageKey, zoomFactor);
    }
  }, [props.zoomFactorStorageKey, zoomFactor]);
  useEffect(() => {
    return () => {
      if (refLoadingTask.current) {
        Log.info("PDFViewer.unmount found refLoadingTask");
        try {
          refLoadingTask.current.destroy().then(() => {
            Log.info("PDFViewer.unmount destroyed refLoadingTask");
          });
        } catch (err) {
          Log.info("PDFViewer.unmount err refLoadingTask", err);
        }
      }
    };
  }, []);
  useEffect(() => {
    return () => {
      Log.info("PDFViewer.unmount check pdfinstance", pdfInstance);
      if (pdfInstance) {
        Log.info("PDFViewer.unmount cleanup pdfinstance");
        pdfInstance.cleanup().finally(() => {
          Log.info("PDFViewer.unmount destroy pdfinstance");
          pdfInstance.destroy();
        });
      }
    };
  }, [pdfInstance]);
  // useEffect(() => {
  //   return () => {
  //     if (pdfInstance) {
  //       pdfInstance.cleanup().finally(() => pdfInstance.destroy());
  //     }
  //   };
  // }, [pdfInstance]);

  useEffect(() => {
    let subId = null;
    if (props.scrollGroup) {
      subId = DataBus.subscribe(
        DataBusSubKeys.SCROLL_GROUP,
        (data: ScrollGroupOptions) => {
          if (props.scrollGroup === data.groupId) {
            if (props.scrollIdentifier !== data.senderId) {
              scrollRef.current?.scrollTo({
                top: data.top,
                left: data.left,
              });
            }
          }
        }
      );
    }
  }, [props.scrollGroup, props.scrollIdentifier]);
  useEffect(() => {
    if (props.url) {
      loadPdf(props.url);
    } else {
      setError();
    }
  }, [props.url]);

  const setError = async () => {
    if (pdfInstance) {
      await pdfInstance.cleanup();
      await pdfInstance.destroy();
    }

    setState("error");
  };
  const loadPdf = async (url: string) => {
    if (pdfInstance) {
      await pdfInstance.cleanup();
      await pdfInstance.destroy();
    }

    if (refLoadingTask.current) {
      Log.info("PDFViewer.loadPdf found refLoadingTask");
      try {
        await refLoadingTask.current.destroy();
        Log.info("PDFViewer.loadPdf destroyed refLoadingTask");
      } catch (err) {
        Log.info("PDFViewer.loadPdf err refLoadingTask", err);
      }
    }

    setState("loading");

    GlobalWorkerOptions.workerSrc = "/scripts/pdf.worker.min.js";

    const loadingTask = getDocument(url);
    refLoadingTask.current = loadingTask;
    try {
      const pdf = await loadingTask.promise;
      refLoadingTask.current = null;
      setPdfInstance(pdf);
      setState("success");
      scrollRef.current?.scrollTo({
        top: 0,
      });
    } catch (err) {
      if (err?.message === "Worker was destroyed") {
        Log.info("PDFViewer.loadPdf error destroyed worker", err);
      } else {
        Log.error(`PDFViewer.loadPdf error loading pdf`, err);
        await setError();
      }
    }
  };

  return (
    <div className="pdf-viewer" style={{ height: props.height || "100%" }}>
      <DebugDataComponent
        data={{
          props: props,
        }}
      />
      {state === "loading" && (
        <div className="loading">
          <Loader size="md" />
        </div>
      )}
      {state === "error" && (
        <div className="error">
          <div className="error-text">
            {i18n.t(
              "PDFViewer.ErrorAtLoadingPDF",
              "Das PDF konnte nicht geladen werden. Bitte versuchen Sie es erneut, sollte es nicht funktionieren, aktualisieren Sie bitte die Seite."
            )}
          </div>
        </div>
      )}
      {state !== "loading" && state !== "error" && (
        <>
          <div className="controls">
            <div className="filename">{props.filename}</div>
            <div className="pages">{pdfInstance?.numPages || "-"} Seiten</div>
            <div className="action">
              <BFDropdown
                className="zoom-level"
                label={`${Math.floor(zoomFactor * 100)} %`}
                items={[
                  {
                    type: "button",
                    text: "50%",
                    onSelect: () => setZoomFactor(0.5),
                  },
                  {
                    type: "button",
                    text: "75%",
                    onSelect: () => setZoomFactor(0.75),
                  },
                  {
                    type: "button",
                    text: "100%",
                    onSelect: () => setZoomFactor(1),
                  },
                  {
                    type: "button",
                    text: "125%",
                    onSelect: () => setZoomFactor(1.25),
                  },
                  {
                    type: "button",
                    text: "150%",
                    onSelect: () => setZoomFactor(1.5),
                  },
                ]}
              />
            </div>
            <div className="action">
              <button
                className="simple-action"
                onClick={() =>
                  FileUtils.forceDownloadFile(props.url, props.filename)
                }
              >
                <BfIcon type="light" data="download-bottom" size="xs" />
              </button>
            </div>
          </div>
          <div
            className="content"
            ref={scrollRef}
            onScroll={(e) => {
              if (props.scrollGroup) {
                DataBusDefaults.scrollGroup({
                  groupId: props.scrollGroup,
                  senderId: props.scrollIdentifier,
                  top: e.currentTarget.scrollTop,
                  left: e.currentTarget.scrollLeft,
                });
              }
            }}
            onDoubleClick={(ev) => {
              if (zoomFactor === 1) {
                var mouseX =
                  ev.clientX - scrollRef.current.getBoundingClientRect().left;
                var mouseY =
                  ev.clientY - scrollRef.current.getBoundingClientRect().top;

                var newScrollLeft =
                  (mouseX / scrollRef.current.scrollWidth) *
                    (scrollRef.current.scrollWidth * 1.5 -
                      scrollRef.current.clientWidth) +
                  scrollRef.current.scrollLeft;
                var newScrollTop =
                  (mouseY / scrollRef.current.scrollHeight) *
                    (scrollRef.current.scrollHeight * 1.5 -
                      scrollRef.current.clientHeight) +
                  scrollRef.current.scrollTop;

                setTimeout(() => {
                  scrollRef.current.scrollLeft = newScrollLeft;
                  scrollRef.current.scrollTop = newScrollTop;
                }, 20);
                setZoomFactor(1.5);
              } else {
                setZoomFactor(1);
              }
            }}
          >
            {Array(pdfInstance?.numPages)
              .fill(1)
              .map((_e, index) => (
                <PDFPage
                  zoomFactor={zoomFactor}
                  key={index}
                  pageIndex={index + 1}
                  pdfInstance={pdfInstance}
                  highlights={props.highlights?.filter(
                    (e) => e.page === index + 1
                  )}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PDFViewer;

interface PageProps {
  pageIndex: number;
  pdfInstance: PDFDocumentProxy;
  highlights?: PDFHighlight[];
  zoomFactor: number;
}
const PDFPage = (props: PageProps) => {
  const [scaleFactor, setScaleFactor] = useState<number>(1);
  const [state, setState] = useState<State>("loading");
  const [page, setPage] = useState<PDFPageProxy>(null);
  const ref = useRef<HTMLCanvasElement>();
  const refContainer = useRef<HTMLDivElement>();
  const viewportWidth = useTypedSelector(
    (state: AppState) => state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
  );

  useEffect(() => {
    renderPage();
  }, [page, props.zoomFactor, viewportWidth]);
  useEffect(() => {
    return () => {
      if (page) {
        page.cleanup();
      }
    };
  }, []);

  useEffect(() => {
    if (props.pdfInstance) {
      setState("loading");
      try {
        props.pdfInstance
          .getPage(props.pageIndex)
          .then((pageProxy) => {
            setPage(pageProxy);
          })
          .catch((err) => {
            setState("error");
          });
      } catch (err) {
        setState("error");
      }
    }
  }, [props.pdfInstance, props.pageIndex]);

  const renderPage = () => {
    if (!page) {
      return;
    }
    let pdfViewport = page.getViewport({ scale: 1 });

    const useScale =
      Math.min(2, refContainer.current.offsetWidth / pdfViewport.width) *
      props.zoomFactor;
    setScaleFactor(useScale);
    pdfViewport = page.getViewport({
      scale: useScale,
    });
    const canvas = ref.current;
    const context = canvas.getContext("2d");
    canvas.height = pdfViewport.height;
    canvas.width = pdfViewport.width;

    page
      .render({
        canvasContext: context,
        viewport: pdfViewport,
      })
      .promise.then(() => {})
      .catch((err) => {
        setState("error");
        Log.error(`error rendering pdf`, err);
      });
  };

  return (
    <div className={`pdf-page page-${props.pageIndex}`} ref={refContainer}>
      <div className="wrapper">
        <div className={`center-position`}>
          <canvas
            onError={(err) => {
              Log.info(`error in canvas`, err);
            }}
            ref={ref}
          ></canvas>
          {props.highlights
            ?.filter((highlight) => {
              //filter out invalid highlight boxes, can occur if extraction module cannot get the dimension properly, boxes are out of bounds then
              if (
                (highlight.left + highlight.width) * scaleFactor <=
                  ref.current?.width &&
                (highlight.top + highlight.height) * scaleFactor <=
                  ref.current?.height
              ) {
                return true;
              } else {
                return false;
              }
            })
            .map((highlight) => (
              <PDFHighlightComponent
                key={highlight.identifier}
                {...highlight}
                scaleFactor={scaleFactor}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

interface PDFHighlightComponentPrps extends PDFHighlight {
  scaleFactor: number;
}
const PDFHighlightComponent = (props: PDFHighlightComponentPrps) => {
  const highlightRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (props.scrollIntoView) {
      highlightRef.current?.scrollIntoView({
        // behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  }, [props.scrollIntoView]);
  return (
    <div
      ref={highlightRef}
      className={`pdf-highlight-component`}
      style={{
        left:
          props.left * props.scaleFactor -
          (props.padding || 0) * props.scaleFactor,
        top:
          props.top * props.scaleFactor -
          (props.padding || 0) * props.scaleFactor,
        width:
          props.width * props.scaleFactor +
          (props.padding || 0) * 2 * props.scaleFactor,
        height:
          props.height * props.scaleFactor +
          (props.padding || 0) * 2 * props.scaleFactor,
      }}
    ></div>
  );
};
