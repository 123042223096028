import i18n from "@/i18n";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButtonToggle from "@/modules/abstract-ui/general/Button/BFButtonToggle";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { UnitStructType } from "@/redux/reducers/struct/StructInterface";
import classNames from "classnames";
import { useState } from "react";
import CBRentalAgreementActivityList from "./LinkedActivitiesList";
import "./LinkedActivitiesListTab.scss";

interface LinkedActivitiesListTabProps {
  assetId: string;
  type: string;
  activities: {
    structType: UnitStructType;
    assetType: string;
    title: string;
  }[];
}
const LinkedActivitiesListTab = (props: LinkedActivitiesListTabProps) => {
  const [search, setSearch] = useState("");
  const [activityStatus, seetActivityStatus] = useState<
    "active" | "inactive" | "waiting"
  >("active");
  return (
    <div className={classNames(`linked-activities-list-tab __card`)}>
      <div className={`filter-row`}>
        <div>
          <BFInput
            placeholder={i18n.t("Global.Labels.search")}
            value={search}
            onChange={(value: string) => setSearch(value)}
            prefix={<BfIcon size="xs" {...DefaultIcons.SEARCH} />}
          />
        </div>
        <div className={`fill`} />
        <BFButtonToggle
          value={activityStatus}
          onChange={(value) => seetActivityStatus(value)}
          buttons={[
            {
              value: "active",
              text: i18n.t("Activities.Status.Active", "Aktiv"),
            },
            {
              value: "waiting",
              text: i18n.t("Activities.Status.Waiting", "Wartend"),
            },
            {
              value: "inactive",
              text: i18n.t("Activities.Status.Inactive", "Geschlosssen"),
            },
          ]}
        />
      </div>

      <div className={`activities-content`}>
        {props.activities.map((activity) => (
          <CBRentalAgreementActivityList
            activityStatus={activityStatus}
            assetType={activity.assetType}
            assetId={props.assetId}
            type={props.type}
            search={search}
            structType={activity.structType}
            title={activity.title}
            // renderData={(activity, struct: DamageClaimStructClass) => {
            //   return <div>test</div>;
            // }}
          />
        ))}
      </div>
    </div>
  );
};

export default LinkedActivitiesListTab;
