import classNames from "classnames";
import i18n from "../../../../i18n";
import LanguageService from "../../../../services/LanguageService";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import BFStatus from "../status/BFStatus";
import "./BFIconDetails.scss";
import { IconAsset, IconFamilyAsset } from "./BFIconInterfaces";

interface BFIconDetailsProps {
  icon: IconAsset;
  familyConfig: IconFamilyAsset;
  onCategoryChange: (category: string) => void;
  onTagClick: (tag: string) => void;
}
const BFIconDetails = (props: BFIconDetailsProps) => {
  const category = props.familyConfig.data.categories.find(
    (category) => category.category === props.icon.data.category
  );
  const subCategory = category?.subCategories.find(
    (subCategory) => subCategory.subCategory === props.icon.data.subCategory
  );
  return (
    <div className={classNames(`bf-icon-details`)}>
      <div className={`icon-container`}>
        <BfIcon
          type={props.icon.data.family}
          data={props.icon.data.fileName}
          size="5x"
        />
      </div>
      <div className={`name`}>{props.icon.data.name}</div>
      <div className={`category`}>
        <div className={`__h4`}>
          {i18n.t("BFComponents.IconPicker.Category", "Kategorie")}
        </div>
        <BFButton
          appearance="link"
          noPadding
          onClick={() => props.onCategoryChange(`CAT_${category.category}`)}
        >
          {LanguageService.translateLabel(category.displayName)}
        </BFButton>
      </div>
      <div className={`category`}>
        <div className={`__h4`}>
          {i18n.t("BFComponents.IconPicker.SubCategory", "Unterkategorie")}
        </div>
        <BFButton
          appearance="link"
          noPadding
          onClick={() =>
            props.onCategoryChange(`SUB_${subCategory.subCategory}`)
          }
        >
          {LanguageService.translateLabel(subCategory.displayName)}
        </BFButton>
      </div>
      <div className={`tags`}>
        <div className={`__h4`}>
          {i18n.t("BFComponents.IconPicker.Tags", "Tags")}
        </div>
        <div className={`tag-list`}>
          {props.icon.data.tags.map((tag) => (
            <BFStatus
              label={tag}
              onClick={() => {
                props.onTagClick(tag);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BFIconDetails;
