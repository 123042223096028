import _ from "lodash";
import md5 from "md5";

import Log from "../debug/Log";
import BaseAsset from "../model/general-assets/BaseAsset";
import { patchTableData } from "../redux/actions/application/application-actions";
import InfiniteTable from "../redux/actions/application/application-infinite-table-actions";
import { CACHE_TTL, DataBusSubKeys } from "../utils/Constants";
import MQ from "../utils/MatchQueryUtils";
import {
  SET_APPICATION_CACHE_DATA,
  SET_APPICATION_CACHE_DEPRECATED,
  SET_APPICATION_CACHE_ERROR,
  SET_APPICATION_CACHE_LOADING,
} from "./../redux/actions/application/application-action-types";
import { store } from "./../redux/store";
import { HTTP } from "./../utils/Http";
import DataBus from "./DataBus";
import { MatchQuery } from "./DataService";
import { UpdateCommand } from "./socket/NotificationInterface";
import SocketService from "./socket/SocketService";

export const CacheSubs = {
  CACHE_DEPRECATE: "CACHE_DEPRECATE",
  CACHE_LOAD_DATA: "CACHE_LOAD_DATA",
};

type CacheById = {
  id: string;
};
export interface CacheDeprecate {
  oType: "group" | "user" | "asset" | "team";
  assetType?: string;
  id: string;
  changeCmd?: UpdateCommand;
}
export type AssetCacheLoadDataByMatchQuery = {
  oType: "asset";
  global?: boolean;
  assetType?: string;
  query: MatchQuery;
  forceReload?: boolean;
  silentReload?: boolean;
  ignoreDelay?: boolean;
};
export type AssetCacheLoadDataById = {
  oType: "asset";
  global?: boolean;
  assetType?: string;
  forceReload?: boolean;
  checkTables?: boolean;
  ignoreDelay?: boolean;
  silentReload?: boolean;
} & CacheById;
export type AssetCacheLoadData =
  | AssetCacheLoadDataByMatchQuery
  | AssetCacheLoadDataById;
export type BaseCacheLoadData = {
  oType: "group" | "user" | "team";
  checkTables?: boolean;
  global?: boolean;
  forceReload?: boolean;
  silentReload?: boolean;
  ignoreDelay?: boolean;
} & CacheById;
export type CacheLoadData = AssetCacheLoadData | BaseCacheLoadData;

type DelayedQueryParam = {
  oType?: "asset" | "group" | "user" | "team";
  assetType: string;
  useMatchQuery?: boolean;
  query?: MatchQuery;
  id?: string;
  selector: string;
  onSuccess: (oType, assetType, dataId, dataEntry) => void;
  onError: (err, oType, assetType) => void;
};
class CacheServiceClass {
  registeredSocketListeners = {
    asset: {},
    user: null,
    team: null,
    group: null,
  };
  init() {
    DataBus.subscribe<CacheDeprecate>(CacheSubs.CACHE_DEPRECATE, (data) =>
      this.setDataDeprecated(data)
    );
    DataBus.subscribe<CacheLoadData>(CacheSubs.CACHE_LOAD_DATA, (data) =>
      this.getData(data)
    );

    // SET_APPICATION_CACHE_DEPRECATED
  }

  delayedQueries: {
    [type: string]: {
      timeout: NodeJS.Timeout;
      queries: DelayedQueryParam[];
    };
  } = {};

  private delayedQuery = (cacheData: DelayedQueryParam) => {
    const delayedQuerySelector = `${cacheData.oType}_${
      cacheData.assetType || ""
    }`;
    if (!this.delayedQueries[delayedQuerySelector]) {
      this.delayedQueries[delayedQuerySelector] = {
        timeout: null,
        queries: [],
      };
    }
    this.delayedQueries[delayedQuerySelector].queries.push(cacheData);
    if (this.delayedQueries[delayedQuerySelector].timeout) {
      clearTimeout(this.delayedQueries[delayedQuerySelector].timeout);
    }
    this.delayedQueries[delayedQuerySelector].timeout = setTimeout(() => {
      this.runDelayedQuery(delayedQuerySelector);
    }, 250);
  };

  private runDelayedQuery = (selector: string) => {
    const data = this.delayedQueries[selector];
    this.delayedQueries[selector] = null;

    const chunkedQueries = this.chunkQueries(data.queries);
    chunkedQueries.forEach((chunk) => {
      if (chunk.length === 1) {
        const param = chunk[0];
        const usePost = false;
        HTTP[usePost ? "post" : "get"]({
          url: param.useMatchQuery
            ? `asset/${usePost ? "list/" : ""}${
                (param as AssetCacheLoadDataByMatchQuery).assetType
              }`
            : `${
                param.oType !== "asset"
                  ? param.oType
                  : param.oType + "/" + param.assetType
              }/${(param as CacheById).id}`,
          bodyParams:
            usePost && param.useMatchQuery
              ? {
                  limit: 1,
                  matchQuery: {
                    type: "and",
                    query: [(param as AssetCacheLoadDataByMatchQuery).query],
                  },
                }
              : undefined,
          queryParams: usePost
            ? undefined
            : param.useMatchQuery
            ? {
                param: {
                  limit: 1,
                  matchQuery: {
                    type: "and",
                    query: [(param as AssetCacheLoadDataByMatchQuery).query],
                  },
                },
              }
            : undefined,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((data) => {
            let dataId, dataEntry;
            if (!param.useMatchQuery) {
              dataId = data._id;
              dataEntry = data;
            } else {
              if (
                !Array.isArray((data as any).data) ||
                (data as any).data.length !== 1
              ) {
                dataId = null;
                dataEntry = null;
              } else {
                dataId = (data as any).data[0]._id;
                dataEntry = (data as any).data[0];
              }
            }

            param.onSuccess(
              param.oType,
              (param as AssetCacheLoadDataByMatchQuery).assetType,
              dataId,
              dataEntry
            );
          })
          .catch((err) => {
            param.onError(
              err,
              param.oType,
              (param as AssetCacheLoadDataByMatchQuery).assetType
            );
          });
      } else {
        const param = chunk[0];
        const matchQuery = {
          type: "or",
          query: chunk
            .map((param) => {
              if (param.useMatchQuery) {
                return param.query;
              } else {
                return {
                  type: "op",
                  op: "eq",
                  name: "_id",
                  value: param.selector,
                } as MatchQuery;
              }
            })
            .filter((e) => e),
        };
        const usePost = param.oType === "asset";
        HTTP[usePost ? "post" : "get"]({
          url:
            param.oType === "asset"
              ? `asset/${usePost ? "list/" : ""}${
                  (param as AssetCacheLoadDataByMatchQuery).assetType
                }`
              : `${
                  param.oType !== "asset"
                    ? param.oType
                    : param.oType + "/" + param.assetType
                }`,
          bodyParams: usePost
            ? {
                limit: 30,
                matchQuery: matchQuery,
              }
            : undefined,
          queryParams: usePost
            ? undefined
            : {
                param: {
                  limit: 30,
                  matchQuery: matchQuery,
                },
              },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            const data = response.data;
            chunk.forEach((chunkEntry) => {
              if (chunkEntry.useMatchQuery) {
                const found =
                  data.find((e) => MQ.check(e, chunkEntry.query)) || null;
                chunkEntry.onSuccess(
                  chunkEntry.oType,
                  (chunkEntry as AssetCacheLoadDataByMatchQuery).assetType,
                  found?._id,
                  found
                );
              } else {
                const found =
                  data.find((e) => e._id === chunkEntry.selector) || null;

                chunkEntry.onSuccess(
                  chunkEntry.oType,
                  (chunkEntry as AssetCacheLoadDataByMatchQuery).assetType,
                  found?._id,
                  found
                );
              }
            });
          })
          .catch((err) => {
            chunk.forEach((param) => {
              param.onError(
                err,
                param.oType,
                (param as AssetCacheLoadDataByMatchQuery).assetType
              );
            });
          });
      }
    });
  };
  private chunkQueries = (queries: DelayedQueryParam[]) => {
    const chunkedQueries = [];
    let chunk = [];
    for (let i = 0; i < queries.length; i++) {
      chunk.push(queries[i]);
      if (chunk.length === 30) {
        chunkedQueries.push(chunk);
        chunk = [];
      }
    }
    if (chunk.length > 0) {
      chunkedQueries.push(chunk);
    }
    return chunkedQueries;
  };

  registerSocketListener = (
    type: "asset" | "user" | "team" | "group",
    assetType?: string
  ) => {
    if (type === "asset") {
      if (this.registeredSocketListeners.asset[assetType]) {
        return null;
      }
    } else if (this.registeredSocketListeners[type]) {
      return null;
    }

    const subFC = (cmd: UpdateCommand) => {
      (Array.isArray(cmd.objectID) ? cmd.objectID : [cmd.objectID]).forEach(
        (objectId) => {
          if (cmd.type === "asset") {
            this.setDataDeprecated({
              oType: "asset",
              id: objectId,
              assetType: cmd.assetType,
              changeCmd: cmd,
            });
          } else {
            this.setDataDeprecated({
              oType: cmd.type,
              id: objectId,
              changeCmd: cmd,
            });
          }
        }
      );
    };

    if (type === "asset") {
      SocketService.subscribeAsset(assetType, subFC);
      this.registeredSocketListeners.asset[assetType] = true;
    } else {
      this.registeredSocketListeners[type] = true;
      switch (type) {
        case "user":
          SocketService.subscribeUser(subFC);
          break;
        case "team":
          SocketService.subscribeTeam(subFC);
          break;
        case "group":
          SocketService.subscribeGroup(subFC);
          break;
      }
    }
  };

  clear(oType: string, assetType: string, selector: string) {
    store.dispatch({
      type: SET_APPICATION_CACHE_DEPRECATED,
      oType: oType,
      id: selector,
      assetType: assetType,
    });
  }

  setDataDeprecated(param: CacheDeprecate) {
    store.dispatch({
      type: SET_APPICATION_CACHE_DEPRECATED,
      oType: param.oType,
      id: param.id,
      assetType: param.assetType,
      changeCmd: param.changeCmd,
    });
  }

  generateQueryId = (assetType: string, idOrQuery: string | MatchQuery) => {
    if (typeof idOrQuery === "string") {
      return idOrQuery;
    } else {
      return md5(`${assetType};${JSON.stringify(idOrQuery)}`);
    }
  };
  setData(assetType: string, id: string, data: any) {
    store.dispatch({
      type: SET_APPICATION_CACHE_DATA,
      oType: "asset",
      id: id,
      data,
      ttl: CACHE_TTL, //todo configurable value,
      assetType: assetType,
    });
  }

  checkIfExistingAndReloadAsset(
    assetType: string,
    assetId: string,
    loadAnyway: boolean = false,
    silentReload: boolean = true
  ) {
    return new Promise<any>((resolve, reject) => {
      const currentState = store.getState();
      let assetExists = loadAnyway;

      if (
        !assetExists &&
        currentState.application.cache[assetType]?.[assetId]
      ) {
        assetExists = true;
      }

      if (!assetExists && currentState.application.cache[assetType]) {
        Object.values(currentState.application.cache[assetType]).forEach(
          (e) => {
            if ((e as any).data?._id === assetId) {
              assetExists = true;
            }
          }
        );
      }

      if (!assetExists) {
        Object.entries(currentState.application.tables)
          .filter(
            ([identifier, table]) =>
              table?.url?.substr(table?.url?.lastIndexOf("/") + 1) === assetType
          )
          .forEach(([identifier, table]) => {
            const obj = table.data?.find((e) => e["_id"] === assetId);
            if (obj) {
              assetExists = true;
            }
          });
      }

      if (!assetExists) {
        Object.entries(currentState.application.infiniteTables)
          .filter(
            ([identifier, table]) =>
              table?.url?.substr(table?.url?.lastIndexOf("/") + 1) === assetType
          )
          .forEach(([identifier, table]) => {
            const obj = table.data?.find((e) => e["_id"] === assetId);
            if (obj) {
              assetExists = true;
            }
          });
      }

      if (assetExists) {
        this.getData({
          id: assetId,
          oType: "asset",
          assetType,
          forceReload: true,
          silentReload: silentReload,
        })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      } else {
        resolve(null);
      }
    });
  }

  update(
    data: BaseAsset,
    mode: "overwrite" | "patchRoot" | "merge" = "patchRoot"
  ) {
    this.updateDataInCaches(data._id, data, mode);
  }
  updateUserInCaches(
    dataId: string,
    data: any,
    mode: "overwrite" | "patchRoot" | "merge" = "patchRoot"
  ) {
    this.updateDataInTables(dataId, data, mode);

    const currentState = store.getState();
    const users = currentState.application.cache;

    const oldObj = users[dataId]?.data || {};

    const newData =
      mode === "overwrite"
        ? data
        : mode === "patchRoot"
        ? { ...oldObj, ...data }
        : _.merge({}, oldObj, data);

    store.dispatch({
      type: SET_APPICATION_CACHE_DATA,
      oType: "user",
      id: dataId,
      data: newData,
      ttl: CACHE_TTL,
    });
  }

  updateDataInCaches(
    dataId: string,
    data: any,
    mode: "overwrite" | "patchRoot" | "merge" = "patchRoot"
  ) {
    this.updateDataInTables(dataId, data, mode);

    const currentState = store.getState();

    Object.entries(currentState.application.cache)
      .filter(
        ([key]) =>
          ["user", "group", "asset", "flex", "query"].indexOf(key) === -1
      )
      .forEach(([assetType, values]) => {
        if (!values[dataId]) return;

        const oldObj = values[dataId].data || {};

        const newData =
          mode === "overwrite"
            ? data
            : mode === "patchRoot"
            ? { ...oldObj, ...data }
            : _.merge({}, oldObj, data);

        store.dispatch({
          type: SET_APPICATION_CACHE_DATA,
          oType: "asset",
          id: dataId,
          data: newData,
          ttl: CACHE_TTL, //todo configurable value,
          assetType: assetType,
        });
      });

    try {
      DataBus.emit(DataBusSubKeys.ASSET_UPDATED, data);
    } catch (err) {
      Log.error(err);
    }
  }
  updateDataInTables(
    dataId: string,
    data: any,
    mode: "overwrite" | "patchRoot" | "merge" = "patchRoot"
  ) {
    const currentState = store.getState();
    Object.entries(currentState.application.tables)
      // .filter(([identifier, table]) => table?.url === url)
      .forEach(([identifier, table]) => {
        const obj = table.data?.find((e) => e["_id"] === dataId);
        if (obj) {
          store.dispatch(patchTableData(identifier, dataId, data, mode));
        }
      });

    store.dispatch(InfiniteTable.searchInTablesAnPatchData(dataId, data, mode));
  }

  async getDataMultiple(
    assetsToLoad: { assetType: string; assetId: string }[]
  ) {
    const assets = [];

    const data = await Promise.allSettled(
      assetsToLoad.map((assetToLoad) =>
        this.getData({
          oType: "asset",
          id: assetToLoad.assetId,
          assetType: assetToLoad.assetType,
          checkTables: true,
        })
      )
    );

    data.forEach((d) => {
      if (d.status === "fulfilled") {
        assets.push(d.value);
      }
    });

    return assets;
  }

  getData(param: CacheLoadData) {
    return new Promise<any>((resolve, reject) => {
      const useMatchQuery =
        param.oType === "asset" && !(param as AssetCacheLoadDataById).id;
      const selector = this.generateQueryId(
        param.oType === "asset" ? param.assetType : null,
        (param as CacheById).id ||
          (param as AssetCacheLoadDataByMatchQuery).query
      );

      const state = store.getState();
      const currentCachedType =
        state.application.cache[
          param.oType !== "asset" ? param.oType : param.assetType
        ];
      let currentCachedData = currentCachedType
        ? currentCachedType[selector]
        : null;

      Log.debug("#CacheService called getData", param);
      this.registerSocketListener(
        param.oType,
        (param as AssetCacheLoadData).assetType
      );

      let forceSilent = false;
      let reloadData = true;
      if (currentCachedData) {
        if (currentCachedData.state === "loading") {
          reloadData = false;
        } else if (currentCachedData.state === "error") {
          reloadData = false;
        } else if (currentCachedData.state === "cached") {
          if (
            !currentCachedData.deprecated &&
            Number(new Date()) - currentCachedData.timestamp <
              currentCachedData.ttl
          ) {
            reloadData = false;
          } else {
            forceSilent = true;
          }
        }
      }

      // check if data is already in a table and use it
      if (
        param.oType === "asset" &&
        (param as AssetCacheLoadDataById).checkTables &&
        (!currentCachedData || !currentCachedData.data)
      ) {
        let dataEntry = null;

        Object.entries(state.application.tables)
          .filter(
            ([identifier, table]) =>
              table?.url?.substr(table?.url?.lastIndexOf("/") + 1) ===
              param.assetType
          )
          .forEach(([identifier, table]) => {
            const obj = table.data?.find(
              (e) => e["_id"] === (param as AssetCacheLoadDataById).id
            );
            if (obj) {
              dataEntry = obj;
            }
          });
        if (!dataEntry) {
          Object.entries(state.application.infiniteTables)
            .filter(
              ([identifier, table]) =>
                table?.url === `/api/asset/${param.assetType}`
            )
            .forEach(([identifier, table]) => {
              const obj = table.data?.find(
                (e) => e["_id"] === (param as AssetCacheLoadDataById).id
              );
              if (obj) {
                dataEntry = obj;
              }
            });
        }
        if (dataEntry) {
          store.dispatch({
            type: SET_APPICATION_CACHE_DATA,
            oType: param.oType,
            id: selector,
            data: dataEntry,
            ttl: CACHE_TTL, //todo configurable value,
            assetType: (param as AssetCacheLoadData).assetType,
            global: param.global,
          });

          DataBus.emit(DataBusSubKeys.ASSET_CACHED, {
            oType: param.oType,
            assetType: param.oType === "asset" ? param?.assetType : undefined,
            selector: selector,
            data: dataEntry,
          });

          return resolve(dataEntry);
        }
      }

      if (param.forceReload || reloadData) {
        const silentReload = forceSilent || param.silentReload;
        if (
          !silentReload ||
          !currentCachedData ||
          currentCachedData.state === "error"
        ) {
          store.dispatch({
            type: SET_APPICATION_CACHE_LOADING,
            oType: param.oType,
            id: selector,
            assetType: (param as AssetCacheLoadData).assetType,
          });
        }

        const onSuccess = (oType, assetType, dataId, dataEntry) => {
          store.dispatch({
            type: SET_APPICATION_CACHE_DATA,
            oType: oType,
            id: selector,
            data: dataEntry,
            ttl: CACHE_TTL, //todo configurable value,
            assetType: assetType,
            global: param.global,
          });

          //patch table data if availbale
          let url: string;
          if (oType === "asset") {
            url = `asset/${assetType}`;
          } else {
            url = oType;
          }
          if (dataId) {
            this.updateDataInTables(dataId, dataEntry);
          }

          DataBus.emit(DataBusSubKeys.ASSET_CACHED, {
            oType: oType,
            assetType: oType === "asset" ? assetType : undefined,
            selector: selector,
            data: dataEntry,
          });

          resolve(dataEntry);
        };
        const onError = (err, oType, assetType) => {
          store.dispatch({
            type: SET_APPICATION_CACHE_ERROR,
            oType: oType,
            id: selector,
            error: err,
            assetType: assetType,
          });
          reject(err);
        };

        if (param.ignoreDelay) {
          HTTP.get({
            url: useMatchQuery
              ? `asset/${(param as AssetCacheLoadDataByMatchQuery).assetType}`
              : `${
                  param.oType !== "asset"
                    ? param.oType
                    : param.oType + "/" + param.assetType
                }/${(param as CacheById).id}`,
            queryParams: useMatchQuery
              ? {
                  param: {
                    limit: 1,
                    matchQuery: {
                      type: "and",
                      query: [(param as AssetCacheLoadDataByMatchQuery).query],
                    },
                  },
                }
              : undefined,
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((data) => {
              let dataId, dataEntry;
              if (!useMatchQuery) {
                dataId = data._id;
                dataEntry = data;
              } else {
                if (
                  !Array.isArray((data as any).data) ||
                  (data as any).data.length !== 1
                ) {
                  dataId = null;
                  dataEntry = null;
                } else {
                  dataId = (data as any).data[0]._id;
                  dataEntry = (data as any).data[0];
                }
              }

              onSuccess(
                param.oType,
                (param as AssetCacheLoadDataByMatchQuery).assetType,
                dataId,
                dataEntry
              );
            })
            .catch((err) => {
              onError(
                err,
                param.oType,
                (param as AssetCacheLoadDataByMatchQuery).assetType
              );
            });
        } else {
          this.delayedQuery({
            oType: param.oType,
            assetType: (param as AssetCacheLoadDataByMatchQuery).assetType,
            id: selector,
            selector: selector,
            query: useMatchQuery
              ? (param as AssetCacheLoadDataByMatchQuery).query
              : undefined,
            useMatchQuery: useMatchQuery,
            onSuccess: onSuccess,
            onError: onError,
          });
        }
      } else {
        if (currentCachedData.state === "loading") {
          const intervalId = setInterval(() => {
            let currentCachedData =
              store.getState().application.cache[
                param.oType !== "asset" ? param.oType : param.assetType
              ]?.[selector];
            if (currentCachedData?.state === "error") {
              clearInterval(intervalId);
              reject(currentCachedData?.error);
            } else if (currentCachedData?.state === "cached") {
              clearInterval(intervalId);
              resolve(currentCachedData?.data);
            }
          }, 100);
        } else if (currentCachedData?.state === "cached") {
          resolve(currentCachedData?.data);
        }
      }
    });
  }
}

const CacheService = new CacheServiceClass();

export default CacheService;
