import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Animation } from "rsuite";
import i18n from "../../../../i18n";
import ArrayUtils from "../../../../utils/ArrayUtils";
import BFInput from "../../forms/input/BFInput";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import {
  FilterOption,
  FilterSearchOption,
  FilterValue,
} from "./BFTableFilterModel";
import "./BFTableSearchInput.scss";

interface BFTableAutocompleteProps {
  filterOptions?: FilterOption[];
  value: FilterValue[];
  searchTerm?: string;
  searchPlaceholder?: string;
  onChange: (value: FilterValue[]) => void;
  onSearch: (search: string) => void;
}
const BFTableSearchInput = (props: BFTableAutocompleteProps) => {
  const inputRef = useRef<BFInput>();
  const autocompleteRef = useRef<HTMLDivElement>();

  const [search, setSearch] = useState<string>(props.searchTerm || "");
  const [searchFocused, setSearchFocused] = useState<boolean>(false);

  const [allOptions, setAllOptions] = useState<FilterSearchOption[]>([]);
  const [focusedEntryIndex, setFocusedEntryIndex] = useState<number>(0);

  useEffect(() => {
    setFocusedEntryIndex(0);
  }, [searchFocused, search]);

  useEffect(() => {
    const options: FilterSearchOption[] = [
      {
        value: "search",
        valueKey: "__search__",
        label: `Nach "${search}" suchen`,
        onSelect: () => {
          props.onSearch(search);
        },
        filterKey: "__search__",
        labelAsString: "__search__",
        typeLabel: "",
      },
      ...ArrayUtils.fuzzyFilter(
        search,
        props.filterOptions?.reduce(
          (prev, current) => [
            ...prev,
            ...(current.getOptions
              ? current.getOptions(
                  (props.value || []).find((e) => e.filterKey === current.key)
                    ?.value,
                  search,
                  props.value
                )
              : []),
          ],
          []
        ),
        {
          ignoreLocation: true,
          threshold: 0.4,
          keys: ["labelAsString", "searchKeys"],
        }
      )
        .slice(0, 15)
        .map((e) => e.item),
    ];
    setAllOptions(options);
  }, [search]);

  const onOptionSelect = (option: FilterSearchOption, resetSearch: boolean) => {
    const currentValue = props.value.find(
      (e) => e.filterKey === option.filterKey
    );
    option.onSelect(option.value, currentValue?.value, (value: any) => {
      currentValue
        ? props.onChange(
            props.value.map((e) =>
              e.filterKey === option.filterKey
                ? { filterKey: option.filterKey, value: value }
                : e
            )
          )
        : props.onChange([
            ...props.value,
            { filterKey: option.filterKey, value: value },
          ]);
    });
    if (resetSearch) {
      setSearch("");
      props.onSearch("");
    } else {
      // blur the element - search is starting
      (inputRef.current.inputRef as HTMLInputElement).blur();
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setFocusedEntryIndex(((focusedEntryIndex || 0) + 1) % allOptions.length);
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === "ArrowUp") {
      setFocusedEntryIndex(
        ((focusedEntryIndex || 0) - 1 + allOptions.length) % allOptions.length
      );
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === "Enter") {
      const option = allOptions[focusedEntryIndex || 0];
      if (option) {
        onOptionSelect(option, (focusedEntryIndex || 0) !== 0);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div
      className={classNames("bf-table-search-input", {
        expanded: searchFocused || search !== "",
      })}
    >
      <BFInput
        ref={inputRef as any}
        onFocus={() => setSearchFocused(true)}
        onBlur={() => {
          props.onSearch(search.trim());
          setSearch(search.trim());
          setSearchFocused(false);
        }}
        placeholder={
          props.searchPlaceholder ||
          i18n.t("BFTableSearch.searchPlaceholder", "Suche...")
        }
        value={search}
        onChange={(value: string) => {
          setSearch(value);
        }}
        prefix={<BfIcon type="light" data="search" size="xs" />}
        suffix={
          search && (
            <BFButton
              appearance="clear-on-white"
              icon={{ type: "light", data: "close", size: "xs" }}
              onClick={() => {
                setSearch("");
                props.onSearch("");
              }}
            />
          )
        }
        onKeyDown={onKeyDown}
      />

      <Animation.Bounce
        in={searchFocused && search !== ""}
        unmountOnExit
        timeout={200}
      >
        <div className="search-autocomplete-wrapper">
          <div
            ref={autocompleteRef}
            className={classNames(`bf-table-autocomplete`)}
          >
            {allOptions.map((option, index) => (
              <BFTableSearchEntry
                focused={focusedEntryIndex === index}
                key={`${option.filterKey}_${option.valueKey || ""}`}
                option={option}
                valueLabel={option.label}
                typeLabel={option.typeLabel}
                onClick={() => {
                  onOptionSelect(option, index !== 0);
                }}
              />
            ))}
          </div>
        </div>
      </Animation.Bounce>
    </div>
  );
};

export default BFTableSearchInput;

interface BFTableSearchEntryProps {
  option: FilterSearchOption;
  focused?: boolean;
  onClick: () => void;
  typeLabel?: string;
  valueLabel: string | React.ReactNode;
}
const BFTableSearchEntry = (props: BFTableSearchEntryProps) => {
  const ref = useRef<HTMLButtonElement>();
  useEffect(() => {
    if (props.focused && ref.current) {
      ref.current.scrollIntoView({ behavior: "auto", block: "nearest" });
    }
  }, [props.focused]);
  return (
    <button
      ref={ref}
      tabIndex={-1}
      className={classNames(`bf-table-search-entry`, {
        focused: props.focused,
      })}
      onClick={props.onClick}
    >
      {props.typeLabel && <span className="type-label">{props.typeLabel}</span>}
      <span className="value-label">{props.valueLabel}</span>
    </button>
  );
};
