import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";
import { useEffect, useRef, useState } from "react";
import Log from "../../debug/Log";
import i18n from "../../i18n";
import { Textblock } from "../../model/db/UserConfigAsset";
import DataBusDefaults from "../../services/DataBusDefaults";
import StorageUtils from "../../utils/StorageUtils";
import "./TextEditor.scss";

export interface MentionItem {
  id: string;
  type: "team" | "user";
  name: string;
}
interface TextEditorProps {
  initialValue?: string;
  onEmptyChange?: (isEmpty: boolean) => void;
  editorRef?: React.MutableRefObject<any>;
  mentionSource?: (search: string) => Promise<MentionItem[]>;
  placeholder?: string;

  value?: string;
  onInit?: (editor) => void;

  onChange?: (value: string) => void;
  onChangeTrigger?: () => void;

  textblocks?: Textblock[];
}
const TextEditor = (props: TextEditorProps) => {
  const [isEmpty, setIsEmpty] = useState<boolean>(
    !props.initialValue && !props.value
  );

  useEffect(() => {
    if (StorageUtils.SessionStorage.get("CKEDITOR_RESTART")) {
      StorageUtils.SessionStorage.delete("CKEDITOR_RESTART");

      DataBusDefaults.toast({
        type: "warning",
        duration: 8000,
        text: i18n.t(
          "TextEditor.ErrorRestarted",
          "Leider ist der Texteditor abgestürzt. Bitte versuche es erneut."
        ),
      });
    }
  }, []);
  useEffect(() => {
    props.onEmptyChange?.(isEmpty);
  }, [isEmpty]);
  const editorRef = useRef<any>(null);
  return (
    <div className="text-editor">
      <CKEditor
        editor={Editor}
        onReady={(editor) => {
          editorRef.current = editor;
          if (props.editorRef) {
            props.editorRef.current = editor;
          }

          props.onInit?.(editor);
        }}
        onError={(error, props) => {
          if (props.willEditorRestart) {
            StorageUtils.SessionStorage.save("CKEDITOR_RESTART", true);
          }
          Log.error("error texteditor", error, props);
        }}
        onChange={(event, editor: any) => {
          // for builded online editor there are no types
          props.onChange && props.onChange(editor.getData());
          props.onChangeTrigger?.();
          setTimeout(() => {
            const firstParagraph =
              editor.ui.view.editable.element.querySelector("p");
            if (firstParagraph) {
              if (firstParagraph.classList.contains("ck-placeholder")) {
                setIsEmpty(true);
              } else {
                setIsEmpty(false);
              }
            }
          });
        }}
        data={props.value}
        config={
          {
            initialData: props.initialValue,
            textblocks: props.textblocks,
            placeholder: props.placeholder,
            htmlSupport: {
              allow: [
                {
                  name: "div",
                  classes: ["iberio-signature"],
                },
              ],
            },
            extraPlugins: [MentionCustomization],
            //   plugins: [],
            ...(props.mentionSource && {
              mention: {
                feeds: [
                  {
                    marker: "@",
                    feed: async (search) => {
                      const users = await props.mentionSource(search);

                      return users.map((user) => ({
                        id: `@${user.name}`,
                        name: user.name,
                        type: user.type,
                        objectId: user.id,
                      }));
                    },
                    itemRenderer: renderMentionItem,
                  },
                ],
              },
            }),
          } as any
        }
        // config={ /* the editor configuration */ }
      />
    </div>
  );
};

export default TextEditor;

function renderMentionItem(item: MentionItem) {
  const itemElement = document.createElement("span");

  itemElement.classList.add("mention");
  itemElement.textContent = `${item.name}`;

  return itemElement;
}

function MentionCustomization(editor) {
  editor.conversion.for("upcast").elementToAttribute({
    view: {
      name: "span",
      key: "data-mention",
      classes: "mention",
      attributes: {
        "data-mention-type": true,
        "data-object-id": true,
      },
    },
    model: {
      key: "mention",
      value: (viewItem) => {
        const mentionAttribute = editor.plugins
          .get("Mention")
          .toMentionAttribute(viewItem, {
            type: viewItem.getAttribute("data-mention-type"),
            objectId: viewItem.getAttribute("data-object-id"),
          });

        return mentionAttribute;
      },
    },
    converterPriority: "high",
  });

  editor.conversion.for("downcast").attributeToElement({
    model: "mention",
    view: (modelAttributeValue, { writer }) => {
      if (!modelAttributeValue) {
        return;
      }

      return writer.createAttributeElement(
        "span",
        {
          class: "mention",
          "data-mention": modelAttributeValue.id,
          "data-object-id": modelAttributeValue.objectId,
          "data-mention-type": modelAttributeValue.type,
        },
        {
          priority: 20,
          id: modelAttributeValue.uid,
        }
      );
    },
    converterPriority: "high",
  });
}
