import StructLoader from "@/components/StructLoader/StructLoader";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import React from "react";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import ObjectDetailView from "../../../../../../../components/ObjectDetailView/ObjectDetailView";
import WrappedText from "../../../../../../../components/WrappedText/WrappedText";
import PersistentSplitPane from "../../../../../../../configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import CommentsModule from "../../../../../../../modules/comments-module/CommentsModule";
import { DocumentStoreDocument } from "../../../../../../../modules/document-store/DSInterfaces";
import DocumentStoreRouted from "../../../../../../../modules/document-store/DocumentStoreRouted";
import EZTextfield from "../../../../../../../modules/ez-form/form-elements/ez-textfield/EZTextfield";
import EZField from "../../../../../../../modules/ez-form/form-group/ez-field/EZField";
import { RSLinkedAssetFieldIdentifier } from "../../../../../../../modules/resubmission/RSConstants";
import { RSAssetBaseParams } from "../../../../../../../modules/resubmission/RSInterfaces";
import useOpenResubmissionForm from "../../../../../../../modules/resubmission/hooks/useOpenResubmissionForm";
import CacheService from "../../../../../../../services/CacheService";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import GlobalActions from "../../../../../../../services/GlobalActions";
import StringUtils from "../../../../../../../utils/StringUtils";
import { CB_RENTALVACANCY_DOCUMENTS_FIELD_PATH } from "../../../../CashBudgetUtils";
import CBRentalService from "../../CBRentalService";
import CBRentalUtils from "../../CBRentalUtils";
import { EnrichtedRentalUnit, RentalVacancy } from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import CBRentalVacancyAdvertisement from "./CBRentalVacancyAdvertisement";
import "./CBRentalVacancyDetails.scss";
import CBRentalVacancyResubmissionView from "./resubmission/CBRentalVacancyResubmissionView";
//
interface CBRentalVacancyDetailsProps {
  rentalVacancy: RentalVacancy;
  goBack: () => void;
  integrated?: boolean;
}
const CBRentalVacancyDetails = (props: CBRentalVacancyDetailsProps) => {
  const { openResubmissionBtnIconProps, openResubmissionDialog } =
    useOpenResubmissionForm();

  if (!props.rentalVacancy) {
    return null;
  }
  return (
    <StructLoader
      unitType={props.rentalVacancy.data.type}
      structTypes={["objectKind", "unit", "orga"]}
      render={() => (
        <ObjectDetailView
          integrated={props.integrated}
          goBack={props.goBack}
          className="cb-rental-vacancy-detail-view"
          mainTitle={i18n.t("cb:RentalVacancy.Title", "Leerstand")}
          main={(isMobile) => (
            <DetailsPage hideBackButton={isMobile} {...props} />
          )}
          routes={[
            // {
            //   name: "Dashboard",
            //   route: "dashboard",
            //   render: () => <div>Dashboard</div>,
            // },
            {
              name: i18n.t("cb:RentalVacancy.Tabs.Comments", "Kommunikation"),
              route: "communication",
              render: () => (
                <div className={`cb-vacancy-communication`}>
                  <CommentsModule
                    disableEmailCommunication
                    // allowMailUpload
                    identifier={`cb-vacancy-communication`}
                    assetType={AssetTypes.Rental.RentalVacancy}
                    assetId={props.rentalVacancy._id}
                    type={props.rentalVacancy.data.type}
                    subactivity="allow"
                  />
                </div>
              ),
            },
            {
              name: i18n.t("cb:RentalVacancy.Tabs.Documents", "Dokumente"),
              route: "attachments",
              render: () => (
                <div>
                  <DocumentStoreRouted
                    assetParams={{
                      asset: props.rentalVacancy,
                      assetType: AssetTypes.Rental.RentalVacancy,
                      type: props.rentalVacancy.data.type,
                      documentsFieldPath: "data.attachments",
                    }}
                    documentEntryActions={[
                      {
                        type: "button",
                        text: i18n.t(
                          "Resubmission.Labels.Create",
                          "Wiedervorlage erstellen"
                        ),
                        onSelect: (document: DocumentStoreDocument) => {
                          const type = props.rentalVacancy.data.type;

                          const assetParams: RSAssetBaseParams = {
                            assetType: AssetTypes.Rental.RentalVacancy,
                            assetId: props.rentalVacancy._id,
                            assetDescription: `${i18n.t(
                              "AssetTypes.CB.Labels.RentalVacancy",
                              "Leerstand"
                            )} - ${i18n.t(
                              "DocumentStore.Labels.Document",
                              "Dokument:"
                            )} ${document.name}`,
                            assetField: {
                              identifier:
                                RSLinkedAssetFieldIdentifier.DSDocument,
                              fieldPath: CB_RENTALVACANCY_DOCUMENTS_FIELD_PATH,
                              id: document.linkToCdn,
                            },
                          };

                          openResubmissionDialog({
                            linkedAsset: assetParams,
                            typePermissions: [type],
                          });
                        },
                        icon: openResubmissionBtnIconProps,
                      },
                    ]}
                  />
                </div>
              ),
            },
            {
              name: i18n.t(
                "cb:RentalVacancy.Tabs.Resubmission",
                "Wiedervorlagen"
              ),
              route: "resubmissions",
              render: () => (
                <CBRentalVacancyResubmissionView
                  rentalVacancy={props.rentalVacancy}
                />
              ),
            },
          ]}
        />
      )}
    />
  );
};

export default CBRentalVacancyDetails;

const DetailsPage = (
  props: CBRentalVacancyDetailsProps & { hideBackButton: boolean }
) => {
  const chartId = React.useRef<string>(null);
  let status = CBRentalUtils.calculateVacancyStatus(props.rentalVacancy);

  return (
    <div className={`main-page __card`}>
      <PersistentSplitPane
        identifier="cb-rental-vacancy-details"
        split="horizontal"
        defaultSize={"50%"}
        maxSize={-200}
        minSize={150}
        primary="first"
        allowResize
        onSizeChange={() => {
          if (chartId.current) {
            DataBusDefaults.chartResized(chartId.current);
          }
        }}
      >
        <div className={`object-stacking-plan-wrapper`}>
          <ObjectStackingPlan
            onMounted={(ident, chart) => {
              chartId.current = ident;
            }}
            objectId={props.rentalVacancy.data.objectId}
            markedUnits={[props.rentalVacancy.data.rentalUnit]}
            selectMode="single"
            onSelect={(selected: EnrichtedRentalUnit) => {
              GlobalActions.openDetails(
                selected.data.rentalStatus === "vacant"
                  ? AssetTypes.Rental.RentalUnit
                  : AssetTypes.Rental.RentalAgreement,
                selected.data.rentalStatus === "vacant"
                  ? selected._id
                  : selected.data.agreement._id,
                selected.data.type
              );
            }}
          />
        </div>
        <div className={`page-content`}>
          <div className={`header`}>
            {!props.hideBackButton && (
              <BFButton
                className={`back-button`}
                appearance={"clear-on-white"}
                icon={{ size: "xs", ...DefaultIcons.BACK }}
                onClick={props.goBack}
              />
            )}
            <div className={`title`}>
              <div className={`id`}>
                {i18n.t("cb:RentalVacancy.DetailsTitle", "Leerstand")}
                <DebugDataComponent data={props.rentalVacancy} />
              </div>
              <div className={`name`}>
                {props.rentalVacancy.data.displayName}
              </div>
              {/* <div className={`object`}>
            <BFButton
              appearance="link"
              size="xs"
              onClick={() =>
                DataBusDefaults.route({
                  stayInApp: true,
                  route: `/objects/${props.rentalVacancy.data.objectId}`,
                })
              }
            >
              {
                OrgaStruct.getObject(props.rentalVacancy.data.objectId)
                  ?.id
              }{" "}
              -{" "}
              {
                OrgaStruct.getObject(props.rentalVacancy.data.objectId)
                  ?.displayName
              }
            </BFButton>
          </div> */}
            </div>
          </div>
          <div className={`content`}>
            <div className={`entry-list`}>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalVacancy.Object", "Objekt")}
                </div>
                <div className={`value`}>
                  <BFDetailsButton
                    noPadding
                    appearance="link"
                    data={{
                      assetType: AssetTypes.Portfolio.Object,
                      assetId: props.rentalVacancy.data.objectId,
                      type: props.rentalVacancy.data.type,
                    }}
                  >
                    {
                      OrgaStruct.getObject(props.rentalVacancy.data.objectId)
                        ?.id
                    }{" "}
                    -{" "}
                    {
                      OrgaStruct.getObject(props.rentalVacancy.data.objectId)
                        ?.displayName
                    }
                  </BFDetailsButton>
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalVacancy.RentalUnit", "Mieteinheit")}
                </div>
                <div className={`value`}>
                  <AssetLoader
                    assetType={AssetTypes.Rental.RentalUnit}
                    id={props.rentalVacancy.data.rentalUnit}
                    render={(rentalUnit) => (
                      <BFDetailsButton
                        noPadding
                        appearance="link"
                        data={{
                          assetType: AssetTypes.Rental.RentalUnit,
                          assetId: props.rentalVacancy.data.rentalUnit,
                          type: props.rentalVacancy.data.type,
                        }}
                      >
                        {rentalUnit.data.displayName}
                      </BFDetailsButton>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={`entry-list`}>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalVacancy.Details.Status", "Status")}
                </div>
                <div className={`value`}>
                  <BFStatus
                    label={status.label()}
                    color={status.color}
                    size={"sm"}
                  />
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t(
                    "cb:RentalVacancy.Details.StartDate",
                    "Leerstand Anfang"
                  )}
                </div>
                <div className={`value`}>
                  {StringUtils.formatDate(props.rentalVacancy.data.moveIn)}
                </div>
              </div>
              <div className={`entry`}>
                <div className={`label`}>
                  {i18n.t("cb:RentalVacancy.Details.EndDate", "Leerstand Ende")}
                </div>
                <div className={`value`}>
                  {props.rentalVacancy.data.moveOut
                    ? StringUtils.formatDate(props.rentalVacancy.data.moveOut)
                    : "-"}
                </div>
              </div>
            </div>
            <div className={`__ez-section-title subtitle`}>
              {i18n.t(
                "cb:RentalVacancy.Details.currentState",
                "Aktueller Status"
              )}
            </div>
            <div className={`current-state`}>
              <EZField
                value={props.rentalVacancy.data.currentState}
                url={`/api/asset/${AssetTypes.Rental.RentalVacancy}`}
                transformSubmitData={(value) => ({
                  _id: props.rentalVacancy._id,
                  data: {
                    currentState: value,
                  },
                })}
                onSubmitSuccess={(value) =>
                  CacheService.updateDataInCaches(value._id, value)
                }
              >
                {({ value, onChange, onBlur, loading, submitData }) => (
                  <EZTextfield
                    // label={}
                    placeholder={i18n.t(
                      "cb:RentalVacancy.Details.currentStatePlaceholder",
                      "-"
                    )}
                    textType={"default"}
                    className="title-input"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              </EZField>
            </div>

            <div className={`sub-title margin-bottom-10 margin-top-10`}>
              <div className={`__h3 `}>
                {i18n.t("cb:RentalVacancy.Details.advertisement", "Inserate")}
              </div>
              <BFButton
                className={`margin-left-10`}
                appearance="link"
                size="xs"
                onClick={() => {
                  ModalManager.show({
                    size: "md",
                    backdrop: "static",
                    noPadding: true,
                    content: (states, setStates, closeModal) => (
                      <CBRentalVacancyAdvertisement
                        rentalVacancy={props.rentalVacancy}
                        onSuccess={(data) => {
                          closeModal();
                        }}
                        onClose={() => {
                          closeModal();
                        }}
                      />
                    ),
                  });
                }}
              >
                {i18n.t("Global.Add", "Hinzufügen")}
              </BFButton>
            </div>

            <div className={`vacancy-entries`}>
              {(props.rentalVacancy.data.advertisements || []).length === 0 && (
                <div className={`__empty`}>
                  {i18n.t(
                    "cb:RentalVacancy.Details.noAdvertisements",
                    "Keine Inserate vorhanden"
                  )}
                </div>
              )}
              {(props.rentalVacancy.data.advertisements || []).map(
                (advertisement) => (
                  <div className={`vacant-advertisement-entry`}>
                    <div className={`href`}>
                      <a href={advertisement.link} target="_blank">
                        {advertisement.link}
                      </a>
                    </div>
                    <div className={`margin-top-10 entry-list`}>
                      <div className={`entry`}>
                        <div className={`label`}>
                          {i18n.t(
                            "cb:RentalVacancy.Advertisement.Status",
                            "Status"
                          )}
                        </div>
                        <div className={`value`}>
                          <BFStatus
                            label={
                              advertisement.status === "active"
                                ? i18n.t(
                                    "cb:RentalVacancy.Advertisement.Active",
                                    "Aktiv"
                                  )
                                : i18n.t(
                                    "cb:RentalVacancy.Advertisement.Inactive",
                                    "Inaktiv"
                                  )
                            }
                            color={
                              advertisement.status === "active"
                                ? "#d6ffd5"
                                : "#efa9a9"
                            }
                            size={"sm"}
                          />
                        </div>
                      </div>
                      <div className={`entry`}>
                        <div className={`label`}>
                          {i18n.t(
                            "cb:RentalVacancy.Advertisement.platform",
                            "Plattform"
                          )}
                        </div>
                        <div className={`value`}>
                          {advertisement.platform || "-"}
                        </div>
                      </div>
                      <div className={`entry`}>
                        <div className={`label`}>
                          {i18n.t(
                            "cb:RentalVacancy.Advertisement.from",
                            "Aktiv ab"
                          )}
                        </div>
                        <div className={`value`}>
                          {advertisement.from
                            ? StringUtils.formatDate(advertisement.from)
                            : "-"}
                        </div>
                      </div>
                      <div className={`entry`}>
                        <div className={`label`}>
                          {i18n.t(
                            "cb:RentalVacancy.Advertisement.to",
                            "Aktiv bis"
                          )}
                        </div>
                        <div className={`value`}>
                          {advertisement.to
                            ? StringUtils.formatDate(advertisement.to)
                            : "-"}
                        </div>
                      </div>
                      <div className={`actions`}>
                        <BFButton
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            ModalManager.show({
                              size: "md",
                              backdrop: "static",
                              noPadding: true,
                              content: (states, setStates, closeModal) => (
                                <CBRentalVacancyAdvertisement
                                  advertisement={advertisement}
                                  rentalVacancy={props.rentalVacancy}
                                  onSuccess={(data) => {
                                    closeModal();
                                  }}
                                  onClose={() => {
                                    closeModal();
                                  }}
                                />
                              ),
                            });
                          }}
                        >
                          {i18n.t("Global.Buttons.edit")}
                        </BFButton>
                        <BFButton
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            ModalManager.confirm({
                              message: i18n.t(
                                "cb:RentalVacancy.Advertisement.RemoveConfirm",
                                "Wollen Sie das Inserat aus diesem Leerstand entfernen?"
                              ),
                              confirmButtonText: i18n.t(
                                "Global.Buttons.delete"
                              ) as string,
                              title: i18n.t(
                                "cb:RentalVacancy.Advertisement.RemoveConfirmTitle",
                                "Inserat entfernen"
                              ),
                              onConfirm: () => {
                                CBRentalService.removeVacantAdvertisement(
                                  props.rentalVacancy,
                                  advertisement.id
                                );
                              },
                            });
                          }}
                        >
                          {i18n.t("Global.Buttons.remove")}
                        </BFButton>
                      </div>
                    </div>
                    {advertisement.note && (
                      <div className={`entry-list`}>
                        <div className={`entry`}>
                          <div className={`label`}>
                            {i18n.t(
                              "cb:RentalVacancy.Advertisement.note",
                              "Notiz"
                            )}
                          </div>
                          <div className={`value`}>
                            <WrappedText text={advertisement.note} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
            {/* <div className={`__h3 margin-bottom-10  margin-top-10`}>
          {i18n.t(
            "cb:RentalVacancy.Details.interestedPersons",
            "Interessenten"
          )}
        </div> */}
          </div>
        </div>
      </PersistentSplitPane>
    </div>
  );
};
