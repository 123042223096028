import LinkedActivitiesListTab from "@/apps/tatar/activityApp/components/activity-list/LinkedActivitiesListTab";
import StructLoader from "@/components/StructLoader/StructLoader";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import ObjectDetailView from "../../../../../../../components/ObjectDetailView/ObjectDetailView";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import CommentsModule from "../../../../../../../modules/comments-module/CommentsModule";
import { RentalAgreement } from "../../TenantsInterfaces";
import "./CBRentalAgreementDetails.scss";
import { CBRentalAgreementDetailsPage } from "./CBRentalAgreementDetailsPage";
import CBRentalAgreementDocumentsView from "./CBRentalAgreementDocumentsView";
import CBRentalAgreementPeriods from "./CBRentalAgreementPeriods";
import CBRentalAgreementResubmissionView from "./resubmission/CBRentalAgreementResubmissionView";

interface CBRentalAgreementDetailsProps {
  goBack: () => void;
  rentalAgreement: RentalAgreement;
  integrated?: boolean;
}
const CBRentalAgreementDetails = (props: CBRentalAgreementDetailsProps) => {
  if (!props.rentalAgreement) {
    return null;
  }

  const obj = OrgaStruct.getObject(props.rentalAgreement.data.objectId);
  const kind = ObjectKindStruct.getKind(obj.objectKindId);
  return (
    <StructLoader
      unitType={props.rentalAgreement.data.type}
      structTypes={["unit", "orga", "objectKind"]}
      render={() => (
        <ObjectDetailView
          integrated={props.integrated}
          goBack={props.goBack}
          className="cb-rental-agreement-detail-view"
          mainTitle={i18n.t("cb:RentalAgreement.Title", "Mietvertrag")}
          main={(isMobile) => {
            return (
              <div className={`main-page __card`}>
                <CBRentalAgreementDetailsPage
                  hideBackButton={isMobile}
                  kind={kind}
                  {...props}
                />
              </div>
            );
          }}
          routes={[
            // {
            //   name: "Dashboard",
            //   route: "dashboard",
            //   render: () => <div>Dashboard</div>,
            // },
            // {
            //   name: i18n.t("cb:RentalAgreement.Tabs.Payments", "Zahlungen"),
            //   route: "payments",
            //   render: () => <div>test</div>,
            // },
            {
              name: i18n.t("cb:RentalAgreement.Tabs.History", "Verlauf"),
              route: "periods",
              render: () => (
                <CBRentalAgreementPeriods
                  rentalAgreement={props.rentalAgreement}
                />
              ),
            },
            {
              name: i18n.t("cb:RentalVacancy.Tabs.Comments", "Kommunikation"),
              route: "communication",
              render: () => (
                <div className={`cb-vacancy-communication`}>
                  <CommentsModule
                    disableEmailCommunication
                    // allowMailUpload
                    identifier={`cb-vacancy-communication`}
                    assetType={AssetTypes.Rental.RentalAgreement}
                    assetId={props.rentalAgreement._id}
                    type={props.rentalAgreement.data.type}
                    subactivity="allow"
                  />
                </div>
              ),
            },
            {
              name: i18n.t("cb:RentalVacancy.Tabs.Activities", "Aktivitäten"),
              route: "activities",
              render: () => (
                <div
                  style={{
                    height: "calc(100% - 20px)",
                  }}
                >
                  <LinkedActivitiesListTab
                    assetId={props.rentalAgreement._id}
                    type={props.rentalAgreement.data.type}
                    activities={[
                      {
                        structType: "damageClaim",
                        assetType: AssetTypes.Activity.DamageClaim,
                        title: i18n.t(
                          "Activities.DamageClaims",
                          "Schadensmeldungen"
                        ),
                      },
                    ]}
                  />
                </div>
              ),
            },
            {
              name: i18n.t("cb:RentalAgreement.Tabs.Documents", "Dokumente"),
              route: "attachments",
              render: () => (
                <CBRentalAgreementDocumentsView
                  rentalAgreement={props.rentalAgreement}
                />
              ),
            },
            {
              name: i18n.t(
                "cb:RentalAgreement.Tabs.Resubmission",
                "Wiedervorlagen"
              ),
              route: "resubmissions",
              render: () => (
                <CBRentalAgreementResubmissionView
                  rentalAgreement={props.rentalAgreement}
                />
              ),
            },
          ]}
        />
      )}
    />
  );
};

export default CBRentalAgreementDetails;
