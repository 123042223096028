import moment from "moment";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DatePicker } from "rsuite";
import { RangeType } from "rsuite/esm/DateRangePicker";
import { Placement } from "../../common/Placements";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import LabeledInput from "../LabeledInput";
import "./BFDate.scss";

export type BFDateProps = {
  label?: string;
  labelPosition?: "top" | "left";
  tabIndex?: number;
  appearance?: string;
  format?: string; // ('YYYY-MM-DD')	Format date
  style?: any;
  readOnly?: boolean;
  info?: string;
  className?: string;
  // block:	boolean	Blocking an entire row
  calendarDefaultDate?: Date; //	Calendar panel default presentation date and time
  cleanable?: boolean; // (true)	Whether the selected value can be cleared
  container?: HTMLElement | (() => HTMLElement); //	Sets the rendering container
  // defaultOpen	boolean	Default value of open property
  defaultValue?: Date; //	Default value
  disabled?: boolean; //Whether disabled the component
  disabledDate?: (date: Date) => boolean; //	Disabled date
  disabledHours?: (hour: number, date: Date) => boolean; //	Disabled hours
  disabledMinutes?: (minute: number, date: Date) => boolean; //	Disabled minutes
  disabledSeconds?: (second: number, date: Date) => boolean; //	Disabled seconds
  hideHours?: (hour: number, date: Date) => boolean; //	Hidden hours
  hideMinutes?: (minute: number, date: Date) => boolean; //	Hidden minutes
  hideSeconds?: (second: number, date: Date) => boolean; //	Hidden seconds
  inline?: boolean; //Display date panel when component initial
  isoWeek?: boolean; //	ISO 8601 standard, each calendar week begins on Monday and Sunday on the seventh day
  limitEndYear?: number; // (1000)	Set the lower limit of the available year relative to the current selection date
  // locale?: Object, // (Locale)	i18n config
  onChange?: (date: Date) => void; //	Callback fired when value changed
  onChangeCalendarDate?: (date: Date, event?: React.SyntheticEvent) => void; //	Callback function that changes the calendar date.
  onClose?: () => void; //	Callback fired when close component
  onClean?: (event: React.SyntheticEvent) => void; //	Callback fired when value clean
  onNextMonth?: (date: Date) => void; //	Switch to the callback function for the next Month
  onOk?: (date: Date, event: React.SyntheticEvent) => void; //	Click the OK callback function
  onOpen?: () => void; //	Callback fired when open component
  onPrevMonth?: (date: Date) => void; //	Switch to the callback function for the previous Month
  onSelect?: (date: Date) => void; //	Callback fired when date or time is selected
  onToggleMonthDropdown?: (open: boolean) => void; //	Callback function that switches to the month view
  onToggleTimeDropdown?: (open: boolean) => void; //	Callback function that switches to the time view
  open?: boolean; //	Whether open the component
  oneTap?: boolean; //	One click to complete the selection date
  placeholder?: string; //	Placeholder
  placement?: Placement; //('bottomStart')	The placement of component
  ranges?: RangeType[]; // (Ranges)	Shortcut config
  // toggleComponentClass	:React.ElementType ('a')	You can use a custom element for this component
  value?: Date; //Value (Controlled)

  toggleAs?: (props: any) => React.ReactNode;
  onBlur?: () => void;
  onFocus?: () => void;
  validatorStyle?: ValidatorPopoverStyle;

  day?: boolean;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  hint?: string;
};

type States = {};

class BFDate extends Component<BFDateProps & WithTranslation, States> {
  // genId: string;
  container;
  dateRef;

  static defaultProps = {
    isoWeek: true,
    oneTap: true,
  };
  //
  // componentWillMount(): void {
  //     this.genId = uuid();
  // }

  render() {
    const {
      label,
      labelPosition,
      i18n,
      tReady,
      validation,
      validatorStyle,
      ...rest
    } = this.props;
    (rest as any).t = undefined;
    let translations = i18n.getResourceBundle(i18n.language, "common");

    if (!translations) {
      translations = i18n.getResourceBundle("en", "common");
    }

    const propsToGo: any = {
      ...rest,
      block: true,
      container: () => {
        return this.container;
      },
    };
    if (!propsToGo.format) {
      propsToGo.format = i18n.t("Formats.dateFormatFN");
    }
    if (propsToGo.value === "") {
      propsToGo.value = null;
    }
    if (typeof propsToGo.value === "string") {
      propsToGo.value = new Date(propsToGo.value);
    }

    /*

      i18n.t("common:Global.Date.sunday")
      i18n.t("common:Global.Date.monday")
      i18n.t("common:Global.Date.tuesday")
      i18n.t("common:Global.Date.wednesday")
      i18n.t("common:Global.Date.thursday")
      i18n.t("common:Global.Date.friday")
      i18n.t("common:Global.Date.saturday")
      i18n.t("common:Global.Date.ok")
      i18n.t("common:Global.Date.today")
      i18n.t("common:Global.Date.yesterday")
      i18n.t("common:Global.Date.hours")
      i18n.t("common:Global.Date.minutes")
      i18n.t("common:Global.Date.seconds")
    */
    return (
      <LabeledInput
        label={label}
        info={this.props.info}
        labelPosition={labelPosition}
        error={!!validation?.message}
      >
        <ValidationPopover
          validatorStyle={validatorStyle}
          level={validation ? validation.level : "error"}
          message={validation ? validation.message : null}
        >
          <div
            className={`bf-date ${!rest.value ? "no-value" : ""} ${
              validation?.message && validation?.level === "error"
                ? "error"
                : ""
            }`}
            style={{ position: "relative" }}
            ref={(ref) => {
              this.container = ref;
            }}
          >
            <DatePicker
              ref={(ref) => (this.dateRef = ref)}
              locale={translations?.["Global.dates"] || undefined}
              {...propsToGo}
              onOpen={() => {
                // this.dateRef.resetPageDate();
                // Log.debug("##DATE ref", this.dateRef);
                this.container
                  ?.getElementsByClassName("rs-picker-menu")[0]
                  ?.scrollIntoView({ behavior: "smooth", block: "center" });

                if (this.props.onOpen) {
                  this.props.onOpen();
                }
                document.querySelector("html").classList.add("date-open");
              }}
              onClose={() => {
                if (this.props.onClose) {
                  this.props.onClose();
                }
                document.querySelector("html").classList.remove("date-open");
              }}
              onChange={(date: Date) => {
                if (this.props.day) {
                  this.props.onChange?.(
                    moment(date).utc(true).startOf("day").toDate()
                  );
                } else {
                  this.props.onChange?.(date);
                }
              }}
            />
          </div>
          {this.props.hint && (
            <div className={`input-hint`}>{this.props.hint}</div>
          )}
        </ValidationPopover>
      </LabeledInput>
    );
  }
}

export default withTranslation()(BFDate);
