import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import { AssetTypes } from "@/model/AssetTypes";
import LanguageService from "@/services/LanguageService";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../../i18n";
import BFDate from "../../../../../../../../modules/abstract-ui/forms/date/BFDate";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "../../../../../../../../utils/Helpers";
import StringUtils from "../../../../../../../../utils/StringUtils";
import "./CBRentalAgreementDepositForm.scss";

interface CBRentalAgreementDepositFormProps {}
const CBRentalAgreementDepositForm = (
  props: CBRentalAgreementDepositFormProps
) => {
  return (
    <FormFieldValues names={["objectId"]}>
      {([objectId]) => (
        <AssetLoader
          assetType={AssetTypes.Portfolio.Object}
          id={objectId}
          render={(object: OAObject) => (
            <Field name="deposits" defaultValue={null} allowNull>
              {({ input, meta }) =>
                isDefined(input.value) ? (
                  <FieldArray name="deposits">
                    {({ fields }) => (
                      <div className={`cb-rental-agreement-deposit-form`}>
                        <div className={`label-header`}>
                          <div className={`section-title __h3`}>
                            {`${i18n.t(
                              "cb:RentalAgreement.Form.Fields.deposit",
                              "Kaution"
                            )}`}
                          </div>
                          <BFButton
                            noPadding
                            onClick={() => fields.push({})}
                            appearance="link"
                            size="xs"
                          >
                            {i18n.t(
                              "cb:RentalAgreement.Form.Fields.addDepositEntry",
                              "Weitere Kaution hinzufügen"
                            )}
                          </BFButton>
                        </div>
                        {fields.map((name, index) => (
                          <div className={`deposit-entry`}>
                            <div className={`headline`}>
                              <BFButton
                                appearance="link"
                                size="xs"
                                onClick={() => {
                                  if (fields.length > 1) {
                                    fields.remove(index);
                                  } else {
                                    input.onChange(null);
                                  }
                                }}
                              >
                                {i18n.t("Global.Buttons.remove")}
                              </BFButton>
                            </div>
                            <div className={`form`}>
                              <div className={`field-row`}>
                                <div className={`_field flex-1`}>
                                  <Field
                                    name={`${name}.identifier`}
                                    validate={FormValidators.compose(
                                      FormValidators.required()
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <BFSelect
                                        label={`${i18n.t(
                                          "cb:RentalAgreement.Form.Fields.depositType",
                                          "Kautionstyp"
                                        )}*`}
                                        data={object.data.feature?.immo?.accounting?.deposit.map(
                                          (d) => ({
                                            value: d.id,
                                            label:
                                              LanguageService.translateLabel(
                                                d.displayName
                                              ),
                                          })
                                        )}
                                        {...FormValidators.getValidation(meta)}
                                        {...input}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div className={`_field flex-1`}>
                                  <Field
                                    name={`${name}.depositValue`}
                                    validate={FormValidators.compose(
                                      FormValidators.required(),
                                      FormValidators.min(0)
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <BFInput
                                        type="priceInput"
                                        prefix={StringUtils.getCurrencySymbol()}
                                        label={`${i18n.t(
                                          "cb:RentalAgreement.Form.Fields.depositValue",
                                          "Wert"
                                        )}*`}
                                        {...FormValidators.getValidation(meta)}
                                        {...input}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div className={`_field flex-1`}>
                                  <Field
                                    name={`${name}.depositStart`}
                                    validate={FormValidators.compose(
                                      FormValidators.required()
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <BFDate
                                        label={`${i18n.t(
                                          "cb:RentalAgreement.Form.Fields.depositStart",
                                          "Kautionsbeginn"
                                        )}*`}
                                        {...FormValidators.getValidation(meta)}
                                        {...input}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div className={`_field flex-1`}>
                                  <Field name={`${name}.depositPayedOn`}>
                                    {({ input, meta }) => (
                                      <BFDate
                                        label={`${i18n.t(
                                          "cb:RentalAgreement.Form.Fields.depositPayedOn",
                                          "Bezahlt am"
                                        )}`}
                                        {...FormValidators.getValidation(meta)}
                                        {...input}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div className={`_field flex-1`}>
                                  <Field name={`${name}.depositPayedBackOn`}>
                                    {({ input, meta }) => (
                                      <BFDate
                                        label={`${i18n.t(
                                          "cb:RentalAgreement.Form.Fields.depositPayedBackOn",
                                          "Zurück bezahlt am"
                                        )}`}
                                        {...FormValidators.getValidation(meta)}
                                        {...input}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className={`_field flex-1`}>
                                <Field
                                  name={`${name}.comment`}
                                  validate={FormValidators.compose(
                                    FormValidators.max(400)
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <BFInput
                                      type="textarea"
                                      label={`${i18n.t(
                                        "cb:RentalAgreement.Form.Fields.comment",
                                        "Notiz"
                                      )}`}
                                      {...FormValidators.getValidation(meta)}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                ) : null
              }
            </Field>
          )}
        />
      )}
    </FormFieldValues>
  );
};

export default CBRentalAgreementDepositForm;

export const CBRentalAgreementDepositButton = () => {
  return (
    <FormFieldValues names={["objectId", "paymentPositions"]}>
      {([objectId, paymentPositions]) => (
        <Field name="deposits" allowNull defaultValue={null}>
          {({ input, meta }) => {
            if (!isDefined(input.value)) {
              return (
                <BFButton
                  appearance="outline"
                  size="xs"
                  onClick={() => input.onChange([{ test: "test" }])}
                  text={i18n.t(
                    "cb:RentalAgreement.Form.Buttons.addDeposit",
                    "Kaution hinzufügen"
                  )}
                />
              );
            } else {
              return null;
            }
          }}
        </Field>
      )}
    </FormFieldValues>
  );
};
