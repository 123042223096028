import { useInViewport } from "react-in-viewport";
import { Loader } from "rsuite";
import BFPlaceholder from "../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
import { useAssetCache } from "../../redux/hooks";
import { MatchQuery } from "../../services/DataService";

import { useEffect, useRef, useState } from "react";
import i18n from "../../i18n";
import CacheService from "../../services/CacheService";
import "./AssetLoader.scss";

type Props = {
  assetType: string;
  render: (
    data: any,
    selector: string,
    reload: (silent?: boolean) => void
  ) => JSX.Element;
  renderLoading?: () => JSX.Element;
  renderError?: (err: any) => JSX.Element;
  id?: string;
  query?: MatchQuery;
  silentReload?: boolean;
  ignoreDelay?: boolean;
  placeholderProps?: {
    width: number | string;
  };
  global?: boolean;

  clearOnUnmount?: boolean;
  ignoreViewportDelay?: boolean;
};

const AssetLoader = (props: Props) => {
  if (props.ignoreViewportDelay) {
    return <AssetLoaderRender {...props} />;
  } else {
    return <AssetLoaderViewportDelayed {...props} />;
  }
};

const AssetLoaderViewportDelayed = (props: Props) => {
  const myRef = useRef();
  const { inViewport, enterCount, leaveCount } = useInViewport(myRef);

  const [firstRendered, setFirstRendered] = useState(false);

  if (inViewport && !firstRendered) {
    setFirstRendered(true);
  }

  if (firstRendered) {
    return <AssetLoaderRender {...props} />;
  } else {
    return (
      <div className="asset-loader loading" ref={myRef}>
        <Loader />
      </div>
    );
  }
};

const AssetLoaderRender = (props: Props) => {
  const assetCache = useAssetCache(
    props.assetType,
    props.id || props.query,
    props.silentReload,
    props.global,
    props.ignoreDelay
  );

  useEffect(() => {
    return () => {
      if (props.clearOnUnmount) {
        CacheService.clear("asset", props.assetType, assetCache.selector);
      }
    };
  }, []);

  const renderError = (errorMessage: string) => {
    if (props.renderError) {
      return props.renderError(assetCache.error);
    } else {
      return <div className="asset-loader error">{errorMessage}</div>;
    }
  };

  if (assetCache.state === "cached" && !assetCache.deprecated) {
    return props.render(
      (assetCache as any).data,
      assetCache.selector,
      assetCache.reload
    );
  }
  if (assetCache.state === "error") {
    return renderError(
      i18n.t("Error.LoadingData", "Fehler beim Laden der Daten")
    );
  }

  if (props.renderLoading) {
    return props.renderLoading();
  } else {
    if (props.placeholderProps) {
      return (
        <BFPlaceholder loading width={props.placeholderProps.width}>
          -
        </BFPlaceholder>
      );
    } else {
      return (
        <div className="asset-loader loading">
          <Loader />
        </div>
      );
    }
  }
};

export default AssetLoader;
