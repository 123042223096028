import { RentalVacancy } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import CBRentalVacancyDetails from "@/apps/tatar/cashBudget/views/tenants/components/rental-vacancy/CBRentalVacancyDetails";
import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {}
const ROUTE_NAME = "__asset_rental-vacancy";
const RentalVacanyDetailsModal = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.Rental.RentalVacancy, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Rental.RentalVacancy}
      routeName={ROUTE_NAME}
      size="full"
      className="cb-rental-modal vacancy"
      render={(asset: RentalVacancy) => (
        <div style={{ height: "calc(100vh - 50px)" }}>
          <CBRentalVacancyDetails
            goBack={() => {
              // DataBusDefaults.closeModal(ROUTE_NAME);
              history.goBack();
            }}
            rentalVacancy={asset}
            integrated
          />
        </div>
      )}
    />
  );
};

export default RentalVacanyDetailsModal;
