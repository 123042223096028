import classNames from "classnames";
import { Field } from "react-final-form";
import Userlabel from "../../../../../../components/AvatarComponent/Userlabel";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import WrappedText from "../../../../../../components/WrappedText/WrappedText";
import i18n from "../../../../../../i18n";
import BFCheckbox from "../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "../../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import { differenceOfDates } from "../../../../../../modules/gantt-chart/BFGanttChart.utils";
import { Task } from "../../../../../../modules/gantt-chart/lib";
import { hasValue } from "../../../../../../utils/Helpers";
import StringUtils from "../../../../../../utils/StringUtils";
import { useActivityConstants } from "../../../ActivityHooks";
import ActivityService from "../../../ActivityService";
import { ProjectScheduleEntry } from "./APProjectBudgetInterfaces";
import "./APScheduleTaskProgress.scss";

interface APScheduleTaskProgressProps {
  activityId: string;
  task: Task;
  scheduleProgressEntry?: ProjectScheduleEntry;
  includeSunday?: boolean;
  includeSaturday?: boolean;
}
const APScheduleTaskProgress = (props: APScheduleTaskProgressProps) => {
  const constants = useActivityConstants();
  const timePlanned = differenceOfDates(
    props.task.start,
    props.task.end,
    props.includeSaturday,
    props.includeSunday
  );
  return (
    <div className={`ap-schedule-task-progress content`}>
      {props.task.type === "project" && (
        <div className={`project-progress`}>
          {StringUtils.formatPercent(props.task?.progress)}
        </div>
      )}
      {props.task.type === "task" && (
        <div className={`task-progress`}>
          <BFOverlay
            placement="bottom"
            trigger="click"
            speaker={
              <div className={`ap-task-progress-overlay`}>
                {props.task.progress === 100 ? (
                  <div className={`panel-content`}>
                    <div className={`title`}>{props.task.name}</div>
                    <div className={`description`}>
                      {i18n.t(
                        "apTemplate:Activity.Schedule.Progress.UncheckDescription",
                        "Wollen Sie den Fortschritt der Aufgabe zurücksetzen?"
                      )}
                    </div>
                    <div className={`actions`}>
                      <BFButton
                        appearance="outline"
                        onClick={async () => {
                          await ActivityService.unsetSchedule(
                            constants.serviceUrl,
                            props.activityId,
                            props.task.id
                          );
                        }}
                      >
                        {i18n.t(
                          "apTemplate:Activity.Schedule.Progress.Uncheck",
                          "Zurücksetzen"
                        )}
                      </BFButton>
                    </div>
                  </div>
                ) : (
                  <div className={`panel-content`}>
                    <div className={`title`}>{props.task.name}</div>
                    <div className={`description`}>
                      {i18n.t(
                        "apTemplate:Activity.Schedule.Progress.CheckDescription",
                        "Wollen Sie die Aufgabe abschließen?"
                      )}
                    </div>
                    <FormStruct
                      submitText={i18n.t(
                        "apTemplate:Activity.Schedule.Progress.Check",
                        "Aufgabe abschließen"
                      )}
                      onSubmit={async (values) => {
                        await ActivityService.setSchedule(
                          constants.serviceUrl,
                          props.activityId,
                          props.task.id,
                          values.daysNeeded,
                          values.comment
                        );
                      }}
                      noPadding
                      initialValues={{
                        daysNeeded: timePlanned,
                        comment: "",
                      }}
                      render={(formProps) => (
                        <>
                          <div className={`__field`}>
                            <Field name="daysNeeded">
                              {({ input, meta }) => (
                                <BFInput
                                  type="number"
                                  label={i18n.t(
                                    "apTemplate:Activity.Schedule.Progress.daysNeeded",
                                    "Aufwand"
                                  )}
                                  textAlign="right"
                                  suffix={i18n.t(
                                    "apTemplate:Activity.Schedule.Progress.Days",
                                    "Tage"
                                  )}
                                  step={1}
                                  {...input}
                                  {...FormValidators.getValidation(meta)}
                                />
                              )}
                            </Field>
                          </div>
                          <div className={`__field`}>
                            <Field name="comment">
                              {({ input, meta }) => (
                                <BFInput
                                  // onKeyDown={(e) => {
                                  //   console.log(e);
                                  //   if (e.key === " " || e.key === "Enter") {
                                  //     input.onChange(
                                  //       (e.target as HTMLInputElement).value
                                  //     );
                                  //   }
                                  // }}
                                  type="textarea"
                                  label={i18n.t(
                                    "apTemplate:Activity.Schedule.Progress.Comment",
                                    "Kommentar"
                                  )}
                                  placeholder={i18n.t(
                                    "apTemplate:Activity.Schedule.Progress.CommentPlaceholder",
                                    "Kommentar"
                                  )}
                                  {...input}
                                  onChange={(value) => {
                                    input.onChange(value);
                                  }}
                                  {...FormValidators.getValidation(meta)}
                                />
                              )}
                            </Field>
                          </div>
                        </>
                      )}
                    />
                  </div>
                )}
              </div>
            }
          >
            <div>
              <BFCheckbox
                appearance="modern"
                size="md"
                checked={props.task.progress === 100}
              ></BFCheckbox>
            </div>
          </BFOverlay>

          {hasValue(props.scheduleProgressEntry?.daysNeeded) &&
            (props.scheduleProgressEntry?.daysNeeded || 0) - timePlanned !==
              0 && (
              <div
                className={classNames("days-misssed", {
                  negative:
                    (props.scheduleProgressEntry?.daysNeeded || 0) -
                      timePlanned >
                    0,
                })}
              >
                <div className={`value`}>
                  {(props.scheduleProgressEntry?.daysNeeded || 0) -
                    timePlanned >
                  0
                    ? `+${
                        (props.scheduleProgressEntry?.daysNeeded || 0) -
                        timePlanned
                      }`
                    : (props.scheduleProgressEntry?.daysNeeded || 0) -
                      timePlanned}
                </div>
                <div className={`label`}>
                  {i18n.t("apTemplate:Activity.Schedule.Progress.Days", "Tage")}
                </div>
              </div>
            )}
          {props.scheduleProgressEntry?.comment?.trim() && (
            <BFOverlay
              speaker={
                <div>
                  <div className={`ap-schedule-task-comment-overlay`}>
                    <div className={`head`}>
                      <Userlabel id={props.scheduleProgressEntry.userId} />
                      <span className={`date`}>
                        {StringUtils.formatDate(
                          props.scheduleProgressEntry.date
                        )}
                      </span>
                    </div>
                    <div className={`comment`}>
                      <WrappedText
                        text={props.scheduleProgressEntry?.comment}
                      />
                    </div>
                  </div>
                </div>
              }
            >
              <div className={`comment-bubble`}>
                <BfIcon type="light" data="messages-bubble" size="xxs" />
              </div>
            </BFOverlay>
          )}
        </div>
      )}
    </div>
  );
};

export default APScheduleTaskProgress;

export const APSchduleTaskProgressHeader = () => {
  return (
    <div className={`ap-schedule-task-progress header`}>
      {i18n.t("apTemplate:Activity.Schedule.Progress.Progress", "Fortschritt")}
    </div>
  );
};
