import classNames from "classnames";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown } from "rsuite";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import i18n from "../../../../i18n";
import { TableSort } from "../../../../model/common/CommonInterfaces";
import InfiniteTable from "../../../../redux/actions/application/application-infinite-table-actions";
import { MatchQuery } from "../../../../services/DataService";
import GlobalActions from "../../../../services/GlobalActions";
import LabeledInput from "../../../abstract-ui/forms/LabeledInput";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import JumpToButton from "../../../abstract-ui/general/Button/jump-to/JumpToButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../../abstract-ui/general/ValidationPopover/ValidationPopover";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import EZAssetSelectDropdown from "./EZAssetSelectDropdown";
import "./EZAssetSelectMultiple.scss";

export interface AssetValue {
  assetType: string;
  assetId: string;
}
export interface AssetSelectProps {
  label: string;
  id: string;
  assetType: string;
  matchQuery?: MatchQuery;
  icon?: BfIconProps;
  renderValue: (value: any, index?: number) => React.ReactNode;
  renderMenu?: (value: any) => React.ReactNode;
  sort?: TableSort[];
  group?: string;
}
export type AssetSelectStyle = "default" | "bf" | "clear";
interface EZAssetSelectProps {
  addButtonText?: string;
  readonly?: boolean;
  block?: boolean;
  additionalMatchQuery?: MatchQuery;
  identifier: string;
  className?: string;
  value: AssetValue[];
  onChange?: (value: AssetValue[]) => void;
  onBlur?: (ev, value: AssetValue[]) => void;
  onValueAdded?: (value: AssetValue) => void;
  onValueRemoved?: (value: AssetValue) => void;
  disabled?: boolean | ((value: AssetValue) => boolean);
  cleanable?: boolean;
  label?: string;
  labelPosition?: "top" | "left";
  assetTypes: AssetSelectProps[];
  disabledAssetTypes?: string[];
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  appearance?: AssetSelectStyle;
  showJump?: boolean;
  initialAssetType?: string;
}

const EZAssetSelectMultiple = (props: EZAssetSelectProps) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const onRemove = async (value: AssetValue) => {
    const newValue = (props.value || []).filter(
      (e) => e.assetId !== value.assetId
    );
    await props.onChange?.(newValue);
    await props.onBlur?.(undefined, newValue);

    await props.onValueRemoved?.(value);
  };
  const onSelect = async (value: AssetValue, ev?) => {
    const newValue = [...(props.value || []), value];
    await props.onChange?.(newValue);
    await props.onBlur?.(ev, newValue);

    await props.onValueAdded?.(value);

    if (ref?.current) {
      (ref?.current as any)
        ?.querySelector("button.rs-dropdown-toggle")
        ?.click();
    }
  };

  return (
    <LabeledInput
      label={props.label}
      labelPosition={props.labelPosition}
      error={!!props.validation?.message}
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <div
          className={classNames(
            `ez-asset-select-multiple`,
            props.className,
            props.block && "block",
            `appearance-${props.appearance || "default"}`,
            { open: isOpen }
          )}
        >
          {(props.value || [])?.map((value, index) => {
            const selectedAssetType = props.assetTypes.find(
              (e) => e.assetType === value.assetType
            );
            return (
              <div className={`render-value`} key={value.assetId}>
                {selectedAssetType?.icon && (
                  <div className={`prefix-icon`}>
                    <BfIcon {...selectedAssetType?.icon} />
                  </div>
                )}
                <AssetLoader
                  assetType={value.assetType}
                  id={value.assetId}
                  render={(asset) => (
                    <div className={`asset-select-label`}>
                      {selectedAssetType?.renderValue(asset, index)}
                    </div>
                  )}
                  placeholderProps={{ width: 50 }}
                />

                {props.showJump && (
                  <JumpToButton
                    onClick={() => {
                      GlobalActions.openDetails(value.assetType, value.assetId);
                    }}
                  />
                )}
                {(typeof props.disabled !== "boolean" || !props.disabled) &&
                  !props.readonly && (
                    <div className={`delete-button`}>
                      <BFButton
                        disabled={
                          typeof props.disabled === "function"
                            ? props.disabled(value)
                            : undefined
                        }
                        appearance="link"
                        onClick={() => onRemove(value)}
                        size="xs"
                        tooltip={{ tooltip: i18n.t("Global.Buttons.delete") }}
                      >
                        <BfIcon type="light" data="bin-2" size="xs" />
                      </BFButton>
                    </div>
                  )}
              </div>
            );
          })}
          {!props.readonly && (
            <Dropdown
              disabled={
                typeof props.disabled === "boolean" ? props.disabled : undefined
              }
              onOpen={() => {
                setIsOpen(true);
              }}
              onClose={() => {
                setIsOpen(false);
                dispatch(InfiniteTable.setSearch(props.identifier, ""));
              }}
              ref={ref}
              className="select-dropdown"
              title={props.addButtonText || i18n.t("Global.Buttons.add")}
              toggleAs={(buttonProps) => (
                <Button {...buttonProps} appearance="link" />
              )}
            >
              <EZAssetSelectDropdown
                filterSelectionOut
                initialAssetType={props.initialAssetType}
                isOpen={isOpen}
                assetTypes={
                  (props.assetTypes || []).filter(
                    (e) =>
                      (props.disabledAssetTypes || []).indexOf(e.assetType) ===
                      -1
                  ) || []
                }
                additionalMatchQuery={props.additionalMatchQuery}
                selectedIds={(props.value || [])?.map((e) => e.assetId) || []}
                onSelect={onSelect}
                identifier={props.identifier}
              />
            </Dropdown>
          )}
        </div>
      </ValidationPopover>
    </LabeledInput>
  );
};

export default EZAssetSelectMultiple;
