import React, { CSSProperties } from "react";
import { Field, FormSpy } from "react-final-form";
import { useTranslation } from "react-i18next";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import { BFPrompt } from "../../../components/NavigationPrompt/NavigationPrompt";
import BFMessage from "../../abstract-ui/general/Message/BFMessage";
import {
  GFFieldLabel,
  GFFieldValue,
} from "../../generic-forms/display/DisplayComponents";
import GenericLayoutComponent from "../../generic-forms/GenericLayoutComponent";
import {
  GenericFormsLayoutProps,
  GFBaseElement,
  GFBaseElementProps,
} from "../../generic-forms/GFBaseElement";
import "./LayoutComponentsImpl.scss";

type GFAvatarProps = {
  avatarField: string;
  displaynameField: string;

  className?: string;
  condition?: string;
  renderEmpty: boolean;
  stateSubscriptions: string[];
  style?: CSSProperties;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
} & GenericFormsLayoutProps;
export const GFShowAvatar: React.FC<GFAvatarProps> = (props) => {
  const {
    className,
    condition,
    stateSubscriptions,
    style,
    size,
    displaynameField,
    avatarField,
  } = props;

  return (
    <Field
      name={displaynameField}
      render={(displaynameProps) => {
        return (
          <Field
            name={avatarField}
            render={(avatarProps) => {
              return (
                <GenericLayoutComponent
                  stateSubscriptions={stateSubscriptions}
                  condition={condition}
                  style={style}
                  render={(styleProps) => {
                    return (
                      <AvatarComponent
                        avatar={avatarProps.input.value}
                        displayName={displaynameProps.input.value}
                        size={size}
                      />
                    );
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

type GFCardLayout = {
  className?: string;
  items: { [key: string]: any };
  condition?: string;
  stateSubscriptions: string[];
  style?: CSSProperties;
} & GenericFormsLayoutProps;
export const GFCard: React.FC<GFCardLayout> = (props) => {
  const { className, condition, components, style, stateSubscriptions } = props;

  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      condition={condition}
      style={style}
      render={(styleProps) => {
        return (
          <div
            className={`card-component ${className ? className : ""}`}
            style={styleProps}
          >
            {Object.values(components).map((item) => (
              <GFBaseElement {...item} params={props.params} />
            ))}
          </div>
        );
      }}
    />
  );
};

type GFMessageProps = {
  messageType: "info" | "success" | "warning" | "error";
  titleKey?: string;
  textKey: string;
  title?: string;
  text?: string;
  closable?: boolean;
  showIcon?: boolean;

  className?: string;
  condition?: string;
  stateSubscriptions?: string[];
  style?: CSSProperties;
} & GenericFormsLayoutProps;
export const GFMessage: React.FC<GFMessageProps> = (props) => {
  const {
    className,
    condition,
    style,
    stateSubscriptions,
    messageType,
    titleKey,
    textKey,
    closable,
    showIcon,
    title,
    text,
  } = props;

  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      condition={condition}
      style={style}
      render={(styleProps) => {
        return (
          <BFMessage
            text={text}
            title={title}
            type={messageType}
            titleKey={titleKey}
            textKey={textKey}
            closable={closable}
            showIcon={showIcon}
            style={styleProps}
          />
        );
      }}
    />
  );
};

type GFFieldViewTableProps = {
  className?: string;
  items: (GFBaseElementProps | string)[];
  condition?: string;
  stateSubscriptions: string[];
  style?: CSSProperties;
  editable?: string[];
} & GenericFormsLayoutProps;
export const BFFieldViewTable: React.FC<GFFieldViewTableProps> = (props) => {
  const {
    className,
    condition,
    stateSubscriptions,
    components,
    params,
    style,
    editable,
  } = props;

  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      condition={condition}
      style={style}
      render={(styleProps) => {
        return (
          <div style={styleProps}>
            <div className={`field-view-table ${className ? className : ""}`}>
              {Object.values(components).map((item, index) => {
                if (typeof item === "string" || item.fieldName) {
                  const keyName =
                    typeof item === "string" ? item : item.fieldName;
                  const prefix =
                    typeof item === "string" ? null : props.params.prefix;
                  const itemProps = params.allProperties[keyName];

                  return (
                    <GenericLayoutComponent
                      key={item + "_" + index}
                      condition={itemProps.condition}
                      // style={style}
                      render={(styleProps) => (
                        <div style={styleProps} className={"row-entry"}>
                          <div className={"field-label"}>
                            <GFFieldLabel name={keyName} params={params} />
                          </div>
                          {editable && editable.indexOf(keyName) !== -1 ? (
                            <div className={"field-value"}>
                              <GFBaseElement
                                _component={"property"}
                                name={keyName}
                                params={params}
                                label={""}
                              />
                            </div>
                          ) : (
                            <div className={"field-value"}>
                              <GFFieldValue name={keyName} params={params} />
                            </div>
                          )}
                        </div>
                      )}
                    />
                  );
                } else {
                  return (
                    <GenericLayoutComponent
                      key={index}
                      condition={item.condition}
                      style={item.style}
                      // style={style}
                      render={(styleProps) => (
                        <div
                          className={"row-entry subtitle"}
                          style={styleProps}
                        >
                          <GFBaseElement {...item} params={params} />
                        </div>
                      )}
                    />
                  );
                }
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

type GFPromptBlockProps = {
  textKey?: string;
  items?: { [key: string]: any };
} & GenericFormsLayoutProps;
export const GFPromptBlock: React.FC<GFPromptBlockProps> = (props) => {
  const { i18n } = useTranslation();
  const { components, textKey } = props;

  return (
    <>
      <FormSpy subscription={{ pristine: true, submitSucceeded: true }}>
        {({ pristine, submitSucceeded }) => {
          return (
            <BFPrompt
              when={(currentLocation, nextLocation) =>
                currentLocation?.pathname !== nextLocation?.pathname &&
                !submitSucceeded &&
                !pristine
              }
              textKey={textKey}
            />
          );
        }}
      </FormSpy>

      {components
        ? Object.values(components).map((item) => (
            <GFBaseElement {...item} params={props.params} />
          ))
        : null}
    </>
  );
};
