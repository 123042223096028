import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import CBRentalUtils from "../../../../../../apps/tatar/cashBudget/views/tenants/CBRentalUtils";
import { RentalVacancy } from "../../../../../../apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import LanguageService from "../../../../../../services/LanguageService";
import StringUtils from "../../../../../../utils/StringUtils";
import BFStatus from "../../../../../abstract-ui/data/status/BFStatus";
import LabeledInput from "../../../../../abstract-ui/forms/LabeledInput";
import { Resubmission } from "../../../../RSInterfaces";
import RSAssetDetails from "../../../asset-details/RSAssetDetails";
import RSSubAssetMapper from "../../../asset-details/sub-asset-mapper/RSSubAssetMapper";
import "./RSRentalVacancyPreview.scss";

interface RSRentalVacancyPreviewProps {
  resubmission: Resubmission;
}

const RSRentalVacancyPreview = (props: RSRentalVacancyPreviewProps) => {
  const { linkedAsset } = props.resubmission.data;

  const renderTypeInfoDetails = (rentalVacancy: RentalVacancy) => {
    return (
      <RSSubAssetMapper
        resubmission={props.resubmission}
        asset={rentalVacancy}
      />
    );
  };

  return (
    <div className={classNames("rs-rental-vacancy-preview")}>
      <LabeledInput
        type="sub"
        label={i18n.t(
          "Resubmission.Dashboard.RentalVacancy.Title",
          "Leerstand Daten"
        )}
      >
        <RSAssetDetails
          assetParams={linkedAsset}
          renderAssetData={(asset: RentalVacancy) => {
            if (asset === null || asset === undefined) {
              return (
                <div className="rs-asset-not-found">
                  {i18n.t(
                    "Resubmission.Error.RentalVacancyNotFound",
                    "Keine Leerstand Daten gefunden"
                  )}
                </div>
              );
            }

            let status = CBRentalUtils.calculateVacancyStatus(asset);
            return (
              <div className="rental-vancancy-container">
                <div className={`content`}>
                  <div className={`entry-list`}>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:RentalVacancy.Object", "Objekt")}
                      </div>
                      <div className={`value`}>
                        <div>
                          {OrgaStruct.getObject(asset.data.objectId)?.id} -{" "}
                          {
                            OrgaStruct.getObject(asset.data.objectId)
                              ?.displayName
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:RentalVacancy.RentalUnit", "Mieteinheit")}
                      </div>
                      <div className={`value`}>
                        <AssetLoader
                          assetType={AssetTypes.Rental.RentalUnit}
                          id={asset.data.rentalUnit}
                          render={(rentalUnit) => {
                            if (!rentalUnit) {
                              return <div>Not found</div>;
                            }
                            return (
                              <div>
                                {LanguageService.translateLabel(
                                  rentalUnit.data.displayName
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`entry-list`}>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t("cb:RentalVacancy.Details.Status", "Status")}
                      </div>
                      <div className={`value`}>
                        <BFStatus
                          label={status.label()}
                          color={status.color}
                          size={"sm"}
                        />
                      </div>
                    </div>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t(
                          "cb:RentalVacancy.Details.StartDate",
                          "Leerstand Anfang"
                        )}
                      </div>
                      <div className={`value`}>
                        {StringUtils.formatDate(asset.data.moveIn)}
                      </div>
                    </div>
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t(
                          "cb:RentalVacancy.Details.EndDate",
                          "Leerstand Ende"
                        )}
                      </div>
                      <div className={`value`}>
                        {asset.data.moveOut
                          ? StringUtils.formatDate(asset.data.moveOut)
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div className={`entry-list`}>
                    {renderTypeInfoDetails(asset)}
                  </div>
                </div>
              </div>
            );
          }}
        />
      </LabeledInput>
    </div>
  );
};

export default RSRentalVacancyPreview;
