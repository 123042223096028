import moment from "moment";
import { Trans } from "react-i18next";
import { AdminUser } from "../../../apps/administration/AdminInterfaces";
import { APActivity } from "../../../apps/tatar/activityApp/ActivityInterfaces";
import ACActivityUtils from "../../../apps/utils/ACActivityUtils";
import AssetLoader from "../../../components/AssetLoader/AssetLoader";
import UserAvatar from "../../../components/AvatarComponent/UserAvatar";
import Username from "../../../components/AvatarComponent/Username";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import i18n from "../../../i18n";
import CacheService from "../../../services/CacheService";
import DataBus from "../../../services/DataBus";
import { DataBusSubKeys } from "../../../utils/Constants";
import BFStatus from "../../abstract-ui/data/status/BFStatus";

import UserLoader from "@/components/AssetLoader/UserLoader";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { hasValue } from "@/utils/Helpers";
import { AssetTypes } from "../../../model/AssetTypes";
import LanguageService from "../../../services/LanguageService";
import { RSAssetBaseParams } from "../../resubmission/RSInterfaces";
import useOpenResubmissionForm from "../../resubmission/hooks/useOpenResubmissionForm";
import CommentUtils from "../CommentUtils";
import {
  Comment,
  InternComment,
  MailIncomingComment,
  MailOutgoingComment,
  SystemComment,
  SystemMessages,
} from "../Comments";
import { CommentContainer } from "./CommentContainer";
import "./CommentEntry.scss";
import CommentEntryContent from "./CommentEntryContent";
import { CommentAttachmentEntry } from "./CommentInput";
import { EmailAddressFieldModel } from "./EmailAddressFields";
import MailOutgoingStatus from "./MailOutgoingStatus";

export const EMAIL_TAG_COLOR = "#dcdcdc";
interface CommentEntryProps {
  collapsible?: boolean;
  comment: Comment;
  commentModuleIdentifier?: string;
  context?: {
    id: number;
    name: string;
  };
  onPinChange?: () => void;
  onContextClick?: (contextId: number) => void;
  defaultSignature?: string;
  allowFullscreen?: boolean;
  hideAllActions?: boolean;
  disableFocusMode?: boolean;
  hideResubmissionActionForMail?: boolean;
  apperance?: "normal" | "slim";
  onClose?: () => void;
  renderMailDuplicateAction?: (
    comment: Comment,
    onClose: () => void
  ) => JSX.Element;
}
const CommentEntry = (props: CommentEntryProps) => {
  return (
    <div className={`comment-entry text-selection-all`}>
      <DebugDataComponent data={props.comment} />
      {props.comment.data?.type === "system" && (
        <SystemCommentEntry comment={props.comment as SystemComment} />
      )}
      {props.comment.data?.type === "intern" && (
        <InternCommentEntry
          collapsible={props.collapsible}
          onPinChange={props.onPinChange}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          apperance={props.apperance}
          context={props.context}
          comment={props.comment as InternComment}
          commentModuleIdentifier={props.commentModuleIdentifier}
          allowFullscreen={props.allowFullscreen}
          disableFocusMode={props.disableFocusMode}
          hideAllActions={props.hideAllActions}
        />
      )}
      {props.comment.data?.type === "mail_outgoing" && (
        <MailOutgoingCommentEntry
          collapsible={props.collapsible}
          onPinChange={props.onPinChange}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          apperance={props.apperance}
          context={props.context}
          comment={props.comment as MailOutgoingComment}
          defaultSignature={props.defaultSignature}
          commentModuleIdentifier={props.commentModuleIdentifier}
          allowFullscreen={props.allowFullscreen}
          hideResubmissionActionForMail={props.hideResubmissionActionForMail}
          disableFocusMode={props.disableFocusMode}
          renderMailDuplicateAction={props.renderMailDuplicateAction}
          hideAllActions={props.hideAllActions}
        />
      )}
      {props.comment.data?.type === "mail_incoming" && (
        <MailIncomingCommentEntry
          collapsible={props.collapsible}
          onPinChange={props.onPinChange}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          apperance={props.apperance}
          context={props.context}
          comment={props.comment as MailIncomingComment}
          defaultSignature={props.defaultSignature}
          commentModuleIdentifier={props.commentModuleIdentifier}
          allowFullscreen={props.allowFullscreen}
          hideResubmissionActionForMail={props.hideResubmissionActionForMail}
          disableFocusMode={props.disableFocusMode}
          renderMailDuplicateAction={props.renderMailDuplicateAction}
          hideAllActions={props.hideAllActions}
        />
      )}
    </div>
  );
};

export default CommentEntry;

interface MailOutgoingCommentProps {
  onPinChange?: () => void;
  comment: MailOutgoingComment;
  commentModuleIdentifier: string;
  context?: {
    id: number;
    name: string;
  };
  onContextClick?: (contextId: number) => void;
  defaultSignature?: string;
  allowFullscreen?: boolean;
  disableFocusMode?: boolean;
  hideAllActions?: boolean;
  onClose?: () => void;
  onRedirect?: () => void;
  hideResubmissionActionForMail?: boolean;
  apperance?: "normal" | "slim";
  renderMailDuplicateAction?: (
    comment: Comment,
    onClose: () => void
  ) => JSX.Element;
  collapsible?: boolean;
}
export const MailOutgoingCommentEntry = (props: MailOutgoingCommentProps) => {
  const { openResubmissionDialog } = useOpenResubmissionForm();

  const possibleTags = (
    UnitStruct.getAllTagConfigs(AssetTypes.Comment) || []
  ).filter((e) => e.status !== "archived");

  const openResubmissionAction = async () => {
    const linkedAsset: RSAssetBaseParams = {
      assetType: props.comment.data?.linkedAsset.type,
      assetId: props.comment.data?.linkedAsset.id,
      assetDescription: `${i18n.t(
        "CommentsModule.OutgoingMail.Label",
        "E-Mail (ausgehend)"
      )}: ${props.comment.data?.typeData.subject}`,
      subAsset: {
        assetType: AssetTypes.Comment,
        assetId: props.comment._id,
      },
    };
    const activity = (await CacheService.getData({
      oType: "asset",
      assetType: props.comment.data?.linkedAsset.type,
      id: props.comment.data?.linkedAsset.id,
      forceReload: true,
    })) as APActivity;

    openResubmissionDialog({
      linkedAsset,
      typePermissions: [activity.data.type],
    });
  };

  const renderMailDuplicateAction = props.renderMailDuplicateAction
    ? (onClose: () => void) => {
        if (props.renderMailDuplicateAction) {
          return props.renderMailDuplicateAction(props.comment, onClose);
        }
      }
    : undefined;

  return (
    <CommentContainer
      collapsible={props.collapsible}
      pinned={props.comment.data.pin}
      onPinChange={props.onPinChange}
      archived={props.comment.data?.archived}
      commentId={props.comment._id}
      tagData={{
        possibleTags,
        tags: props.comment.data?.tags,
      }}
      hideAllActions={props.hideAllActions}
      onClose={props.onClose}
      onContextClick={props.onContextClick}
      className={`outgoing-mail`}
      disableFocusMode={props.disableFocusMode}
      apperance={props.apperance}
      onPrint={() => ({
        subject: props.comment.data?.typeData.subject,
        cc: props.comment.data?.typeData.cc,
        to: props.comment.data?.typeData.recipients,
        date: props.comment.data?.date,
      })}
      allowFullscreen={props.allowFullscreen}
      title={
        <>
          <strong>
            <Username id={props.comment.data?.typeData.userId} />
          </strong>{" "}
          <Trans i18nKey="CommentsModule.OutgoingMail.headerLabel">
            hat eine <span className="weight-600">Mail versendet</span>
          </Trans>
        </>
      }
      context={props.context}
      sender={props.comment.data?.typeData.userId}
      date={props.comment.data?.date}
      content={<CommentEntryContent comment={props.comment} />}
      subData={
        <div className={`mail-data`}>
          <div className={`recipients field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.recipients", "Empfänger")}:
            </div>
            <div className="value">
              {props.comment.data?.typeData.recipients.map(
                (recipient, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(recipient)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                )
              )}
            </div>
          </div>
          {props.comment.data?.typeData.cc?.length !== 0 && (
            <div className={`cc field`}>
              <div className={`label`}>
                {i18n.t("CommentsModule.OutgoingMail.cc", "CC")}:
              </div>
              <div className="value">
                {props.comment.data?.typeData.cc.map((cc, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(cc)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                ))}
              </div>
            </div>
          )}
          {props.comment.data?.typeData.bcc?.length !== 0 && (
            <div className={`bcc field`}>
              <div className={`label`}>
                {i18n.t("CommentsModule.OutgoingMail.bcc", "BCC")}:
              </div>
              <div className="value">
                {props.comment.data?.typeData.bcc.map((bcc, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(bcc)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className={`subject field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.subject", "Betreff")}:
            </div>
            <div className="value">{props.comment.data?.typeData.subject}</div>
          </div>
        </div>
      }
      footer={{
        attachment: {
          asset: props.comment,
          assetType: "comment",
          attachmentFieldPath: "data.attachments",
          attachments: props.comment.data?.attachments,
        },
        center: (
          <MailOutgoingStatus status={props.comment.data?.typeData.status} />
        ),
        onCreateResubmission:
          props.hideResubmissionActionForMail || props.hideAllActions
            ? undefined
            : openResubmissionAction,
        onReply:
          props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
            <p>
            </p>
            ${props.defaultSignature || ""}
            <br>
            <br>
            <div class="iberio-cite-prefix">${i18n.t(
              "CommentsModule.OutgoingMail.citePrefix",
              "Am {{date}}, Sie schrieben: ",
              {
                date: moment(props.comment.data?.date).format(
                  i18n.t("Formats.dateTimeFormat")
                ),
              }
            )}<br></div>
            <blockquote type="cite" cite="mid:${props.comment._id}">
            ${CommentUtils.stripImagesBase64FromHTML(
              CommentUtils.stripCommentsFromHTML(
                props.comment.cdnHtml ||
                  props.comment.data?.message.split("\n").join("<br>")
              )
            )}
            </blockquote>

                  `,
                  sendAsMail: true,
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: props.comment.data?.typeData.recipients,
                    cc: props.comment.data?.typeData.cc,
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,

        onRedirect:
          props.onRedirect && !props.hideAllActions
            ? props.onRedirect
            : props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
      <p>
      </p>
      ${props.defaultSignature || ""}
      <br>
      <br>
      
      <div class="iberio-cite-prefix">${i18n.t(
        "CommentsModule.OutgoingMail.citePrefix",
        "{{date}}: ",
        {
          date: moment(props.comment.data?.date).format(
            i18n.t("Formats.dateTimeFormat")
          ),
        }
      )}<br></div>
      <blockquote type="cite">
      ${CommentUtils.stripImagesBase64FromHTML(
        CommentUtils.stripCommentsFromHTML(
          props.comment.cdnHtml ||
            props.comment.data?.message.split("\n").join("<br>")
        )
      )}
      </blockquote>
    `,
                  sendAsMail: true,
                  attachments: props.comment.cdn
                    .filter((cdn) => {
                      return props.comment.data?.attachments?.find(
                        (attachment) => attachment.linkToCdn === cdn._id
                      );
                    })
                    .map(
                      (cdn) =>
                        ({
                          cdnId: cdn._id,
                          filename: cdn.filename,
                          fromtAssetId: props.comment._id,
                        } as CommentAttachmentEntry)
                    ),
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: [],
                    cc: [],
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,
      }}
      renderMailDuplicateAction={renderMailDuplicateAction}
    />
  );
};

interface MailIncomingCommentProps {
  onPinChange?: () => void;
  comment: MailIncomingComment;
  commentModuleIdentifier?: string;
  context?: {
    id: number;
    name: string;
  };
  onContextClick?: (contextId: number) => void;
  defaultSignature?: string;
  allowFullscreen?: boolean;
  onReply?: () => void;
  onRedirect?: () => void;
  hideAllActions?: boolean;
  disableFocusMode?: boolean;
  hideResubmissionActionForMail?: boolean;
  apperance?: "normal" | "slim";
  onClose?: () => void;
  renderMailDuplicateAction?: (
    comment: Comment,
    onClose: () => void
  ) => JSX.Element;
  collapsible?: boolean;
}
export const MailIncomingCommentEntry = (props: MailIncomingCommentProps) => {
  const { openResubmissionDialog } = useOpenResubmissionForm();

  const possibleTags = (
    UnitStruct.getAllTagConfigs(AssetTypes.Comment) || []
  ).filter((e) => e.status !== "archived");

  const openResubmissionAction = () => {
    const linkedAsset: RSAssetBaseParams = {
      assetType: props.comment.data?.linkedAsset?.type,
      assetId: props.comment.data?.linkedAsset.id,
      assetDescription: `${i18n.t(
        "CommentsModule.OutgoingMail.Label",
        "E-Mail (ausgehend)"
      )}: ${props.comment.data?.typeData.subject}`,
      subAsset: {
        assetType: AssetTypes.Comment,
        assetId: props.comment._id,
      },
    };
    openResubmissionDialog({ linkedAsset });
  };

  const renderMailDuplicateAction = props.renderMailDuplicateAction
    ? (onClose: () => void) => {
        if (props.renderMailDuplicateAction) {
          return props.renderMailDuplicateAction(props.comment, onClose);
        }
      }
    : undefined;

  return (
    <CommentContainer
      collapsible={props.collapsible}
      pinned={props.comment.data.pin}
      onPinChange={props.onPinChange}
      archived={props.comment.data?.archived}
      commentId={props.comment._id}
      tagData={{
        possibleTags,
        tags: props.comment.data?.tags,
      }}
      hideAllActions={props.hideAllActions}
      onClose={props.onClose}
      onContextClick={props.onContextClick}
      className={`incoming-mail`}
      allowFullscreen={props.allowFullscreen}
      disableFocusMode={props.disableFocusMode}
      subTitle={
        hasValue(props.comment.data?.typeData.replyTo) && (
          <>
            <Trans
              tOptions={{
                interpolation: {
                  escapeValue: true,
                },
              }}
              i18nKey="CommentsModule.IncomingMail.replyToText"
            >
              Antwort wird gesendet an:
              <span className="weight-600">
                {{
                  replyTo: props.comment.data?.typeData.replyTo,
                }}
              </span>
            </Trans>
          </>
        )
      }
      title={
        <>
          <Trans
            tOptions={{
              interpolation: {
                escapeValue: true,
              },
            }}
            i18nKey="CommentsModule.IncomingMail.headerLabel"
          >
            Sie haben eine <span className="weight-600"> E-Mail</span> von{" "}
            <span className="weight-600">
              {{
                from: CommentUtils.formatEmailUser(
                  props.comment.data?.typeData.sender
                ),
              }}
            </span>{" "}
            erhalten.
          </Trans>
        </>
      }
      context={props.context}
      icon={"email-action-unread"}
      apperance={props.apperance}
      onPrint={() => ({
        subject: props.comment.data?.typeData.subject,
        cc: props.comment.data?.typeData.cc,
        to: props.comment.data?.typeData.recipients,
        date: props.comment.data?.date,
        from: props.comment.data?.typeData.sender,
      })}
      date={props.comment.data?.date}
      content={<CommentEntryContent comment={props.comment} />}
      subData={
        <div className={`mail-data`}>
          <div className={`recipients field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.recipients", "Empfänger")}:
            </div>
            <div className="value">
              {props.comment.data?.typeData.recipients.map((recipient, key) => (
                <BFStatus
                  key={key}
                  label={CommentUtils.formatEmailUser(recipient)}
                  size="sm"
                  color={EMAIL_TAG_COLOR}
                  style={{ fontWeight: 400 }}
                />
              ))}
            </div>
          </div>
          {props.comment.data?.typeData.cc?.length !== 0 && (
            <div className={`cc field`}>
              <div className={`label`}>
                {i18n.t("CommentsModule.OutgoingMail.cc", "CC")}:
              </div>
              <div className="value">
                {props.comment.data?.typeData.cc.map((cc, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(cc)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className={`subject field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.subject", "Betreff")}:
            </div>
            <div className="value">{props.comment.data?.typeData.subject}</div>
          </div>
        </div>
      }
      footer={{
        attachment: {
          asset: props.comment,
          assetType: "comment",
          attachmentFieldPath: "data.attachments",
          attachments: props.comment.data?.attachments,
        },
        onCreateResubmission:
          props.hideResubmissionActionForMail || props.hideAllActions
            ? undefined
            : openResubmissionAction,
        onRedirect:
          props.onRedirect && !props.hideAllActions
            ? props.onRedirect
            : props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
        <p>
        </p>
        ${props.defaultSignature || ""}
        <br>
        <br>
        
        <div class="iberio-cite-prefix">${i18n.t(
          "CommentsModule.IncomingMail.citePrefix",
          "Am {{date}}, {{sender}} schrieb: ",
          {
            date: moment(props.comment.data?.date).format(
              i18n.t("Formats.dateTimeFormat")
            ),
            sender: CommentUtils.formatEmailUser(
              props.comment.data?.typeData.sender
            ),
          }
        )}<br></div>
        <blockquote type="cite" cite="mid:${
          props.comment.data?.typeData.sender.address
        }">
        ${CommentUtils.stripImagesBase64FromHTML(
          CommentUtils.stripCommentsFromHTML(
            props.comment.cdnHtml ||
              props.comment.data?.message.split("\n").join("<br>")
          )
        )}
        </blockquote>
      `,
                  sendAsMail: true,
                  attachments: props.comment.cdn
                    .filter((cdn) => {
                      return props.comment.data?.attachments?.find(
                        (attachment) => attachment.linkToCdn === cdn._id
                      );
                    })
                    .map(
                      (cdn) =>
                        ({
                          cdnId: cdn._id,
                          filename: cdn.filename,
                          fromtAssetId: props.comment._id,
                        } as CommentAttachmentEntry)
                    ),
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: [],
                    cc: [],
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,
        onReply:
          props.onReply && !props.hideAllActions
            ? props.onReply
            : props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
            <p>
            </p>
            ${props.defaultSignature || ""}
            <br>
            <br>
            
            <div class="iberio-cite-prefix">${i18n.t(
              "CommentsModule.IncomingMail.citePrefix",
              "Am {{date}}, {{sender}} schrieb: ",
              {
                date: moment(props.comment.data?.date).format(
                  i18n.t("Formats.dateTimeFormat")
                ),
                sender: CommentUtils.formatEmailUser(
                  props.comment.data?.typeData.sender
                ),
              }
            )}<br></div>
            <blockquote type="cite" cite="mid:${
              props.comment.data?.typeData.sender.address
            }">
            ${CommentUtils.stripImagesBase64FromHTML(
              CommentUtils.stripCommentsFromHTML(
                props.comment.cdnHtml ||
                  props.comment.data?.message.split("\n").join("<br>")
              )
            )}
            </blockquote>
          `,
                  sendAsMail: true,
                  // attachments: props.comment.cdn
                  //   .filter((cdn) => {
                  //     return props.comment.data?.attachments?.find(
                  //       (attachment) => attachment.linkToCdn === cdn._id
                  //     );
                  //   })
                  //   .map(
                  //     (cdn) =>
                  //       ({
                  //         cdnId: cdn._id,
                  //         filename: cdn.filename,
                  //       } as CommentAttachmentEntry)
                  //   ),
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: hasValue(props.comment.data?.typeData.replyTo)
                      ? [
                          {
                            name: "",
                            address: props.comment.data?.typeData.replyTo,
                          },
                        ]
                      : [props.comment.data?.typeData.sender],
                    cc: props.comment.data?.typeData.cc,
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,
      }}
      renderMailDuplicateAction={renderMailDuplicateAction}
    />
  );
};

interface InternCommentProps {
  onPinChange?: () => void;
  comment: InternComment;
  commentModuleIdentifier: string;
  context?: {
    id: number;
    name: string;
  };
  onContextClick?: (contextId: number) => void;
  allowFullscreen?: boolean;
  hideAllActions?: boolean;
  disableFocusMode?: boolean;
  apperance?: "normal" | "slim";
  onClose?: () => void;
  collapsible?: boolean;
}
export const InternCommentEntry = (props: InternCommentProps) => {
  const possibleTags = (
    UnitStruct.getAllTagConfigs(AssetTypes.Comment) || []
  ).filter((e) => e.status !== "archived");

  return (
    <UserLoader
      id={props.comment.data?.typeData.userId}
      render={(sender) => (
        <CommentContainer
          collapsible={props.collapsible}
          pinned={props.comment.data.pin}
          onPinChange={props.onPinChange}
          archived={props.comment.data?.archived}
          commentId={props.comment._id}
          tagData={{
            possibleTags,
            tags: props.comment.data?.tags,
          }}
          hideAllActions={props.hideAllActions}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          className="intern-comment"
          allowFullscreen={props.allowFullscreen}
          apperance={props.apperance}
          onPrint={() => ({
            from: {
              name: sender.displayname,
            },
            date: props.comment.data?.date,
          })}
          title={
            <>
              <strong>
                <Username id={props.comment.data?.typeData.userId} />
              </strong>{" "}
              <Trans i18nKey="CommentsModule.InternalMessage.headerText">
                hat eine <span className="weight-600">interne Nachricht</span>{" "}
                hinzugefügt
              </Trans>
            </>
          }
          disableFocusMode={props.disableFocusMode}
          context={props.context}
          sender={props.comment.data?.typeData.userId}
          date={props.comment.data?.date}
          content={<CommentEntryContent comment={props.comment} />}
          footer={{
            onReply:
              props.commentModuleIdentifier && !props.hideAllActions
                ? async () => {
                    const replyToId = props.comment.data?.typeData.userId;
                    const replyTo = (await CacheService.getData({
                      oType: "user",
                      checkTables: true,
                      id: replyToId,
                    })) as AdminUser;
                    DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                      identifier: props.commentModuleIdentifier,
                      cursorPosition: "end",
                      subactivity: props.comment.data?.subactivityId,
                      comment: `
                <span class="mention" data-mention="@${
                  replyTo.displayname
                }" data-object-id="${replyToId}" data-mention-type="user">@${
                        replyTo.displayname
                      }</span>
                <br>
                <blockquote>${CommentUtils.stripQuotesFromHTML(
                  CommentUtils.stripMentionsFromHTML(
                    props.comment.cdnHtml ||
                      props.comment.data?.message.split("\n").join("<br>")
                  )
                )}</blockquote><p><br></p><p><br></p>`,
                      sendAsMail: false,
                    });
                  }
                : undefined,
          }}
        />
      )}
    />
  );
};

interface SystemCommentProps {
  comment: SystemComment;
}
export const SystemCommentEntry = (props: SystemCommentProps) => {
  return (
    <div className={`system-comment`}>
      <div className={`line`} />
      <div className={`comment-content`}>
        <div className={`comment-text`}>
          {(props.comment.data?.message ===
            SystemMessages.DAMAGECLAIM_OFFER_STARTED ||
            props.comment.data?.message ===
              SystemMessages.ACTIVITY_OFFER_STARTED) && (
            <div>
              {i18n.t(
                "CommentsModule.SystemMessages.ACTIVITY_OFFER_STARTED",
                "Aktivität in Genehmigung gestellt"
              )}
            </div>
          )}
          {(props.comment.data?.message ===
            SystemMessages.DAMAGECLAIM_ACTIVITY_CREATED ||
            props.comment.data?.message ===
              SystemMessages.ACTIVITY_CREATED) && (
            <div>
              {i18n.t(
                "CommentsModule.SystemMessages.ACTIVITY_CREATED",
                "Aktivität wurde erstellt"
              )}
            </div>
          )}
          {(props.comment.data?.message ===
            SystemMessages.DAMAGECLAIM_STATUS_CHANGE ||
            props.comment.data?.message ===
              SystemMessages.ACTIVITY_STATUS_CHANGE) && (
            <AssetLoader
              assetType={props.comment.data?.linkedAsset?.type}
              id={props.comment.data?.linkedAsset.id}
              render={(asset: APActivity) => {
                let label = "";
                let color = "";
                if (typeof props.comment.data?.typeData.extra === "string") {
                  const statusEntry = ACActivityUtils.getStatus(
                    asset.data?.type,
                    props.comment.data?.typeData.extra
                  );

                  label = LanguageService.translateLabel(
                    statusEntry?.displayName
                  );
                  color = statusEntry?.color;
                } else {
                  const statusEntry = ACActivityUtils.getStatus(
                    asset.data?.type,
                    props.comment.data?.typeData.extra?.newStatus
                  );
                  label = LanguageService.translateLabel(
                    statusEntry?.displayName
                  );
                  color = statusEntry?.color;
                  const subStatus = props.comment.data?.typeData.extra
                    ?.newSubStatus
                    ? statusEntry?.subStatus?.find(
                        (e) =>
                          e.id ===
                          props.comment.data?.typeData.extra?.newSubStatus
                      )
                    : null;
                  if (subStatus) {
                    label = `${label} - ${LanguageService.translateLabel(
                      subStatus?.displayName
                    )}`;
                  }
                }

                return (
                  <div className={`state-change`}>
                    <div className={`label`}>
                      {i18n.t(
                        "CommentsModule.SystemMessages.ACTIVITY_STATUS_CHANGE",
                        "Status wurde geändert zu"
                      )}
                    </div>
                    <BFStatus label={label} color={color} />
                  </div>
                );
              }}
            />
          )}
        </div>
        <div className={`meta`}>
          {props.comment.data?.typeData.userId ? (
            <div className={`user`}>
              <UserAvatar id={props.comment.data?.typeData.userId} size={16} />
              <Username id={props.comment.data?.typeData.userId} />
            </div>
          ) : (
            <div className={`system`}>
              {i18n.t("Labels.changedBySystem", "Vom System geändert")}
            </div>
          )}
          <div className={`timestamp`}>
            {moment(props.comment.data?.date).format(
              i18n.t("Formats.dateTimeFormat")
            )}
          </div>
        </div>
      </div>
      <div className={`line`} />
    </div>
  );
};
