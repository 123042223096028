import CacheService from "@/services/CacheService";
import { HTTP } from "@/utils/Http";
import { OAObject, ObjectFormValue } from "./types/object.interface";

class ObjectServiceClass {
  public async submitObject(
    objectFormValue: ObjectFormValue,
    assetId?: string
  ) {
    if (!objectFormValue) {
      throw new Error("objectFormValue is null or undefined");
    }
    const submitData = { ...objectFormValue };
    if (assetId) {
      submitData.entity = undefined;
      submitData.objectKindId = undefined;
      submitData.type = undefined;
    } else {
      submitData.status = undefined;
    }

    const asset = (await HTTP.post({
      url: assetId
        ? `/api/portfolioObject/${assetId}/updatePortfolioObject`
        : `/api/portfolioObject/createPortfolioObject`,
      target: "EMPTY",
      bodyParams: {
        ...submitData,
      },
    })) as OAObject;

    CacheService.update(asset);
    return asset;
  }
}
const ObjectService = new ObjectServiceClass();
export default ObjectService;
