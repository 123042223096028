import classNames from "classnames";
import { valueOrDefault } from "../../../../utils/Helpers";
import BFPlaceholder from "../../general/Placeholder/BFPlaceholder";
import "./BFValueDisplay.scss";

type StringValue = {
  value: string;
  formatter?: (value: string) => React.ReactNode;
};
type AnyValue = {
  value: any;
  formatter: (value: any) => React.ReactNode;
};
type BFValueDisplayProps = (StringValue | AnyValue) & {
  className?: string;
  label: string;
  paddingRight?: number;
  paddingLeft?: number;
  loading?: boolean;
};
const BFValueDisplay = (props: BFValueDisplayProps) => {
  return (
    <div
      className={classNames(`bf-value-display`, props.className)}
      style={{
        paddingRight: valueOrDefault(props.paddingRight, 15),
        paddingLeft: props.paddingLeft,
      }}
    >
      <div className={`bf-value-display__label`}>{props.label}</div>
      <div className={`bf-value-display__value`}>
        <BFPlaceholder width={50} loading={props.loading}>
          {props.formatter ? props.formatter(props.value) : props.value}
        </BFPlaceholder>
      </div>
    </div>
  );
};

export default BFValueDisplay;
