import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import moment from "moment";
import React from "react";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import PersistentSplitPane from "../../../../../../../configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { Contact } from "../../../../../../../model/db/Contact";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import EZTextfield from "../../../../../../../modules/ez-form/form-elements/ez-textfield/EZTextfield";
import EZField from "../../../../../../../modules/ez-form/form-group/ez-field/EZField";
import { useAggregationStatisticQuery } from "../../../../../../../redux/hooks";
import CacheService from "../../../../../../../services/CacheService";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import GlobalActions from "../../../../../../../services/GlobalActions";
import LanguageService from "../../../../../../../services/LanguageService";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  EnrichtedRentalUnit,
  RentalAgreement,
  RentalUnit,
} from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import "./CBRentalAgreementDetailsPage.scss";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "./CBRentalAgreementHelper";
import CBRentalAgreementRentChange from "./CBRentalAgreementRentChange";

interface CBRentalAgreementDetailsProps {
  goBack?: () => void;
  rentalAgreement: RentalAgreement;
  hideBackButton?: boolean;
  hideStackingPlan?: boolean;
  hideActions?: boolean;
  kind: ObjectKind;
}
export const CBRentalAgreementDetailsPage = (
  props: CBRentalAgreementDetailsProps
) => {
  const chartId = React.useRef<string>(null);
  const comp = (
    <>
      <div className={`page-content`}>
        <div className={`header`}>
          {!props.hideBackButton && (
            <BFButton
              className={`back-button`}
              appearance={"clear-on-white"}
              icon={{ size: "xs", ...DefaultIcons.BACK }}
              onClick={props.goBack}
            />
          )}
          <div className={`title`}>
            <div className={`id`}>
              {i18n.t("cb:RentalAgreement.DetailsTitle", "Mietvertrag")}{" "}
              {props.rentalAgreement.data.id}
              <DebugDataComponent data={props.rentalAgreement} />
            </div>
            <div className={`name`}>
              {props.rentalAgreement.data.displayName}
            </div>
            <div className={`object`}>
              <BFDetailsButton
                appearance="link"
                size="xs"
                data={{
                  assetType: AssetTypes.Portfolio.Object,
                  assetId: props.rentalAgreement.data.objectId,
                  type: props.rentalAgreement.data.type,
                }}
              >
                {OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.id}{" "}
                -{" "}
                {
                  OrgaStruct.getObject(props.rentalAgreement.data.objectId)
                    ?.displayName
                }
              </BFDetailsButton>
            </div>
          </div>
        </div>
        <div className={`content`}>
          <div className={`unit-data entry-list`}>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t("cb:RentalAgreement.Labels.moveIn", "Mietbeginn")}
              </div>
              <div className={`value`}>
                {props.rentalAgreement.data.moveIn
                  ? moment(props.rentalAgreement.data.moveIn).format(
                      i18n.t("Formats.dateFormat")
                    )
                  : "-"}
              </div>
            </div>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreement.Form.Fields.agreementExpiration",
                  "Vertrag bis"
                )}
              </div>
              <div className={`value`}>
                {props.rentalAgreement.data.agreementExpiration
                  ? moment(
                      props.rentalAgreement.data.agreementExpiration
                    ).format(i18n.t("Formats.dateFormat"))
                  : "-"}
              </div>
            </div>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t("cb:RentalAgreement.Form.Fields.moveOut", "Auszug")}
              </div>
              <div className={`value`}>
                {props.rentalAgreement.data.moveOut
                  ? moment(props.rentalAgreement.data.moveOut).format(
                      i18n.t("Formats.dateFormat")
                    )
                  : "-"}
              </div>
            </div>
          </div>

          <div className={`description-container`}>
            <div className={`label`}>
              {i18n.t("cb:RentalAgreement.Labels.note", "Notiz")}
            </div>
            <div className={`value`}>
              {props.rentalAgreement.data.note || "-"}
            </div>
          </div>

          <div className={`description-container margin-top-10`}>
            <div className={`label`}>
              {i18n.t(
                "cb:RentalAgreement.Labels.currentState",
                "Aktueller Status"
              )}
            </div>
            <div className={`description`}>
              <EZField
                value={props.rentalAgreement.data.currentState}
                url={`/api/asset/${AssetTypes.Rental.RentalAgreement}`}
                transformSubmitData={(value: string) => ({
                  _id: props.rentalAgreement._id,
                  data: {
                    currentState: value,
                  },
                })}
                onSubmitSuccess={(value) =>
                  CacheService.updateDataInCaches(value._id, value)
                }
              >
                {({ value, onChange, onBlur, loading, submitData }) => (
                  <EZTextfield
                    // label={}
                    placeholder="-"
                    textType={"default"}
                    className="title-input"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              </EZField>
            </div>

            {/* <div className={`value`}>
              {props.rentalAgreement.data.note ? (
                <WrappedText text={props.rentalAgreement.data.note} />
              ) : (
                "-"
              )}
            </div> */}
          </div>

          <div className={`tenant-data`}>
            <div className={`__h3`}>
              {i18n.t("cb:RentalAgreement.Labels.Tenant", "Mieter ")}
            </div>
            <AssetLoader
              assetType={AssetTypes.Contact}
              id={props.rentalAgreement.data.tenant}
              render={(tenant: Contact) => <TenantEntry tenant={tenant} />}
            />
          </div>

          <RentEntry
            kind={props.kind}
            hideActions={props.hideActions}
            rentalAgreement={props.rentalAgreement}
          />
          <DepositView rentalAgreement={props.rentalAgreement} />

          <RentalUnits rentalAgreement={props.rentalAgreement} />
        </div>
      </div>
    </>
  );
  return (
    <div className="cb-rental-agreement-details">
      {props.hideStackingPlan ? (
        comp
      ) : (
        <PersistentSplitPane
          identifier="cb-rental-agreement-details"
          split="horizontal"
          defaultSize={"50%"}
          maxSize={-200}
          minSize={150}
          primary="first"
          allowResize
          onSizeChange={() => {
            if (chartId.current) {
              DataBusDefaults.chartResized(chartId.current);
            }
          }}
        >
          {props.hideStackingPlan === true ? null : (
            <div className={`object-stacking-plan-wrapper`}>
              <ObjectStackingPlan
                onMounted={(ident, chart) => {
                  chartId.current = ident;
                }}
                objectId={props.rentalAgreement.data.objectId}
                markedUnits={props.rentalAgreement.data.rentalUnits}
                selectMode="single"
                onSelect={(selected: EnrichtedRentalUnit) => {
                  GlobalActions.openDetails(
                    selected.data.rentalStatus === "vacant"
                      ? AssetTypes.Rental.RentalUnit
                      : AssetTypes.Rental.RentalAgreement,
                    selected.data.rentalStatus === "vacant"
                      ? selected._id
                      : selected.data.agreement._id,
                    selected.data.type
                  );
                  //   DataBusDefaults.route({
                  //     route: `/rental-unit/${selected}`,
                  //     stayInApp: true,
                  //   });
                }}
              />
            </div>
          )}
          {comp}
        </PersistentSplitPane>
      )}
    </div>
  );
};

const RentalUnits = (props: { rentalAgreement: RentalAgreement }) => {
  return (
    <div className={`rental-units`}>
      <div className={`rental-unit-header`}>
        <div className={`__h3`}>
          {i18n.t("cb:RentalAgreement.Labels.Rentalunits", "Mieteinheiten")}
        </div>
      </div>
      <RentalUnitTableHeader />

      {props.rentalAgreement.data.rentalUnits.map((rentalUnit) => (
        <AssetLoader
          assetType={AssetTypes.Rental.RentalUnit}
          id={rentalUnit}
          render={(rentalUnit: RentalUnit) => (
            <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
          )}
        />
      ))}
    </div>
  );
};

const DepositView = (props: { rentalAgreement: RentalAgreement }) => {
  const depositPositions = []; //TODO portfolio restruct
  //  OrgaStruct.getObject(
  //   props.rentalAgreement.data.objectId
  // )?.objectConfig?.depositPositions;

  return (
    <div className={`deposit`}>
      <div className={`rent-header`}>
        <div className={`__h3`}>
          {i18n.t("cb:RentalAgreement.Labels.Deposit", "Kaution")}
        </div>
      </div>
      {(props.rentalAgreement.data.deposit || []).length === 0 && (
        <div className={`__empty`}>
          {i18n.t(
            "cb:RentalAgreement.Labels.NoDeposit",
            "Keine Kaution hinterlegt"
          )}
        </div>
      )}
      {(props.rentalAgreement.data.deposit || []).map((deposit) => (
        <div className={`deposit-entry`}>
          <div className={`entry-list`}>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreement.Labels.DepositValue",
                  "Kautionswert"
                )}
              </div>
              <div className={`value`}>
                {StringUtils.formatCurrency(deposit.depositValue, true)}
              </div>
            </div>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t("cb:RentalAgreement.Labels.DepositType", "Kautionsart")}
              </div>
              <div className={`value`}>
                {LanguageService.translateLabel(
                  depositPositions?.find(
                    (e) => e.identifier === deposit.identifier
                  )?.label
                ) || "-"}
              </div>
            </div>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreement.Labels.DepositStart",
                  "Kautionsbeginn"
                )}
              </div>
              <div className={`value`}>
                {StringUtils.formatDate(deposit.depositStart)}
              </div>
            </div>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreement.Labels.DepositPayedOn",
                  "Kautionseingang"
                )}
              </div>
              <div className={`value`}>
                {StringUtils.formatDate(deposit.depositPayedOn)}
              </div>
            </div>
            <div className={`entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreement.Labels.DepositPayedBacOn",
                  "Rückzahlung Kaution"
                )}
              </div>
              <div className={`value`}>
                {StringUtils.formatDate(deposit.depositPayedBackOn)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const RentEntry = (props: {
  rentalAgreement: RentalAgreement;
  hideActions?: boolean;
  kind: ObjectKind;
}) => {
  const plannedRent = useAggregationStatisticQuery(
    AssetTypes.Rental.RentalUnit,
    {
      type: "and",
      query: [
        {
          type: "op",
          name: "_id",
          op: "in",
          value: props.rentalAgreement.data.rentalUnits,
        },
      ],
    },
    [
      {
        name: "general",
        op: [
          {
            key: "rentNet",
            op: "sum",
            field: "data.rentNet",
          },
          {
            key: "rentGross",
            op: "sum",
            field: "data.rentGross",
          },
          {
            key: "operatingCostNet",
            op: "sum",
            field: "data.operatingCostNet",
          },
          {
            key: "operatingCostGross",
            op: "sum",
            field: "data.operatingCostGross",
          },
        ],
        query: {},
      },
    ]
  );
  return (
    <div className={`rent-data`}>
      <div className={`rent-header`}>
        <div className={`__h3`}>
          {i18n.t("cb:RentalAgreement.Labels.Rent", "Miete")}
        </div>
        {/* {PermissionService.hasBusinessUnitRole("cb_rentalEdit") &&
          props.hideActions !== true && ( */}
        <BFButton
          size="xs"
          noPadding
          appearance="link"
          onClick={() => {
            ModalManager.show({
              backdrop: "static",
              noPadding: true,
              size: "lg",
              content: (states, setStates, closeModal) => (
                <CBRentalAgreementRentChange
                  onClose={closeModal}
                  kind={props.kind}
                  rentalAgreement={props.rentalAgreement}
                />
              ),
            });
          }}
        >
          {i18n.t("Global.Buttons.Customize", "Anpassen")}
        </BFButton>
        {/* )} */}
      </div>
      <div className={`entry-list rent`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:RentalAgreement.Labels.rentNet", "Miete netto")}
          </div>
          <div className={`value`}>
            <span className={`should`}>
              {StringUtils.formatCurrency(
                props.rentalAgreement?.data?.rentNet,
                true
              )}
            </span>{" "}
            {plannedRent.data ? (
              <span className={`plan`}>
                ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                {StringUtils.formatCurrency(
                  plannedRent.data["general"]?.rentNet,
                  true
                )}
                )
              </span>
            ) : null}
          </div>
        </div>
        {props.rentalAgreement.data.taxable && (
          <div className={`entry`}>
            <div className={`label`}>
              {i18n.t("cb:RentalAgreement.Labels.rentGross", "Miete brutto")}
            </div>
            <div className={`value`}>
              <span className={`should`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.rentGross,
                  true
                )}
              </span>{" "}
              {plannedRent.data ? (
                <span className={`plan`}>
                  ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                  {StringUtils.formatCurrency(
                    plannedRent.data["general"]?.rentGross,
                    true
                  )}
                  )
                </span>
              ) : null}
            </div>
          </div>
        )}
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.operatingCostNet",
              "Nebenkosten netto"
            )}
          </div>
          <div className={`value`}>
            <span className={`should`}>
              {StringUtils.formatCurrency(
                props.rentalAgreement.data.operatingCostNet,
                true
              )}
            </span>{" "}
            {plannedRent.data ? (
              <span className={`plan`}>
                ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                {StringUtils.formatCurrency(
                  plannedRent.data["general"]?.operatingCostNet,
                  true
                )}
                )
              </span>
            ) : null}
          </div>
        </div>

        {props.rentalAgreement.data.taxable && (
          <div className={`entry`}>
            <div className={`label`}>
              {i18n.t(
                "cb:RentalAgreement.Labels.operatingCostGross",
                "Nebenkosten brutto"
              )}
            </div>
            <div className={`value`}>
              <span className={`should`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.operatingCostGross,
                  true
                )}
              </span>{" "}
              {plannedRent.data ? (
                <span className={`plan`}>
                  ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                  {StringUtils.formatCurrency(
                    plannedRent.data["general"]?.operatingCostGross,
                    true
                  )}
                  )
                </span>
              ) : null}
            </div>
          </div>
        )}

        {/* <div className={`entry`}>
            <div className={`label`}>
              {i18n.t("cb:RentalAgreement.Labels.deposit", "Kaution")}
            </div>
            <div className={`value`}>
              {StringUtils.formatCurrency(props.rentalAgreement.data.deposit, true)}
            </div>
          </div> */}
      </div>
    </div>
  );
};

const TenantEntry = (props: { tenant: Contact }) => {
  return (
    <div className={`tenant entry-list`}>
      <div className={`entry`}>
        <div className={`label`}>
          {i18n.t("cb:Tenant.Labels.Name", "Mieter")}
        </div>
        <div className={`value`}>
          <BFDetailsButton
            appearance="link"
            noPadding
            data={{
              assetType: AssetTypes.Contact,
              assetId: props.tenant._id,
              type: props.tenant.data.type,
              params: {
                contactType: "TENANT",
              },
            }}
          >
            {props.tenant.data.displayName}
          </BFDetailsButton>
        </div>
      </div>
    </div>
  );
};
