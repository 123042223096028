import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import { InfiniteTableSortOption } from "../../../../../../../../configurable/data/SortComponent/BaseTableSort";
import VirtualizedTable from "../../../../../../../../configurable/data/VirtualizedTable/VirtualizedTable";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import {
  useAggregationTableQuery,
  useAssetCache,
  useConstants,
} from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import { MatchQuery } from "../../../../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import { RentalAgreement } from "../../../../tenants/TenantsInterfaces";
import {
  RENTAL_AGREEMENT_FIELDS,
  RENTAL_AGREEMENT_STATIC_SELECTORS,
} from "../../../../tenants/components/rental-agreements/CBRentalAgreementsList";
import { CBPortfolioImmoConfig } from "../../../interfaces/CBPortfolioAsset";
import "./CBVacancySoonCard.scss";

const TABLE_IDENT = `cb-vacancy-soon-table`;

const sortOptions: InfiniteTableSortOption[] = [
  {
    label: () => i18n.t("cb:Vacancy.Sort.moveOutDesc"),
    icon: "arrange-list-descending",
    definition: {
      dir: "desc",
      key: "data.moveOut",
    },
  },
  {
    label: () => i18n.t("cb:Vacancy.Sort.moveOutAsc"),
    icon: "arrange-list-ascending",
    definition: {
      dir: "asc",
      key: "data.moveOut",
    },
  },

  {
    label: () => i18n.t("cb:Vacancy.Sort.rentDesc"),
    icon: "arrange-list-descending",
    definition: {
      dir: "desc",
      key: "data.rentGross",
    },
  },
  {
    label: () => i18n.t("cb:Vacancy.Sort.rentAsc"),
    icon: "arrange-list-ascending",
    definition: {
      dir: "asc",
      key: "data.rentGross",
    },
  },

  {
    label: () => i18n.t("cb:Vacancy.Sort.AreaDesc", "Fläche absteigend"),
    icon: "arrange-list-descending",
    definition: {
      dir: "desc",
      key: "nested.unitArea",
    },
  },
  {
    label: () => i18n.t("cb:Vacancy.Sort.AreaAsc", "Fläche aufsteigend"),
    icon: "arrange-list-ascending",
    definition: {
      dir: "asc",
      key: "nested.unitArea",
    },
  },
  {
    label: () =>
      i18n.t("cb:Vacancy.Sort.PQuantityAsc", "Stellplätze/Sonstige absteigend"),
    icon: "arrange-list-descending",
    definition: {
      dir: "desc",
      key: "nested.unitQuantity",
    },
  },
  {
    label: () =>
      i18n.t(
        "cb:Vacancy.Sort.PQuantityAsc",
        "Stellplätze/Sonstige aufsteigend"
      ),
    icon: "arrange-list-ascending",
    definition: {
      dir: "asc",
      key: "nested.unitQuantity",
    },
  },

  {
    label: () => i18n.t("cb:Vacancy.Sort.nameAsc"),
    icon: "arrange-list-descending",
    definition: {
      dir: "desc",
      key: "data.displayName",
    },
  },
];

const generateVacancySoonMatchQuery = (
  filterMonths: number,
  config: CBPortfolioImmoConfig,
  objectIds?: string[]
) => {
  return {
    type: "and",
    query: [
      { type: "op", name: "data.objectId", op: "in", value: objectIds || [] },
      {
        type: "op",
        name: "data.agreementExpiration",
        op: "gte",
        value: moment().startOf("day").toISOString(),
      },
      {
        type: "op",
        name: "data.agreementExpiration",
        op: "lte",
        value: moment().add(filterMonths, "month").endOf("day").toISOString(),
      },
    ],
  } as MatchQuery;
};

interface VacancySoonCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBVacancySoonCard = (props: VacancySoonCardProps) => {
  const [matchQuery, setMatchQuery] = useState<MatchQuery>();
  const aggregated = useAggregationTableQuery(
    TABLE_IDENT,
    RENTAL_AGREEMENT_STATIC_SELECTORS
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          aggregated.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  const constants = useConstants();
  const configCache = useAssetCache<CBPortfolioImmoConfig>("portfolio-config", {
    type: "op",
    name: "data.type",
    op: "in",
    value: constants.currency === "USD" ? ["immo-us"] : ["immo"],
  });
  const [filterMonths, setFilterMonths] = useState<number>(12);
  const loading = configCache.state === "loading";

  useEffect(() => {
    setMatchQuery(
      configCache.state === "cached"
        ? generateVacancySoonMatchQuery(
            filterMonths,
            configCache.data,
            props.objectIds
          )
        : undefined
    );
  }, [configCache.state, filterMonths]);

  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-vacancy-soon-card"
      title={i18n.t("cb:Vacancy.soonVacancy")}
      data={
        configCache.state === "cached"
          ? {
              query: matchQuery,
            }
          : undefined
      }
      loading={loading}
      // headRight={

      // }
      subHead={
        <div className="filters">
          <div className="groups">
            <BFButtonToggle
              buttons={[
                {
                  text: i18n.t("cb:Vacancy.6months", "6 Monate"), //"6 Monate",
                  value: 6,
                },
                {
                  text: i18n.t("cb:Vacancy.oneYear", "1 Jahr"), //"12 Monate",
                  value: 12,
                },
                {
                  text: i18n.t("cb:Vacancy.twoYears", "2 Jahre"), //"24 Monate",
                  value: 24,
                },
                {
                  text: i18n.t("cb:Vacancy.fiveYears", "5 Jahre"), //"24 Monate",
                  value: 60,
                },
              ]}
              value={filterMonths}
              onChange={(val: number) => setFilterMonths(val)}
            />
          </div>
        </div>
      }
      childOptions={{
        height: 400,
        render: (data) => {
          return (
            <div className="vacancy-soon-table">
              <div className="table-wrapper">
                <VirtualizedTable
                  dataUrl={`/api/asset/list/${AssetTypes.Rental.RentalAgreement}`}
                  asPost
                  identifier={TABLE_IDENT}
                  params={{ aggregated }}
                  additionalMatchQuery={data?.query}
                  initialVisibleSort={{
                    dir: "asc",
                    key: "data.agreementExpiration",
                  }}
                  expandKeys={["data.rentalUnit"]}
                  emptyText={i18n.t(
                    "cb:Vacancy.VacancySoonEmpty",
                    "Keine bevorstehende Leerstände"
                  )}
                  columns={_.merge({}, RENTAL_AGREEMENT_FIELDS(true), {
                    documentUnmetRequirements: null,
                    "data.displayName": {
                      render: (node: RentalAgreement, index, params) => (
                        <LinkCell
                          assetType={AssetTypes.Rental.RentalVacancy}
                          id={node._id}
                          text={renderCellValue(node?.data?.displayName, "-")}
                        />
                      ),
                    },
                  })}
                />
              </div>
            </div>
          );
        },
      }}
    />
  );
};
export default CBVacancySoonCard;
