import classNames from "classnames";
import React from "react";
import "./Page.scss";

interface PageProps {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  canOverflow?: boolean;
}
const Page = (props: PageProps) => {
  return (
    <div
      className={classNames(`standard-page`, props.className, {
        "no-padding": props.noPadding,
        "can-overflow": props.canOverflow,
      })}
    >
      {props.children}
    </div>
  );
};

export default Page;
