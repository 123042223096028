import classNames from "classnames";
import { useState } from "react";

import { CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER } from "@/apps/tatar/activityApp/specific/maintenance/technical-units/APTechnicalUnitsConst";
import APSupplyUnitCard from "@/apps/tatar/activityApp/specific/supplyContracts/supply-units/details/APSupplyUnitCard";
import SupplyUnitForm from "@/apps/tatar/activityApp/specific/supplyContracts/supply-units/details/APSupplyUnitForm";
import { PortfolioSupplyUnit } from "@/apps/tatar/cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import ModalManager from "@/components/ModalComponent/ModalManager";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "@/services/DataBusDefaults";
import MQ from "@/utils/MatchQueryUtils";
import { OAObject } from "../../../types/object.interface";
import "./ObjectSupplyUnits.scss";

interface CBPortfolioObjectSupplyUnitsProps {
  asset: OAObject;
}
const ObjectSupplyUnits = (props: CBPortfolioObjectSupplyUnitsProps) => {
  const [openDetails, setOpenDetails] = useState<string>(null);
  const onCreate = async () => {
    ModalManager.show({
      noPadding: true,
      content: (states, setStates, closeModal) => (
        <SupplyUnitForm
          onClose={closeModal}
          onSuccess={() => {
            DataBusDefaults.reload({
              identifiers: [CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER],
            });

            closeModal();
          }}
          forObjectId={props.asset._id}
        />
      ),
    });
  };
  return (
    <div className={classNames(`object-supply-units`)}>
      <div className={`action-row`}>
        <BFButton onClick={onCreate}>
          {i18n.t(
            "cb:Portfolio.Objekt.supplyUnit.create.createUnit",
            "Neue Einheit erstellen"
          )}
        </BFButton>
      </div>
      {/* <div className={`supply-unit-floor-plan`}>
        <FloorPlan
          features={[]}
          floorplanBackground={{
            type: "static-image",
            url: "test",
          }}
        />
      </div> */}
      <div className={`supply-unit-list`}>
        <ListComponent
          assetType={AssetTypes.Portfolio.SupplyUnit}
          identifier={CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER}
          render={(entry: PortfolioSupplyUnit) => (
            <APSupplyUnitCard asset={entry} key={entry._id} />
          )}
          reloadOnMount
          additionalMatchQuery={MQ.combine("and", [
            MQ.eq("data.objectId", props.asset?._id),
            MQ.in("data.status", ["active"]),
          ])}
        />
      </div>
    </div>
  );
};

export default ObjectSupplyUnits;
