import { Field } from "react-final-form";
import FormValidators from "../../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../../i18n";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "../../../../../../../../utils/Helpers";
interface CBRentalAgreementNoteFormProps {}
const CBRentalAgreementNoteForm = (props: CBRentalAgreementNoteFormProps) => {
  return (
    <Field
      name="note"
      validate={FormValidators.compose(FormValidators.max(400))}
      allowNull
      defaultValue={null}
    >
      {({ input, meta }) =>
        isDefined(input.value) ? (
          <div className={`__field __flex-1`}>
            <div className={`label-header`}>
              <div className={`label`}>
                {`${i18n.t("cb:RentalAgreement.Form.Fields.note", "Notiz")}`}
              </div>
              <BFButton
                noPadding
                onClick={() => input.onChange(null)}
                appearance="link"
                size="xs"
              >
                {i18n.t("Global.Buttons.remove")}
              </BFButton>
            </div>
            <BFInput
              type="textarea"
              validation={
                meta.error && meta.touched
                  ? {
                      level: "error",
                      message: meta.error,
                    }
                  : undefined
              }
              {...input}
            />
          </div>
        ) : null
      }
    </Field>
  );
};

export default CBRentalAgreementNoteForm;

export const CBRentalAgreementNoteButton = () => {
  return (
    <Field name="note" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              appearance="outline"
              size="xs"
              onClick={() => input.onChange("")}
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addNote",
                "Notiz hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};
