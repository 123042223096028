import { css } from "emotion";
import React, { CSSProperties, ReactNode, SyntheticEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import Tools from "../../../../utils/Tools";
import { AbstractComponent } from "../../../../utils/abstracts/AbstractComponent";
import { PlacementAll } from "../../common/Placements";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import BFBadge from "../Badge/BFBadge";
import BFUseTooltip from "../tooltip/BFUseTooltip";
import "./BFButton.scss";

export type BFButtonProps = {
  active?: boolean;
  appearance?:
    | "default"
    | "primary"
    | "link"
    | "jump"
    | "clear"
    | "outline"
    | "outline-white"
    | "clear-on-white"
    | "clear-highlight"
    | "transparent-dark"
    | "transparent-light"
    | "debug";
  block?: boolean;
  children: ReactNode;
  componentClass?: React.ElementType;
  disabled?: boolean;
  noPadding?: boolean;
  href?: string;
  loading?: boolean;
  size?: "lg" | "md" | "sm" | "xs";
  onClick?: (e: SyntheticEvent, params?: any) => void | Promise<any>;
  className?: string;
  type?: "button" | "submit" | "reset";
  circle?: boolean;
  textKey?: string;
  icon?: BfIconProps;
  iconPosition?: "left" | "right" | "top" | "bottom";
  tabIndex?: number;
  style?: CSSProperties;
  text?: string;
  badgeOnContent?: boolean;
  badge?: {
    content?: string;
    color?: "red" | "orange" | "yellow" | "green" | "cyan" | "blue" | "violet";
  };
  tooltip?: {
    tooltip: string;
    delay?: number; //	Delay Time
    delayHide?: number; //	Hidden delay Time
    delayShow?: number; //	Show Delay Time
    placement?: PlacementAll; // PlacementAll ('right')	Dispaly placement
    trigger?: "click" | "hover" | "focus" | "active"; //union: 'click', 'hover', 'focus', 'active' (['hover','focus'])
    container?: HTMLElement | (() => HTMLElement);
  };
  ignoreInternalLoading?: boolean;
} & WithTranslation;

type States = { internalLoading: boolean };

class BFButton extends AbstractComponent<BFButtonProps, States> {
  constructor(props: any) {
    super(props);
    this.state = { internalLoading: false };
  }

  getAppearance(): "default" | "primary" | "link" | "subtle" {
    if (
      this.props.appearance === "clear" ||
      this.props.appearance === "clear-on-white" ||
      this.props.appearance === "clear-highlight" ||
      this.props.appearance === "transparent-dark" ||
      this.props.appearance === "transparent-light" ||
      this.props.appearance === "debug"
    ) {
      return "subtle";
    }
    if (
      this.props.appearance === "outline" ||
      this.props.appearance === "outline-white"
    ) {
      return "subtle";
    }
    if (this.props.appearance === "jump") {
      return "link";
    }
    return this.props.appearance;
  }

  renderContent() {
    const { i18n, children, text, textKey, icon, iconPosition } = this.props;
    if (children) {
      return children;
    }

    let iconEl = null;
    if (icon) {
      iconEl = React.createElement(BfIcon, icon);
    }

    let content = null;
    if (textKey) {
      content = (
        <div
          className={`label ${
            iconEl ? (iconPosition ? "icon-" + iconPosition : "icon-left") : ""
          }`}
        >
          {iconEl ? iconEl : null}
          <div className="text-label">{i18n.t(textKey)}</div>
        </div>
      );
    } else if (text) {
      content = (
        <div
          className={`label ${
            iconEl ? (iconPosition ? "icon-" + iconPosition : "icon-left") : ""
          }`}
        >
          {iconEl ? iconEl : null}
          <div className="text-label">{text}</div>
        </div>
      );
    } else {
      content = iconEl;
    }

    if (this.props.badge && this.props.badgeOnContent) {
      return <BFBadge {...this.props.badge}>{content}</BFBadge>;
    } else {
      return content;
    }
  }

  render() {
    if (!this.props.badge || this.props.badgeOnContent) {
      return this.renderCheckTooltip();
    } else {
      return (
        <BFBadge {...this.props.badge}>{this.renderCheckTooltip()}</BFBadge>
      );
    }
  }
  renderCheckTooltip() {
    if (this.props.tooltip) {
      if (this.props.disabled) {
        return (
          <BFUseTooltip {...this.props.tooltip}>
            <div style={{ pointerEvents: "all", cursor: "not-allowed" }}>
              <div style={{ pointerEvents: "none" }}>{this.renderButton()}</div>
            </div>
          </BFUseTooltip>
        );
      } else {
        return (
          <BFUseTooltip {...this.props.tooltip}>
            {this.renderButton()}
          </BFUseTooltip>
        );
      }
    }
    return this.renderButton();
  }
  renderButton() {
    const {
      badge,
      stateSubscriptions,
      tReady,
      i18n,
      textKey,
      icon,
      iconPosition,
      style,
      tooltip,
      circle,
      loading,
      noPadding,
      ...buttonProperties
    } = this.props;
    const buttonProps = {
      ...buttonProperties,
      className: `bf-button ${circle ? "circle" : ""} ${
        this.props.className ? this.props.className : ""
      }  ${this.props.appearance} ${style ? css(style as any) : ""} ${
        noPadding ? "no-padding" : ""
      }`,
      appearance: this.getAppearance(),
      href: undefined,
    };
    (buttonProps as any).t = undefined;

    const onlyIcon =
      icon && !textKey && !this.props.text && !this.props.children;
    if (onlyIcon) {
      if (buttonProps.className) {
        buttonProps.className = buttonProps.className + " only-icon";
      } else {
        buttonProps.className = "only-icon";
      }
    }
    const href = this.props.href;

    const setLoading = loading || this.state.internalLoading;
    const clickHandler = (
      e: React.SyntheticEvent<Element, Event>,
      params?: any
    ) => {
      if (this.props.onClick && !this.state.internalLoading) {
        const onClickResult = this.props.onClick(e, params);
        if (Tools.isPromise(onClickResult)) {
          if (!this.props.ignoreInternalLoading) {
            this.setState({ internalLoading: true });
            (onClickResult as Promise<any>).finally(() => {
              this.setState({ internalLoading: false });
            });
          }
        }
      }
    };
    if (href) {
      const { className, type, ...restProps } = buttonProps;

      return (
        <Link
          draggable="false"
          to={href}
          style={{ width: "fit-content", textDecoration: "none" }}
          innerRef={(ref) => {
            if (ref) {
              ref.tabIndex = -1;
            }
          }}
          onClick={clickHandler}
        >
          <Button
            {...restProps}
            type={type || "button"}
            className={className}
            loading={setLoading}
            onClick={clickHandler}
          >
            {this.renderContent()}
          </Button>
        </Link>
      );
    } else {
      return (
        <Button {...buttonProps} loading={setLoading} onClick={clickHandler}>
          {this.renderContent()}
        </Button>
      );
    }
  }
}

export default withTranslation()(BFButton);
