import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { TableSort } from "../../../../model/common/CommonInterfaces";
import BfIcon from "../../icon/BfIcon";
import { ColumnConfigWithIdentifier } from "./BFVirtualizedTable";
import BFVirtualizedTableHeaderContextMenu from "./BFVirtualizedTableHeaderContextMenu";
import BFVirtualizedTableResizeHandler from "./BFVirtualizedTableResizeHandler";

interface BFVirtualizedTableHeaderProps {
  tableIdentifier: string;
  column: ColumnConfigWithIdentifier;
  index: number;
  sort?: TableSort;
  onSort?: (sort: TableSort) => void;
  params?: any;
  persistCustomizations?: boolean;
  ignoreResizable?: boolean;
  allDependingColumns: ColumnConfigWithIdentifier[];
  hiddenColumns: ColumnConfigWithIdentifier[];
}
const BFVirtualizedTableHeader = (props: BFVirtualizedTableHeaderProps) => {
  const sortDir =
    props.sort?.key === props.column.identifier ? props.sort?.dir : null;

  return (
    <div
      // onContextMenu={(ev) => {
      //   ev.preventDefault();
      // }}§
      key={props.column.identifier}
      className={classNames(
        "header-column",
        `alignment-${props.column.alignment || "left"}`,
        {
          sortable: props.column.identifier,
          sorted: sortDir,
        }
      )}
      style={{
        flex: !props.column.fixedWidth
          ? `${props.column.flexWidth || 1} 0 auto`
          : undefined,
        width: props.column.flexWidth || props.column.fixedWidth || 1,
        minWidth: props.column.flexWidth || props.column.fixedWidth || 1,
      }}
    >
      {/* <DebugDataComponent data={props.column} /> */}

      <button
        onClick={(ev) => {
          if (props.column.sortable) {
            props.onSort({
              key: props.column.identifier,
              dir: sortDir === "asc" ? "desc" : "asc",
            });
          }
        }}
        className="label"
      >
        {sortDir && (
          <div className={`sort-indicator`}>
            <BfIcon
              size="xs"
              data={sortDir === "asc" ? "arrow-down-1" : "arrow-up-1"}
              type="light"
            />
          </div>
        )}
        <span className={`label-span`}>
          {typeof props.column.label === "function"
            ? props.column.label(props.params)
            : props.column.label}
        </span>
      </button>
      {props.column.disableCustomize !== true && (
        <BFVirtualizedTableHeaderContextMenu
          hiddenColumns={props.hiddenColumns}
          column={props.column}
          tableIdentifier={props.tableIdentifier}
          persistCustomizations={props.persistCustomizations}
          allDependingColumns={props.allDependingColumns}
        />
      )}
      {props.column.resizable && props.ignoreResizable !== true && (
        <BFVirtualizedTableResizeHandler
          column={props.column}
          tableIdentifier={props.tableIdentifier}
          persistCustomizations={props.persistCustomizations}
        />
      )}
    </div>
  );
};

export default React.memo(BFVirtualizedTableHeader, (prev, next) => {
  // fix memo issue with functions - we should consider some general solution for this issue
  return _.isEqualWith(prev, next, (a, b) => {
    if (typeof a === "function" && typeof b === "function") {
      return true;
    }
    return undefined;
  });
});
