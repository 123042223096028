import {
  ObjectKind,
  RentalUnitType,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import { ColumnConfig } from "@/modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import InfiniteTableFilter, {
  FilterComponentInitialState,
} from "../../../../../../../configurable/data/FilterComponent/InfiniteTableFilter";
import TablePage from "../../../../../../../configurable/layouts/TablePageLayout/TablePage";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import {
  getDefaultAreaNumberFormat,
  getDefaultCurrencyNumberFormat,
} from "../../../../../../../modules/export/export.model";
import { setFlexCacheData } from "../../../../../../../redux/actions/application/application-actions";
import { selectFlexConfig } from "../../../../../../../redux/actions/application/application-selectors";
import {
  useAggregationTableQuery,
  useTypedSelector,
} from "../../../../../../../redux/hooks";
import { AggregationStatisticQuerySelector } from "../../../../../../../redux/model";
import DataBus from "../../../../../../../services/DataBus";
import LanguageService from "../../../../../../../services/LanguageService";
import PermissionService from "../../../../../../../services/PermissionService";
import { useRestrictionsMatchQuery } from "../../../../../../../services/RestrictionService";
import { DataBusSubKeys } from "../../../../../../../utils/Constants";
import { valueOrDefault } from "../../../../../../../utils/Helpers";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  CB_RENTAL_UNIT_LIST,
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import { RentalUnit } from "../../TenantsInterfaces";
import { CBRentalUnitFilterOptions } from "./CBRentalUnitFilterOptions";
import CBRentalUnitForm from "./CBRentalUnitForm";
import "./CBRentalUnitList.scss";

// import "./RentersView.scss";

const STATIC_SELECTORS: AggregationStatisticQuerySelector[] = [
  {
    name: "general",
    op: [
      { key: "count", op: "count" },
      {
        key: "value",
        op: "sum",
        field: "data.value",
      },
    ],
    query: {},
  },
  //   {
  //     name: "booked",
  //     op: [{ key: "count", op: "count" }],
  //     query: {
  //       matchQuery: {
  //         type: "op",
  //         op: "eq",
  //         name: "data.booked",
  //         value: true,
  //       },
  //     },
  //   },
  //   {
  //     name: "payed",
  //     op: [{ key: "count", op: "count" }],
  //     query: {
  //       matchQuery: {
  //         type: "op",
  //         op: "eq",
  //         name: "data.payed",
  //         value: true,
  //       },
  //     },
  //   },
  //   {
  //     name: "isChecked",
  //     op: [{ key: "count", op: "count" }],
  //     query: {
  //       matchQuery: {
  //         type: "op",
  //         op: "eq",
  //         name: "data.payment.isChecked",
  //         value: true,
  //       },
  //     },
  //   },
];

const filterData = () => {
  return [
    {
      label: i18n.t("cb:RentalUnit.FilterBy.all", "Alle Mieteinheiten"),
      value: "all",
      query: null,
    },
    {
      label: i18n.t(
        "cb:RentalUnit.FilterBy.active",
        "Aktuelle Mieteneinheiten"
      ),
      value: "active",
      query: MQ.and(
        MQ.lte("data.administratedFrom", moment().startOf("day")),
        MQ.or(
          MQ.gte("data.administratedTo", moment().startOf("day")),
          MQ.isNull("data.administratedTo")
        )
      ),
    },
    {
      label: i18n.t(
        "cb:RentalUnit.FilterBy.inactive",
        "Abgelaufene Mieteneinheiten"
      ),
      value: "inactive",
      query: MQ.and(MQ.lte("data.administratedTo", moment().endOf("day"))),
    },
    {
      label: i18n.t(
        "cb:RentalUnit.FilterBy.future",
        "Zukünftige Mieteneinheiten"
      ),
      value: "future",
      query: MQ.and(MQ.gt("data.administratedFrom", moment().startOf("day"))),
    },
  ];
};

const FlexKey = "cb_rentalUnitList";
interface RentersViewProps {
  kind: ObjectKind;
}
const CBRentalUnitList = (props: RentersViewProps) => {
  const dispatch = useDispatch();
  const restrictions = useRestrictionsMatchQuery();
  const [initialState, setInitialState] =
    useState<FilterComponentInitialState>(undefined);
  const aggregated = useAggregationTableQuery(
    CB_RENTAL_UNIT_LIST,
    STATIC_SELECTORS
  );

  const { filterValue } = useTypedSelector((state) =>
    selectFlexConfig(state, FlexKey)
  ) || { filterValue: "active" };

  const filterOptions = filterData();
  const additionalMatchQuery = filterOptions.find(
    (e) => e.value === filterValue
  )?.query;

  return (
    <TablePage
      layout={{
        className: "cb-rental-unit-list",
        cardHeader: (
          <div className={`units-header`}>
            <div className={`margin-bottom-10 __h1 `}>
              {i18n.t("cb:RentalUnit.title", "Mieteinheiten")}
            </div>
            <div>
              <BFSelect
                onChange={(value) => {
                  dispatch(setFlexCacheData(FlexKey, "filterValue", value));
                }}
                cleanable={false}
                value={filterValue}
                data={filterOptions.map((e) => ({
                  label: e.label,
                  value: e.value,
                }))}
              />
            </div>
          </div>
        ),
        pageHeader: (
          <>
            <InfiniteTableFilter
              initialState={initialState}
              identifier={CB_RENTAL_UNIT_LIST}
              filterOptions={CBRentalUnitFilterOptions(
                props.kind._id,
                PermissionService.hasBusinessUnitRole("cb_rentalView")
              )}
            />
            {PermissionService.hasObjectKindPermission(
              props.kind,
              "rental.units.createEdit"
            ) && (
              <BFButton
                onClick={() => {
                  ModalManager.show({
                    backdrop: "static",
                    size: "lg",
                    noPadding: true,
                    content: (state, setState, onClose) => (
                      <CBRentalUnitForm onClose={onClose} kind={props.kind} />
                    ),
                  });
                }}
              >
                {i18n.t("cb:RentalUnit.add", "Mieteinheit hinzufügen")}
              </BFButton>
            )}
          </>
        ),
      }}
      table={{
        onRowDoubleClick: (asset: RentalUnit, index) => {
          DataBus.emit(DataBusSubKeys.ROUTE, {
            append: true,
            route: `${asset._id}`,
          });
        },
        additionalMatchQuery: MQ.and(restrictions, additionalMatchQuery),
        identifier: CB_RENTAL_UNIT_LIST,
        selection: "single",
        dataUrl: `/api/asset/list/${AssetTypes.Rental.RentalUnit}`,
        hover: true,
        asPost: true,
        onRowClick: (node: RentalUnit) => {},
        params: {
          aggregated,
        },
        columns: {
          "data.type": {
            label: i18n.t("Base.Unit"),
            sortable: true,
            flexWidth: 120,
            resizableOptions: {
              min: 40,
              max: 200,
            },
            resizable: true,
            render: (node: RentalUnit, index, params) =>
              renderCellValue(
                node?.data?.type,
                "-",
                (value: string) => UnitStruct.getUnit(value)?.data.label
              ),
            renderFooter: (params) =>
              renderCellValue(
                `${i18n.t("cb:RentalUnit.count", "Anz.")}: ${
                  params?.aggregated?.data?.["general"]?.count || "-"
                }`
              ),
          },
          "data.entity": {
            label: i18n.t("Base.Entity"),
            // sortKey: "",
            flexWidth: 120,
            sortable: true,
            resizable: true,
            render: (node: RentalUnit, index, params) =>
              renderCellValue(
                node?.data?.entity,
                "-",
                (value: string) => OrgaStruct.getEntity(value)?.displayName
              ),
          },
          "data.objectId": {
            label: i18n.t("Base.Object"),
            flexWidth: 200,
            resizable: true,
            sortable: true,
            render: (node: RentalUnit, index, params) => (
              <LinkCell
                assetType={AssetTypes.Portfolio.Object}
                id={node?.data.objectId}
                text={`${OrgaStruct.getObject(node?.data.objectId)?.id} - ${
                  OrgaStruct.getObject(node?.data.objectId)?.displayName
                }`}
                type={node?.data.type}
              />
            ),
          },
          ...RENTAL_UNIT_FIELDS(),
          /**
           * area
           * from
           * to
           *
           *
           *
           */
        },
      }}
    />
  );
};

export default CBRentalUnitList;

export const RENTAL_UNIT_FIELDS = () =>
  ({
    "data.id": {
      label: i18n.t("cb:RentalUnit.id", "ID"),
      // sortKey: "",
      flexWidth: 90,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) => (
        <>
          <DebugDataComponent data={node} />
          {renderCellValue(node?.data?.id, "-")}
        </>
      ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.id", "ID"),
        type: "string",
        selector: (node: RentalUnit) => valueOrDefault(node?.data?.id, "-"),
      },
    },
    "data.displayName": {
      label: i18n.t("cb:RentalUnit.displayName", "Name"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.displayName, "-"),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.displayName", "Name"),
        type: "string",
        selector: (node: RentalUnit) =>
          valueOrDefault(node?.data?.displayName, "-"),
      },
    },
    "data.rentalStatus": {
      label: i18n.t("cb:RentalUnit.rentalStatus", "Status"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) => {
        const status = getConfigRentalStatus(node.data.rentalStatus);
        if (status) {
          return (
            <div style={{ maxWidth: "100%", overflow: "hidden" }}>
              <BFStatus size="sm" color={status.color} label={status.label} />
            </div>
          );
        }
        return null;
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentalStatus", "Status"),
        type: "string",
        selector: (node: RentalUnit) =>
          valueOrDefault(
            getConfigRentalStatus(node.data?.rentalStatus)?.label,
            "-"
          ),
      },
    },
    "data.unitTypeGroup": {
      label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
      // sortKey: "",
      flexWidth: 100,
      sortable: false,
      resizable: true,
      render: (node: RentalUnit, index, params) => {
        const unit = ObjectKindStruct.getUnitTypeBy(node.data.unitType);
        if (!unit) return null;

        const status = getConfigRentalUnitTypeGroup(unit.group);
        if (status) {
          return (
            <div style={{ maxWidth: "100%", overflow: "hidden" }}>
              <BFStatus size="sm" color={status.color} label={status.label} />
            </div>
          );
        }
        return null;
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
        type: "string",
        selector: (node: RentalUnit) => {
          const unit = ObjectKindStruct.getUnitTypeBy(node.data.unitType);
          if (!unit) return null;

          const status = getConfigRentalUnitTypeGroup(unit.group);
          if (status) {
            return status.label;
          }
          return null;
        },
      },
    },
    "data.unitType": {
      label: i18n.t("cb:RentalUnit.unitType", "Art"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(
          ObjectKindStruct.getUnitTypeBy(node.data.unitType),
          "-",
          (value: RentalUnitType) =>
            LanguageService.translateLabel(value.displayName)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.unitType", "Art"),
        type: "string",
        selector: (node: RentalUnit) => {
          const unit = ObjectKindStruct.getUnitTypeBy(node.data.unitType);
          if (!unit) return null;

          return LanguageService.translateLabel(unit.displayName);
        },
      },
    },
    "data.building": {
      label: i18n.t("cb:RentalUnit.building", "Gebäude"),
      // sortKey: "",
      flexWidth: 80,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.building, "-"),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.building", "Gebäude"),
        type: "string",
        selector: (node: RentalUnit) => {
          return node?.data.building;
        },
      },
    },
    "data.floor": {
      label: i18n.t("cb:RentalUnit.floor", "Etage"),
      // sortKey: "",
      flexWidth: 80,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.floor, "-"),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.floor", "Etage"),
        type: "number",
        selector: (node: RentalUnit) => {
          return node?.data.floor;
        },
      },
    },
    "data.area": {
      label: i18n.t("cb:RentalUnit.area", "Fläche"),
      // sortKey: "",
      flexWidth: 90,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.area, "-", (value: number) => {
          return `${StringUtils.formatNumber(
            value
          )} ${StringUtils.getAreaUnit()}`;
        }),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.area", "Fläche"),
        type: "number",
        style: {
          numFmt: getDefaultAreaNumberFormat(),
        },
        selector: (node: RentalUnit) => {
          return node?.data.area;
        },
      },
    },
    "data.quantity": {
      label: i18n.t("cb:RentalUnit.quantityOther", "Stellplätze/Sonstiges"),
      // sortKey: "",
      flexWidth: 80,
      sortable: true,
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.quantity, "-", (value: number) => {
          return value;
        }),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.quantityOther", "Stellplätze/Sonstiges"),
        type: "number",
        selector: (node: RentalUnit) => {
          return node?.data.quantity;
        },
      },
    },
    "data.rentNet": {
      label: i18n.t("cb:RentalUnit.rentNet", "Planmiete netto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.rentNet, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentNet", "Planmiete netto"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalUnit) => {
          return node?.data.rentNet;
        },
      },
    },
    "data.rentGross": {
      label: i18n.t("cb:RentalUnit.rentGross", "Planmiete brutto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      alignment: "right",
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.rentGross, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.rentGross", "Planmiete brutto"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalUnit) => {
          return node?.data.rentGross;
        },
      },
    },
    "data.operatingCostGross": {
      label: i18n.t(
        "cb:RentalUnit.operatingCostGross",
        "Plan-Nebenkosten brutto"
      ),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      alignment: "right",
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.operatingCostGross, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t(
          "cb:RentalUnit.operatingCostGross",
          "Plan-Nebenkosten brutto"
        ),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalUnit) => {
          return node?.data.operatingCostGross;
        },
      },
    },
    "data.operatingCostNet": {
      label: i18n.t("cb:RentalUnit.operatingCostNet", "Plan-Nebenkosten netto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      alignment: "right",
      resizable: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.operatingCostNet, "-", (value: number) =>
          StringUtils.formatCurrency(value)
        ),
      export: {
        width: 20,
        label: i18n.t(
          "cb:RentalUnit.operatingCostNet",
          "Plan-Nebenkosten netto"
        ),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalUnit) => {
          return node?.data.operatingCostNet;
        },
      },
    },
    "data.administratedFrom": {
      label: i18n.t("cb:RentalUnit.administratedFrom", "Verwaltet von"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      hidden: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.administratedFrom, "-", (value: Date) =>
          moment(value).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.administratedFrom", "Verwaltet von"),
        type: "date",
        selector: (node: RentalUnit) => {
          return node?.data.administratedFrom
            ? new Date(node?.data.administratedFrom)
            : null;
        },
      },
    },
    "data.administratedTo": {
      label: i18n.t("cb:RentalUnit.administratedTo", "Verwaltet bis"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      hidden: true,
      render: (node: RentalUnit, index, params) =>
        renderCellValue(node?.data?.administratedTo, "-", (value: Date) =>
          moment(value).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.administratedTo", "Verwaltet bis"),
        type: "date",
        selector: (node: RentalUnit) => {
          return node?.data.administratedTo
            ? new Date(node?.data.administratedTo)
            : null;
        },
      },
    },
  } as { [key: string]: ColumnConfig });
