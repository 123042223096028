import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "rsuite";
import i18n from "../../i18n";
import BaseAsset from "../../model/general-assets/BaseAsset";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { openOrDownloadDocument } from "../../redux/actions/ui-config/ui-config-actions";
import CDNService, { CDNBucketObject } from "../../services/CDNService";
import FileUtils from "../../utils/FileUtils";
import CDNLink from "../CDNLink/CDNLink";
import PDFPreview from "../PDFViewer/PDFPreview";
import "./FilePreviewComponent.scss";

type ByFile = {
  file: File;
  type: "file";
};
type ByCDN = {
  assetType: string;
  assetField: string;
  hasFolderPermissions: boolean;
  asset: BaseAsset;
  cdnID: string;
  type: "cdn";

  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
};
type ByCDNBucket = {
  type: "cdnBucket";
  bucket: CDNBucketObject;
  cdnID: string;
  assetId: string;
  assetType: string;
  assetField: string;
  hasFolderPermissions: boolean;
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
};
type FilePreviewComponentProps = (ByFile | ByCDN | ByCDNBucket) & {
  width: number;
  height: number;
  onClick?: () => void;
};
const FilePreviewComponent = (props: FilePreviewComponentProps) => {
  const mimetype =
    props.type === "file"
      ? props.file.type
      : props.type === "cdn"
      ? props.asset?.cdn.find((e) => e._id === props.cdnID)?.content_type
      : props.bucket.content_type;
  const [objUrl] = useState(
    mimetype.match(/application\/pdf/) && props.type === "file"
      ? URL.createObjectURL(props.file)
      : null
  );
  const dispatch = useDispatch();

  const openDocument = async () => {
    if (props.onClick) {
      props.onClick();
      return;
    }
    if (props.type === "file") {
      const url = URL.createObjectURL(props.file);
      dispatch(
        openOrDownloadDocument(url, FileUtils.mimeToExt(props.file.type))
      );
      //    dispatch(openOrDownloadDocument())
    } else if (props.type === "cdn") {
      const cdnItem = props.asset?.cdn.find((e) => e._id === props.cdnID);
      const url = await CDNService.fetchCDNLink({
        assetType: props.assetType,
        assetId: props.asset?._id,
        assetField: props.assetField,
        cdnId: props.cdnID,
        hasFolderReadPermissions: true,
        fileKey: cdnItem?.key,
      });
      dispatch(
        openOrDownloadDocument(url, FileUtils.mimeToExt(cdnItem.content_type))
      );
    } else {
      const url = await CDNService.fetchCDNLink({
        assetType: props.assetType,
        assetId: props.assetId,
        assetField: props.assetField,
        cdnId: props.cdnID,
        hasFolderReadPermissions: true,
        fileKey: props.bucket.key,
      });
    }
  };
  return (
    <div
      className={classNames(`file-preview-component`)}
      style={{ width: props.width, height: props.height }}
    >
      {mimetype.match(/image.*/) ? (
        props.type === "file" ? (
          <ImageOfFile file={props.file} onClick={openDocument} />
        ) : props.type === "cdn" ? (
          <ImageOfCDN
            onClick={openDocument}
            asset={props.asset}
            assetField={props.assetField}
            cdnID={props.cdnID}
            assetType={props.assetType}
            width={props.width}
            height={props.height}
            dimension={props.dimension}
            hasFolderPermissions={props.hasFolderPermissions}
          />
        ) : (
          <ImageOfCDNBucket
            assetId={props.assetId}
            onClick={openDocument}
            cdnBucket={props.bucket}
            assetField={props.assetField}
            cdnID={props.cdnID}
            assetType={props.assetType}
            width={props.width}
            height={props.height}
            dimension={props.dimension}
            hasFolderPermissions={props.hasFolderPermissions}
          />
        )
      ) : mimetype.match(/application\/pdf/) && props.type === "file" ? (
        <PDFPreview url={objUrl} width={props.width} height={props.height} />
      ) : mimetype.match(/application\/pdf/) && props.type === "cdn" ? (
        <CDNLink
          assetId={props.asset._id}
          assetField={props.assetField}
          cdnId={props.cdnID}
          assetType={props.assetType}
          hasFolderReadPermissions={props.hasFolderPermissions}
          fileKey={props.asset?.cdn?.find((e) => e._id === props.cdnID)?.key}
          filename={
            props.asset?.cdn?.find((e) => e._id === props.cdnID)?.filename
          }
          render={(url) => (
            <PDFPreview url={url} width={props.width} height={props.height} />
          )}
        />
      ) : mimetype.match(/application\/pdf/) && props.type === "cdnBucket" ? (
        <CDNLink
          assetId={props.assetId}
          assetField={props.assetField}
          cdnId={props.cdnID}
          assetType={props.assetType}
          hasFolderReadPermissions={props.hasFolderPermissions}
          fileKey={props.bucket.key}
          filename={props.bucket.filename}
          render={(url) => (
            <PDFPreview url={url} width={props.width} height={props.height} />
          )}
        />
      ) : (
        <BFButton
          className={`pdf-button`}
          appearance="link"
          onClick={openDocument}
        >
          <div className={`icon`}>
            <BfIcon type="light" data={iconOf(mimetype)} />
          </div>
          <div className={`label`}>
            {i18n.t("FilePreview.show", "Anzeigen")}
          </div>
        </BFButton>
      )}
    </div>
  );
};

export default FilePreviewComponent;

const ImageOfFile = (props: { file: File; onClick: () => void }) => {
  const [data, setData] = useState<string>();
  const [err, setErr] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    FileUtils.toBase64(props.file)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setErr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  if (loading) return <Loader size="xs" />;
  if (err)
    return <BfIcon type="light" data="alert-triangle" size="xs" color="red" />;

  return (
    <div
      className={`image`}
      style={{ backgroundImage: `url(${data})` }}
      onClick={props.onClick}
    ></div>
  );
};

const calcDimension = (
  width: number,
  height: number,
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs"
) => {
  if (dimension) {
    return dimension;
  }
  if (Math.max(width, height) <= 100) {
    return "thumb";
  }
  if (Math.max(width, height) <= 300) {
    return "sm";
  }
  if (Math.max(width, height) <= 700) {
    return "md";
  }
  if (Math.max(width, height) <= 1000) {
    return "lg";
  }
  if (Math.max(width, height) <= 1300) {
    return "sl";
  }
  return "xs";
};
const ImageOfCDN = (props: {
  assetType: string;
  assetField: string;
  hasFolderPermissions: boolean;
  asset: BaseAsset;
  cdnID: string;
  width: number;
  height: number;
  onClick: () => void;
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
}) => {
  const [data, setData] = useState<string>();
  const [err, setErr] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    CDNService.fetchCDNLink({
      assetType: props.assetType,
      assetId: props.asset?._id,
      assetField: props.assetField,
      cdnId: props.cdnID,
      fileKey: props.asset?.cdn.find((e) => e._id === props.cdnID)?.key,
      hasFolderReadPermissions: props.hasFolderPermissions,
    })
      .then((url) => {
        setData(url);
      })
      .catch((err) => {
        setErr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  if (loading) return <Loader size="xs" />;
  if (err)
    return <BfIcon type="light" data="alert-triangle" size="xs" color="red" />;

  return (
    <div
      className={`image`}
      style={{
        backgroundImage: `url(${data}&d=${calcDimension(
          props.width,
          props.height,
          props.dimension
        )})`,
      }}
      onClick={props.onClick}
    ></div>
  );
};

const ImageOfCDNBucket = (props: {
  assetType: string;
  assetField: string;
  assetId: string;
  hasFolderPermissions: boolean;
  cdnID: string;
  cdnBucket: CDNBucketObject;
  width: number;
  height: number;
  onClick: () => void;
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
}) => {
  const [data, setData] = useState<string>();
  const [err, setErr] = useState<string>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    CDNService.fetchCDNLink({
      assetType: props.assetType,
      assetId: props.assetId,
      assetField: props.assetField,
      cdnId: props.cdnID,
      fileKey: props.cdnBucket.key,
      hasFolderReadPermissions: props.hasFolderPermissions,
    })
      .then((url) => {
        setData(url);
      })
      .catch((err) => {
        setErr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  if (loading) return <Loader size="xs" />;
  if (err)
    return <BfIcon type="light" data="alert-triangle" size="xs" color="red" />;

  return (
    <div
      className={`image`}
      style={{
        backgroundImage: `url(${data}&d=${calcDimension(
          props.width,
          props.height,
          props.dimension
        )})`,
      }}
      onClick={props.onClick}
    ></div>
  );
};

const iconOf = (mimetype: string) => {
  switch (mimetype) {
    case "application/pdf":
      return "office-file-pdf-1";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      return "office-file-doc-1";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
      return "xlsx-office-file";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.ms-powerpoint":
      return "office-file-ppt-1";
    default:
      return "paragraph-image-right";
  }
};
