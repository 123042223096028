import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { PDFHighlight } from "../../../../../components/PDFViewer/PDFViewer";
import i18n from "../../../../../i18n";
import { Placement } from "../../../../../modules/abstract-ui/common/Placements";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import { ExtractionAsset } from "../../RAInterfaces";
import "./ExtractField.scss";

type ExtractFieldProps = {
  invoice: ExtractionAsset;
  hideActionButton?: boolean;
  field: string | string[];
  onHighlight: (highlight?: PDFHighlight | PDFHighlight[]) => void;
  checkExtractValue?: (
    value: any,
    extractValue: string | { [key: string]: string }
  ) => boolean;
  placement?: Placement;
  formatValue?: (value: string | { [key: string]: string }) => string;
  onExtractValueChange?: (
    value: string | { [key: string]: string },
    oldValue: any
  ) => any;
  disableExtraction?:
    | boolean
    | ((extractValue: string | { [key: string]: string }) => boolean);
};
export const ExtractField: <
  FieldValue = any,
  RP extends FieldRenderProps<FieldValue, T> = FieldRenderProps<
    FieldValue,
    HTMLElement
  >,
  T extends HTMLElement = HTMLElement
>(
  props: FieldProps<FieldValue, RP, T> & ExtractFieldProps
) => React.ReactElement = (props) => {
  const extractFields = props.invoice.extract?.filter((e) =>
    Array.isArray(props.field)
      ? props.field.includes(e.identifier)
      : e.identifier === props.field
  );

  const extractFieldsValue =
    extractFields?.length > 0
      ? Array.isArray(props.field)
        ? Object.fromEntries(extractFields?.map((e) => [e.identifier, e.value]))
        : extractFields?.[0].value
      : null;
  // when no extraction is available, just render default field without adaptions
  if (
    extractFields?.length === 0 ||
    (props.disableExtraction !== undefined
      ? typeof props.disableExtraction === "function"
        ? props.disableExtraction(extractFieldsValue)
        : props.disableExtraction
      : false)
  ) {
    return <Field {...props}>{props.children}</Field>;
  }

  return (
    <Field {...props}>
      {(fieldProps) => {
        const fieldPropsToUse = { ...fieldProps };

        const matchingExtractValue = props.checkExtractValue
          ? props.checkExtractValue(
              fieldPropsToUse.input.value,
              extractFieldsValue
            )
          : fieldPropsToUse.input.value === extractFields?.[0].value;

        const oldOnFocus = fieldPropsToUse.input.onFocus;
        fieldPropsToUse.input.onFocus = (ev) => {
          oldOnFocus?.(ev);
          if (matchingExtractValue) {
            props.onHighlight(
              extractFields
                ?.filter((e) => e.box)
                .map((extractField) => ({
                  identifier: extractField.identifier,
                  value: extractField.value,
                  stroke: "#000",
                  top: extractField.box.y0,
                  left: extractField.box.x0,
                  width: extractField.box.x1 - extractField.box.x0,
                  height: extractField.box.y1 - extractField.box.y0,
                  page: extractField.box.pageIndex + 1,
                  padding: 10,
                  scrollIntoView: true,
                }))
            );
          }
        };
        const oldOnBlur = fieldPropsToUse.input.onBlur;
        fieldPropsToUse.input.onBlur = (ev) => {
          oldOnBlur?.(ev);
          props.onHighlight(null);
        };
        return (
          <BFOverlay
            className="extract-field-overlay-container"
            placement={props.placement || "topStart"}
            open={matchingExtractValue ? false : undefined}
            enterable
            trigger="focus"
            speaker={
              <div>
                <div
                  className={`extract-field-overlay`}
                  onMouseEnter={() => {
                    props.onHighlight(
                      extractFields
                        ?.filter((e) => e.box)
                        .map((extractField) => ({
                          identifier: extractField.identifier,
                          value: extractField.value,
                          stroke: "#000",
                          top: extractField.box.y0,
                          left: extractField.box.x0,
                          width: extractField.box.x1 - extractField.box.x0,
                          height: extractField.box.y1 - extractField.box.y0,
                          page: extractField.box.pageIndex + 1,
                          padding: 10,
                          scrollIntoView: true,
                        }))
                    );
                  }}
                  onMouseLeave={() => {
                    props.onHighlight(null);
                  }}
                >
                  <div className={`title`}>
                    {i18n.t("ExtractField.suggestion", "Vorschlag")}
                  </div>
                  <div className={`value`}>
                    {props.formatValue
                      ? props.formatValue(extractFieldsValue)
                      : extractFieldsValue.toString()}
                  </div>
                  {!props.hideActionButton && (
                    <div className={`action`}>
                      <BFButton
                        appearance="link"
                        noPadding
                        size="xxs"
                        onClick={() => {
                          const value = props.onExtractValueChange
                            ? props.onExtractValueChange(
                                extractFieldsValue,
                                fieldProps.input.value
                              )
                            : extractFieldsValue;

                          fieldPropsToUse.input.onChange(value);
                        }}
                      >
                        {i18n.t("ExtractField.use", "Anwenden")}
                      </BFButton>
                    </div>
                  )}
                </div>
              </div>
            }
          >
            <div>
              {typeof props.children === "function"
                ? props.children(fieldPropsToUse)
                : props.children}
            </div>
          </BFOverlay>
        );
      }}
    </Field>
  );
};
