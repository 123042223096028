import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import classNames from "classnames";
import { useState } from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import StructLoader from "../../../../../components/StructLoader/StructLoader";
import ListComponent from "../../../../../configurable/components/ListComponent/ListComponent";
import InfiniteTableFilter from "../../../../../configurable/data/FilterComponent/InfiniteTableFilter";
import i18n from "../../../../../i18n";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import {
  MailIncomingComment,
  MailOutgoingComment,
} from "../../../../../modules/comments-module/Comments";
import CommentEntry from "../../../../../modules/comments-module/components/CommentEntry";
import PermissionService from "../../../../../services/PermissionService";
import { useRestrictionsMatchQuery } from "../../../../../services/RestrictionService";
import MQ from "../../../../../utils/MatchQueryUtils";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import { AP_ASSIGN_COMMENT_TO_ACTIVITY } from "../../ActivityUtils";
import APDashboardActivityEntry from "../dashboard/components/APDashboardActivityEntry";
import { APListFilterOptions } from "../list/APListFilterOptions";
import "./APAssignMessageToActivity.scss";
interface APAssignMessageToActivityProps {
  mail: MailIncomingComment | MailOutgoingComment;
  onClose: () => void;
  onAssign: (data: APActivity) => Promise<void>;
  title?: string;
  assignActionLabel?: string;
  description?: string;
}
const APAssignMessageToActivity = (props: APAssignMessageToActivityProps) => {
  const constants = useActivityConstants();
  const [selectedActivity, setSelectedActivity] = useState<string | null>(null);
  const restrictionMatchQuery = useRestrictionsMatchQuery();

  const title = props.title
    ? props.title
    : i18n.t(
        "apTemplate:Activity.AssignMessageToActivity.Title",
        "Aktivität zuordnen",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      );

  const description = props.description
    ? props.description
    : i18n.t(
        "apTemplate:Activity.AssignMessageToActivity.Description",
        "Bitte wählen Sie die Aktivität aus, zu der die E-Mail zugeordnet werden soll.",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      );

  const assignmentActionLabel = props.assignActionLabel
    ? props.assignActionLabel
    : i18n.t(
        "apTemplate:Activity.AssignMessageToActivity.Assign",
        "E-Mail zu diesem Schaden zuordnen",
        {
          ns: [constants?.assetType, "apTemplate"],
        }
      );

  const activityQuery = props.mail.data?.linkedAsset?.id
    ? MQ.ne("_id", props.mail.data?.linkedAsset?.id)
    : undefined;

  const typeOptions = UnitStruct.getUnitSelectOptions(
    PermissionService.hasBusinessUnitRole(
      constants?.permissionPrefix + "listActivities"
    ) || []
  );

  const additionalMatchQuery = MQ.combine("and", [
    MQ.in(
      "data.type",
      typeOptions.map((o) => o.value)
    ),
    restrictionMatchQuery,
    activityQuery,
  ]);

  return (
    <StructLoader
      unitTypes={constants.businessUnits}
      structTypes={[constants.serviceUrl as any, "unit", "orga"]}
      render={([structToUse]) => (
        <div className={classNames(`ap-assign-message-to-activity`)}>
          <div className={`title __h1`}>{title}</div>
          <div className={`description`}>{description}</div>
          <div className="ap-assignment-content">
            <div className="activity-container">
              <div className={`filter-container`}>
                <InfiniteTableFilter
                  identifier={AP_ASSIGN_COMMENT_TO_ACTIVITY}
                  filterOptions={APListFilterOptions(
                    PermissionService.hasBusinessUnitRole(
                      `${constants?.permissionPrefix}createActivities`
                    ),
                    constants,
                    structToUse
                  )}
                />
              </div>
              <div className={`list-container`}>
                <ListComponent
                  identifier={AP_ASSIGN_COMMENT_TO_ACTIVITY}
                  params={{ selectedActivity }}
                  asPost
                  dataUrl={`/api/asset/list/${constants?.assetType}`}
                  additionalMatchQuery={additionalMatchQuery}
                  render={(node: APActivity, index, params) => {
                    return (
                      <div className={`activity-entry`}>
                        <BFButton
                          className={`activity-entry-button`}
                          onClick={() => {
                            setSelectedActivity(node._id);
                          }}
                        >
                          <div className={`button-content`}>
                            <APDashboardActivityEntry activity={node} />
                          </div>
                        </BFButton>
                        <Collapse in={selectedActivity === node._id}>
                          <div>
                            <div className={`assign-action-row`}>
                              <BFButton
                                appearance="link"
                                onClick={async () => {
                                  await props.onAssign(node);
                                }}
                              >
                                {assignmentActionLabel}
                              </BFButton>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="mail-container">
              <CommentEntry comment={props.mail} allowFullscreen={false} />
            </div>
          </div>
          <div className={`actions`}>
            <BFButton appearance="outline" onClick={props.onClose}>
              {i18n.t("Global.Buttons.cancel")}
            </BFButton>
          </div>
        </div>
      )}
    />
  );
};

export default APAssignMessageToActivity;
