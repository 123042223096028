import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { Field } from "react-final-form";
import FormFieldValues from "../../../../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../i18n";
import BFDate from "../../../../../../../modules/abstract-ui/forms/date/BFDate";
import BFInput from "../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import PermissionService from "../../../../../../../services/PermissionService";
import NumberUtils from "../../../../../../../utils/ NumberUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import { useActivityConstants } from "../../../../../activityApp/ActivityHooks";
import CBRentalService from "../../CBRentalService";
import {
  CB_RENTAL_UNIT_LIST,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import { RentalUnit } from "../../TenantsInterfaces";
import "./CBRentalUnitForm.scss";

interface CBRentalUnitFormProps {
  rentalUnit?: RentalUnit;
  forObject?: string;
  kind: ObjectKind;
  onClose: () => void;
}
const CBRentalUnitForm = (props: CBRentalUnitFormProps) => {
  const constants = useActivityConstants();
  const typeOptions = UnitStruct.getUnitSelectOptions(
    PermissionService.hasBusinessUnitRole("cb_rentalEdit") || []
  );

  const objectInitial = props.forObject
    ? {
        objectId: props.forObject,
        entity: OrgaStruct.getObject(props.forObject)?.entityId,
        type: OrgaStruct.getObject(props.forObject)?.type,
      }
    : {};
  const initialValues = props.rentalUnit
    ? {
        ...props.rentalUnit.data,
      }
    : typeOptions.length === 1
    ? { type: typeOptions[0].value, ...objectInitial }
    : { ...objectInitial };

  return (
    <FormStruct
      className={"cb-rental-unit-form"}
      submitText={
        props.rentalUnit
          ? i18n.t("Global.Buttons.submit")
          : i18n.t("Global.Buttons.add")
      }
      title={
        props.rentalUnit
          ? i18n.t("cb:RentalUnit.Form.EditTitle", "Mieteinheit bearbeiten")
          : i18n.t("cb:RentalUnit.Form.AddTitle", "Mieteinheit hinzufügen")
      }
      description={
        props.rentalUnit
          ? i18n.t(
              "cb:RentalUnit.Form.EditDescription",
              "Bearbeiten Sie die Daten der Mieteinheit"
            )
          : i18n.t(
              "cb:RentalUnit.Form.AddDescription",
              "Füllen Sie die Daten der Mieteinheit aus"
            )
      }
      initialValues={initialValues}
      onSubmit={async (values) => {
        // todo submit data
        const result = await CBRentalService.submitRentalUnit(
          values,
          props.rentalUnit
        );

        DataBusDefaults.reload({
          identifiers: [CB_RENTAL_UNIT_LIST],
        });

        props.onClose();
      }}
      onAbort={props.onClose}
      render={({ form }) => {
        return (
          <>
            <div className={`section section-assignment-data`}>
              <div className={`section-title __h3 margin-bottom-10 `}>
                {i18n.t("cb:RentalUnit.Form.Sections.assignment", "Zuordnung")}
              </div>
              <div className={`field-row`}>
                {typeOptions.length !== 1 && (
                  <div className={`__field`}>
                    <Field
                      name="type"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          {...input}
                          label={`${i18n.t(
                            "cb:RentalUnit.Form.Fields.type",
                            "Bereich"
                          )}*`}
                          validation={
                            meta.error && meta.touched
                              ? { level: "error", message: meta.error }
                              : undefined
                          }
                          data={typeOptions}
                          onChange={(value) => {
                            input.onChange(value);
                            form.mutators.setValue("entity", null);
                            form.mutators.setValue("objectId", null);
                            form.mutators.setValue("relations", []);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                )}

                <div className={`__field`}>
                  <FormFieldValues names={["type"]}>
                    {([type]) => (
                      <Field
                        name="entity"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <BFSelect
                            {...input}
                            disabled={!type}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.entity",
                              "Gesellschaft"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            data={OrgaStruct.getEntitySelectOptions(type)}
                            onChange={(value) => {
                              input.onChange(value);
                              form.mutators.setValue("objectId", null);
                            }}
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
                <div className={`__field`}>
                  <FormFieldValues names={["type", "entity"]}>
                    {([type, entity]) => (
                      <Field
                        name="objectId"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <BFSelect
                            {...input}
                            disabled={!type}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.objectId",
                              "Objekt"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            data={OrgaStruct.getObjectSelectOptions(entity, [
                              type,
                            ])}
                            onChange={(value) => {
                              input.onChange(value);
                              if (!entity) {
                                const entityObj = OrgaStruct.getEntity(
                                  OrgaStruct.getObject(value).entityId
                                );
                                form.mutators.setValue("entity", entityObj._id);
                              }
                            }}
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
              </div>
            </div>
            <div className={`section section-base-data`}>
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t(
                  "cb:RentalUnit.Form.Sections.rentalUnitData",
                  "Mieteinheit"
                )}
              </div>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <FormFieldValues names={["type", "quantity"]}>
                    {([type, entity]) => (
                      <Field
                        name="unitType"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <BFSelect
                            {...input}
                            disabled={!type}
                            groupBy="group"
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.unitType",
                              "Nutzungsart"
                            )}*`}
                            sort={(isGroup) => {
                              if (isGroup) {
                                return (a, b) => {
                                  let orderIndexA = a.children[0]?.groupOrder;
                                  let orderIndexB = b.children[0]?.groupOrder;

                                  return orderIndexA - orderIndexB;
                                };
                              }

                              return (a, b) => {
                                let nameA = a.label.toUpperCase();
                                let nameB = b.label.toUpperCase();

                                if (nameA < nameB) {
                                  return -1;
                                }
                                if (nameA > nameB) {
                                  return 1;
                                }
                                return 0;
                              };
                            }}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            data={ObjectKindStruct.getUnitTypeSelectOptions(
                              props.kind._id
                            )}
                            onChange={(value) => {
                              input.onChange(value);
                            }}
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>

                <FormFieldValues names={["unitType"]}>
                  {([unitType]) => {
                    const unitTypeEl = ObjectKindStruct.getUnitTypeBy(unitType);
                    const group = getConfigRentalUnitTypeGroup(
                      unitTypeEl?.group
                    );

                    return (
                      <div className={`__field`}>
                        <Field
                          name="quantity"
                          validate={FormValidators.compose(
                            FormValidators.required(),
                            FormValidators.min(0)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              type="number"
                              allowNull
                              step={1}
                              prefix={
                                group?.areaType === "count"
                                  ? undefined
                                  : StringUtils.getAreaUnit()
                              }
                              {...input}
                              label={
                                group?.areaType === "count"
                                  ? `${i18n.t(
                                      "cb:RentalUnit.Form.Fields.quantityCount",
                                      "Anzahl"
                                    )}*`
                                  : `${i18n.t(
                                      "cb:RentalUnit.Form.Fields.quantityArea",
                                      "Fläche"
                                    )}*`
                              }
                              validation={
                                meta.error && meta.touched
                                  ? { level: "error", message: meta.error }
                                  : undefined
                              }
                            />
                          )}
                        </Field>
                      </div>
                    );
                  }}
                </FormFieldValues>
              </div>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <Field
                    name="id"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.max(50)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.id",
                          "ID"
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field display-name`}>
                  <Field
                    name="displayName"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.max(150)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.displayName",
                          "Name"
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className={`field-row`}>
                <div className={`__field field-building`}>
                  <Field
                    name="building"
                    // validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.building",
                          "Gebäude"
                        )}`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field field`}>
                  <Field
                    name="floor"
                    // validate={}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        type="number"
                        allowNull
                        step={1}
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.floor",
                          "Geschoss"
                        )}`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className={`section section-base-data`}>
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t("cb:RentalUnit.Form.Sections.planData", "Plandaten")}
              </div>
              <CBRentalUnitFormPlanDataFormPart />
            </div>
            <div className={`section section-base-administration`}>
              {/**
               * status
               * from
               */}
              <div
                className={`section-title __h3 margin-bottom-10 margin-top-20`}
              >
                {i18n.t(
                  "cb:RentalUnit.Form.Sections.administration",
                  "Administrationsdaten"
                )}
              </div>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <Field
                    name="administratedFrom"
                    validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFDate
                        oneTap
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.administratedFrom",
                          "Von"
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
                <div className={`__field`}>
                  <Field
                    name="administratedTo"
                    // validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFDate
                        oneTap
                        {...input}
                        label={`${i18n.t(
                          "cb:RentalUnit.Form.Fields.administratedTo",
                          "Bis"
                        )}`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default CBRentalUnitForm;

export const CBRentalUnitFormPlanDataFormPart = () => {
  return (
    <>
      <FormFieldValues names={["unitType"]}>
        {([unitType]) => {
          const unitTypeObj = unitType
            ? ObjectKindStruct.getUnitTypeBy(unitType)
            : null;

          const taxFactor = unitTypeObj?.taxRate || 0;

          return (
            <>
              <div className={`field-row`}>
                <div className={`__field`}>
                  <FormFieldValues names={["rentGross"]}>
                    {([rentGross]) => (
                      <Field
                        name="rentNet"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.rentNet",
                              "Planmiete netto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            removeSuffixPadding
                            suffix={
                              rentGross &&
                              !NumberUtils.equalsNormalized(
                                rentGross / (taxFactor / 100 + 1),
                                input.value
                              ) ? (
                                <BFButton
                                  appearance="link"
                                  size="xs"
                                  onClick={() => {
                                    input.onChange(
                                      NumberUtils.normalizeDecimal(
                                        rentGross / (taxFactor / 100 + 1)
                                      )
                                    );
                                  }}
                                >
                                  {i18n.t("Global.Labels.hint", "Vorschlag")}:{" "}
                                  {StringUtils.formatCurrency(
                                    rentGross / (taxFactor / 100 + 1)
                                  )}
                                </BFButton>
                              ) : undefined
                            }
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
                <div className={`__field`}>
                  <FormFieldValues names={["rentNet"]}>
                    {([rentNet]) => (
                      <Field
                        name="rentGross"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.rentGross",
                              "Planmiete brutto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            removeSuffixPadding
                            suffix={
                              rentNet &&
                              !NumberUtils.equalsNormalized(
                                rentNet * (taxFactor / 100 + 1),
                                input.value
                              ) ? (
                                <BFButton
                                  appearance="link"
                                  size="xs"
                                  onClick={() => {
                                    input.onChange(
                                      NumberUtils.normalizeDecimal(
                                        rentNet * (taxFactor / 100 + 1)
                                      )
                                    );
                                  }}
                                >
                                  {i18n.t("Global.Labels.hint", "Vorschlag")}:{" "}
                                  {StringUtils.formatCurrency(
                                    rentNet * (taxFactor / 100 + 1)
                                  )}
                                </BFButton>
                              ) : undefined
                            }
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
              </div>

              <div className={`field-row`}>
                <div className={`__field`}>
                  <FormFieldValues names={["operatingCostGross"]}>
                    {([operatingCostGross]) => (
                      <Field
                        name="operatingCostNet"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.operatingCostNet",
                              "Nebenkosten netto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            removeSuffixPadding
                            suffix={
                              operatingCostGross &&
                              !NumberUtils.equalsNormalized(
                                operatingCostGross / (taxFactor / 100 + 1),
                                input.value
                              ) ? (
                                <BFButton
                                  appearance="link"
                                  size="xs"
                                  onClick={() => {
                                    input.onChange(
                                      NumberUtils.normalizeDecimal(
                                        operatingCostGross /
                                          (taxFactor / 100 + 1)
                                      )
                                    );
                                  }}
                                >
                                  {i18n.t("Global.Labels.hint", "Vorschlag")}:{" "}
                                  {StringUtils.formatCurrency(
                                    operatingCostGross / (taxFactor / 100 + 1)
                                  )}
                                </BFButton>
                              ) : undefined
                            }
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
                <div className={`__field`}>
                  <FormFieldValues names={["operatingCostNet"]}>
                    {([operatingCostNet]) => (
                      <Field
                        name="operatingCostGross"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(0)
                        )}
                      >
                        {({ input, meta }) => (
                          <BFInput
                            {...input}
                            prefix={StringUtils.getCurrencySymbol()}
                            textAlign={"left"}
                            type={"priceInput"}
                            label={`${i18n.t(
                              "cb:RentalUnit.Form.Fields.operationCostGross",
                              "Nebenkosten brutto"
                            )}*`}
                            validation={
                              meta.error && meta.touched
                                ? { level: "error", message: meta.error }
                                : undefined
                            }
                            removeSuffixPadding
                            suffix={
                              operatingCostNet &&
                              !NumberUtils.equalsNormalized(
                                operatingCostNet * (taxFactor / 100 + 1),
                                input.value
                              ) ? (
                                <BFButton
                                  appearance="link"
                                  size="xs"
                                  onClick={() => {
                                    input.onChange(
                                      NumberUtils.normalizeDecimal(
                                        operatingCostNet * (taxFactor / 100 + 1)
                                      )
                                    );
                                  }}
                                >
                                  {i18n.t("Global.Labels.hint", "Vorschlag")}:{" "}
                                  {StringUtils.formatCurrency(
                                    operatingCostNet * (taxFactor / 100 + 1)
                                  )}
                                </BFButton>
                              ) : undefined
                            }
                          />
                        )}
                      </Field>
                    )}
                  </FormFieldValues>
                </div>
              </div>
            </>
          );
        }}
      </FormFieldValues>
    </>
  );
};
