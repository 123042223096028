import React from "react";
import GenericLayoutComponent from "../GenericLayoutComponent";

type GFButtonProps = {
  type: "submit" | "button" | "reset";
  onClick: () => void;
  disabled?: boolean;
};
export const GFButton: React.FC<GFButtonProps> = props => {
  return <button {...props}>{props.children}</button>;
};
