import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import _ from "lodash";
import Log from "../../debug/Log";
import BaseAsset from "../../model/general-assets/BaseAsset";
import { openOrDownloadDocument } from "../../redux/actions/ui-config/ui-config-actions";
import { store } from "../../redux/store";
import CDNService from "../../services/CDNService";
import CacheService from "../../services/CacheService";
import ServiceUtils from "../../services/ServiceUtils";
import FileUtils from "../../utils/FileUtils";
import { HTTP } from "../../utils/Http";
import {
  DSDirectoryFlattenMapValue,
  DSDirectoryRequirements,
  DocumentStoreAssetParams,
  DocumentStoreDirectory,
  DocumentStoreDocument,
} from "./DSInterfaces";

class DocumentStoreServiceClass {
  getDefaultAccept = () => {
    return {
      "application/eml": [".eml"],
      "message/rfc822": [],
      "application/msword": [],
      "application/pdf": [],
      "application/vnd.ms-excel": [],
      "application/vnd.ms-outlook": [],
      "text/plain": [".msg"],
      "application/vnd.ms-powerpoint": [],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "image/*": [".heic", ".heif", ".webp"],
    };
  };

  /**
   * Gets the directory configuration for the given asset and assetParams from the unit configuration.
   *
   * If no directory configuration is found and defaultFallback is true, the default directory is returned.
   * If no directory configuration is found and defaultFallback is false, undefined is returned.
   *
   * @param unit
   * @param assetParams
   * @param defaultFallback
   * @returns
   */
  getDirectoryConfigurationForAsset = (
    unit: string,
    assetType: string,
    documentsFieldPath: string,
    defaultFallback = false
  ) => {
    const rootDirectory =
      UnitStruct.getUnit(unit)?.data?.objectConfig?.[assetType]?.directories?.[
        documentsFieldPath
      ];

    if (!rootDirectory && defaultFallback) {
      if (defaultFallback) {
        return this.getDefaultDocumentStoreDirectory();
      }
      Log.error("No directory configuration found for asset type " + assetType);
      return undefined;
    }

    return rootDirectory;
  };

  /**
   * Returns the documents array from the given asset or an empty array if the asset has no documents
   *
   * @param assetParams
   * @returns
   */
  getDocumentsFromAsset = (assetParams: DocumentStoreAssetParams) => {
    const documents = _.get(
      assetParams.asset,
      assetParams.documentsFieldPath.split(".")
    );
    return documents || [];
  };

  /**
   * If no DocumentStoryDirectory for an asset is given, this default directory is used
   *
   * @returns
   */
  getDefaultDocumentStoreDirectory = (): DocumentStoreDirectory => {
    return {
      isVirtual: true,
      id: "DEFAULT_DOCUMENT_STORE_DIRECTORY_SHOULD_NOT_BE_IN_DB",
      name: {
        de: "Dokumente",
        en: "Documents",
      },
      pathIdentifier: "documents",
      accept: this.getDefaultAccept(),
      isRoot: true,
    };
  };

  /**
   * Searches for the given document in asset.cdn and returns the corresponding content type
   * If the document is not found in asset.cdn, undefined is returned
   *
   * @param document
   * @param asset
   * @returns
   */
  getContentTypeFromDocument = (
    document: DocumentStoreDocument,
    asset: BaseAsset
  ) => {
    if (!asset || !asset.cdn) {
      Log.error("CDN property of asset is missing", asset);
      return undefined;
    }

    return (
      asset?.cdn?.find((e) => e._id === document.linkToCdn)?.content_type ||
      undefined
    );
  };
  /**
   * Map the file type to the corresponding icon
   * @param file
   * @returns
   */
  getIconFromContentType = (contentType: string) => {
    if (!contentType) {
      Log.error("No content type given");
      return "common-file-empty-9";
    }

    switch (contentType.toLowerCase()) {
      case "application/pdf":
        return "office-file-pdf-1-13";
      case "application/doc":
      case "application/docx":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "office-file-doc-1-5";
      case "application/xls":
      case "application/xlsx":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "office-file-xls-1";
      case "application/ppt":
      case "application/pptx":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "office-file-ppt-1-7";
      case "application/eml":
      case "application/vnd.ms-outlook.msg":
        return "email-file-5";
      case "image/svg+xml":
      case "image/jpg":
      case "image/jpeg":
      case "image/gif":
      case "image/tif":
      case "image/tiff":
      case "image/svg":
      case "image/png":
      case "image/webp":
      case "image/heic":
        return "image-file-landscape-4";
      default:
        Log.warning("Unknown content type: " + contentType);
        return "common-file-empty-9";
    }
  };

  /**
   * Creates a map with all directories and their id as key. The value of each map entry is an array of all chlidren directory ids. Which is also
   * flattened.
   *
   * @param rootDirectory
   * @returns
   */
  private flattenDirectoryMap = (
    directory: DocumentStoreDirectory,
    resultMap = new Map<string, DSDirectoryFlattenMapValue>(),
    currentPath = ""
  ) => {
    if (!directory) {
      return [];
    }
    const descendants = [];

    if (Array.isArray(directory.subDirectories)) {
      directory.subDirectories.forEach((child) => {
        const childPath =
          currentPath === ""
            ? child.pathIdentifier
            : `${currentPath}/${child.pathIdentifier}`;
        descendants.push(
          child.id,
          ...this.flattenDirectoryMap(child, resultMap, childPath)
        );
      });
    }

    resultMap.set(directory.id, {
      directory,
      descendantIds: descendants,
      currentPath,
    });
    return descendants;
  };

  getDocumentPath(
    document: DocumentStoreDocument,
    directoryConf: DocumentStoreDirectory
  ) {
    const flattenMap = this.getFlattenDirectoryMap(directoryConf);

    return flattenMap.get(document.directoryId)?.currentPath;
  }

  /**
   * Creates a map with all directories and their id as key. The value of each map entry is an array of all descendant directory ids. Which is also
   * flattened. Each entry also holds the directory object matching the id in the key.
   *
   * @param directory
   * @returns
   */
  getFlattenDirectoryMap = (
    directory: DocumentStoreDirectory
  ): Map<string, DSDirectoryFlattenMapValue> => {
    const resultMap = new Map<string, DSDirectoryFlattenMapValue>();
    this.flattenDirectoryMap(directory, resultMap);
    return resultMap;
  };

  getFlattenDirectories = (
    directory: DocumentStoreDirectory
  ): DocumentStoreDirectory[] => {
    const resultMap = this.getFlattenDirectoryMap(directory);
    const directories: DocumentStoreDirectory[] = [];
    resultMap.forEach((value) => {
      directories.push(value.directory);
    });
    return directories;
  };
  /**
   * Creates a map with all directories and their id as key. The value of each map entry is an array of all documents having this
   * directory id. Every empty directory is removed from the map.
   *
   * @param documents
   * @param rootDirectory
   * @returns
   */
  getDirectoryDocumentMap = (
    documents: DocumentStoreDocument[],
    rootDirectory: DocumentStoreDirectory,
    showArchivedDocuments = false
  ): Map<string, DocumentStoreDocument[]> => {
    const directoryDocumentMap = this.getFlattenDirectoryMap(rootDirectory);
    return this.getDirectoryDocumentFromFlatConfig(
      documents,
      directoryDocumentMap,
      rootDirectory,
      showArchivedDocuments
    );
  };

  /**
   * Creates a map with all directories and their id as key. The value of each map entry is an array of all documents having this
   * directory id. Every empty directory is removed from the map.
   *
   * @param documents
   * @param directoryMap
   * @param rootDirectory
   * @returns
   */
  getDirectoryDocumentFromFlatConfig = (
    documents: DocumentStoreDocument[],
    directoryMap: Map<string, DSDirectoryFlattenMapValue>,
    rootDirectory: DocumentStoreDirectory,
    showArchivedDocuments = false
  ): Map<string, DocumentStoreDocument[]> => {
    const rootDirectoryId = rootDirectory.id;
    const docs = documents || [];

    const directoryDocumentMap = new Map<string, DocumentStoreDocument[]>();

    // create map with directoryId as key and documents as value
    // put documents without directoryId in root directory and mark them as orphaned
    docs.forEach((document) => {
      if (!showArchivedDocuments && document.status === "archived") {
        //hide archived documents
        return;
      }

      let targetDirectoryId = undefined;
      let isOrphaned = false;

      if (!document.directoryId) {
        targetDirectoryId = rootDirectoryId;
        isOrphaned = true;
      } else {
        // document with directoryIds will be added to the corresponding directory if it exists
        if (directoryMap.has(document.directoryId)) {
          targetDirectoryId = document.directoryId;
        } else {
          // if not put it also into the root directory
          targetDirectoryId = rootDirectoryId;
          isOrphaned = true;
        }
      }

      const documentObject = {
        ...document,
        isOrphaned: isOrphaned,
      };
      // add document to map
      if (directoryDocumentMap.has(targetDirectoryId)) {
        directoryDocumentMap.get(targetDirectoryId)?.push(documentObject);
      } else {
        directoryDocumentMap.set(targetDirectoryId, [documentObject]);
      }
    });

    directoryDocumentMap.forEach((documents, directoryId) => {
      if (documents.length === 0) {
        directoryDocumentMap.delete(directoryId);
      }
    });
    return directoryDocumentMap;
  };

  /**
   * Checks if the descendants of a directory has set the directoryRequirements property and
   * if the requirements are met.
   *
   * returns all descendant directories with unmet requirements, incuding the given directory
   *
   * @param documents
   * @param directory
   * @param flattenDocumentMap
   * @returns
   */
  findUnmetRequirementsInDescendants = (
    documentsMap: Map<string, DocumentStoreDocument[]>,
    directory: DocumentStoreDirectory,
    flattenDirectoryMap: Map<string, DSDirectoryFlattenMapValue>
  ): DocumentStoreDirectory[] => {
    // props.assetParams.asset[props.assetParams.documentsFieldPath] || [];
    const unmetRequirementDirs: DocumentStoreDirectory[] = [];
    // get the map entry for the given directory and check for unmet requirements
    const directoryMapEntry = flattenDirectoryMap.get(directory.id);

    // run through all descendant directories and check if they have mandatory documents
    // and check for unmet requirements
    if (directoryMapEntry) {
      directoryMapEntry.descendantIds.forEach((directoryId) => {
        const directory = flattenDirectoryMap.get(directoryId)?.directory;
        if (directory) {
          if (!this.doesDirectoryMeetRequirements(directory, documentsMap)) {
            unmetRequirementDirs.push(directory);
          }
        }
      });
    }

    // check the given directory itself
    if (directory.directoryRequirements && !documentsMap.has(directory.id)) {
      unmetRequirementDirs.push(directory);
    }

    return unmetRequirementDirs;
  };

  /**
   * This function checks if the given directory meets the requirements.
   * In the configuration different requirements can be configured. For an overview see DSDirectoryRequirements in DSInterfaces.ts
   *
   * @param documentsMap
   * @param directory
   * @returns
   */
  doesDirectoryMeetRequirements = (
    directory: DocumentStoreDirectory,
    documentsMap: Map<string, DocumentStoreDocument[]>
  ): boolean => {
    // no requirements set, so it is always met
    if (!directory.directoryRequirements) {
      return true;
    }
    const requirements: DSDirectoryRequirements =
      directory.directoryRequirements;
    const documents = documentsMap.get(directory.id);
    // check if the files for this folders are equal or more than the required number
    // 0 should alwas meet the requirements
    if (requirements.type === "file-number") {
      if (requirements.requiredFileNumber === 0) {
        return true;
      }
      return documents?.length >= requirements.requiredFileNumber;
    }

    // If a requirement is set, it sure needs at least one document
    if (!documents) {
      return false;
    }

    // check if a file matches the regex
    if (requirements.type === "regex") {
      const regex = new RegExp(requirements.regex);
      let match = false;
      documents.forEach((document) => {
        if (regex.test(document.name)) {
          match = true;
        }
      });
      return match;
    }

    return true;
  };

  /**
   * Opens a document in the browser or downloads it if the browser does not support the document type.
   * @async
   * @param {DocumentStoreDocument} document - The document to open or download.
   * @param {DocumentStoreAssetParams} assetParams - The asset parameters for the document.
   * @returns {Promise<void>} - A Promise that resolves when the document is opened or downloaded.
   */
  async openDocument(
    document: DocumentStoreDocument,
    assetParams: DocumentStoreAssetParams
  ) {
    // Get the MIME type of the document
    const mimetype = DSService.getContentTypeFromDocument(
      document,
      assetParams.asset
    );

    // Find the CDN item for the document
    const cdnItem = assetParams.asset.cdn.find(
      (e) => e._id === document.linkToCdn
    );

    // Fetch the CDN link for the document
    const url = await CDNService.fetchCDNLink({
      assetType: assetParams.assetType,
      assetId: assetParams.asset._id,
      assetField: assetParams.documentsFieldPath,
      cdnId: document.linkToCdn,
      hasFolderReadPermissions: true,
      fileKey: cdnItem?.key,
    });

    // Set the document name
    let documentName =
      document.name || document.originalfileName || cdnItem.filename;
    if (!documentName.includes(".")) {
      documentName += "." + FileUtils.mimeToExt(mimetype);
    }
    // Open or download the document
    store.dispatch(
      openOrDownloadDocument(url, FileUtils.mimeToExt(mimetype), documentName)
    );
  }

  async updateDocument(
    documentStoreAssetParams: DocumentStoreAssetParams,
    document: DocumentStoreDocument,
    newMetaData: any
  ) {
    return await ServiceUtils.toastError(async () => {
      const { linkToCdn } = document;
      const result = await HTTP.post({
        url: "updateCDN",
        target: "CDN",
        bodyParams: {
          assetType: documentStoreAssetParams.assetType,
          assetId: documentStoreAssetParams.asset._id,
          cdnId: linkToCdn,
          meta: {
            path: documentStoreAssetParams.documentsFieldPath,
            type: "array",
            value: newMetaData,
          },
        },
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }
  async deleteDocument(
    documentStoreAssetParams: DocumentStoreAssetParams,
    document: DocumentStoreDocument
  ) {
    const { linkToCdn, isOrphaned, ...metaData } = document;
    return await this.updateDocument(documentStoreAssetParams, document, {
      ...metaData,
      status: "archived",
    });
  }
  async moveDocument(
    documentStoreAssetParams: DocumentStoreAssetParams,
    document: DocumentStoreDocument,
    directoryId: string
  ) {
    const { linkToCdn, isOrphaned, ...metaData } = document;
    await this.updateDocument(documentStoreAssetParams, document, {
      ...metaData,
      directoryId,
    });
  }
  async renameDocument(
    documentStoreAssetParams: DocumentStoreAssetParams,
    document: DocumentStoreDocument,
    newName: string
  ) {
    const { linkToCdn, isOrphaned, ...metaData } = document;
    await this.updateDocument(documentStoreAssetParams, document, {
      ...metaData,
      name: newName,
    });
  }
}
const DSService = new DocumentStoreServiceClass();
export default DSService;
