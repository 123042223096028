import moment from "moment";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "rsuite";
import CBLoanUtils, {
  LoanDataFields,
} from "../../../apps/tatar/cashBudget/views/portfolio/loans/CBLoanUtils";
import ModalManager from "../../../components/ModalComponent/ModalManager";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import i18n from "../../../i18n";
import DataBus from "../../../services/DataBus";
import DataBusDefaults from "../../../services/DataBusDefaults";
import { isDefined } from "../../../utils/Helpers";
import StringUtils from "../../../utils/StringUtils";
import BFVirtualized from "../data/virtualized/BFVirtualized";
import BFDropzone from "../dropzone/BFDropzone";
import BFDate from "../forms/date/BFDate";
import BFInput from "../forms/input/BFInput";
import BFButton from "../general/Button/BFButton";
import BFMessage from "../general/Message/BFMessage";
import BfIcon from "../icon/BfIcon";
import BFLoanConfirmDialog from "./BFLoanConfirmDialog";
import "./BFLoanData.scss";

export type LoanValueEntry = {
  paymentNumber?: number;
  date: Date | string;
  startBalance: number;
  payout: number;
  totalPayment: number;
  repaymentAmount: number;
  repaymentInPercent: number;
  interestAmount: number;
  endBalance: number;
  accumulatedInterest: number;
  otherCost: number;
  comment: string;
  id: string;
  edited: boolean;
  confirmed?: boolean;
};

export type CalulcatedLoanData = {
  sumInterest: number;
  scheduledPayments: number;
  schuduledAnnuity: number;
  scheduledLastRate: number;
  loanEndDate: moment.Moment;
};

export type AnnuityLoanData = {
  type: "annuity";
  loanData: {
    loanTermYears: number;
    loanAmount: number;
    loanPayoutDate: moment.Moment;
    loanStartDate: moment.Moment;
    annualInterest: number;
    countPayments: number;
    repaymentRate: number;
    paymentsPerYear: number;
    paymentAmount: number;
  };
};

type LoanData = AnnuityLoanData;
type Props = {
  height?: string | number;
  readonly?: boolean;
  value: LoanValueEntry[];
  onChange?: (value: LoanValueEntry[]) => void;
  onCalculatedValues?: (calcValues: CalulcatedLoanData) => void;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  updateOnDatabusEvent?: string;
  updateLoanFields?: (fields: Partial<LoanDataFields>) => void;
} & LoanData;

const loanDataCheck = (loan: LoanData) => {
  if (loan.type === "annuity") {
    if (
      loan.loanData.paymentAmount &&
      loan.loanData.loanAmount &&
      loan.loanData.loanPayoutDate &&
      loan.loanData.loanStartDate &&
      loan.loanData.annualInterest &&
      loan.loanData.loanTermYears &&
      loan.loanData.paymentsPerYear
    ) {
      return true;
    }
  }

  return false;
};

type EditForm = {
  id: string;
  date: string;
  interest: number;
  repayment: number;
  comment: string;
  payout: number;
  otherCost: number;
};

const HeaderRow = (props: { scrollX: number }) => {
  return (
    <div className="header-wrapper">
      <div
        className="row-entry header"
        style={{ transform: `translateX(${-props.scrollX}px)` }}
      >
        <div className="column paymentNumber center">
          {i18n.t("BFLoanData.paymentNumber", "Zahlungsnr.")}
        </div>
        <div className="column date center">
          {i18n.t("BFLoanData.date", "Datum")}
        </div>
        <div className="column startBalance right">
          {i18n.t("BFLoanData.startBalance", "Anfang Saldo")}
        </div>
        <div className="column payout right">
          {i18n.t("BFLoanData.payout", "Auszahlung")}
        </div>
        <div className="column otherCost right">
          {i18n.t("BFLoanData.extraCost", "Nebenkosten")}
        </div>
        <div className="column totalPayment right">
          {i18n.t("BFLoanData.totalPayment", "Annuität")}
        </div>
        <div className="column repaymentAmount right">
          {i18n.t("BFLoanData.repaymentAmount", "Tilgung")}
        </div>
        <div className="column repaymentInPercent center">
          {i18n.t("BFLoanData.repaymentInPercent", "Tilgung in %")}
        </div>
        <div className="column interestAmount right">
          {i18n.t("BFLoanData.interestAmount", "Zinsen")}
        </div>
        <div className="column endBalance right">
          {i18n.t("BFLoanData.endBalance", "Ende Saldo")}
        </div>
        <div className="column accumulatedInterest right">
          {i18n.t("BFLoanData.accumulatedInterest", "Kumuliert Zinsen")}
        </div>
        <div className="column comment left">
          {i18n.t("BFLoanData.comment", "Kommentar")}
        </div>

        <div className="column actions" />
      </div>
    </div>
  );
};
const Row = (props: {
  readonly?: boolean;
  entry: LoanValueEntry;
  editForm: EditForm;
  onEditClick: () => void;
  onSaveEditState: (checkChanges?: boolean) => void;
  onAbortClick: () => void;
  onValueChange: (form: EditForm) => void;
  onUndoClick: () => void;
  onDropdownAction: (action: DropdownAction) => void;
  dropdownOpen: boolean;
  onDropdownState: (open: boolean) => void;
  odd: boolean;
  renderYearLine: boolean;
  rootRef: React.MutableRefObject<HTMLElement>;
}) => {
  const editActive = props.editForm?.id === props.entry.id;

  return (
    <div
      className={`row-entry ${props.odd ? "odd" : ""} ${
        props.entry.edited ? "edited" : ""
      } ${props.renderYearLine ? "year-switch" : ""} ${
        props.entry.confirmed ? "confirmed" : ""
      }`}
      onDoubleClick={(ev) => {
        if (
          props.readonly ||
          props.editForm?.id === props.entry.id ||
          props.entry.confirmed
        ) {
          return;
        }
        if (props.editForm) {
          props.onSaveEditState(true);
        }
        props.onEditClick();
        const target = ev.target as HTMLElement;
        if (target?.classList.contains("focusable")) {
          setTimeout(() => {
            let classNameJoin = ``;
            target?.classList.forEach((cl) => (classNameJoin += `.${cl}`));

            const el = document.querySelector(
              `${classNameJoin} input`
            ) as HTMLElement;
            el?.focus && el.focus();
            (el as any)?.select && (el as any).select();
          });
        }
      }}
    >
      <div className="column paymentNumber center">
        {props.entry.paymentNumber || "-"}
        <DebugDataComponent data={props.entry} />
      </div>
      <div
        className={`column date center ${editActive ? "edit" : ""} focusable`}
      >
        {editActive ? (
          <BFDate
            oneTap
            cleanable={false}
            value={new Date(props.editForm.date)}
            onChange={(value) =>
              props.onValueChange({
                ...props.editForm,
                date: moment(value).startOf("day").utc(true).toISOString(),
              })
            }
            onBlur={() => {
              if (!props.rootRef?.current.contains(document.activeElement)) {
                props.onSaveEditState(true);
              }
            }}
          />
        ) : (
          <>
            {props.entry.date
              ? moment(props.entry.date)
                  .startOf("day")
                  .utc(true)
                  .format(i18n.t("Formats.dateFormat"))
              : "-"}
          </>
        )}
      </div>
      <div className="column startBalance right">
        {props.entry.startBalance
          ? StringUtils.formatCurrency(props.entry.startBalance, true)
          : "-"}
      </div>
      <div
        className={`column payout right ${editActive ? "edit" : ""} focusable`}
      >
        {editActive ? (
          <BFInput
            type="priceInput"
            textAlign="right"
            suffix={StringUtils.getCurrencySymbol()}
            value={props.editForm.payout || 0}
            // onKeyUp={(e) => {
            //   if (e.key === "Enter") {
            //     props.onSaveEditState();
            //   }
            // }}
            onChange={(value) =>
              props.onValueChange({
                ...props.editForm,
                payout: value as number,
              })
            }
            // onBlur={() => {
            //   if (!props.rootRef?.current.contains(document.activeElement)) {
            //     props.onSaveEditState(true);
            //   }
            // }}
          />
        ) : (
          <>
            {props.entry.payout
              ? StringUtils.formatCurrency(props.entry.payout, true)
              : "-"}
          </>
        )}
      </div>

      <div
        className={`column otherCost right ${
          editActive ? "edit" : ""
        } focusable`}
      >
        {editActive ? (
          <BFInput
            type="priceInput"
            textAlign="right"
            suffix={StringUtils.getCurrencySymbol()}
            value={props.editForm.otherCost || 0}
            // onKeyUp={(e) => {
            //   if (e.key === "Enter") {
            //     props.onSaveEditState();
            //   }
            // }}
            onChange={(value) =>
              props.onValueChange({
                ...props.editForm,
                otherCost: value as number,
              })
            }
            // onBlur={() => {
            //   if (!props.rootRef?.current.contains(document.activeElement)) {
            //     props.onSaveEditState(true);
            //   }
            // }}
          />
        ) : (
          <>
            {props.entry.otherCost
              ? StringUtils.formatCurrency(props.entry.otherCost, true)
              : "-"}
          </>
        )}
      </div>

      <div className="column totalPayment right">
        {editActive ? (
          <>
            {StringUtils.formatCurrency(
              (props.editForm.interest || 0) + (props.editForm.repayment || 0),
              true
            )}
          </>
        ) : (
          <>
            {props.entry.totalPayment
              ? StringUtils.formatCurrency(props.entry.totalPayment, true)
              : "-"}
          </>
        )}
      </div>
      <div
        className={`column repaymentAmount right ${
          editActive ? "edit" : ""
        } focusable`}
      >
        {editActive ? (
          <BFInput
            type="priceInput"
            textAlign="right"
            suffix={StringUtils.getCurrencySymbol()}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     props.onSaveEditState();
            //   }
            // }}
            value={props.editForm.repayment || 0}
            onChange={(value) =>
              props.onValueChange({
                ...props.editForm,
                repayment: value as number,
              })
            }
            // onBlur={() => {
            //   if (!props.rootRef?.current.contains(document.activeElement)) {
            //     props.onSaveEditState(true);
            //   }
            // }}
          />
        ) : (
          <>
            {props.entry.repaymentAmount
              ? StringUtils.formatCurrency(props.entry.repaymentAmount, true)
              : "-"}
          </>
        )}
      </div>
      <div className="column repaymentInPercent center">
        {editActive ? (
          <>
            {`${StringUtils.formatNumber(
              ((props.editForm.repayment || 0) /
                ((props.editForm.interest || 0) +
                  (props.editForm.repayment || 0))) *
                100,
              true
            )} %`}
          </>
        ) : (
          <>
            {props.entry.totalPayment
              ? `${StringUtils.formatNumber(props.entry.repaymentInPercent)} %`
              : "-"}
          </>
        )}
      </div>
      <div
        className={`column interestAmount right ${
          editActive ? "edit" : ""
        } focusable `}
      >
        {editActive ? (
          <BFInput
            type="priceInput"
            textAlign="right"
            suffix={StringUtils.getCurrencySymbol()}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     props.onSaveEditState();
            //   }
            // }}
            value={props.editForm.interest || 0}
            onChange={(value) =>
              props.onValueChange({
                ...props.editForm,
                interest: value as number,
              })
            }
            // onBlur={() => {
            //   if (!props.rootRef?.current.contains(document.activeElement)) {
            //     props.onSaveEditState(true);
            //   }
            // }}
          />
        ) : (
          <>
            {props.entry.interestAmount
              ? StringUtils.formatCurrency(props.entry.interestAmount, true)
              : "-"}
          </>
        )}
      </div>
      <div className="column endBalance right">
        {editActive ? (
          <>
            {StringUtils.formatCurrency(
              (props.entry.startBalance || 0) +
                (props.editForm.otherCost || 0) +
                (props.editForm.payout || 0) -
                (props.editForm.repayment || 0),
              true
            )}
          </>
        ) : (
          <>
            {props.entry.endBalance !== undefined
              ? StringUtils.formatCurrency(props.entry.endBalance, false)
              : "-"}
          </>
        )}
      </div>
      <div className="column accumulatedInterest right">
        {editActive ? (
          <>
            {StringUtils.formatCurrency(
              (props.entry.accumulatedInterest || 0) -
                (props.entry.interestAmount || 0) +
                (props.editForm.interest || 0),
              true
            )}
          </>
        ) : (
          <>
            {props.entry.accumulatedInterest
              ? StringUtils.formatCurrency(
                  props.entry.accumulatedInterest,
                  true
                )
              : "-"}
          </>
        )}
      </div>
      <div
        className={`column comment left ${editActive ? "edit" : ""} focusable`}
      >
        {editActive ? (
          <BFInput
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.onSaveEditState();
              }
            }}
            value={props.editForm.comment || ""}
            onChange={(value) =>
              props.onValueChange({
                ...props.editForm,
                comment: value as string,
              })
            }
          />
        ) : (
          <>{props.entry.comment || "-"}</>
        )}
      </div>

      {!props.readonly && (
        <div className="column actions">
          <LoanDataAction
            row={props.entry}
            editState={props.editForm}
            onEditClick={props.onEditClick}
            onAbortClick={props.onAbortClick}
            onSaveEditState={props.onSaveEditState}
            onUndoClick={props.onUndoClick}
            dropdownOpen={props.dropdownOpen}
            onDropdownState={props.onDropdownState}
            onDropdownAction={props.onDropdownAction}
            // setEditState({
            //   id: row.id,
            //   date: row.date,
            //   interest: row.interestAmount,
            //   repayment: row.repaymentAmount,
            //   comment: row.comment,
            // })
          />
        </div>
      )}
    </div>
  );
};

const checkChangesFc = (editForm: EditForm, entry: LoanValueEntry) => {
  return (
    editForm.date !== entry.date ||
    editForm.interest !== entry.interestAmount ||
    editForm.comment !== entry.comment ||
    editForm.repayment !== entry.repaymentAmount ||
    entry.payout !== editForm.payout
  );
};

const BFLoanData = (props: Props) => {
  const rootRef = useRef<HTMLElement>();
  const [dropdownOpenId, setDropdownOpenId] = useState<string>(null);
  const [editState, setEditState] = useState<EditForm>(null);
  const [scrollX, setScrollX] = useState<number>(0);
  useEffect(() => {
    const handleKeyDown = (ev) => {
      if (ev.key === "Escape") {
        if (editState) {
          setEditState(null);
          ev.stopPropagation();
          ev.preventDefault();
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    const subId = props.updateOnDatabusEvent
      ? DataBus.subscribe(props.updateOnDatabusEvent, () => {
          if (loanDataCheck(props) && props.value) {
            const newValues = CBLoanUtils.recalculateAnnuityLoan(
              props.value,
              props
            );
            props.onChange(newValues);
            setEditState(null);
          }
        })
      : undefined;
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  const onDropdownAction = (
    action: DropdownAction,
    index: number,
    element: LoanValueEntry
  ) => {
    setDropdownOpenId(null);
    let tempValues: Partial<LoanValueEntry>[];

    switch (action) {
      case "deleteRow":
        tempValues = props.value.filter((e) => e.id !== element.id);
        break;
      case "insertDown":
        tempValues = [
          ...props.value.slice(0, index + 1),
          { id: nanoid() },
          ...props.value.slice(index + 1, props.value.length),
        ];
        break;
      case "insertUp":
        tempValues = [
          ...props.value.slice(0, index),
          { id: nanoid() },
          ...props.value.slice(index, props.value.length),
        ];
        break;
      case "markConfirmed":
        tempValues = props.value.map((e, i) =>
          i <= index ? { ...e, confirmed: true } : e
        );
        break;
      case "unmarkConfirmed":
        tempValues = props.value.map((e, i) =>
          i >= index ? { ...e, confirmed: false } : e
        );
        break;
      default:
        tempValues = null;
    }
    if (tempValues) {
      const newValues = CBLoanUtils.recalculateAnnuityLoan(
        tempValues as LoanValueEntry[],
        props
      );
      props.onChange(newValues);
    }
  };
  // useEffect(() => {
  //   if (loanDataCheck(props) && props.value) {
  //     const tempValues = props.value.map((entry) => ({
  //       ...entry,
  //       edited: false,
  //     }));
  //     const newValues = CBLoanUtils.recalculateAnnuityLoan(tempValues, props);
  //     props.onChange(newValues);
  //     setEditState(null);
  //   }
  // }, [props.loanData]);
  // const []
  const dataValid = loanDataCheck(props);

  const indexSelected = editState
    ? (props.value || []).findIndex((e) => e.id === editState.id)
    : null;

  const rowStyles = indexSelected
    ? { indexSelected: { zIndex: 1 } }
    : undefined;

  const renderData = (open: () => void) => (
    <div className="bf-loan-data" ref={rootRef as any}>
      {!props.readonly && !dataValid && (
        <div className="information not-valid">
          <BFMessage
            type="error"
            text={i18n.t(
              "BFLoanData.notAllDataFilled",
              "Die Darlehensdaten sind bisher nicht vollständig ausgefüllt. Bitte füllen Sie die aus, um den Zahlungsplan generieren zu können."
            )}
          />
        </div>
      )}
      {!props.readonly && dataValid && !props.value && (
        <div className="information valid">
          <BFMessage
            type="info"
            text={
              <div className="message-content">
                <div>
                  {i18n.t(
                    "BFLoanData.allDataFilled",
                    "Die Darlehensdaten sind vollständig ausgefüllt. Bitte generieren Sie den Zahlungsplan um fortzufahren."
                  )}
                </div>
                <BFButton
                  appearance="link"
                  onClick={() => {
                    props.onChange(CBLoanUtils.calculateAnnuityLoan(props));
                  }}
                >
                  {i18n.t(
                    "BFLoanData.generatePaymentplan",
                    "Zahlungsplan generieren"
                  )}
                </BFButton>
              </div>
            }
          />
        </div>
      )}
      {!props.readonly && (
        <div className="actions">
          {open && (
            <BFButton appearance="link" onClick={open}>
              {i18n.t("BFLoanData.UploadPaymentPlan", "Zahlungsplan hochladen")}
            </BFButton>
          )}
          <div className={`fill`} />
          {props.value && dataValid && (
            <BFButton
              appearance="link"
              onClick={() => {
                ModalManager.confirm({
                  title: "Änderungen zurücksetzen",
                  message: `Wollen Sie alle Änderungen zurücksetzen?`,
                  onConfirm: () =>
                    props.onChange(CBLoanUtils.calculateAnnuityLoan(props)),
                });
              }}
            >
              {i18n.t(
                "Global.Button.revertAllChanged",
                "Alle Änderungen zurücksetzen"
              )}
            </BFButton>
          )}
        </div>
      )}
      {props.value && (
        <div className="values">
          <HeaderRow scrollX={scrollX} />
          <BFVirtualized
            onScroll={(ev) => setScrollX((ev.target as any).scrollLeft)}
            height={props.height || 450}
            data={props.value}
            getStyles={(data, index) => ({
              zIndex:
                editState !== null
                  ? data.id === editState.id
                    ? 1
                    : 0
                  : data.id === dropdownOpenId
                  ? 1
                  : 0,
            })}
            renderElement={(data: LoanValueEntry, index, allData) => {
              const isYearBreak =
                index !== 0 &&
                !moment(allData[index - 1].date)
                  .utc(true)
                  .isSame(moment(data.date).utc(true), "year");
              return (
                <Row
                  rootRef={rootRef}
                  readonly={props.readonly}
                  dropdownOpen={data.id === dropdownOpenId}
                  onDropdownState={(open) => {
                    if (open) {
                      setDropdownOpenId(data.id);
                    } else {
                      if (dropdownOpenId === data.id) {
                        setDropdownOpenId(null);
                      }
                    }
                  }}
                  onDropdownAction={(action) =>
                    onDropdownAction(action, index, data)
                  }
                  renderYearLine={isYearBreak}
                  onValueChange={(editState) => setEditState(editState)}
                  onSaveEditState={(checkChanges) => {
                    const tempValues = props.value.map((entry) =>
                      entry.id === editState.id &&
                      (!checkChanges || checkChangesFc(editState, entry))
                        ? {
                            ...entry,
                            date: editState.date,
                            interestAmount: editState.interest,
                            repaymentAmount: editState.repayment,
                            comment: editState.comment,
                            otherCost: editState.otherCost,
                            payout: editState.payout,
                            edited: true,
                          }
                        : entry
                    );
                    const newValues = CBLoanUtils.recalculateAnnuityLoan(
                      tempValues,
                      props
                    );
                    props.onChange(newValues);
                    setEditState(null);
                  }}
                  onUndoClick={() => {
                    const tempValues = props.value.map((entry) =>
                      entry.id === data.id
                        ? {
                            ...entry,
                            edited: false,
                          }
                        : entry
                    );
                    const newValues = CBLoanUtils.recalculateAnnuityLoan(
                      tempValues,
                      props
                    );
                    props.onChange(newValues);
                    setEditState(null);
                  }}
                  onAbortClick={() => setEditState(null)}
                  odd={index % 2 === 0}
                  editForm={editState}
                  entry={data}
                  onEditClick={() =>
                    setEditState({
                      id: data.id,
                      date: data.date as string,
                      interest: StringUtils.normalizeDecimal(
                        data.interestAmount || 0
                      ),
                      repayment: StringUtils.normalizeDecimal(
                        data.repaymentAmount || 0
                      ),
                      otherCost: StringUtils.normalizeDecimal(
                        data.otherCost || 0
                      ),
                      comment: data.comment,
                      payout: StringUtils.normalizeDecimal(data.payout || 0),
                    })
                  }
                />
              );
            }}
            estimatedSize={38}
          />
        </div>
      )}

      {props.validation?.message && (
        <div className={`validation ${props.validation.level}`}>
          {props.validation.message}
        </div>
      )}
      {/* <br />
  <pre>
    <code>{JSON.stringify(props, null, 2)}</code>
  </pre> */}
    </div>
  );
  return props.readonly ? (
    renderData(undefined)
  ) : (
    <BFDropzone
      multipe={false}
      accept={Object.fromEntries(
        [
          "text/csv",
          "application/vnd.ms-excel",
          "application/csv",
          "text/x-csv",
          "application/x-csv",
          "text/comma-separated-values",
          "text/x-comma-separated-values",
        ].map((e) => [e, [".csv"]])
      )}
      onDrop={(accepted, rejected, ev) => {
        if (accepted.length === 1 && rejected.length === 0) {
          CBLoanUtils.uploadCSVFileForLoan(accepted[0], props)
            .then((result) => {
              const setData = (fieldsToSet: Partial<LoanDataFields> = {}) => {
                const overwriteData = Object.fromEntries(
                  Object.entries(fieldsToSet).filter(([key, value]) =>
                    isDefined(value)
                  )
                );
                const newValues = CBLoanUtils.recalculateAnnuityLoan(
                  result.loanValues,
                  {
                    ...props,
                    loanData: {
                      ...props.loanData,
                      ...overwriteData,
                    },
                  }
                );
                props.onChange(newValues);
                setEditState(null);
              };

              if (props.updateLoanFields) {
                ModalManager.show({
                  noPadding: true,
                  size: "xs",
                  content: (state, setStates, onClose) => (
                    <BFLoanConfirmDialog
                      fields={result.meta}
                      oldData={{
                        loanAmount: isDefined(props.loanData?.loanAmount)
                          ? props.loanData?.loanAmount
                          : undefined,
                        loanPayoutDate: isDefined(
                          props.loanData?.loanPayoutDate
                        )
                          ? moment(props.loanData?.loanPayoutDate)
                              .utc(true)
                              .toDate()
                          : undefined,
                        loanStartDate: isDefined(props.loanData?.loanStartDate)
                          ? moment(props.loanData?.loanStartDate)
                              .utc(true)
                              .toDate()
                          : undefined,
                        annualInterest: isDefined(
                          props.loanData?.annualInterest
                        )
                          ? props.loanData?.annualInterest
                          : undefined,
                        loanTermYears: isDefined(props.loanData?.loanTermYears)
                          ? props.loanData?.loanTermYears
                          : undefined,
                        paymentAmount: isDefined(props.loanData?.paymentAmount)
                          ? props.loanData?.paymentAmount
                          : undefined,
                      }}
                      onClose={onClose}
                      onSubmit={(fields: Partial<LoanDataFields>) => {
                        props.updateLoanFields(fields);
                        onClose();
                        setData(fields);
                      }}
                    />
                  ),
                });
              } else {
                setData();
              }
            })
            .catch((err) => {
              DataBusDefaults.toast({
                type: "error",
                text: i18n.t(
                  "BFLoanData.uploadFailed",
                  "Upload fehlgeschlagen. Bitte überprüfen Sie das Format der Datei."
                ),
              });
            });
          // setUploadFiles(accepted);
          // openUploadDialog(accepted[0]);
        }
      }}
      render={(open) => renderData(open)}
    />
  );
};
export default BFLoanData;

type DropdownAction =
  | "insertUp"
  | "insertDown"
  | "deleteRow"
  | "markConfirmed"
  | "unmarkConfirmed";
interface LoanDataActionParams {
  editState: EditForm;
  row: LoanValueEntry;
  onEditClick: () => void;
  onSaveEditState: (checkChanges?: boolean) => void;
  onAbortClick: () => void;
  onUndoClick: () => void;
  dropdownOpen: boolean;
  onDropdownState: (open: boolean) => void;
  onDropdownAction: (action: DropdownAction) => void;
}
const LoanDataAction = (props: LoanDataActionParams) => {
  if (props.editState === null) {
    return (
      <>
        {props.row.edited && !props.row.confirmed && (
          <BFButton
            appearance="link"
            icon={{ type: "light", data: "undo", size: "xs" }}
            onClick={() => props.onUndoClick()}
          />
        )}
        {!props.row.confirmed && (
          <BFButton
            appearance="link"
            icon={{ type: "light", data: "pencil", size: "xs" }}
            onClick={() => props.onEditClick()}
          />
        )}
        <Dropdown
          placement="leftStart"
          className="further-actions"
          onOpen={() => props.onDropdownState(true)}
          onClose={() => props.onDropdownState(false)}
          open={props.dropdownOpen}
          onSelect={(eventKey) => props.onDropdownAction(eventKey)}
          renderToggle={(renderProps, ref) => (
            <BFButton
              ref={ref}
              {...renderProps}
              appearance="link"
              icon={{
                type: "light",
                data: "navigation-menu-horizontal",
                size: "xs",
              }}
            />
          )}
        >
          <Dropdown.Item
            eventKey="insertUp"
            style={{ display: props.row.confirmed ? "none" : undefined }}
          >
            <div className="icon-entry">
              <BfIcon type="light" data="reorder-up" size="xs" />
              <div className="label">
                {i18n.t("BFLoanData.addRowAbove", "Zeile oberhalb hinzufügen")}
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="insertDown"
            style={{ display: props.row.confirmed ? "none" : undefined }}
          >
            <div className="icon-entry">
              <BfIcon type="light" data="reorder-down" size="xs" />

              <div className="label">
                {i18n.t("BFLoanData.addRowBelow", "Zeile unterhalb hinzufügen")}
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            divider
            style={{ display: props.row.confirmed ? "none" : undefined }}
          />
          <Dropdown.Item
            eventKey="deleteRow"
            style={{ display: props.row.confirmed ? "none" : undefined }}
          >
            <div className="icon-entry">
              <BfIcon type="light" data="delete" size="xs" />
              <div className="label">
                {i18n.t("BFLoanData.removeRow", "Zeile entfernen")}
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            divider
            style={{ display: props.row.confirmed ? "none" : undefined }}
          />

          <Dropdown.Item
            eventKey="unmarkConfirmed"
            style={{ display: !props.row.confirmed ? "none" : undefined }}
          >
            <div className="icon-entry">
              <BfIcon type="light" data="subtract-square" size="xs" />
              <div className="label">
                {i18n.t("BFLoanData.removeCheck", "Bestätigung entfernen")}
              </div>
            </div>
          </Dropdown.Item>

          <Dropdown.Item
            eventKey="markConfirmed"
            style={{ display: props.row.confirmed ? "none" : undefined }}
          >
            <div className="icon-entry">
              <BfIcon type="light" data="check-2" size="xs" />
              <div className="label">
                {i18n.t("BFLoanData.checkData", "Daten bestätigen")}
              </div>
            </div>
          </Dropdown.Item>
        </Dropdown>
      </>
    );
  }
  if (props.row.id === props.editState.id) {
    return (
      <>
        <BFButton
          onClick={() => props.onAbortClick()}
          appearance="link"
          icon={{ type: "light", data: "close", size: "xs" }}
        />
        <BFButton
          onClick={() => props.onSaveEditState()}
          appearance="link"
          icon={{ type: "light", data: "check-1", size: "xs" }}
        />
      </>
    );
  } else {
    return null;
  }
};
