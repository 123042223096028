import { DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER } from "@/apps/tatar/cashBudget/views/portfolio/CBPortfolioConst";
import {
  EnrichtedRentalUnit,
  convertEnrichtedRentalUnitToEnrichtedRentalUnitKPISGathered,
} from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import CBRentalUnitForm from "@/apps/tatar/cashBudget/views/tenants/components/rental-unit/CBRentalUnitForm";
import CBRentalUnitsOverviewTable from "@/apps/tatar/cashBudget/views/tenants/components/stacking-plan/CBRentalUnitsOverviewTable";
import ObjectStackingPlan from "@/apps/tatar/cashBudget/views/tenants/components/stacking-plan/ObjectStackingPlan";
import ModalManager from "@/components/ModalComponent/ModalManager";
import PersistentSplitPane from "@/configurable/data/SplitPane/PersistentSplitPane";
import VirtualizedTableExportButton from "@/configurable/data/VirtualizedTable/VirtualizedTableExportButton";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { useHttpCache } from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import GlobalActions from "@/services/GlobalActions";
import PermissionService from "@/services/PermissionService";
import React from "react";
import { OAObject } from "../../../types/object.interface";
import "./ObjectDetailStackingPlan.scss";

type PropsObject = {
  asset: OAObject;
};
type Props = PropsObject;
const ObjectDetailStackingPlan = (props: Props) => {
  const data = useHttpCache<EnrichtedRentalUnit[]>(
    `object-stacking-plan-${props.asset?._id}`,
    `/rental/getStackingPlan`,
    "post",
    null,
    {
      objectIds: [props.asset?._id],
    }
  );

  const convData = data.data
    ? convertEnrichtedRentalUnitToEnrichtedRentalUnitKPISGathered(data.data)
    : [];
  const chartId = React.useRef<string>(null);
  return (
    <div className="object-stackingplan __card">
      <PersistentSplitPane
        identifier="object-stackingplan"
        split="horizontal"
        defaultSize={"50%"}
        maxSize={-200}
        minSize={150}
        primary="first"
        allowResize
        onSizeChange={() => {
          if (chartId.current) {
            DataBusDefaults.chartResized(chartId.current);
          }
        }}
      >
        <div className={`plan`}>
          <ObjectStackingPlan
            objectId={props.asset?._id}
            selectMode="single"
            onMounted={(ident, chart) => {
              chartId.current = ident;
            }}
            onSelect={(selected: EnrichtedRentalUnit) => {
              GlobalActions.openDetails(
                selected.data.rentalStatus === "vacant"
                  ? AssetTypes.Rental.RentalUnit
                  : AssetTypes.Rental.RentalAgreement,
                selected.data.rentalStatus === "vacant"
                  ? selected._id
                  : selected.data.agreement._id,
                selected.data.type
              );
            }}
          />
        </div>
        <div className={`entries`}>
          <div className={`actions`}>
            {PermissionService.hasBusinessUnitRole("cb_rentalEdit") && (
              <BFButton
                appearance="link"
                size="xs"
                onClick={() => {
                  ModalManager.show({
                    backdrop: "static",
                    size: "lg",
                    noPadding: true,
                    content: (state, setState, onClose) => (
                      <CBRentalUnitForm
                        forObject={props.asset?._id}
                        onClose={onClose}
                        kind={ObjectKindStruct.getKind(
                          props.asset.data.objectKindId
                        )}
                      />
                    ),
                  });
                }}
              >
                {i18n.t(
                  "cb:Portfolio.Object.addRentalUnit",
                  "Mieteinheit hinzufügen"
                )}
              </BFButton>
            )}
            <VirtualizedTableExportButton
              managedData
              identifier={DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER}
            />
          </div>
          <div className={`content`}>
            <CBRentalUnitsOverviewTable objectId={props.asset?._id} />
          </div>
        </div>
      </PersistentSplitPane>
    </div>
  );
};
export default ObjectDetailStackingPlan;
