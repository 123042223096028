import _ from "lodash";
import { useEffect, useState } from "react";
import CacheService from "../../services/CacheService";
import PromiseLoader from "../PromiseLoader/PromiseLoader";

interface MultipleAssetLoadersProps {
  assets: { assetType: string; assetId: string }[];

  render: (data: any[]) => JSX.Element;
}
const MultipleAssetLoaders = (props: MultipleAssetLoadersProps) => {
  const [assets, setAssets] = useState<
    { assetType: string; assetId: string }[]
  >(props.assets);
  useEffect(() => {
    if (!_.isEqual(assets, props.assets)) {
      setAssets(props.assets);
    }
  }, [props.assets, assets]);
  return (
    <PromiseLoader
      param={assets}
      promise={(param) => CacheService.getDataMultiple(param)}
      render={(data) => props.render(data)}
    />
  );
};

export default MultipleAssetLoaders;
