import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { Animation } from "rsuite";
import ArrayUtils from "../../../../utils/ArrayUtils";
import ValidationPopover from "../../../abstract-ui/general/ValidationPopover/ValidationPopover";
import EZTextfield from "../ez-textfield/EZTextfield";
import "./EZAutocomplete.scss";

interface Option {
  value: string;
  label: string | React.ReactNode;
}
interface EZAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;

  placeholder?: string;
  error?: string;
  options: string[];
  maxLength?: number;
  newEntryLabel?: (search: string) => React.ReactNode;
  appearance?: "default" | "bf";
}
const EZAutocomplete = (props: EZAutocompleteProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const autocompleteRef = useRef<HTMLDivElement>();
  const [search, setSearch] = useState<string>(props.value || "");
  const [searchFocused, setSearchFocused] = useState<boolean>(false);
  const [allOptions, setAllOptions] = useState<Option[]>([]);
  const [focusedEntryIndex, setFocusedEntryIndex] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (props.error) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [props.error]);
  useEffect(() => {
    setSearch(props.value || "");
  }, [props.value]);
  useEffect(() => {
    setFocusedEntryIndex(0);
  }, [searchFocused, search]);

  useEffect(() => {
    setShowError(false);
    const fuzzyFiltered =
      (search === ""
        ? props.options
        : ArrayUtils.fuzzyFilter<string>(search, props.options, {
            ignoreLocation: true,
            threshold: 0.4,
            keys: ["value"],
          }).map((e) => e.item)) || [];
    const searchIncluded = fuzzyFiltered.some((e) => e === search);

    const options: Option[] = [
      ...(!searchIncluded && search.trim() !== ""
        ? [
            {
              value: search,
              label: props.newEntryLabel ? (
                props.newEntryLabel(search)
              ) : (
                <Trans i18nKey="EZAutocomplete.createContext">
                  <strong>{{ search }}</strong> erstellen
                </Trans>
              ),
            },
          ]
        : []),
      ...fuzzyFiltered.slice(0, 15).map((e) => ({
        value: e,
        label: e,
        onSelect: () => {
          props.onChange(e);
        },
      })),
    ];
    setAllOptions(options);
  }, [search]);

  const onOptionSelect = (option: Option) => {
    const currentValue = props.value;
    props.onChange(option.value);

    ref.current?.querySelector("textarea")?.blur();
    // if (resetSearch) {
    //   setSearch("");
    //   props.onChange("");
    // } else {
    //   // blur the element - search is starting
    //   //   (inputRef.current.inputRef as HTMLInputElement).blur();
    // }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "ArrowDown") {
      setFocusedEntryIndex(((focusedEntryIndex || 0) + 1) % allOptions.length);
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === "ArrowUp") {
      setFocusedEntryIndex(
        ((focusedEntryIndex || 0) - 1 + allOptions.length) % allOptions.length
      );
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === "Enter") {
      const option = allOptions[focusedEntryIndex || 0];
      if (option) {
        onOptionSelect(option);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <ValidationPopover
      validatorStyle="default"
      message={showError ? props.error : undefined}
      level="error"
    >
      <div
        ref={ref}
        className={classNames(`ez-autocomplete`, {
          expanded: searchFocused || search !== "",
          error: showError,
          "appearance-bf": props.appearance === "bf",
        })}
        onBlur={() => {
          setSearchFocused(false);
        }}
      >
        <EZTextfield
          appearance={props.appearance}
          placeholder={props.placeholder}
          onChange={(value) => {
            if (props.maxLength && value.length > props.maxLength) return;
            setSearch(value || "");
          }}
          onBlur={() => {
            onOptionSelect({
              label: "",
              value: search,
            });
          }}
          onFocus={() => {
            setSearchFocused(true);
          }}
          value={search}
          onKeyPress={onKeyDown}
        />

        <Animation.Bounce
          in={searchFocused && allOptions.length > 0}
          unmountOnExit
          // timeout={200}
        >
          <div className="search-autocomplete-wrapper">
            <div
              ref={autocompleteRef}
              className={classNames(`auto-complete-entries`)}
            >
              {allOptions.map((option, index) => (
                <AutocompleteEntry
                  focused={focusedEntryIndex === index}
                  key={`${option.value || ""}`}
                  option={option}
                  valueLabel={option.label}
                  onClick={() => {
                    onOptionSelect(option);
                  }}
                  onFocus={() => {
                    setFocusedEntryIndex(index);
                  }}
                />
              ))}
            </div>
          </div>
        </Animation.Bounce>
      </div>
    </ValidationPopover>
  );
};

export default EZAutocomplete;

interface AutocompleteEntryProps {
  option: Option;
  focused?: boolean;
  onClick: () => void;
  valueLabel: string | React.ReactNode;
  onFocus: () => void;
}
const AutocompleteEntry = (props: AutocompleteEntryProps) => {
  const ref = useRef<HTMLButtonElement>();
  useEffect(() => {
    if (props.focused && ref.current) {
      ref.current.scrollIntoView({ behavior: "auto", block: "nearest" });
    }
  }, [props.focused]);
  return (
    <button
      type="button"
      ref={ref}
      tabIndex={-1}
      className={classNames(`ez-autocomplete-entry`, {
        focused: props.focused,
      })}
      onMouseOver={props.onFocus}
      onClick={props.onClick}
    >
      <span className="value-label">{props.valueLabel}</span>
    </button>
  );
};
