import classNames from "classnames";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import { isDefined } from "../../../../utils/Helpers";
import StringUtils from "../../../../utils/StringUtils";
import BFButton from "../../general/Button/BFButton";
import BFDetailsButton from "../../general/Button/BFDetailsButton";
import "./TableUtils.scss";

export const renderCellValue = (
  val: any,
  optional?: string,
  transform?: (val: any) => any,
  bold?: boolean,
  color?: string,
  className?: string
) => {
  let renderedVal = val;
  if (isDefined(val)) {
    if (transform) {
      renderedVal = transform(val);
    }
  } else {
    renderedVal = optional || val;
  }

  return (
    <span
      className={`cell cell-value text-selection ${bold ? "bold" : ""} ${
        className || ""
      }`}
      style={{ color }}
      title={typeof renderedVal === "string" ? renderedVal : undefined}
    >
      {renderedVal}
    </span>
  );
};

export const LinkCell = (props: {
  text: React.ReactNode;
  assetType?: string;
  id: string;
  type?: string;
  onClick?: () => void;
}) => {
  return (
    <div className={`cell asset-cell`}>
      {props.onClick ? (
        <BFButton
          noPadding
          appearance={"link"}
          onClick={() => {
            props.onClick();
          }}
        >
          <span className={`link-text`}>{props.text}</span>
        </BFButton>
      ) : (
        <BFDetailsButton
          noPadding
          appearance={"link"}
          data={{
            assetType: props.assetType,
            assetId: props.id,
            type: props.type,
          }}
        >
          <span className={`link-text`}>{props.text}</span>
        </BFDetailsButton>
      )}
    </div>
  );
};

export const AssetCell = (props: {
  assetType: string;
  id: string;
  render: (asset: any) => React.ReactNode;
  onClick?: (asset: any) => void;
  renderError?: (err: any) => JSX.Element;
}) => {
  return (
    <AssetLoader
      assetType={props.assetType}
      id={props.id}
      renderError={props.renderError}
      render={(asset) => (
        <LinkCell
          id={props.id}
          assetType={props.assetType}
          text={props.render(asset)}
          onClick={props.onClick ? () => props.onClick(asset) : null}
          type={asset.data?.type}
        />
      )}
    />
  );
};

export const ProgressCell = (props: { progress: number }) => {
  return (
    <div className={`progress-cell`}>
      <div className={classNames(`progress`, { error: props.progress > 1 })}>
        {StringUtils.formatPercent(props.progress, true)}
      </div>
      <div className={`progress-bar`}>
        <div
          className={`progress-inner`}
          style={{
            width:
              props.progress <= 1
                ? `${props.progress * 100}%`
                : (1 / props.progress) * 100 + "%",
          }}
        />
        {props.progress > 1 && (
          <div
            className={`progress-inner-overflow`}
            style={{
              width: ((props.progress - 1) / props.progress) * 100 + "%",
            }}
          />
        )}
      </div>
    </div>
  );
};
