import { CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER } from "@/apps/tatar/activityApp/specific/maintenance/technical-units/APTechnicalUnitsConst";
import { CBPortfolioTechnicalUnit } from "@/apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import CBTechnicalUnitCard from "@/apps/tatar/cashBudget/views/portfolio/object/components/technicalUnits/CBTechnicalUnitCard";
import CBTechnicalUnitForm from "@/apps/tatar/cashBudget/views/portfolio/object/components/technicalUnits/CBTechnicalUnitForm";
import ModalManager from "@/components/ModalComponent/ModalManager";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "@/services/DataBusDefaults";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { useState } from "react";
import { OAObject } from "../../../types/object.interface";
import "./ObjectTechnicalUnits.scss";

interface CBPortfolioObjectTechnicalUnitsProps {
  asset: OAObject;
}
const ObjectTechnicalUnits = (props: CBPortfolioObjectTechnicalUnitsProps) => {
  const [openDetails, setOpenDetails] = useState<string>(null);
  const onCreate = async () => {
    ModalManager.show({
      noPadding: true,
      content: (states, setStates, closeModal) => (
        <CBTechnicalUnitForm
          onClose={closeModal}
          onSuccess={() => {
            DataBusDefaults.reload({
              identifiers: [CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER],
            });

            closeModal();
          }}
          forObjectId={props.asset._id}
        />
      ),
    });
  };
  return (
    <div className={classNames(`object-technical-units`)}>
      <div className={`action-row`}>
        <BFButton onClick={onCreate}>
          {i18n.t(
            "cb:Portfolio.Objekt.technicalUnit.create.createUnit",
            "Neue Einheit erstellen"
          )}
        </BFButton>
      </div>
      {/* <div className={`technical-unit-floor-plan`}>
        <FloorPlan
          features={[]}
          floorplanBackground={{
            type: "static-image",
            url: "test",
          }}
        />
      </div> */}
      <div className={`technical-unit-list`}>
        <ListComponent
          dataUrl="/api/asset/portfolio-technical-unit"
          identifier={CB_PORTFOLIO_OBJECT_TECHNICAL_UNIT_IDENTIFIER}
          render={(entry: CBPortfolioTechnicalUnit) => (
            <CBTechnicalUnitCard asset={entry} key={entry._id} />
          )}
          reloadOnMount
          additionalMatchQuery={MQ.combine("and", [
            MQ.eq("data.objectId", props.asset?._id),
            MQ.in("data.status", ["active"]),
          ])}
        />
      </div>
    </div>
  );
};

export default ObjectTechnicalUnits;
