import classNames from "classnames";
import { FormApi, SubmissionErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import React from "react";
import { Form, FormRenderProps } from "react-final-form";
import Collapse from "rsuite/esm/Animation/Collapse";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import i18n from "../../../i18n";
import { BFButtonProps } from "../../../modules/abstract-ui/general/Button/BFButton";
import { GFPromptBlock } from "../../../modules/generic-forms-impl/layout-components/LayoutComponentsImpl";
import { valueOrDefault } from "../../../utils/Helpers";
import FormActionRow from "../FormActionRow/FormActionRow";
import "./FormStruct.scss";

interface FormStructProps {
  className?: string;
  title?: string;
  description?: React.ReactNode;
  render: (props: FormRenderProps<any>) => React.ReactNode;

  validate?: (value: any) => any;
  notModal?: boolean;
  onSubmit: (
    data: any,
    form?: FormApi<any>,
    callback?: (errors?: SubmissionErrors) => void,
    submitActionId?: string
  ) => void | Promise<void>;
  initialValues?: any;
  onAbort?: (form: FormRenderProps) => void | Promise<void>;
  cancelText?: string;
  submitText?: string;
  additionalActions?: React.ReactNode;
  noPadding?: boolean;
  usePrompt?: boolean;
  actionsOnChange?: boolean;
  ignoreSubmitOnEnter?: boolean;
  hideSubmit?: boolean;
  abortBtnProps?: Partial<BFButtonProps>;
  submitBtnProps?: Partial<BFButtonProps>;

  additionalSubmit?: {
    submitText: string;
    props?: Partial<BFButtonProps>;
    dataActionid: string;
  };
}

const FormStruct = (props: FormStructProps) => {
  const submitActionRef = React.useRef<string>();
  const hideSubmit = props.hideSubmit || false;

  return (
    <div
      className={classNames(`form-struct`, props.className, {
        "no-padding": props.noPadding,
        "not-modal": props.notModal,
      })}
    >
      <Form
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            if (state.formState.values) {
              changeValue(state, field, () => value);
            }
          },
        }}
        initialValues={props.initialValues}
        onSubmit={async (values, form, errors) =>
          await props.onSubmit(values, form, errors, submitActionRef.current)
        }
        validate={props.validate}
        render={(renderProps) => (
          <form
            onSubmit={(ev) => {
              const submitterAction = (ev.nativeEvent as SubmitEvent)
                .submitter as HTMLButtonElement;
              if (submitterAction) {
                submitActionRef.current =
                  submitterAction.getAttribute("data-action-id");
              }
              renderProps.handleSubmit(ev);
            }}
            onKeyDown={
              valueOrDefault(props.ignoreSubmitOnEnter, true)
                ? (e) => {
                    if (e.key === "Enter") {
                      if ((e.target as HTMLElement).localName !== "textarea") {
                        e.preventDefault();
                      }
                    }
                  }
                : undefined
            }
          >
            {props.usePrompt && <GFPromptBlock />}
            <DebugDataComponent
              data={{ values: renderProps.values, errors: renderProps.errors }}
            />

            {props.title && <div className={"form-title"}>{props.title}</div>}
            <div className={`form-content`}>
              {props.description && (
                <div className={`form-description`}>{props.description}</div>
              )}
              <div className={`form-fields`}>{props.render(renderProps)}</div>
            </div>

            {props.actionsOnChange && (
              <Collapse in={renderProps.dirty}>
                <div>
                  <FormActionRow
                    submitting={renderProps.submitting}
                    onAbort={
                      props.onAbort
                        ? () => props.onAbort(renderProps)
                        : undefined
                    }
                    cancelText={
                      props.cancelText || i18n.t("Global.Buttons.cancel")
                    }
                    submitText={
                      props.submitText || i18n.t("Global.Buttons.create")
                    }
                  />
                </div>
              </Collapse>
            )}
            {!props.actionsOnChange && (
              <FormActionRow
                abortBtnProps={props.abortBtnProps}
                submitBtnProps={props.submitBtnProps}
                additionalContent={props.additionalActions}
                submitting={renderProps.submitting}
                onAbort={
                  props.onAbort ? () => props.onAbort(renderProps) : undefined
                }
                cancelText={props.cancelText || i18n.t("Global.Buttons.cancel")}
                submitText={props.submitText || i18n.t("Global.Buttons.create")}
                hideSubmit={hideSubmit}
                additionalSubmit={props.additionalSubmit}
              />
            )}
          </form>
        )}
      />
    </div>
  );
};

export default FormStruct;
