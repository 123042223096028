import { FormApi } from "final-form";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormValidators from "../../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../../i18n";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";

export const PhoneFormPart = (props: {
  form: FormApi;
  prefix?: string;
  disabled?: boolean;
}) => {
  return (
    <FieldArray name={(props.prefix || "") + "phone"}>
      {({ fields }) => (
        <div className={`section phone-section`}>
          <div className={`section-title __h3 margin-bottom-10 margin-top-20`}>
            {i18n.t("cb:Tenant.Form.Sections.Phone", "Telefon/Mobil")}
            <BFButton
              disabled={props.disabled}
              className={`margin-left-10`}
              appearance="link"
              size="xs"
              onClick={() => fields.push("")}
            >
              {i18n.t(
                "cb:Tenant.Form.Fields.Phone.AddAnother",
                "Weitere Nummer hinzufügen"
              )}
            </BFButton>
          </div>
          <div className="phones">
            {fields.map((name, index) => (
              <div className={`phone-entry`} key={index}>
                <Field
                  name={name}
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.max(40)
                  )}
                >
                  {({ input, meta }) => (
                    <div className={`field-row`}>
                      <div className={`__field __flex-1`}>
                        <BFInput
                          disabled={props.disabled}
                          {...input}
                          placeholder={i18n.t(
                            "cb:Tenant.Form.Fields.Phone.Placeholder",
                            "Telefon/Mobil"
                          )}
                          validation={
                            meta.error && meta.touched
                              ? {
                                  level: "error",
                                  message: meta.error,
                                }
                              : undefined
                          }
                        />
                      </div>
                      <BFButton
                        appearance="link"
                        size="xs"
                        disabled={props.disabled}
                        onClick={() => {
                          if (fields.length > 1) {
                            fields.remove(index);
                          } else {
                            props.form.mutators.setValue(
                              (props.prefix || "") + "phone",
                              null
                            );
                            props.form.mutators.setValue(
                              (props.prefix || "") + "helpers.showPhone",
                              false
                            );
                          }
                        }}
                      >
                        {i18n.t("Global.Buttons.remove")}
                      </BFButton>
                    </div>
                  )}
                </Field>
              </div>
            ))}
          </div>
        </div>
      )}
    </FieldArray>
  );
};
