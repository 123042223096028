import classNames from "classnames";
import Userlabel from "../../../../../../../components/AvatarComponent/Userlabel";
import AssetImages from "../../../../../../../components/CDNImageGallery/AssetImages";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import StructLoader from "../../../../../../../components/StructLoader/StructLoader";
import ListComponent from "../../../../../../../configurable/components/ListComponent/ListComponent";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import { LinkCell } from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import ContactLabel from "../../../../../../../modules/contacts-module/ContactLabel";
import AssetDocumentsButton from "../../../../../../../modules/document-store/impl/AssetDocumentsButton";
import ObjectRelatedSelect from "../../../../../../../modules/structure-components/object/ObjectRelatedSelect";
import SupplyContractStruct from "../../../../../../../redux/actions/struct/implemented/SupplyContractStruct";
import SupplyUnitStruct from "../../../../../../../redux/actions/struct/implemented/SupplyUnitStruct";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import LanguageService from "../../../../../../../services/LanguageService";
import { hasValue } from "../../../../../../../utils/Helpers";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import { PortfolioSupplyUnit } from "../../../../../cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import CBTechnicalUnitIcon from "../../../../../cashBudget/views/config/supply-unit-config/components/CBTechnicalUnitIcon";
import { APContractActivity } from "../../../../views/details/maintenance/APActivityMainteanceInterfaces";
import {
  AP_SUPPLY_UNITS_TABLE,
  CB_PORTFOLIO_OBJECT_SUPPLY_UNIT_IDENTIFIER,
} from "../APSupplyUnitsConst";
import "./APSupplyUnitCard.scss";
import SupplyUnitForm from "./APSupplyUnitForm";
import APSupplyUnitMeterReadings from "./APSupplyUnitMeterReadings";
interface CBSupplyUnitCardProps {
  asset: PortfolioSupplyUnit;
}
const APSupplyUnitCard = (props: CBSupplyUnitCardProps) => {
  return (
    <StructLoader
      structType="supplyUnit"
      unitType={props.asset.data.type}
      render={() => {
        const category = SupplyUnitStruct.getCategory(
          props.asset.data.supplyTechnicalUnitCategory
        );
        const fields = [...(category?.data.fields || [])];

        return (
          <div className={classNames(`__card cb-supply-unit-card`)}>
            <div className={`gallery`}>
              <AssetImages
                assetId={props.asset._id}
                assetType={AssetTypes.Portfolio.SupplyUnit}
                fieldPath="data.images"
              />
            </div>
            <div className={`card-content`}>
              <DebugDataComponent data={props.asset} />
              <div className={`header`}>
                <div className={`id`}>{props.asset.data.id}</div>
                <div className={`fill`} />
                {category && (
                  <div className={`category`}>
                    <CBTechnicalUnitIcon
                      value={{
                        color: category.data.color,
                        icon: category.data.icon.data,
                        type: category.data.icon.type,
                      }}
                    />
                    <div className={`category-label`}>
                      <span>
                        {LanguageService.translateLabel(
                          category.data.displayName
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={`title`}>{props.asset.data.displayName}</div>
              {(props.asset.data.linkedAsset || []).length > 0 && (
                <div className={`relations`}>
                  <ObjectRelatedSelect
                    block
                    identifier={"ap-unit-relation-selection"}
                    appearance="bf"
                    readonly
                    showJump
                    objectId={props.asset.data.objectId}
                    value={props.asset.data.linkedAsset || []}
                    multiple={true}
                    assets={[
                      AssetTypes.Rental.RentalUnit,
                      `${AssetTypes.Rental.RentalUnit}_historical`,
                    ]}
                  />
                </div>
              )}

              {props.asset.data.note && (
                <div className={`note`}>
                  <div className={`note-label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.supplyUnit.create.note",
                      "Notiz"
                    )}
                  </div>
                  <div className={`note-content`}>{props.asset.data.note}</div>
                </div>
              )}
              {fields.length > 0 && (
                <table className={`custom-fields`}>
                  {fields.map((fieldCfg) => {
                    const value = props.asset.data.customFields.find(
                      (e) => e.fieldId === fieldCfg.id
                    )?.value;
                    let formattedValue = hasValue(value) ? value : "-";
                    if (fieldCfg.fieldType === "date" && hasValue(value)) {
                      formattedValue = StringUtils.formatDate(value);
                    }

                    return (
                      <tr>
                        <td className={`label`}>
                          {LanguageService.translateLabel(fieldCfg.displayName)}
                        </td>
                        <td className={`value`}>{formattedValue}</td>
                      </tr>
                    );
                  })}
                </table>
              )}
              <hr />
              <APSupplyUnitMeterReadings asset={props.asset} />
              <hr />
              <StructLoader
                unitType={props.asset.data.type}
                structTypes={["maintenance", "unit", "orga"]}
                render={() => <TechniclUnitContracts asset={props.asset} />}
              />

              <hr />

              <div className={`actions`}>
                <AssetDocumentsButton
                  assetId={props.asset._id}
                  assetType={AssetTypes.Portfolio.SupplyUnit}
                  documentsFieldPath="data.attachments"
                />
                <div className={`fill`} />

                <BFButton
                  appearance="link"
                  noPadding
                  onClick={() => {
                    ModalManager.show({
                      noPadding: true,
                      content: (state, setState, close) => (
                        <SupplyUnitForm
                          asset={props.asset}
                          onClose={close}
                          onArchived={() => {
                            close();
                            DataBusDefaults.reload({
                              identifiers: [
                                AP_SUPPLY_UNITS_TABLE,
                                CB_PORTFOLIO_OBJECT_SUPPLY_UNIT_IDENTIFIER,
                              ],
                            });
                            DataBusDefaults.closeModal("__supply-unit");
                          }}
                          onSuccess={() => {
                            //   DataBusDefaults.reload({
                            //     identifiers: [TABLE_IDENT],
                            //   });
                            close();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  {i18n.t("Global.Buttons.edit")}
                </BFButton>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default APSupplyUnitCard;

const TechniclUnitContracts = (props: { asset: PortfolioSupplyUnit }) => {
  const activeMaintenanceStatus =
    SupplyContractStruct.getStatusByActivityStatusAndType(
      props.asset.data.type,
      "active",
      "waiting"
    );

  return (
    <div className={`contracts`}>
      <ListComponent
        identifier={`supply-unit-contracts-${props.asset._id}`}
        assetType={AssetTypes.Activity.SupplyContract}
        additionalMatchQuery={MQ.and(
          MQ.eq("data.relations.assetId", props.asset._id),
          MQ.in(
            "data.status",
            activeMaintenanceStatus.map((e) => e.id)
          )
        )}
        emptyText={i18n.t(
          "cb:Portfolio.Objekt.supplyUnit.contracts.empty",
          "Keine Versorgungsverträge vorhanden"
        )}
        render={(activity: APContractActivity) => {
          const status = SupplyContractStruct.getStatusOf(activity);
          let statusLabel = null;
          if (status) {
            statusLabel = LanguageService.translateLabel(status.displayName);
            let subStatues = null;
            if (status.subStatus) {
              subStatues = status.subStatus.find(
                (e) => e.id === activity.data.subStatus
              );
              if (subStatues) {
                statusLabel = `${statusLabel} - ${LanguageService.translateLabel(
                  subStatues.displayName
                )}`;
              }
            }
          }
          return (
            <div className={`activity-entry`}>
              <div className={`title-row`}>
                <div className={`activity-id`}>
                  <LinkCell
                    assetType={AssetTypes.Activity.SupplyContract}
                    id={activity._id}
                    text={activity.data.activityId}
                  />
                </div>
                <div className={`activity-name`}>
                  {activity.data.displayName}
                </div>
                {statusLabel && (
                  <div>
                    <BFStatus
                      size="xxs"
                      color={status.color}
                      label={statusLabel}
                      icon={status.icon}
                    />
                  </div>
                )}
              </div>
              <table className={`contract-data`}>
                <tr>
                  <td className={`label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.supplyUnit.contracts.contact",
                      "Vertragspartner"
                    )}
                  </td>
                  <td>
                    {activity.data.contact ? (
                      <ContactLabel contactId={activity.data.contact} />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
                {activity.data.noticePeriod.type === "limited" ? (
                  <>
                    <tr>
                      <td className={`label`}>
                        {i18n.t(
                          "cb:Portfolio.Objekt.supplyUnit.contracts.contractDuration",
                          "Vertragsdauer"
                        )}
                      </td>
                      <td>
                        {activity.data.contractStartDate
                          ? StringUtils.formatDate(
                              activity.data.contractStartDate
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className={`label`}>
                        {i18n.t(
                          "cb:Portfolio.Objekt.supplyUnit.contracts.noticeUntil",
                          "Kündigung bis"
                        )}
                      </td>
                      <td>
                        {activity.data.noticePeriod.typeData
                          .earliestCancelationDate
                          ? StringUtils.formatDate(
                              activity.data.noticePeriod.typeData
                                .earliestCancelationDate
                            )
                          : "-"}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td className={`label`}>
                      {i18n.t(
                        "cb:Portfolio.Objekt.supplyUnit.contracts.contractDuration",
                        "Vertragsdauert"
                      )}
                    </td>
                    <td>
                      {activity.data.contractStartDate
                        ? StringUtils.formatDate(
                            activity.data.contractStartDate
                          )
                        : i18n.t(
                            "cb:Portfolio.Objekt.supplyUnit.contracts.notSetYet",
                            "nicht gesetzt"
                          )}{" "}
                      -{" "}
                      {i18n.t(
                        "cb:Portfolio.Objekt.supplyUnit.contracts.unlimited",
                        "unbefristet"
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={`label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.supplyUnit.contracts.costOfContract",
                      "Vertragskosten"
                    )}
                  </td>
                  <td>
                    {activity.calculated.budgetSumShould
                      ? StringUtils.formatCurrency(
                          activity.calculated.budgetSumShould
                        )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className={`label`}>
                    {i18n.t(
                      "cb:Portfolio.Objekt.supplyUnit.contracts.assignee",
                      "Zugewiesen"
                    )}
                  </td>
                  <td>
                    {activity.data.assignee ? (
                      <Userlabel id={activity.data.assignee} avatarSize={18} />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </table>
            </div>
          );
        }}
      />
      {/* 
     prio, vertragsparrtner, nächste Fälligkeit (zu der technischen einheit),
    Vertrags bis, Kündigung bis, Vertragskosten,
    Verantwortlich
  */}
    </div>
  );
};
