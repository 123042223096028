import { InfiniteTableSortOption } from "../../../configurable/data/SortComponent/BaseTableSort";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import { APOfferStatus, APPriority } from "./ActivityInterfaces";

export const AP_ACTIVITY_DOCUMENTS_FIELD_PATH = "data.attachments";
export const AP_LIST_TABLE_IDENTIFIER = "ap-list-table";
export const AP_TASKS_TABLE_IDENTIFIER = "ap-tasks-table";
export const AP_ASSIGN_COMMENT_TO_ACTIVITY = "ap-assign-comment-to-activity";
export const AP_UNASSGINED_MESSAGES = "ap-unassigned-messages";
export const AP_OUTGOING_MESSAGE = "ap-outgoing-messages";
export const getActivityOfferStatusColor = (status: APOfferStatus) => {
  switch (status) {
    case "new":
      return "#d2f7fd";
    case "inApproval":
      return "#95aedc";
    case "approved":
      return "#9eff9e";
    case "rejected":
      return "#ffa3a3";
    default:
      return "#cacaca";
  }
};
export const getActivityOfferStatusLabel = (status: APOfferStatus) => {
  switch (status) {
    case "new":
      return i18n.t("apTemplate:Offer.status.new", "Angelegt");
    case "inApproval":
      return i18n.t("apTemplate:Offer.status.inApproval", "In Prüfung");
    case "approved":
      return i18n.t("apTemplate:Offer.status.approved", "Genehmigt");
    case "rejected":
      return i18n.t("apTemplate:Offer.status.rejected", "Abgelehnt");
    default:
      return "-";
  }
};

export const getActivityPriorityLabel = (status: APPriority) => {
  switch (status) {
    case APPriority.LOW:
      return i18n.t("apTemplate:Activity.priority.low", "Niedrig");
    case APPriority.MEDIUM:
      return i18n.t("apTemplate:Activity.priority.medium", "Mittel");
    case APPriority.HIGH:
      return i18n.t("apTemplate:Activity.priority.high", "Hoch");
    case APPriority.CRITICAL:
      return i18n.t("apTemplate:Activity.priority.critical", "Kritisch");
    default:
      return "-";
  }
};

export const getAssetLabel = (constants) => {
  if (!constants) {
    return i18n.t("AssetTypes.Activity.Labels.Activity", "Aktivität");
  }

  return getAssetLabelByType(constants.assetType);
};

export const getAssetLabelByType = (assetType: string) => {
  switch (assetType) {
    case AssetTypes.Activity.Budget:
    case AssetTypes.Configuration.Budget:
      return i18n.t("AssetTypes.Labels.Budget", "Budget");
    case AssetTypes.Activity.Insurance:
      return i18n.t(
        "AssetTypes.Activity.Labels.Insurance",
        "Versicherungsvertrag"
      );
    case AssetTypes.Activity.SupplyContract:
      return i18n.t(
        "AssetTypes.Activity.Labels.SupplyContract",
        "Versorgungsvertrag"
      );
    case AssetTypes.Activity.Maintenance:
      return i18n.t(
        "AssetTypes.Activity.Labels.Maintenance",
        "Wartungsvertrag"
      );
    case AssetTypes.Activity.Project:
      return i18n.t("AssetTypes.Activity.Labels.Project", "Projekt");
    case AssetTypes.Activity.DamageClaim:
      return i18n.t(
        "AssetTypes.Activity.Labels.DamageClaim",
        "Schadensmeldung"
      );
    case AssetTypes.Activity.OrderingProcess:
      return i18n.t(
        "AssetTypes.Activity.Labels.OrderingProcess",
        "Bestellvorgang"
      );
    default:
      return i18n.t("AssetTypes.Activity.Labels.Activity", "Aktivität");
  }
};

export const getActivityPriorityOptions = () => {
  return [
    {
      value: APPriority.LOW,
      label: getActivityPriorityLabel(APPriority.LOW),
      color: "#198c62",
      icon: {
        type: "light",
        data: "signal-very-low",
      },
    },
    {
      value: APPriority.MEDIUM,
      label: getActivityPriorityLabel(APPriority.MEDIUM),
      color: "#879300",
      icon: {
        type: "light",
        data: "signal-low",
      },
    },
    {
      value: APPriority.HIGH,
      label: getActivityPriorityLabel(APPriority.HIGH),
      color: "#d26405",
      icon: {
        type: "light",
        data: "signal-strong",
      },
    },

    {
      value: APPriority.CRITICAL,
      label: getActivityPriorityLabel(APPriority.CRITICAL),
      color: "#c40d0d",
      icon: {
        type: "light",
        data: "signal-full",
      },
    },
  ];
};

export const APTaskListSortOptions: InfiniteTableSortOption[] = [
  {
    definition: {
      key: "data.assignmentDate",
      dir: "desc",
    },
    label: () =>
      i18n.t("apTemplate:TaskListSort.AssignmentDate", "Genehmigungsdatum"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "data.assignmentDate",
      dir: "asc",
    },
    label: () =>
      i18n.t("apTemplate:TaskListSort.AssignmentDate", "Genehmigungsdatum"),
    icon: "arrange-list-ascending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.creationDate",
      dir: "desc",
    },
    label: () =>
      i18n.t("apTemplate:TaskListSort.CreationDate", "Schadensdatum"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.creationDate",
      dir: "asc",
    },
    label: () =>
      i18n.t("apTemplate:TaskListSort.CreationDate", "Schadensdatum"),
    icon: "arrange-list-ascending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.dueDate",
      dir: "desc",
    },
    label: () => i18n.t("apTemplate:TaskListSort.DueDate", "Fälligkeitsdatum"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.dueDate",
      dir: "asc",
    },
    label: () => i18n.t("apTemplate:TaskListSort.DueDate", "Fälligkeitsdatum"),
    icon: "arrange-list-ascending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.priority",
      dir: "desc",
    },
    label: () => i18n.t("apTemplate:TaskListSort.Priority", "Priorität"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.priority",
      dir: "asc",
    },
    label: () => i18n.t("apTemplate:TaskListSort.Priority", "Priorität"),
    icon: "arrange-list-ascending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.estimatedCosts",
      dir: "desc",
    },
    label: () =>
      i18n.t("apTemplate:TaskListSort.estimatedCosts", "Geschätzte Kosten"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "expand.linkedAsset.data.estimatedCosts",
      dir: "asc",
    },
    label: () =>
      i18n.t("apTemplate:TaskListSort.estimatedCosts", "Geschätzte Kosten"),
    icon: "arrange-list-ascending",
  },
];
