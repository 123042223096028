import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import VirtualizedTable from "../../../../../../../../configurable/data/VirtualizedTable/VirtualizedTable";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../../modules/abstract-ui/data/table/TableUtils";
import {
  useAggregationTableQuery,
  useAssetCache,
  useConstants,
} from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import { MatchQuery } from "../../../../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import MQ from "../../../../../../../../utils/MatchQueryUtils";
import { RentalVacancy } from "../../../../tenants/TenantsInterfaces";
import {
  RENTAL_VACANCY_FIELDS,
  RENTAL_VACANCY_STATIC_SELECTORS,
} from "../../../../tenants/components/rental-vacancy/CBRentalVacancyList";
import { CBPortfolioImmoConfig } from "../../../interfaces/CBPortfolioAsset";
import "./CBVacancyCurrentCard.scss";

const TABLE_IDENT = `cb-vacancy-current-table`;

const generateVacancyCurrentMatchQuery = (
  config: CBPortfolioImmoConfig,
  objectIds?: string[]
) => {
  return MQ.and(
    MQ.in("data.objectId", objectIds || []),
    MQ.lte("data.moveIn", moment().toISOString()),
    MQ.or(
      MQ.gte("data.moveOut", moment().toISOString()),
      MQ.isNull("data.moveOut")
    )
  );
};

interface VacancyCurrentCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBVacancyCurrentCard = (props: VacancyCurrentCardProps) => {
  const [matchQuery, setMatchQuery] = useState<MatchQuery>();

  const aggregatedFooter = useAggregationTableQuery(
    TABLE_IDENT,
    RENTAL_VACANCY_STATIC_SELECTORS,
    props.referenceId,
    ["data.rentalUnit"]
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          aggregatedFooter.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });
  const dispatch = useDispatch();
  const constants = useConstants();
  const configCache = useAssetCache<CBPortfolioImmoConfig>("portfolio-config", {
    type: "op",
    name: "data.type",
    op: "in",
    value: constants.currency === "USD" ? ["immo-us"] : ["immo"],
  });
  const loading = configCache.state === "loading";

  useEffect(() => {
    aggregatedFooter.reload();
  }, [matchQuery]);
  useEffect(() => {
    setMatchQuery(
      configCache.state === "cached"
        ? generateVacancyCurrentMatchQuery(configCache.data, props.objectIds)
        : undefined
    );
  }, [configCache.state]);

  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-vacancy-current-card"
      title={i18n.t("cb:Vacancy.currentVacancy", "Aktuelle Leerstände")}
      data={
        configCache.state === "cached"
          ? {
              query: matchQuery,
            }
          : undefined
      }
      loading={loading}
      // headRight={

      // }
      // subHead={
      //   <div className="filters">
      //     <div className="groups"></div>
      //     <div className="actions">
      //       <InfiniteTableSort identifier={TABLE_IDENT} options={sortOptions} />
      //     </div>
      //   </div>
      // }
      childOptions={{
        height: 400,
        render: (data) => {
          return (
            <div className="vacancy-current-table">
              <div className="table-wrapper">
                <VirtualizedTable
                  dataUrl={`/api/asset/list/${AssetTypes.Rental.RentalVacancy}`}
                  asPost
                  identifier={TABLE_IDENT}
                  // estimatedSize={90}
                  params={{ aggregated: aggregatedFooter }}
                  additionalMatchQuery={data?.query}
                  initialVisibleSort={{
                    dir: "asc",
                    key: "data.agreementExpiration",
                  }}
                  expandKeys={["data.rentalUnit"]}
                  emptyText={i18n.t(
                    "cb:Vacancy.VacancyCurrentEmpty",
                    "Keine aktuellen Leerstände"
                  )}
                  columns={_.merge({}, RENTAL_VACANCY_FIELDS(true), {
                    status: {
                      hidden: true,
                    },
                    "data.displayName": {
                      render: (node: RentalVacancy, index, params) => (
                        <LinkCell
                          assetType={AssetTypes.Rental.RentalVacancy}
                          id={node._id}
                          text={renderCellValue(node?.data?.displayName, "-")}
                        />
                      ),
                    },
                  })}
                />
              </div>
            </div>
          );
        },
      }}
    />
  );
};
export default CBVacancyCurrentCard;
