import Log from "@/debug/Log";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import _ from "lodash";
import FormStruct from "../../components/Form/FormStruct/FormStruct";
import ModalManager from "../../components/ModalComponent/ModalManager";
import i18n from "../../i18n";
import {
  Contact,
  ContactType,
  OrganizationContact,
} from "../../model/db/Contact";
import ContactService from "../../services/ContactService";
import DataBusDefaults from "../../services/DataBusDefaults";
import ContactDuplicate from "./ContactDuplicate";
import CBTenantFormMain from "./form/CBTenantFormMain";

const DEFAULT_CONTACT_ENTRY = {
  name: "",
  phone: "",
  fax: "",
  email: "",
  street: "",
  zip: "",
  city: "",
  country: "",
  note: "",
};
interface Props {
  contact?: Contact;
  businessUnits: string[];
  onClose: () => void;
  onSuccessfulSubmit?: (contact: Contact) => void;
  addContactType?: ContactType[];
  noPadding?: boolean;
}
const ContactForm = (props: Props) => {
  const typeOptions = props.businessUnits.map((e) => ({
    label: UnitStruct.getUnitLabel(e),
    value: e,
  }));
  const initialValues = props.contact
    ? {
        ...props.contact.data,
        contactType: _.uniq([
          ...(props.contact.data.contactType || []),
          ...(props.addContactType || []),
        ]),
        helpers: {
          showContactPersons:
            (props.contact as OrganizationContact).data.contactPersons?.length >
            0,
          showAddress: props.contact.data.address?.length > 0,
          showEmails: props.contact.data.emails?.length > 0,
          showPhone: props.contact.data.phone?.length > 0,
          showBankAccount: props.contact.data.bankAccount?.length > 0,
          showNote: props.contact.data.note?.trim() !== "",
        },
      }
    : props.businessUnits.length === 1
    ? {
        type: props.businessUnits[0],
        personType: "organization",
        contactType: _.uniq([...(props.addContactType || [])]),
      }
    : {
        personType: "organization",
        contactType: _.uniq([...(props.addContactType || [])]),
      };

  return (
    <FormStruct
      noPadding={props.noPadding}
      className={"cb-tenant-form"}
      submitText={
        props.contact?._id
          ? i18n.t("Global.Buttons.submit")
          : i18n.t("Global.Buttons.add")
      }
      title={
        props.contact?._id
          ? i18n.t("Contact.Form.editTitle", "Kontakt bearbeiten")
          : i18n.t("Contact.Form.AddTitle", "Kontakt hinzufügen")
      }
      description={
        props.contact?._id
          ? i18n.t(
              "Contact.Form.EditDescription",
              "Bearbeiten Sie die Daten des Kontakts"
            )
          : i18n.t(
              "Contact.Form.AddDescription",
              "Füllen Sie die Daten des Kontakts aus"
            )
      }
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const { helpers, ...data } = values;
          const result = (await ContactService.submitContact(
            data,
            props.contact?._id
          )) as Contact;
          props.onSuccessfulSubmit?.(result);
          props.onClose();
        } catch (err) {
          if (err?.response?.status === 300) {
            ModalManager.show({
              noPadding: true,
              content: (states, setStates, closeModal) => (
                // Import duplicate to show modal when duplicate is found
                <ContactDuplicate
                  contactOptions={err.response.data.message}
                  onClose={closeModal}
                  onCreateAnyway={async () => {
                    const { helpers, ...data } = values;
                    const result = (await ContactService.submitContact(
                      data,
                      props.contact?._id,
                      true
                    )) as Contact;
                    props.onSuccessfulSubmit?.(result);
                    props.onClose();
                  }}
                  onSelected={async (contact) => {
                    props.onSuccessfulSubmit?.(contact);
                    props.onClose();
                  }}
                />
              ),
              backdrop: "static",
              size: "sm",
            });
          } else {
            DataBusDefaults.toast({
              type: "error",
              text: err?.error || i18n.t("Global.Errors.unknownError"),
            });
          }
          Log.error(err);
        }
      }}
      onAbort={props.onClose}
      render={({ form }) => {
        return (
          <CBTenantFormMain form={form} prefix="" typeOptions={typeOptions} />
        );
      }}
    />
  );
};

export default ContactForm;
