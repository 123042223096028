import classNames from "classnames";
import { Field, FormRenderProps } from "react-final-form";
import FormFieldValues from "../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../i18n";
import BFDate from "../../../../abstract-ui/forms/date/BFDate";
import BFSelect from "../../../../abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../abstract-ui/general/Button/BFButton";
import ValidationPopover from "../../../../abstract-ui/general/ValidationPopover/ValidationPopover";
import RSService from "../../../RSService";
import RSRemoveAdditionalFieldBtn from "./RSRemoveAdditionalFieldBtn";
import "./RSRepetitionFormPart.scss";

interface RSRepetitionFormPartProps {
  formProps: FormRenderProps<any>;
}

const RSRepetitionFormPart = (props: RSRepetitionFormPartProps) => {
  return (
    <div className={classNames("rs-repetition-formpart")}>
      <div className={`section-title __h3 margin-bottom-10 margin-top-20`}>
        {i18n.t("Resubmission.Titles.Repition", "Termin Wiederholung")}
      </div>
      <div className="row">
        <RSRemoveAdditionalFieldBtn
          clearFields={() => {
            props.formProps.form.mutators.setValue(
              "helpers.showRepetition",
              false
            );
            props.formProps.form.mutators.setValue(
              "resubmissionType",
              "single"
            );
            props.formProps.form.mutators.setValue("interval", null);
          }}
        />
      </div>
      <div className="row">
        <div className={classNames("__field")}>
          <Field
            name="resubmissionType"
            validate={FormValidators.compose(FormValidators.required())}
          >
            {({ input, meta }) => (
              <BFSelect
                validation={
                  meta.error && meta.touched
                    ? { level: "error", message: meta.error }
                    : undefined
                }
                label={i18n.t("Resubmission.Labels.Repitition", "Wiederholung")}
                {...input}
                data={RSService.getResubmissionTypeOptions()}
              />
            )}
          </Field>
        </div>
      </div>
      <FormFieldValues names={["resubmissionType"]}>
        {([resubmissionType]) => {
          if (resubmissionType === "interval") {
            return <RSRepititionIntervalFormPart {...props} />;
          }
          return null;
        }}
      </FormFieldValues>
    </div>
  );
};

export default RSRepetitionFormPart;

const RSRepititionIntervalFormPart = (props: RSRepetitionFormPartProps) => {
  return (
    <>
      <div className="row">
        <div className="__field">
          <Field name="interval.type" validate={FormValidators.required()}>
            {({ input, meta }) => (
              <BFSelect
                validation={
                  meta.error && meta.touched
                    ? { level: "error", message: meta.error }
                    : undefined
                }
                label={i18n.t("Resubmission.Labels.IntervallType", "Intervall")}
                {...input}
                data={RSService.getIntervalOptions()}
                onChange={(value) => {
                  if (value === "daily") {
                    props.formProps.form.mutators.setValue(
                      "interval.days",
                      RSService.getIntervalDayDefaultValues()
                    );
                  } else {
                    props.formProps.form.mutators.setValue(
                      "interval.days",
                      null
                    );
                  }

                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </div>
        <div className="__field">
          <Field
            name="interval.endDate"
            validate={FormValidators.compose(
              FormValidators.required(),
              FormValidators.dateAfter(new Date())
            )}
          >
            {({ input, meta }) => {
              return (
                <BFDate
                  label={i18n.t(
                    "Resubmission.Labels.Intevall.EndDate",
                    "Endet am"
                  )}
                  {...input}
                  validation={{ level: "error", message: meta.error }}
                />
              );
            }}
          </Field>
        </div>
      </div>
      <FormFieldValues names={["interval.type"]}>
        {([intervalType]) => {
          if (intervalType !== "daily") {
            return null;
          }
          return (
            <div className="row">
              <div className="__field">
                <Field
                  name="interval.days"
                  validate={FormValidators.required(
                    i18n.t(
                      "Resubmission.Errors.Intevall.Days",
                      "Bitte wählen Sie mindestens einen Wochentag aus."
                    )
                  )}
                >
                  {({ input, meta }) => {
                    const days = input.value || [];
                    const daysOption = RSService.getIntervalDayOptions();
                    return (
                      <ValidationPopover level="error" message={meta.error}>
                        <div
                          className={classNames(
                            "rs-repitition-day-options",
                            "__field",
                            { error: meta.error }
                          )}
                        >
                          {daysOption.map((dayOption) => {
                            return (
                              <BFButton
                                textKey={dayOption.label}
                                appearance={
                                  days.includes(dayOption.value)
                                    ? "primary"
                                    : "outline"
                                }
                                onClick={() => {
                                  if (days.includes(dayOption.value)) {
                                    const newValue = days.filter(
                                      (day) => day !== dayOption.value
                                    );
                                    props.formProps.form.mutators.setValue(
                                      "interval.days",
                                      newValue
                                    );
                                  } else {
                                    props.formProps.form.mutators.setValue(
                                      "interval.days",
                                      [...days, dayOption.value]
                                    );
                                  }
                                }}
                              />
                            );
                          })}
                        </div>
                      </ValidationPopover>
                    );
                  }}
                </Field>
              </div>
            </div>
          );
        }}
      </FormFieldValues>
    </>
  );
};
