import { StructType } from "../../reducers/struct/StructInterface";

export const STRUCT_SET_DATA = `STRUCT_SET_DATA`;
export const STRUCT_SET_ERROR = `STRUCT_SET_ERROR`;
export const STRUCT_SET_LOADING = `STRUCT_SET_LOADING`;
export const STRUCT_CLEAR_CACHE = `STRUCT_CLEAR_CACHE`;

interface StructSetData {
  type: typeof STRUCT_SET_DATA;
  structType: StructType;
  unitType: string;
  data: any;
}

interface StructSetError {
  type: typeof STRUCT_SET_ERROR;
  structType: StructType;
  unitType: string;
  error: string;
}
interface StructSetLoading {
  type: typeof STRUCT_SET_LOADING;
  structType: StructType;
  unitType: string;
}
interface StructClearData {
  type: typeof STRUCT_CLEAR_CACHE;
}

export type StructAction =
  | StructSetData
  | StructSetError
  | StructSetLoading
  | StructClearData;
