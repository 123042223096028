import moment from "moment";
import i18n from "../../../../../i18n";
import { RentalVacancy } from "./TenantsInterfaces";

export const RentalAgreementChangeReasons = () => [
  {
    value: "creation",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.creation",
      "Mietvertragsabschluss"
    ),
    editable: false,
  },
  {
    value: "agreementChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.agreementChange",
      "Mietvertragsänderung"
    ),
    editable: true,
  },
  {
    value: "indexChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.indexChange",
      "Änderung Mietpreis (Index)"
    ),
    editable: true,
  },
  {
    value: "seasonChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.seasonChange",
      "Änderung Mietpreis (Staffel)"
    ),
    editable: true,
  },
  {
    value: "rentChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.rentChange",
      "Änderung Mietpreis (sonstige)"
    ),
    editable: true,
  },
  {
    value: "operatingCostChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.operatingCostChange",
      "Änderung Betriebskosten"
    ),
    editable: true,
  },
  {
    value: "rentFreeTime",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.rentFreeTime",
      "Mietfreie Zeit"
    ),
    editable: true,
  },
  {
    value: "misc",
    label: i18n.t("cb:RentalAgreementChangeReasons.misc", "Sonstiges"),
    editable: true,
  },
];

const RentalVacancyStatus = {
  future: {
    label: () =>
      i18n.t("cb:RentalVacancyStatus.future", "Zukünftiger Leerstand"),
    value: "future",
    color: "#ffc493",
  },
  current: {
    label: () =>
      i18n.t("cb:RentalVacancyStatus.current", "Aktueller Leerstand"),
    value: "current",
    color: "#dd9f9f",
  },
  past: {
    label: () => i18n.t("cb:RentalVacancyStatus.past", "Ehemaliger Leerstand"),
    value: "past",
    color: "#dbdbdb",
  },
};

class CBRentalUtilsClass {
  calculateVacancyStatus = (vacancy: RentalVacancy) => {
    if (moment(vacancy.data.moveIn).isAfter(moment())) {
      return RentalVacancyStatus.future;
    }
    if (
      !vacancy.data.moveOut ||
      moment(vacancy.data.moveOut).isAfter(moment())
    ) {
      return RentalVacancyStatus.current;
    }
    return RentalVacancyStatus.past;
  };
}
const CBRentalUtils = new CBRentalUtilsClass();
export default CBRentalUtils;
