export const AssetTypes = {
  Invoice: "invoice",
  Reminder: "reminder",
  Contact: "contact",
  Task: "task",

  Global: {
    Icon: "icon",
    IconFamily: "icon-family",
    KanbanBoard: "kanban-board",
  },
  CashBudget: {
    BookingCategoryRuleSet: "lq-bookingCategoryRuleSet",
    CBAssignmentRule: "lq-assignmentRule",
    Booking: "lq-booking",
    BookingLink: "activityBookingLink",
    Attachment: "cashbudget-receipt",
  },
  Portfolio: {
    Object: "object",
    ObjectKind: "object-kind",
    ObjectConfig: "portfolio-objectConfig",
    ObjectGroup: "object-group",
    Renter: "lq-renter",
    SupplyUnit: "portfolio-supply-technical-unit",
    TechnicalUnit: "portfolio-technical-unit",
    TechnicalUnitCategoryGroup: "technical-unit-category-group",
    SupplyUnitCategoryGroup: "supply-technical-unit-category-group",
    Loan: "portfolio-loan",
    Group: "portfolio-group",
    RiskScenario: "portfolio-risk-scenario",
  },
  Configuration: {
    Budget: "lq-budget",
    LQCategory: "lq-category",
    LQBank: "lq-bank",
    LQOpenBankConnection: "openBanking-bankConnection",
    BookingImport: "lq-bookingImport",
    BookingImportMapping: "lq-csvMappingObject",
  },
  Activity: {
    Offer: "activity-offer",

    RentReduction: "rentReduction",
    Insurance: "activity-insurance",
    SupplyContract: "activity-supplyContract",
    OrderingProcess: "activity-orderingProcess",
    Rating: "activity-rating",
    DamageClaim: "activity-damageClaim",
    Project: "activity-project",
    Maintenance: "activity-maintenance",
    ProjectInvoiceLink: "activity-projectInvoiceLink",
    ProjectBudget: "activity-projectBudget",
    ProjectDeprecated: "portfolio-project", // todo migrate date to new activity structure
    MaintenanceProtocol: "portfolio-maintenance-protocol",
    Budget: "activity-budget",
    ConstructionDiary: "activity-projectConstructionDiary",
  },
  ActivityConfig: "activity-config",
  Comment: "comment",
  UserConfig: "user-config",
  DashboardConfig: "dashboard-config",
  InvoiceConfig: {
    ApprovalChain: "invoice-approvalChain",
    InvoiceType: "invoice-type",
    CostCenter: "invoice-type-costCenter",
  },
  Rental: {
    RentalUnit: "rental-unit",
    RentalAgreement: "rental-agreement",
    RentalAgreementPeriod: "rental-agreementTimePeriod",
    RentalUnitType: "rental-unit-type",
    RentalVacancy: "rental-vacant",
    RentalOpos: "rental-opos",
  },
  Resubmission: "resubmission",

  Template: "template",
};
