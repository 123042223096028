import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { isDefined } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { Field } from "react-final-form";
import { OAObject } from "../../../types/object.interface";
import {
  ObjectKind,
  getFeaturesForKind,
} from "../../../types/objectKind.interface";
// import './ObjectFormFeatureImmo.scss';

interface ObjectFormFeatureImmoProps {}

export const getInitialValueFeatureImmo = (
  asset: OAObject,
  kind: ObjectKind
) => {
  const features = getFeaturesForKind(kind);

  if (features.includes("immo")) {
    return {
      constructionYear: asset?.data?.feature?.immo?.constructionYear,
      area: asset?.data?.feature?.immo?.area,
      accountingTemplate: isDefined(asset)
        ? undefined
        : kind.data.config.immo?.accountingTemplate?.[0]?.id,
    };
  } else {
    return undefined;
  }
};
const ObjectFormFeatureImmo = (props: ObjectFormFeatureImmoProps) => {
  return (
    <div className={classNames(`object-form-feature-immo`)}>
      <div className={`field-row`}>
        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.area"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"number"}
                label={`${i18n.t("obj:ObjectForm.Immo.Area", "Fläche")}*`}
                suffix={StringUtils.getAreaUnit()}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>

        {/* <div className={`field __flex-1`}>
          <Field
            name="feature.immo.purchasePrice"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"priceInput"}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.purchasePrice",
                  "Kaufpreis"
                )}*`}
                suffix={StringUtils.getCurrencySymbol()}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div> */}

        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.constructionYear"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"number"}
                step={1}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.constructionYear",
                  "Baujahr"
                )}*`}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ObjectFormFeatureImmo;
