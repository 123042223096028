import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import classNames from "classnames";
import { FormApi } from "final-form";
import moment from "moment";
import { Field } from "react-final-form";
import Collapse from "rsuite/esm/Animation/Collapse";
import FormFieldValues from "../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import LifeCycle from "../../../../../components/LIfeCycle/LifeCycle";
import { PDFHighlight } from "../../../../../components/PDFViewer/PDFViewer";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { OrganizationContactData } from "../../../../../model/db/Contact";
import BFTabs from "../../../../../modules/abstract-ui/data/tabs/BFTabs";
import AutosizeTextarea from "../../../../../modules/abstract-ui/forms/autosize-textarea/AutosizeTextarea";
import BFDate from "../../../../../modules/abstract-ui/forms/date/BFDate";
import BFCurrency from "../../../../../modules/abstract-ui/forms/input/BFCurrency";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFToggle from "../../../../../modules/abstract-ui/forms/toggle/BFToggle";
import BFMessage from "../../../../../modules/abstract-ui/general/Message/BFMessage";
import BFUseTooltip from "../../../../../modules/abstract-ui/general/tooltip/BFUseTooltip";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import ActivitySelect from "../../../../../modules/activity-components/ActivitySelect";
import ContactSelect from "../../../../../modules/contacts-module/ContactSelect";
import EZTags from "../../../../../modules/ez-form/form-elements/ez-tags/EZTags";
import LanguageService from "../../../../../services/LanguageService";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import { isDefined, valueOrDefault } from "../../../../../utils/Helpers";
import StringUtils from "../../../../../utils/StringUtils";
import { InvoicePaymentTypeTags } from "../../../../AppConfigInterfaces";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import { PaymentPlan, RAInvoice } from "../../RAInterfaces";
import { RA_INVOICE_MAIN_FIELDS } from "../../RAUtils";
import { ExtractField } from "./ExtractField";
import { RAInvoiceCostcenterForm } from "./RAInvoiceCostcenterForm";
import RAInvoiceDirectionIndicator from "./RAInvoiceDirectionIndicator";
import "./RAInvoiceForm.scss";
import { INVOICE_EXTRACT_KEYS } from "./RAInvoiceFormConst";
import { RAInvoiceInfoForm } from "./RAInvoiceInfoForm";
import { RAInvoicePaymentPlanForm } from "./RAInvoicePaymentPlanForm";

//
// TODOS:
// on change of invoice type -> reset cost center
// on change of entity -> reset cost center
//

const ACTIVATE_URGENT_FEATURE = true;
interface RAInvoiceFormProps {
  invoice: RAInvoice;
  form: FormApi;
  onHighlight: (highlight?: PDFHighlight | PDFHighlight[]) => void;
}
const RAInvoiceForm = (props: RAInvoiceFormProps) => {
  const direction = props.invoice?.data?.direction;

  const allCustomFields = ACInvoiceUtils.getCustomFields(
    props.invoice.data.type
  );

  const extractValue = (field: string, formatter?: (value: any) => any) => {
    const value = props.invoice?.extract?.find(
      (e) => e.identifier === field
    )?.value;
    if (value && formatter) {
      return formatter(value);
    }
    return value;
  };

  const isInApproval = props.invoice.data.status === "inApproval";
  const invoicePayed = props.invoice.data.payed;
  return (
    <div className={classNames(`ra-invoice-form`)}>
      <div className={`form-head`}>
        <RAInvoiceDirectionIndicator
          direction={props.invoice?.data?.direction}
        />
        <div className={`fill`} />
        {ACTIVATE_URGENT_FEATURE && (
          <Field name="urgent">
            {({ input, meta }) => (
              <div
                className={classNames(`urgend-field`, {
                  urgent: input.value,
                })}
              >
                <BFToggle
                  size="sm"
                  labelPosition="right"
                  checked={input.value}
                  onChange={(checked) => input.onChange(checked)}
                  label={i18n.t("ra:InvoiceForm.urgent", "Dringende Rechnung")}
                />
              </div>
            )}
          </Field>
        )}
      </div>
      <FormFieldValues names={["urgent"]}>
        {([urgent]) => (
          <Collapse in={urgent} unmountOnExit>
            <div>
              <div className={`urgent-message`}>
                <div className={`description`}>
                  {i18n.t(
                    "ra:InvoiceForm.urgentMessage",
                    "Bei einer dringenden Rechnung, werden alle Bearbeiter mit hoher Priorität benachrichtigt. Bitte geben Sie den Grund für die Dringlichkeit an."
                  )}
                </div>
                <Field
                  name="urgentComment"
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <AutosizeTextarea
                      maxHeight={300}
                      type={"textarea"}
                      placeholder={i18n.t(
                        "ra:InvoiceForm.urgentCommentPlaceholder",
                        "Grund der Dringlichkeit"
                      )}
                      className={`urgent-comment`}
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
            </div>
          </Collapse>
        )}
      </FormFieldValues>
      <div className={`main-form`}>
        <div className={`form-row`}>
          <div className={`field __flex-1`}>
            <ExtractField
              name={RA_INVOICE_MAIN_FIELDS().entity.identifier}
              validate={FormValidators.compose(FormValidators.required())}
              invoice={props.invoice}
              onHighlight={props.onHighlight}
              field={
                direction === "INCOMING"
                  ? INVOICE_EXTRACT_KEYS.customerCompanyName
                  : INVOICE_EXTRACT_KEYS.supplierCompanyName
              }
              checkExtractValue={(value: string, extractValue: string) =>
                value === OrgaStruct.findEntityByName(extractValue)?._id
              }
              disableExtraction={(value: string) =>
                OrgaStruct.findEntityByName(value) === undefined
              }
              formatValue={(value: string) =>
                OrgaStruct.findEntityByName(value)?.displayName
              }
              onExtractValueChange={(value: string) =>
                OrgaStruct.findEntityByName(value)?._id
              }
            >
              {({ input, meta }) => (
                <BFSelect
                  {...FormValidators.getValidation(meta)}
                  label={RA_INVOICE_MAIN_FIELDS().entity.label + "*"}
                  cleanable={false}
                  data={ArrayUtils.sortData(
                    OrgaStruct.getEntities(props.invoice.data.type).map(
                      (entity) => ({
                        value: entity._id,
                        label: entity.displayName,
                      })
                    ),
                    { key: "label", dir: "asc" }
                  )}
                  {...input}
                  {...FormValidators.getValidation(meta)}
                  onChange={(value) => {
                    input.onChange(value);
                    props.form.mutators.setValue("objectId", null);
                  }}
                />
              )}
            </ExtractField>
          </div>
          <div className={`field __flex-1`}>
            <Field
              name={RA_INVOICE_MAIN_FIELDS().contact.identifier}
              validate={FormValidators.compose(FormValidators.required())}
            >
              {({ input, meta }) => {
                const initialData = getContactInitialData(props.invoice);

                return (
                  <ContactSelect
                    businessUnits={[props.invoice.data.type]}
                    contactTypes={
                      direction === "INCOMING"
                        ? ["INVOICE"]
                        : ["BILL", "TENANT"]
                    }
                    onChange={input.onChange}
                    value={input.value}
                    label={
                      direction === "INCOMING"
                        ? RA_INVOICE_MAIN_FIELDS().contact.label + "*"
                        : i18n.t(
                            "ra:InvoiceForm.BillReceiver",
                            "Rechnungsempfänger"
                          ) + "*"
                    }
                    {...FormValidators.getValidation(meta)}
                    placeholder={
                      direction === "INCOMING"
                        ? i18n.t(
                            "ra:InvoiceForm.ContactPlaceholder",
                            "Zahlungsempfänger auswählen"
                          )
                        : i18n.t(
                            "ra:InvoiceForm.BillReceiverPlaceholder",
                            "Rechnungsempfänger auswählen"
                          )
                    }
                    addText={
                      initialData?.displayName
                        ? initialData.displayName
                        : undefined
                    }
                    initialCreateObj={
                      initialData
                        ? ({
                            data: {
                              ...initialData,
                              type: props.invoice.data.type,
                              contactType:
                                direction === "INCOMING"
                                  ? ["INVOICE"]
                                  : ["BILL"],
                            },
                          } as any)
                        : undefined
                    }
                    {...FormValidators.getValidation(meta)}
                  />
                );
              }}
            </Field>
          </div>
        </div>
        <div className={`form-row`}>
          <div className={`field __flex-1`}>
            <FormFieldValues
              names={[RA_INVOICE_MAIN_FIELDS().entity.identifier]}
            >
              {([entity]) => (
                <Field name={RA_INVOICE_MAIN_FIELDS().objectId.identifier}>
                  {({ input, meta }) => (
                    <BFSelect
                      label={RA_INVOICE_MAIN_FIELDS().objectId.label}
                      cleanable={true}
                      data={OrgaStruct.getObjectsOfEntity(entity)?.map(
                        (entity) => ({
                          value: entity._id,
                          label: `${entity.id} - ${entity.displayName}`,
                        })
                      )}
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              )}
            </FormFieldValues>
          </div>
          <div className={`field __flex-1`}>
            <Field name={RA_INVOICE_MAIN_FIELDS().activity.identifier}>
              {({ input, meta }) => (
                <ActivitySelect
                  multiple={true}
                  {...FormValidators.getValidation(meta)}
                  block
                  appearance="bf"
                  businessUnits={[props.invoice.data.type]}
                  identifier="ap-damage-activity-assignment"
                  value={input.value || []}
                  onChange={input.onChange}
                  // onValueAdded={async (value) => {
                  //   await InvoiceService.addActivity(props.asset._id, value);
                  // }}
                  // onValueRemoved={async (value) => {
                  //   await InvoiceService.removeActivity(props.asset._id, value);
                  // }}
                  cleanable
                  label={RA_INVOICE_MAIN_FIELDS().activity.label}
                  assets={[
                    AssetTypes.Activity.DamageClaim,
                    AssetTypes.Activity.Project,
                    AssetTypes.Activity.ProjectDeprecated,
                    AssetTypes.Activity.Maintenance,
                    AssetTypes.Activity.OrderingProcess,
                    AssetTypes.Activity.SupplyContract,
                    AssetTypes.Activity.Insurance,
                  ]}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={`form-row`}>
          <div className={`field __flex-1`}>
            <Field
              name={RA_INVOICE_MAIN_FIELDS().invoiceType.identifier}
              validate={FormValidators.compose(FormValidators.required())}
            >
              {({ input, meta }) => (
                <BFSelect
                  label={RA_INVOICE_MAIN_FIELDS().invoiceType.label + "*"}
                  cleanable={false}
                  renderMenuItem={(label, item) => (
                    <div className={`invoice-type-menu-item`}>
                      <div className={`label`}>{label}</div>
                      {item.description && (
                        <BFUseTooltip
                          tooltip={
                            <div
                              style={{ textAlign: "left" }}
                              dangerouslySetInnerHTML={{
                                __html: StringUtils.sanitizeHtml(
                                  item.description
                                ),
                              }}
                            />
                          }
                          delay={0}
                        >
                          <div>
                            <BfIcon
                              type="light"
                              data="question-help-circle"
                              size="xs"
                            />
                          </div>
                        </BFUseTooltip>
                      )}
                    </div>
                  )}
                  {...FormValidators.getValidation(meta)}
                  data={ArrayUtils.sortData(
                    ACInvoiceUtils.getInvoiceTypes(
                      props.invoice?.data?.direction,
                      props.invoice.data.type
                    ).map((type) => ({
                      value: type._id,
                      label: LanguageService.translateLabel(
                        type.data.displayName
                      ),
                      sortIndex: type.data.sortIndex || 0,
                      description: type.data.description,
                    })),
                    [
                      { key: "sortIndex", dir: "asc" },
                      { key: "label", dir: "asc" },
                    ]
                  )}
                  {...input}
                />
              )}
            </Field>
          </div>
          <div className={`__flex-1 flex-container`}>
            <div className={`field __flex-3`}>
              <FormFieldValues names={["paymentPlan"]}>
                {([paymentPlan]) => (
                  <ExtractField
                    name={RA_INVOICE_MAIN_FIELDS().amountToPay.identifier}
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.min(0)
                    )}
                    hideActionButton={
                      (paymentPlan || []).length > 0 || invoicePayed
                    }
                    invoice={props.invoice}
                    onHighlight={props.onHighlight}
                    field={[
                      INVOICE_EXTRACT_KEYS.paymentAmountTotal,
                      INVOICE_EXTRACT_KEYS.currencyCode,
                    ]}
                    checkExtractValue={(value, extractValue) =>
                      (isDefined(
                        extractValue[INVOICE_EXTRACT_KEYS.paymentAmountTotal]
                      )
                        ? value.amount ===
                          extractValue[INVOICE_EXTRACT_KEYS.paymentAmountTotal]
                        : true) &&
                      (isDefined(
                        extractValue[INVOICE_EXTRACT_KEYS.currencyCode]
                      )
                        ? value.currency ===
                          extractValue[INVOICE_EXTRACT_KEYS.currencyCode]
                        : true)
                    }
                    formatValue={(value: { [key: string]: string }) =>
                      [
                        value[INVOICE_EXTRACT_KEYS.paymentAmountTotal]
                          ? StringUtils.formatNumber(
                              Number(
                                value[INVOICE_EXTRACT_KEYS.paymentAmountTotal]
                              )
                            )
                          : null,
                        value[INVOICE_EXTRACT_KEYS.currencyCode],
                      ]
                        .filter((e) => e)
                        .join(" ")
                    }
                    onExtractValueChange={(value, oldValue) => ({
                      amount: isDefined(
                        value[INVOICE_EXTRACT_KEYS.paymentAmountTotal]
                      )
                        ? Number(value[INVOICE_EXTRACT_KEYS.paymentAmountTotal])
                        : oldValue.amount,
                      currency: value[INVOICE_EXTRACT_KEYS.currencyCode]
                        ? value[INVOICE_EXTRACT_KEYS.currencyCode]
                        : oldValue.currency,
                    })}
                  >
                    {({ input, meta }) => (
                      <BFCurrency
                        // disabled={invoicePayed}
                        readonlyAmountField={(paymentPlan || []).length > 0}
                        hint={
                          (paymentPlan || []).length > 0
                            ? i18n.t(
                                "ra:InvoiceForm.calculatedByPaymentPlan",
                                "Durch Zahlungsplan berechnet"
                              )
                            : undefined
                        }
                        label={RA_INVOICE_MAIN_FIELDS().amountToPay.label + "*"}
                        {...FormValidators.getValidation(meta)}
                        {...input}
                      />
                    )}
                  </ExtractField>
                )}
              </FormFieldValues>
            </div>
            <div className={`field __flex-2`}>
              <ExtractField
                name={RA_INVOICE_MAIN_FIELDS().documentDate.identifier}
                validate={FormValidators.compose(FormValidators.required())}
                invoice={props.invoice}
                onHighlight={props.onHighlight}
                field={INVOICE_EXTRACT_KEYS.invoiceDate}
                checkExtractValue={(value, extractValue) =>
                  moment(value).isSame(moment(extractValue), "day")
                }
                formatValue={(value: string) => StringUtils.formatDate(value)}
              >
                {({ input, meta }) => (
                  <BFDate
                    label={RA_INVOICE_MAIN_FIELDS().documentDate.label + "*"}
                    {...FormValidators.getValidation(meta)}
                    {...input}
                    placement="bottomEnd"
                  />
                )}
              </ExtractField>
            </div>
          </div>
        </div>
        <div className={`form-row`}>
          {direction === "INCOMING" && (
            <div className={`field __flex-1`}>
              <FormFieldValues names={["paymentTypeData"]} allProps>
                {([paymentTypeData]) => (
                  <Field
                    name={RA_INVOICE_MAIN_FIELDS().paymentType.identifier}
                    validate={FormValidators.compose(FormValidators.required())}
                  >
                    {({ input, meta }) => (
                      <BFSelect
                        label={RA_INVOICE_MAIN_FIELDS().paymentType.label + "*"}
                        cleanable={false}
                        {...FormValidators.getValidation(meta)}
                        data={ACInvoiceUtils.getPaymentTypesOf(
                          props.invoice.data.type
                        )
                          .filter((e) => e.status === "active")
                          .map((value) => ({
                            value: value.id,
                            label: LanguageService.translateLabel(
                              value.displayName
                            ),
                          }))}
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);

                          if (
                            ACInvoiceUtils.checkPaymentTypeTag(
                              value,
                              InvoicePaymentTypeTags.PAYMENT_IBAN
                            )
                          ) {
                            paymentTypeData.input.onChange({
                              type: "PAYMENT_IBAN",
                              iban:
                                paymentTypeData.input.value?.iban ||
                                extractValue(INVOICE_EXTRACT_KEYS.bankIban) ||
                                "",
                              usage:
                                paymentTypeData.input.value?.usage ||
                                extractValue(INVOICE_EXTRACT_KEYS.usage) ||
                                "",
                              bic:
                                paymentTypeData.input.value?.bic ||
                                extractValue(INVOICE_EXTRACT_KEYS.bankSwift) ||
                                "",
                              paymentDueDate:
                                paymentTypeData.input.value?.paymentDueDate ||
                                extractValue(
                                  INVOICE_EXTRACT_KEYS.paymentDateDue,
                                  (value) => new Date(value)
                                ) ||
                                "",
                            });
                          } else if (
                            ACInvoiceUtils.checkPaymentTypeTag(
                              value,
                              InvoicePaymentTypeTags.PAYMENT_US_INTERNATIONAL
                            ) ||
                            ACInvoiceUtils.checkPaymentTypeTag(
                              value,
                              InvoicePaymentTypeTags.PAYMENT_US_NATIONAL
                            )
                          ) {
                            paymentTypeData.input.onChange({
                              type: InvoicePaymentTypeTags.PAYMENT_US_NATIONAL,
                              account:
                                paymentTypeData.input.value?.account ||
                                paymentTypeData.input.value?.swift ||
                                extractValue(
                                  INVOICE_EXTRACT_KEYS.bankAccountNumber
                                ) ||
                                "",
                              routing:
                                paymentTypeData.input.value?.routing || "",
                              swift:
                                paymentTypeData.input.value?.swift ||
                                extractValue(INVOICE_EXTRACT_KEYS.bankSwift) ||
                                "",
                              paymentDueDate:
                                paymentTypeData.input.value?.paymentDueDate ||
                                extractValue(
                                  INVOICE_EXTRACT_KEYS.paymentDateDue,
                                  (value) => new Date(value)
                                ) ||
                                "",
                              usage:
                                paymentTypeData.input.value?.usage ||
                                extractValue(INVOICE_EXTRACT_KEYS.usage) ||
                                "",
                            });
                          } else {
                            paymentTypeData.input.onChange({
                              type: "NONE",
                            });
                          }
                        }}
                      />
                    )}
                  </Field>
                )}
              </FormFieldValues>
            </div>
          )}
          <div className={`field __flex-1`}>
            <ExtractField
              name={RA_INVOICE_MAIN_FIELDS().invoiceId.identifier}
              validate={FormValidators.compose(FormValidators.required())}
              invoice={props.invoice}
              onHighlight={props.onHighlight}
              field={INVOICE_EXTRACT_KEYS.invoiceNumber}
            >
              {({ input, meta }) => (
                <BFInput
                  {...FormValidators.getValidation(meta)}
                  label={RA_INVOICE_MAIN_FIELDS().invoiceId.label + "*"}
                  {...input}
                />
              )}
            </ExtractField>
          </div>
          {direction === "OUTGOING" && (
            <div className={`field __flex-1`}>
              <ExtractField
                name={"dueDate"}
                validate={FormValidators.compose(FormValidators.required())}
                invoice={props.invoice}
                onHighlight={props.onHighlight}
                field={INVOICE_EXTRACT_KEYS.paymentDateDue}
                checkExtractValue={(value, extractValue) =>
                  moment(value).isSame(moment(extractValue), "day")
                }
                formatValue={(value: string) => StringUtils.formatDate(value)}
              >
                {({ input, meta }) => (
                  <BFDate
                    cleanable={false}
                    day
                    label={
                      i18n.t("ra:RAInvoiceForm.dueDate", "Fälligskeitsdatum") +
                      "*"
                    }
                    placement="bottomEnd"
                    {...FormValidators.getValidation(meta)}
                    {...input}
                  />
                )}
              </ExtractField>
            </div>
          )}
        </div>
        {direction === "INCOMING" && (
          // all payment depending fields
          <FormFieldValues names={["paymentType", "paymentPlan"]} allProps>
            {([paymentType, paymentPlan]) => (
              <>
                {
                  // check for IBAN Payment
                  ACInvoiceUtils.checkPaymentTypeTag(
                    paymentType.input.value,
                    InvoicePaymentTypeTags.PAYMENT_IBAN
                  ) && (
                    <>
                      <div className={`form-row`}>
                        <div className={`field __flex-2`}>
                          <ExtractField
                            name={RA_INVOICE_MAIN_FIELDS().usage.identifier}
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.usage}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                {...FormValidators.getValidation(meta)}
                                label={
                                  RA_INVOICE_MAIN_FIELDS().usage.label + "*"
                                }
                                {...input}
                                onChange={(value: string) => {
                                  input.onChange(value);

                                  if (
                                    (paymentPlan.input.value || []).length > 0
                                  ) {
                                    const newPaymentPlan: PaymentPlan[] = [];
                                    (paymentPlan.input.value || []).forEach(
                                      (plan: PaymentPlan) => {
                                        newPaymentPlan.push({
                                          ...plan,
                                          usage: plan.extra
                                            ? plan.usage
                                            : value,
                                        });
                                      }
                                    );
                                    paymentPlan.input.onChange(newPaymentPlan);
                                  }
                                }}
                              />
                            )}
                          </ExtractField>
                        </div>

                        <div className={`field __flex-1`}>
                          <ExtractField
                            name={
                              RA_INVOICE_MAIN_FIELDS().paymentDueDate.identifier
                            }
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.paymentDateDue}
                            checkExtractValue={(value, extractValue) =>
                              moment(value).isSame(moment(extractValue), "day")
                            }
                            formatValue={(value: string) =>
                              StringUtils.formatDate(value)
                            }
                          >
                            {({ input, meta }) => (
                              <BFDate
                                cleanable={false}
                                day
                                label={
                                  RA_INVOICE_MAIN_FIELDS().paymentDueDate
                                    .label + "*"
                                }
                                placement="bottomEnd"
                                {...FormValidators.getValidation(meta)}
                                {...input}
                              />
                            )}
                          </ExtractField>
                        </div>
                      </div>
                      <div className={`form-row`}>
                        <div className={`field __flex-1`}>
                          <ExtractField
                            name={RA_INVOICE_MAIN_FIELDS().iban.identifier}
                            validate={FormValidators.compose(
                              FormValidators.required(),
                              FormValidators.iban()
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.bankIban}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                type={"ibanInput"}
                                {...FormValidators.getValidation(meta)}
                                label={
                                  RA_INVOICE_MAIN_FIELDS().iban.label + "*"
                                }
                                {...input}
                              />
                            )}
                          </ExtractField>
                        </div>
                        <div className={`field __flex-1`}>
                          <ExtractField
                            name={RA_INVOICE_MAIN_FIELDS().bic.identifier}
                            validate={FormValidators.compose(
                              FormValidators.max(50)
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.bankSwift}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                {...FormValidators.getValidation(meta)}
                                label={RA_INVOICE_MAIN_FIELDS().bic.label}
                                {...input}
                              />
                            )}
                          </ExtractField>
                        </div>
                      </div>
                    </>
                  )
                }
                {
                  // check for IBAN Payment
                  (ACInvoiceUtils.checkPaymentTypeTag(
                    paymentType.input.value,
                    InvoicePaymentTypeTags.PAYMENT_US_INTERNATIONAL
                  ) ||
                    ACInvoiceUtils.checkPaymentTypeTag(
                      paymentType.input.value,
                      InvoicePaymentTypeTags.PAYMENT_US_NATIONAL
                    )) && (
                    <>
                      <div className={`form-row`}>
                        <div className={`field __flex-3`}>
                          <ExtractField
                            name={RA_INVOICE_MAIN_FIELDS().usage.identifier}
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.usage}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                {...FormValidators.getValidation(meta)}
                                label={
                                  RA_INVOICE_MAIN_FIELDS().usage.label + "*"
                                }
                                {...input}
                                onChange={(value: string) => {
                                  input.onChange(value);

                                  if (
                                    (paymentPlan.input.value || []).length > 0
                                  ) {
                                    const newPaymentPlan: PaymentPlan[] = [];
                                    (paymentPlan.input.value || []).forEach(
                                      (plan: PaymentPlan) => {
                                        newPaymentPlan.push({
                                          ...plan,
                                          usage: plan.extra
                                            ? plan.usage
                                            : value,
                                        });
                                      }
                                    );
                                    paymentPlan.input.onChange(newPaymentPlan);
                                  }
                                }}
                              />
                            )}
                          </ExtractField>
                        </div>
                        <div className={`field __flex-2`}>
                          <ExtractField
                            name={RA_INVOICE_MAIN_FIELDS().account.identifier}
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.bankAccountNumber}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                {...FormValidators.getValidation(meta)}
                                label={
                                  RA_INVOICE_MAIN_FIELDS().account.label + "*"
                                }
                                {...input}
                              />
                            )}
                          </ExtractField>
                        </div>
                        <div className={`field __flex-1`}>
                          <ExtractField
                            name={
                              RA_INVOICE_MAIN_FIELDS().paymentDueDate.identifier
                            }
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.paymentDateDue}
                            checkExtractValue={(value, extractValue) =>
                              moment(value).isSame(moment(extractValue), "day")
                            }
                            formatValue={(value: string) =>
                              StringUtils.formatDate(value)
                            }
                          >
                            {({ input, meta }) => (
                              <BFDate
                                cleanable={false}
                                label={
                                  RA_INVOICE_MAIN_FIELDS().paymentDueDate
                                    .label + "*"
                                }
                                placement="bottomEnd"
                                {...FormValidators.getValidation(meta)}
                                {...input}
                              />
                            )}
                          </ExtractField>
                        </div>
                      </div>

                      <div className={`form-row`}>
                        <div className={`field __flex-1`}>
                          <Field
                            name={RA_INVOICE_MAIN_FIELDS().routing.identifier}
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                            // invoice={props.invoice}
                            // onHighlight={props.onHighlight}
                            // field={INVOICE_EXTRACT_KEYS.rou}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                {...FormValidators.getValidation(meta)}
                                label={RA_INVOICE_MAIN_FIELDS().routing.label}
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div className={`field __flex-1`}>
                          <ExtractField
                            name={RA_INVOICE_MAIN_FIELDS().swift.identifier}
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                            invoice={props.invoice}
                            onHighlight={props.onHighlight}
                            field={INVOICE_EXTRACT_KEYS.bankSwift}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                {...FormValidators.getValidation(meta)}
                                label={RA_INVOICE_MAIN_FIELDS().swift.label}
                                {...input}
                              />
                            )}
                          </ExtractField>
                        </div>
                      </div>
                    </>
                  )
                }
              </>
            )}
          </FormFieldValues>
        )}

        {UnitStruct.getTagConfig(props.invoice.data.type, AssetTypes.Invoice)
          ?.length > 0 && (
          <div className={`form-row`}>
            <div className={`field __flex-3`}>
              <Field name={RA_INVOICE_MAIN_FIELDS().tags.identifier}>
                {({ input, meta }) => (
                  <EZTags
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    data={UnitStruct.getTagConfig(
                      props.invoice.data.type,
                      AssetTypes.Invoice
                    ).map((e) => ({
                      value: e.id,
                      label: LanguageService.translateLabel(e.displayName),
                      disabled: e.status === "archived",
                    }))}
                  />
                )}
              </Field>
            </div>
          </div>
        )}
      </div>
      <div className={`additional-tabs`}>
        <FormFieldValues names={["costCenter", "paymentPlan"]} allProps>
          {([costCenter, paymentPlan]) => (
            <BFTabs
              preventUnmount
              tabs={[
                ...(direction === "INCOMING"
                  ? [
                      {
                        id: "costCenter",
                        label: i18n.t(
                          "ra:InvoiceForm.CostCenter.Title",
                          "Kostenstellen"
                        ),
                        content: () => (
                          <RAInvoiceCostcenterForm
                            invoice={props.invoice}
                            withDescription
                          />
                        ),
                        error: costCenter.meta.error && costCenter.meta.touched,
                      },
                    ]
                  : []),
                {
                  id: "paymentPlan",
                  label: i18n.t(
                    "ra:InvoiceForm.Paymentplan.Title",
                    "Zahlungsplan"
                  ),
                  content: () => (
                    <RAInvoicePaymentPlanForm
                      invoice={props.invoice}
                      withDescription
                    />
                  ),
                  error: paymentPlan.meta.error && paymentPlan.meta.touched,
                },
                ...((allCustomFields?.length || 0) > 0
                  ? [
                      {
                        id: "info",
                        label: i18n.t(
                          "ra:InvoiceForm.Info.Title",
                          "Weitere Informationen"
                        ),
                        content: () => (
                          <RAInvoiceInfoForm
                            invoice={props.invoice}
                            withDescription
                          />
                        ),
                      },
                    ]
                  : []),
              ]}
            ></BFTabs>
          )}
        </FormFieldValues>
      </div>

      {isInApproval && (
        <FormFieldValues names={["entity", "objectId", "invoiceType"]}>
          {([entity, objectId, invoiceType]) => {
            if (
              valueOrDefault(invoiceType, null) !==
              valueOrDefault(props.invoice.data.invoice.invoiceType, null)
            ) {
              return (
                <LifeCycle
                  onUnmount={() => {
                    props.form?.mutators?.setValue(
                      "approvalChainChange",
                      false
                    );
                  }}
                  onMount={() => {
                    props.form?.mutators?.setValue("approvalChainChange", true);
                  }}
                >
                  <BFMessage
                    type="warning"
                    text={i18n.t(
                      "ra:InvoiceForm.InvoiceTypeChanged",
                      "Sie ändern die Rechnungsart, dadurch wird die Genehmigung neu gestartet. Die Genehmiger müssen die Rechnung erneut genehmigen."
                    )}
                  />
                </LifeCycle>
              );
            } else if (
              valueOrDefault(entity, null) !==
                valueOrDefault(props.invoice.data.entity, null) ||
              valueOrDefault(objectId, null) !==
                valueOrDefault(props.invoice.data.objectId, null)
            ) {
              return (
                <BFMessage
                  type="warning"
                  text={
                    <div>
                      <Field name="approvalChainChange">
                        {({ input, meta }) => (
                          <LifeCycle
                            onUnmount={() => {
                              input.onChange(false);
                            }}
                          >
                            <>
                              <div className={`description`}>
                                {i18n.t(
                                  "ra:InvoiceForm.ApprovalChainChange",
                                  "Durch eine Änderung des Objekts oder der Gesellschaft könnte sich die Genehmigungskette geändert haben. Wollen Sie die Genehmigung neu starten?"
                                )}
                              </div>
                              <BFToggle
                                checked={input.checked}
                                onChange={(_val, checked) =>
                                  input.onChange(checked)
                                }
                                label={i18n.t(
                                  "ra:InvoiceForm.ApprovalChainChangeToggle",
                                  "Genehmigung neustarten"
                                )}
                              />
                            </>
                          </LifeCycle>
                        )}
                      </Field>
                    </div>
                  }
                />
              );
            } else {
              return null;
            }
          }}
        </FormFieldValues>
      )}
    </div>
  );
};

export default RAInvoiceForm;

type SupplierAddress = {
  formatted: string;
  streetNumber: string;
  street: string;
  apartmentNumber: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  rawInput: string;
  countryCode: string;
  latitude: number;
  longitude: number;
};
const getContactInitialData = (invoice: RAInvoice) => {
  const direction = invoice?.data?.direction;
  const contact: Partial<OrganizationContactData> = {};
  let added = false;
  const name = invoice.extract.find(
    (e) =>
      e.identifier ===
      (direction === "INCOMING"
        ? INVOICE_EXTRACT_KEYS.supplierCompanyName
        : INVOICE_EXTRACT_KEYS.customerCompanyName)
  );
  if (name) {
    contact.displayName = name.value;
    contact.personType = "organization";
    contact.companyName = name.value;
    added = true;
  }

  const address = invoice.extract.find(
    (e) =>
      e.identifier ===
      (direction === "INCOMING"
        ? INVOICE_EXTRACT_KEYS.supplierAddress
        : INVOICE_EXTRACT_KEYS.customerBillingAddress)
  );
  if (address && address.value) {
    const addressValue = address.value as SupplierAddress;

    contact.address = [
      {
        isMain: true,
        street:
          addressValue?.street || addressValue?.streetNumber
            ? [addressValue?.street, addressValue?.streetNumber].join(" ")
            : addressValue?.rawInput || "",
        additional: addressValue?.apartmentNumber,
        city: addressValue?.city,
        zip: addressValue?.postalCode,
        country: addressValue?.country,
      },
    ];
    added = true;
  }

  const phone = invoice.extract.find(
    (e) =>
      e.identifier ===
      (direction === "INCOMING"
        ? INVOICE_EXTRACT_KEYS.supplierPhoneNumber
        : INVOICE_EXTRACT_KEYS.customerPhoneNumber)
  );
  if (phone) {
    contact.phone = [phone.value];
    added = true;
  }

  const email = invoice.extract.find(
    (e) =>
      e.identifier ===
      (direction === "INCOMING"
        ? INVOICE_EXTRACT_KEYS.supplierEmail
        : INVOICE_EXTRACT_KEYS.customerEmail)
  );
  if (email) {
    contact.emails = [email.value];
    added = true;
  }

  // TODO - should we add website for contact?
  // const website = invoice.extract.find(
  //   (e) => e.identifier === INVOICE_EXTRACT_KEYS.supplierWebsite
  // );
  // if(website) {
  //   contact.website = website.value;
  //   added = true;
  // }
  if (added) {
    return contact;
  } else {
    return null;
  }
};
