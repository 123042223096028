import classNames from "classnames";
import moment from "moment";
import AssignmentLabel from "../../../../../components/AssignmentLabel/AssignmentLabel";
import UserAvatar from "../../../../../components/AvatarComponent/UserAvatar";
import Username from "../../../../../components/AvatarComponent/Username";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import { TaskAsset } from "../../../../../model/general-assets/TaskAsset";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import { RAInvoice } from "../../RAInterfaces";
import {
  CurrentProcessEntryType,
  PastProcessEntryType,
  ProcessEntryType,
} from "./RAInvoiceProcessHistoryUtils";

interface RAInvoiceProcessHistoryEntryProps {
  entry: ProcessEntryType;
  invoice: RAInvoice;
  task?: TaskAsset;
}
const RAInvoiceProcessHistoryEntry = (
  props: RAInvoiceProcessHistoryEntryProps
) => {
  if (props.entry.type === "past") {
    return <RAInvoiceProcessHistoryEntryPast entry={props.entry} />;
  } else {
    return <RAInvoiceProcessHistoryEntryCurrent entry={props.entry} />;
  }
};

export default RAInvoiceProcessHistoryEntry;

const RAInvoiceProcessHistoryEntryPast = (props: {
  entry: PastProcessEntryType;
}) => {
  return (
    <div className={classNames(`ra-process-history-entry past`)}>
      <div className={`status-icon`}>
        {props.entry.user === undefined ? (
          <div className={`system-avatar`}>
            <BfIcon type="light" data="cash-payment-bills-1" size={"sm"} />
          </div>
        ) : (
          <UserAvatar size={38} id={props.entry.user} />
        )}
      </div>
      <div className="line" />
      <div className="content">
        <div className="type">{props.entry.statusText}</div>
        <div className={`user`}>
          {props.entry.user === undefined
            ? i18n.t("ra:InvoiceProcessHistory.System", "System")
            : <Username id={props.entry.user} /> || "anonym"}
        </div>
        {props.entry.comment && (
          <div className="comment-container">
            <div className="comment-bubble" />
            <div className="comment">{props.entry.comment}</div>
          </div>
        )}
        <div className="date">
          {moment(props.entry.date).format(i18n.t("Formats.dateTimeFormat"))}
        </div>

        {props.entry.renderExtra && props.entry.renderExtra()}
        <div className="user-or-team"></div>

        <DebugDataComponent data={props.entry} />
      </div>
    </div>
  );
};

const RAInvoiceProcessHistoryEntryCurrent = (props: {
  entry: CurrentProcessEntryType;
}) => {
  return (
    <div
      className={classNames(`ra-process-history-entry current`, {
        "has-assignments": !!props.entry.assignee,
      })}
    >
      <div className={`status-icon`}>
        <div className={`icon-container`}>
          <BfIcon type="light" data={props.entry.icon} />
        </div>
      </div>
      <div className="line" />
      <div className="content">
        <div className="type">{props.entry.statusText}</div>
        {props.entry.assignee && (
          <AssignmentLabel
            teams={props.entry.assignee.teams}
            users={props.entry.assignee.users}
          />
        )}

        {props.entry.renderExtra && props.entry.renderExtra()}
        <div className="user-or-team">{}</div>

        <DebugDataComponent data={props.entry} />
      </div>
    </div>
  );
};
