import moment from "moment";
import { nanoid } from "nanoid";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { Contact } from "../../../../../model/db/Contact";
import CacheService from "../../../../../services/CacheService";
import ContactService from "../../../../../services/ContactService";
import DataBus from "../../../../../services/DataBus";
import SubmitService from "../../../../../services/SubmitService";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import { HTTP } from "../../../../../utils/Http";
import {
  PaymentPosition,
  RentalAgreement,
  RentalAgreementUnit,
  RentalUnit,
  RentalVacancy,
  RentalVacancyAdvertisement,
  SubmitObjectPlanData,
} from "./TenantsInterfaces";
import { RentalOpos } from "./components/rental-opos/OposInterfaces";

class CBRentalServiceClass {
  async updateVacantAdvertisement(
    rentalVacancy: RentalVacancy,
    id: string,
    data: Partial<RentalVacancyAdvertisement>
  ) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: (rentalVacancy.data.advertisements || []).map(
            (advertismement) =>
              advertismement.id === id
                ? { ...advertismement, ...data }
                : advertismement
          ),

          // [
          //   ...rentalVacancy.data.advertisements,
          //   { ...data, id: nanoid() },
          // ],
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }
  async addVacantAdvertisement(
    rentalVacancy: RentalVacancy,
    data: Partial<RentalVacancyAdvertisement>
  ) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: [
            ...(rentalVacancy.data.advertisements || []),
            { ...data, id: nanoid() },
          ],
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }
  async removeVacantAdvertisement(rentalVacancy: RentalVacancy, id: string) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: (rentalVacancy.data.advertisements || []).filter(
            (e) => e.id !== id
          ),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }

  async addTimePeriodToAgreement(
    agreementId: string,
    data: {
      from: Date;
      to?: Date;
      paymentPositions: PaymentPosition[];
      reason: string;
      comment?: string;
      rentalUnits: string[];
    }
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/addTimePeriod`,
      bodyParams: {
        rentalUnitIds: data.rentalUnits,
        from: moment(data.from).toISOString(),
        to: data.to ? moment(data.to).toISOString() : undefined,
        paymentPositions: data.paymentPositions,
        reason: data.reason,
        comment: data.comment,
      },
      target: "EMPTY",
    });
    return result;
  }

  async updateRentOfAgreeement(
    agreementId,
    data: {
      rentNet: number;
      rentGross: number;
      operatingCostNet: number;
      operatingCostGross: number;
      from: Date;
      to: Date;
      reason: string;
      comment: string;
    }
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRent`,
      bodyParams: {
        rentNet: data.rentNet,
        rentGross: data.rentGross,
        operatingCostNet: data.operatingCostNet,
        operatingCostGross: data.operatingCostGross,
        from: moment(data.from).toISOString(),
        to: data.to ? moment(data.to).toISOString() : undefined,
        // reason: data.reason,
        // comment: data.comment,
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async updateRentalUnitsOfAgreement(
    agreementId: string,
    rentalUnits: RentalAgreementUnit[]
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRentalUnit`,
      bodyParams: {
        rentalUnitsInfo: rentalUnits.map((e) => ({
          rentalUnitId: e.rentalUnitId,
          from: moment(e.from).toISOString(),
          to: e.to ? moment(e.to).toISOString() : undefined,
        })),
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async submitRentalAgreement(values: any, rentalAgreement?: RentalAgreement) {
    let tenantId = null;
    if (values.helpers?.tenantAssignmentType === "create-new") {
      const tenant = (await this.submitTenant(values.createTenant)) as Contact;
      tenantId = tenant?._id;
    } else {
      tenantId = values.tenant.assetId;
    }

    const data = {
      _id: rentalAgreement?._id,
      data: {
        type: values.type,
        entity: values.entity,
        objectId: values.objectId,
        rentalUnits: values.rentalUnits.map((e) => e.assetId),
        tenant: tenantId,

        id: "" + Math.floor(Math.random() * 10000),
        displayName: values.displayName,
        moveIn: moment(values.moveIn).toISOString(),
        moveOut: values.moveOut
          ? moment(values.moveOut).toISOString()
          : undefined,
        agreementExpiration: values.agreementExpiration
          ? moment(values.agreementExpiration).toISOString()
          : undefined,
        status: "active",
        paymentPositions: values.paymentPositions?.map((position) => ({
          ...position,
          gross: values.taxable ? position.gross : 0,
        })),
        // rentNet: values.rentNet,
        // rentGross: values.taxable ? values.rentGross : 0,
        // operatingCostNet: values.operatingCostNet,
        // operatingCostGross: values.taxable ? values.operatingCostGross : 0,
        taxable: values.taxable || false,
        deposit: values.deposit || 0,
        note: values.note,
      },
    };

    // let newStatus: RentalStatus = null;
    // if (moment(values.moveIn).isBefore(moment())) {
    //   newStatus = "occupied";
    // } else {
    //   newStatus = "reserved";
    // }
    // for (const rentalUnit of values.rentalUnits) {
    //   await SubmitService.submitDataAsync({
    //     type: "asset",
    //     assetType: AssetTypes.Rental.RentalUnit,
    //     data: {
    //       _id: rentalUnit.assetId,
    //       data: {
    //         rentalStatus: newStatus,
    //       },
    //     },
    //     pushToCache: true,
    //     ignorePropChecks: true,
    //     ignoreSubmitValidation: true,
    //   });
    // }

    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalAgreement,
      data,
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalAgreement;

    DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
      objectId: result.data.objectId,
    });

    return result;
  }
  async submitTenant(values: any, tenant?: Contact) {
    const { helpers, ...formValues } = values;
    return await ContactService.submitContact(
      { contactType: ["TENANT"], ...formValues },
      tenant?._id
    );
  }
  async submitRentalUnit(values: any, rentalUnit?: RentalUnit) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalUnit,
      data: {
        _id: rentalUnit?._id,
        data: {
          ...values,
          ...(rentalUnit
            ? {}
            : {
                // initial value on creation
                rentalStatus: "vacant",
                status: "active",
              }),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalUnit;

    DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
      objectId: result.data.objectId,
    });
    return result;
  }

  async submitRentalOpos(values: any, rentalOpos: RentalOpos) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: rentalOpos?._id,
        data: {
          ...values,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async closeRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "archived",
          closed: moment().toISOString(),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async reopenRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "active",
          closed: null,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }

  async submitRentalUnitPlanData(
    data: SubmitObjectPlanData[],
    reload?: { assetType: string; id: string }
  ) {
    const result = await HTTP.post({
      url: `/rental/changeMultipleRentalUnits`,
      bodyParams: {
        data: data,
      },
      target: "EMPTY",
    });

    if (reload) {
      CacheService.getData({
        assetType: reload.assetType,
        oType: "asset",
        id: reload.id,
        forceReload: true,
        ignoreDelay: true,
      });
    }
  }
}
const CBRentalService = new CBRentalServiceClass();
export default CBRentalService;
