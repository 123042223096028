import classNames from "classnames";
import React, { SyntheticEvent } from "react";
import { Dropdown } from "rsuite";
import { isDefined } from "../../../../utils/Helpers";
import BFCheckbox from "../../forms/checkbox/BFCheckbox";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import BFButton from "../Button/BFButton";
import "./BFDropdownContent.scss";

export type DropdownButton = {
  type: "button";
  text: JSX.Element | React.ReactNode | string;
  icon?: BfIconProps;
  onSelect?: (contextItem?: any) => void;
  disabled?: boolean;
  active?: boolean;
  closeOnClick?: boolean;
  className?: string;
  suffixedAction?: DropdownButtonSuffixAction;
};

type DropdownButtonSuffixAction = {
  tooltipText: string;
  icon: BfIconProps;
  onClick: (contextItem?: any) => void;
};

export type DropdownCheck = {
  type: "check";
  text: React.ReactNode | string;
  onCheck?: (checked: boolean, contextItem?: any) => void;
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  closeOnClick?: boolean;
};
export type DropdownDivider = {
  type: "divider";
};

export type DropdownMenu = {
  type: "menu";
  items: DropdownItem[];
  text: React.ReactNode;
  icon?: BfIconProps;
  active?: boolean;
};
export type DropdownPanel = {
  type: "panel";
  style?: React.CSSProperties;
  children?: React.ReactNode;
  render?: (contextItem: any) => React.ReactNode;
};

export type DropdownItem = { identifier?: string; contextItem?: any } & (
  | DropdownCheck
  | DropdownButton
  | DropdownDivider
  | DropdownMenu
  | DropdownPanel
);

interface BFDropdownContentProps {
  items: DropdownItem[];
  withoutMenu?: boolean;
  onClose: () => void;
  style?: React.CSSProperties;
  className?: string;
  contextItem?: any;
}
const BFDropdownContent = (props: BFDropdownContentProps) => {
  const children = (
    <>
      {props.items.map((item, index) => (
        <BFDropdownItemProps
          key={item.identifier || index}
          item={item}
          onClose={props.onClose}
          contextItem={props.contextItem}
        />
      ))}
    </>
  );

  if (props.withoutMenu) {
    return children;
  }

  return (
    <Dropdown.Menu
      className={classNames("bf-dropdown-content", props.className)}
      style={props.style}
    >
      {children}
    </Dropdown.Menu>
  );
};

export default BFDropdownContent;

interface BFDropdownItemProps {
  item: DropdownItem;
  onClose: () => void;
  contextItem?: any;
}
const BFDropdownItemProps = (props: BFDropdownItemProps) => {
  switch (props.item.type) {
    case "check": {
      const usedProps = props.item as DropdownCheck;
      return (
        <Dropdown.Item
          key={props.item.identifier}
          className="dropdown-item-check"
          disabled={props.item.disabled}
          onSelect={() => {
            if (usedProps.onCheck) {
              usedProps.onCheck(!usedProps.checked, props.contextItem);
            }

            if (usedProps.closeOnClick !== false) {
              props.onClose();
            }
          }}
        >
          <BFCheckbox
            checked={usedProps.checked}
            indeterminate={usedProps.indeterminate}
          >
            {usedProps.text}
          </BFCheckbox>
        </Dropdown.Item>
      );
    }
    case "button":
      const dropDownButtonProps = props.item as DropdownButton;
      const { suffixedAction } = dropDownButtonProps;

      return (
        <Dropdown.Item
          key={props.item.identifier}
          className={`dropdown-item-button ${
            dropDownButtonProps.className || ""
          }`}
          active={dropDownButtonProps.active}
          disabled={dropDownButtonProps.disabled}
          onSelect={() => {
            dropDownButtonProps.onSelect(props.contextItem);
            if (dropDownButtonProps.closeOnClick !== false) {
              props.onClose();
            }
          }}
        >
          <div className={`dropdown-click-wrapper`}>
            {props.item.icon ? (
              <BfIcon {...props.item.icon} size="xs" />
            ) : undefined}
            <span className="dropdown-button-text">
              {dropDownButtonProps.text}
            </span>
            {dropDownButtonProps.suffixedAction && (
              <BFButton
                className="dropdown-suffixed-button"
                size="xs"
                type="button"
                appearance="link"
                tooltip={{ tooltip: suffixedAction.tooltipText }}
                icon={{
                  ...suffixedAction.icon,
                  className: "suffix-action-icon",
                }}
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  suffixedAction.onClick(props.contextItem);
                }}
              />
            )}
          </div>
        </Dropdown.Item>
      );
    case "panel":
      return (
        <Dropdown.Item
          panel
          style={props.item.style}
          key={props.item.identifier}
        >
          {isDefined(props.item.render)
            ? props.item.render(props.contextItem)
            : props.item.children}
        </Dropdown.Item>
      );
    case "divider":
      return <Dropdown.Item divider key={props.item.identifier} />;
    case "menu":
      return (
        <DropdownMenuWrapper
          item={props.item as DropdownMenu}
          onClose={props.onClose}
          contextItem={props.contextItem}
        />
      );
  }
};

const DEFAULT_RECT_TOP = -6;

const DropdownMenuWrapper = (props: {
  item: DropdownMenu;
  onClose: () => void;
  contextItem?: any;
}) => {
  const [pullLeft, setPullLeft] = React.useState(false);
  const [top, setTop] = React.useState(DEFAULT_RECT_TOP);
  const [height, setHeight] = React.useState(null);
  return (
    <Dropdown.Menu
      style={{
        top: top,
        height: height ? height : undefined,
        overflow: height ? "auto" : undefined,
      }}
      open={true}
      onAnimationStart={(e) => {
        const rect = (e.target as HTMLElement).getBoundingClientRect();
        const windowWidth = window.innerWidth;
        if (rect.x + rect.width > windowWidth) {
          setPullLeft(true);
        }

        if (rect.height > window.innerHeight) {
          setHeight(window.innerHeight);
          setTop(
            DEFAULT_RECT_TOP +
              (window.innerHeight - (rect.y + window.innerHeight))
          );
        } else {
          if (rect.y + rect.height > window.innerHeight) {
            setTop(
              DEFAULT_RECT_TOP + (window.innerHeight - (rect.y + rect.height))
            );
          }
        }
      }}
      className={classNames("dropdown-item-menu", {
        "pull-left": pullLeft,
        active: props.item.active,
      })}
      title={props.item.text}
      icon={props.item.icon ? <BfIcon {...props.item.icon} /> : undefined}
    >
      {props.item.items.map((item, index) => (
        <BFDropdownItemProps
          key={item.identifier || index}
          item={item}
          onClose={props.onClose}
          contextItem={props.contextItem}
        />
      ))}
    </Dropdown.Menu>
  );
};
